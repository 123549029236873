// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    inventoryManagementList:Record<string, any>[],
    page:number
    perPageCount:number
    isOpen:boolean
    loading:boolean
    searchProductText: string
    isModalOpen: boolean;
    modalMessage: string;
    isAdminVerified: string;
    isProfileComp: string;
    isSellerVerified: boolean;
    isNotificationBoxOpen: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InventoryManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInventoryListId :string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            inventoryManagementList:[],
            isOpen:true,
            loading:true,
            searchProductText: "",
            page:1,
            perPageCount:0,
            isModalOpen: false,
            modalMessage: "",
            isAdminVerified: 'false',
            isProfileComp: 'false',
            isSellerVerified: false,
            isNotificationBoxOpen: false
            // Customizable Area End
        };

    }


    // Customizable Area Start
    async componentDidMount() {
        const Verified = await getStorageData("isSellerVerified");
        this.setState({ isSellerVerified: Verified });
         this.getInventoryList()
        removeStorageData("addVariantCatalogueID");
        removeStorageData("editVariantCatalogueID");
        removeStorageData("editVarientID");
        removeStorageData("sub_category_id");
        removeStorageData("category_id");
        removeStorageData("currentStep");
        removeStorageData('savedImages');
        removeStorageData('savedVideos');
        let isVerified = await getStorageData('adminVerified');
        const isProfCompleted = await getStorageData("profileCompleted");
        this.setState({ isAdminVerified: isVerified, isProfileComp: isProfCompleted }, () => {
            this.checkIsVerified();
        });
      }

    checkIsVerified = async () => {
        if (this.state.isAdminVerified === 'false' && this.state.isProfileComp === 'true') {
            this.setState({
                isModalOpen: true,
                modalMessage: 'Account is not verified! Please wait for admin approval'
            });
        }
    };

        async receive(from: string, message: Message) {
            this.handleListingResponse(from, message)
        }

        handleListingResponse=(from: string, message: Message)=>{
        if (this.getInventoryListId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
            this.setState({ loading: false });
          if (apiResponse?.catalogues?.data) {
            this.setState({
                inventoryManagementList: apiResponse?.catalogues?.data,
                perPageCount:apiResponse?.meta?.total_pages,
                loading:false,
            })}
          
        }
        }
      
        handleAddProduct=async()=>{      
            const isCompleted = await getStorageData("profileCompleted")
            if(isCompleted === 'true'){
                const message = new Message(getName(MessageEnum.NavigationMessage));
                message.addData(
                    getName(MessageEnum.NavigationTargetMessage),
                    "AddNewProduct"
                );
                message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(message)
            }
            else{
                const message = new Message(getName(MessageEnum.NavigationMessage));
                message.addData(
                    getName(MessageEnum.NavigationTargetMessage),
                    "BankingDetails"
                );
                message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(message)
            }
        }
        
        toggleSideBar = () => {
            this.setState({ isOpen: !this.state.isOpen })
        }

        getInventoryList=async()=>{      
            this.setState({
                loading:true
            })
            const singupLogin =  await getStorageData("singupLogin");
        
            let endPoint = configJSON.getSellerCatalogues
            if (this.state.searchProductText) {
                endPoint += `?search=${this.state.searchProductText}&`;
                endPoint += `page=${this.state.page}&per_page=${6}`;
            } else {

                endPoint += `?page=${this.state.page}&per_page=${6}`;
            }
            const headers = {
                token: singupLogin,
            };

            const getInventoryListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getInventoryListId = getInventoryListMsg.messageId;
        
            getInventoryListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
            );
            getInventoryListMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            getInventoryListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
            );
        
            runEngine.sendMessage(getInventoryListMsg.id, getInventoryListMsg);
        
        }
        
        handleResponseDelete=()=>{
            this.setState({page: 1},()=>{
                this.getInventoryList();
            })
        }

        handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const searchProductText = event.target.value;
            this.setState({ searchProductText }, () => {
                if (searchProductText === '') {
                    this.getInventoryList();
                }
            });
        };

        handleChangePagination=(event: any, value: number)=>{
            this.setState({
                page: value
            }, () => {
                this.getInventoryList();
            });
        }    

        handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                this.getInventoryList();
            }
        };
    
        handleClosemessageModal = () => {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerDashboard");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
        };

        handleCloseModal = () => {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerDashboard");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
        };
    // Customizable Area End
}