import React from "react";

import {
  // Customizable Area Start
  Button,
  Box,
  Avatar,
  Typography,
  Dialog,
  styled,
  TextField,
  Chip,
  Modal,
  IconButton,
  InputAdornment
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider, withStyles, StyleRules } from "@material-ui/core/styles";
import {   location , profileImg, dropDown, avtarImg, createrMall, locationRecommended, liveIcon, searchIcon, locationBlackIcon, recommendTick, liveTick, livenetwork, noRecord, uploadIcon, cancelIcon, calendarIc, checkIcon } from './assets'
import MessageModalWeb from "../../../components/src/MessageModal.web";
import { withRouter } from 'react-router-dom';
import StoryListing from "../../../components/src/CustomStoryListing.web";
import Slider from "react-slick";
let config = require('../../../framework/src/config')
import StarIcon from "@material-ui/icons/Star";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
 
});

const styles: StyleRules = {
  categoryHomeWrapper:{
    marginBottom: "30px",
    padding: "0px 20px 20px 20px"
  },
  homePage: {
    display: 'flex',
    minHeight: '100vh',
    "&::-webkit-scrollbar": {
      width: "7px",
      position: 'absolute',
      left: 0,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "3px",
      background: "#D9D9D955"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "3px",
    },
   
    [theme.breakpoints.up("sm")]: {
      marginTop: "125px"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
      marginTop: "100px",
    },
    '& .hamburger': {
      display: 'none',
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    }
  },
  contenHomeHeader: {
    display: "flex",
    overflowX: "auto",
    paddingBottom: "7px",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100vw - 42px)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw"
    },
    "&::-webkit-scrollbar": {
      height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#D9D9D955"
    },
  },
  feedBtn:{ margin: "0 24px 0 0", 
    [theme.breakpoints.down("sm")]: {
      margin: "0 10px 0 0",
    },
  cursor: "pointer",
},

  contentHomeContainer: {
    padding: "0px 0 30px 30px",
    flex: 1,
    overflow: "hidden",
    '@media (max-width: 320px)': {
      padding: "10px 5px 5px 5px",
    },
  },
 
  storyHomeBorderSeen: {
    background: "grey",
    height: "76px",
    padding: "3.46px",
    width: "76px",
    display: "flex",
    borderRadius: "60px",
    [theme.breakpoints.down("xs")]: {
      height: "70px",
      width: "70px"
    }
  },

  storyHomeBorder: {
    width: "76px",
    height: "76px",
    borderRadius: "60px",
    display: "flex",
    padding: "3.46px",
    background: "linear-gradient(to bottom, #F5EA16 0%, #FF0000 100%)",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
 
  story: {
    borderRadius: "60px",
    padding: "4.00px",
    background: "#FFFFFF",
    width: "70px",
    height: "70px",
    [theme.breakpoints.down("xs")]: {
      width: "64px",
      height: "64px",
    },
  },
  sidebar: {
    '@media(min-width: 600px)': {
      borderRight: '5px solid #D9D9D9',
      marginRight: '-5px',
      paddingLeft: '16px',
      paddingRight: '8px'
    },
  },
 
  categorynamescreen: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: 'white',
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  noDataHomeFoundImg: {
    maxWidth: '200px',
    width: '100%',
    animation: 'fadeIn 2s',
    MozAnimation: 'fadeIn 2s',
    WebkitAnimation: 'fadeIn 2s',
    OAnimation: 'fadeIn 2s',
    msAnimation: 'fadeIn 2s',
  },
 
  noDataFoundHomeImgBox: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down(350)]: {
      textAlign: 'left',
      marginLeft: '-8px'
    },
  },
};



// Customizable Area End

  // Customizable Area Start
import LandingPageLatestController, {
  Props,configJSON,
  settings
} from "./LandingPageLatestController";
import Loader from "../../../components/src/Loader.web";
import CreaterMallBoxComponent from "../../../components/src/CreaterMallBoxComponent";
import ProductCard from "../../../components/src/RecommendProductComponent";
import OrderCard from "../../../components/src/OrderBoxComponent";
import ContentLoader from "react-content-loader";
import { CreatorMallData } from "./ViewAllProductsController";
import MessageModalDownloadWeb from "../../../components/src/MessageModalDownload.web";
  // Customizable Area End

class LandingPageLatest extends LandingPageLatestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  showImageSlider = () => {
    const { bannerData } = this.state;
    const banners = bannerData;

    const settings = {
      dots: true,
      infinite: banners?.length > 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: banners?.length > 1,
    };
    
    return(
      <>
        {banners?.length > 0 &&
          <CarouselContainerHome >
            <Slider {...settings}>
              {
                banners.map((crousel, index) => (
                  <SlideHome key={index}>
                    <img className="bannerImg" src={config.baseURL + crousel.attributes.banner} alt={crousel.attributes.name} data-test-id="bannerId" />
                  </SlideHome>
                ))
              }
            </Slider>
          </CarouselContainerHome>
        }
      </>

    )
  }


  renderMainView = () => {
    return this.renderHomeView();
  }

  renderHomeView = () => {
    const { classes } = this.props;
    return <>
      <Box className={classes.contenHomeHeader}>
        {this.state.allStoryHeader.map((item,userIndex) => (
           <>
           {this.state.loader &&
               <>
                   <ContentLoader
                       height={100}
                       width={100}
                       speed={1}
                       viewBox="0 0 90 90"
                   >
                       <circle cx="40" cy="40" r="40" />
                   </ContentLoader>
               </>}
       {!this.state.loader && 
          <Box className={classes.feedBtn} data-test-id = {`openStoryTestID${userIndex}`} onClick={() => this.handleClickOpen(userIndex)}>
            <Box className={item.attributes.seen?classes.storyHomeBorderSeen:classes.storyHomeBorder}>
              <img data-test-id = {`imgUserTestID${userIndex}`} className={classes.story} src={item.attributes.creator_details.profile_url?item.attributes.creator_details.profile_url:profileImg} />
            </Box>
          </Box>
  }
  </>
        ))}
          <StoryListing
          showNextButton={this.showNextButton}
          userStory={this.state.userStory}
          changeStory={this.changeStory}
          showPrevButton={this.showPrevButton}
          instaModalIndex={this.state.instaModalIndex}
          instaModal={this.state.instaModal}
          allStoryHeader={this.state.allStoryHeader}
          toggleMute={this.toggleMute}
          storyMuted={this.state.storyMuted}
          likeStory={this.likeStory}
          calculateHours={this.calculateHours}
          data-test-id="storyModalTestId"
          currentStoryIndex={this.state.currentStoryIndex}
          setSwiperRef={this.setSwiperRef}
          handleNext={() => this.handleNext()}
          handleClose={this.handleClose}
          handlePrevNext={() => this.handlePrevNext()}
          handlePrev={() => this.handlePrev()}
          storyId={this.handleStoryId}
          commentMessage={this.handleMessage}
          isMessageSent={this.state.channelUrl}      
        />
      </Box>
     
    </>
  }

  renderSideBar = () => {
    return (
      <LeftSidebar>
        <CurrentLocationLeft>
          <Typography className="currentLocationTexLeft">{configJSON.currentLocation}</Typography>
          <Box className="currentLocationBoxLeft" onClick={this.handleChangeLocation} data-test-id="currentLocationLeft">
            <Box><img src={location}  alt="location"/></Box>
            <Typography className="cityLeft">{this.state.currentLocation}</Typography>
            <Box><img src={dropDown} alt="dropDown"/></Box>
          </Box>
        </CurrentLocationLeft>
        <Button data-test-id="goToFeedTestID" onClick={this.handleGoToFeed} className="feedBtnLeft">{configJSON.goToFeedTxt}</Button>

        <CitiesDailogBox onClose={this.handleCloseLocation} open={this.state.citiesModal} style={{bottom:200}} data-test-id="citiesModalID">
          <Box className="citiesInnerContainer">
            <TextField
              variant="outlined"
              className="searchTextField"
              onChange={this.searchCity}
              InputProps={{
                startAdornment: (<img src={searchIcon} alt="searchIcon" />)
              }}
              placeholder={configJSON.searchForCity}
              data-test-id="searchCity"
            />
            <Box>
              <Box className="useCurrentLocationBox" 
              onClick={this.getGpsLocation}
              data-test-id="userCurrentLocation">
                <>
                <img src={locationBlackIcon} alt="locationIcon" />
                </>
                <Typography className="useCurrentLocationText">{configJSON.useCurrentLocation}</Typography>
              </Box>
              <Box>
                  <Typography className="popularCities">{configJSON.popularCities}</Typography>
                  <Box className="flexWrap">
                    {this.state.citiesData.popular_cities?.map((eachCity:string)=>{
                    return(
                     <Box className="eachCity"  onClick={()=>this.selectedCity(eachCity)} data-test-id="eachCity">
                      <>
                      <img src={location} alt="location" style={{marginRight:"0.25em"}}/>
                      </>
                      {eachCity}
                      </Box>)
                    })}
                  </Box>
                  <Typography className="otherCities">{configJSON.otherCities}</Typography>
                  <Box className="flexWrap">
                    {this.state.citiesData.other_cities?.map((eachCity:string)=>{
                    return(
                     <Box className="eachOtherCity" onClick={()=>this.selectedCity(eachCity)}>
                      {eachCity}
                     </Box>)
                    })}
                  </Box>
              </Box>
            </Box>
          </Box>
        </CitiesDailogBox>
      </LeftSidebar>
    )
  }


  renderCategories = () => {
    const { classes } = this.props;
    return (
      <Box  style={{marginTop:"38px"}} className="categoryHomeWrapper">
        <CategoriesHeaderHome>
          <Typography className="title">{configJSON.categoryTxt}</Typography>
          <Typography className="seeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("Categories")}>{configJSON.seeAllTxt}</Typography>
        </CategoriesHeaderHome>
        <ScrollContainerHome className="categoryScroll">
            {this.state.categoryData.length > 0  ? (
           <CategoryContainerHome>
          {
              this.state.categoryData.slice(this.state.categorySlice.startIdx, this.state.categorySlice.endIdx).map((category) => (
                <Category key={category.id} onClick={() => this.handleNavigateCategories(category.id)}>
                  <img src={config.baseURL + category.attributes.light_icon} alt={category.attributes.name} className="categoryImg" />
                  <Typography className="categoryName"  data-test-id="categoryId">{category.attributes.name}</Typography>
                </Category>
              ))
            }
          </CategoryContainerHome>
            ):(
                <Box className={classes.noDataFoundHomeImgBox}>
                <img src={noRecord} className={classes.noDataHomeFoundImg} />
                <Typography><strong>No Record was Found</strong></Typography>
                <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
              </Box>
            )}
        </ScrollContainerHome>
      </Box>
    )
  }

  renderCreatarMall = () => {
    const { CreatorMallData } = this.state;
    return (
      <Box style={{marginTop:"38px"}} className="categoryHomeWrapper">
        <CreaterHeaderHome>
          <Typography className="createrTitle">{configJSON.createrMallTxt}</Typography>
          <Typography className="createrseeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("CreatorMall")}>{configJSON.seeAllTxt}</Typography>
        </CreaterHeaderHome>
        <ScrollContainerHome className="categoryScroll">
          <CreaterContainerHome>
            {
              CreatorMallData?.map((data: CreatorMallData, index: number) => {
                return (
                  <>
                    {
                      data.attributes.creator_malls.data &&
                      <CreaterMallBoxComponent
                        data-test-id={`creatorMallId${index}`}
                        handleCardClick={() => this.handleNavigateTo("ViewCreatorProfile", data.id)}
                        onButtonClick={(event,id) => this.handleNavigateTo("ViewMall", id, event)}
                        title={data.attributes.full_name}
                        description={data.attributes.my_bio}
                        avatarUrl={data.attributes.profile_photo}
                        images={data.attributes.creator_malls.data}
                      />
                    }
                  </>
                )
              })
            }
          </CreaterContainerHome>
        </ScrollContainerHome>
      </Box>
    )
  }


  renderRecommendProduct = () => {
    const { classes } = this.props;
    return (
      <Box style={{marginTop:"38px"}} className="categoryHomeWrapper">
      <RecommendProductHome>
        <Typography className="title">{configJSON.recommendText}</Typography>
        <Typography className="seeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("RecommendProduct")}>{configJSON.seeAllTxt}</Typography>
      </RecommendProductHome>
      {this.state.recommendData.length > 0 ? (
         <ScrollRecommendContainerHome className="categoryScroll">
      {this.state.recommendData.map((item: any) => (
      <RecommendBoxOuter>
        <RecommendBox>
        <ProductCard products={item.catalogues} handleNavigate={this.handleNavigate} />
        </RecommendBox>
        <span className="navigateStore" onClick={()=> this.handleNavigateToStore(item.account.id)} data-test-id={`navigateStoreTestId${item.account.id}`}>
         <StoreInfoContainer>
        <Avatar
          variant="square"
          src={item.account?.background_image}
          alt="Store Image"
          style={{ width: "102px", height: "59px", borderRadius: "8px" }}
        />
        <Box sx={{ marginLeft: "16px", flex: 1 }}>
          <Typography variant="h6" style={{ display: "flex", alignItems: "center"  , fontSize:"16px", fontWeight: 600, color:"#4E4E4E"}}>
            {item.account?.business_name} 
            <img src={recommendTick} style={{ width:"20.01px",height:"20px", color: "#007AFF", marginLeft: "8px" }} />
          </Typography>
          <Typography style={{display:"flex", alignItems:"center", gap:"10px", fontSize:"14px", fontWeight: 300, color:"#6E6E6E"}} >
            <img style={{width:"18px", height:"18px"}} src={locationRecommended}/>
            3km, {item?.account?.address?.address_line_1}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", color: "#fbc02d" }}>
          <StarIcon />
          <Typography style={{ marginLeft: "4px", color:"#2E2E2E", fontSize:"14px", fontWeight: 500 }}>
            4.5
          </Typography>
        </Box>
      </StoreInfoContainer>
        </span>
        </RecommendBoxOuter>
       ))}
        </ScrollRecommendContainerHome >
      ):(
        <Box className={classes.noDataFoundHomeImgBox}>
        <img src={noRecord} className={classes.noDataHomeFoundImg} />
        <Typography><strong>No Record was Found</strong></Typography>
        <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
      </Box>
      )}
     
    </Box>
    )
  }

  renderLiveProduct = () => {
    const { classes } = this.props;
    return (
      <Box style={{marginTop:"38px"}} className="categoryHomeWrapper">
      <LiveProductHome>
        <Typography className="title">{configJSON.liveText}</Typography>
        <Typography className="seeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("LiveStreaming")}>{configJSON.seeAllTxt}</Typography>
      </LiveProductHome>
      {this.state.liveStreamData.length > 0 ? (
<ScrollLiveContainerHome className="categoryScroll">
        <LiveContainerHome>
           {this.state.liveStreamData.map((item: any, index: number) => (
          <Box  style={{width:"318px"}}>
            <LiveCard data-test-id={`LiveStreamingTestId${index}`} onClick={this.openLiveModal}>
              <img
                src={item.attributes.thumbnail}
                alt="Live Stream"
                 style={{borderRadius:"12px",height:"187px", width:"318px",objectFit:"cover",background: "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 35.52%, #000000 103.09%"}}
              />
              <img src={liveIcon}/>
              <LiveBadge 
              icon={
                <img
                  src={livenetwork}
                  alt="icon"
                  style={{ borderRadius: "50%", width:"14px", height:"11px" }} 
                />
              }
              label="10k" />
              
              <CardFooter>
              <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
              {item.attributes.title}
            </Typography>
            <Box style={{display:"flex", width:"100%"}}>
              <Avatar src={item.attributes.host_image} alt="User Avatar" />
                <UserInfo>
                    <Box style={{display:"flex"}}>
                    <Typography variant="subtitle2">{item.attributes.host_name}</Typography>
                    <img src={liveTick} style={{ width:"20.01px",height:"20px", color: "#007AFF", marginLeft: "8px" }} />
                    </Box>
                  <Typography variant="body2" >
                  {"@" + item.attributes.host_name.replace(/\s+/g, "")}
                  </Typography>
                </UserInfo>
            </Box>
                
              </CardFooter>
            </LiveCard>
            
          </Box>
        ))}
        <MessageModalDownloadWeb data-test-id="errorMessage1" displayPopup={this.state.openLiveStreamingModal} closeErrorPopup={this.closeLiveModal} errorMessage={""}/>
        </LiveContainerHome>
        </ScrollLiveContainerHome>
      ):(
        <Box className={classes.noDataFoundHomeImgBox}>
        <img src={noRecord} className={classes.noDataHomeFoundImg} />
        <Typography><strong>No Record was Found</strong></Typography>
        <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
      </Box>
      )}
      
        </Box>
    )
  }

 getTagDetails = (catalogue: any) => {
    if (catalogue.on_order) {
      return {
        tag: "On Order",
        tagColor: "#FFEB3B",
        tagTextColor: "#2E2E2E",
      };
    } else if (catalogue.bargain) {
      return {
        tag: "Bargain",
        tagColor: "#000000",
        tagTextColor: "#FFFFFF",
      };
    }
    return {
      tag: "",
      tagColor: "",
      tagTextColor: "",
    };
  };

  renderTopSellingProduct = () => {
    const { classes } = this.props;
    return (
      <Box style={{marginTop:"38px"}} className="categoryHomeWrapper">
      <TopSellingProductHome>
        <Typography className="title">{configJSON.TopSellingText}</Typography>
        <Typography className="seeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("TopSelling")}>{configJSON.seeAllTxt}</Typography>
      </TopSellingProductHome>
      {this.state.topSellingData.length > 0 ? (
         <ScrollTopSellingContainerHome className="categoryScroll">
        <TopSellingContainerHome>
        {this.state.topSellingData.map((item: any) => {
  const { tag, tagColor, tagTextColor } = this.getTagDetails(item.catalogue);
  return (
    <span  onClick={()=> {tag !== 'On Order' && this.handleNavigate(item.catalogue.id)}}>
    <OrderCard
      tag={tag}
      tagColor={tagColor}
      image={item.catalogue.catalogue_image}
      productName={`${item.catalogue.title.substring(0, 8)}...`}
      price={item.catalogue.selling_price}
      originalPrice={item.catalogue.mrp}
      storeImage={item.store.profile_photo}
      storeName={`${item.store.business_name.substring(0, 15)}...`}
      tagTextColor={tagTextColor}
      handleOnOrderRequestModal={this.handleOnOrderRequestModal}
      prodID={item.catalogue.id}
      isOnOrder={true}
      isFromTopSelling={true}
      handleTopSellingOnOrderReq={this.handleTopSellingOnOrderReq}
      handleNavigate={this.handleNavigate}
      data-test-id="topSellingCompTestId"
    />
    </span>
  );
})}
        </TopSellingContainerHome>
               </ScrollTopSellingContainerHome>
      ):(
        <Box className={classes.noDataFoundHomeImgBox}>
        <img src={noRecord} className={classes.noDataHomeFoundImg} />
        <Typography><strong>No Record was Found</strong></Typography>
        <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
      </Box>
      )}
     
        </Box>
    )
  }

  renderOnOrderSellingProduct = () => {
    const { classes } = this.props;
    return (
      <Box style={{marginTop:"38px"}} className="categoryHomeWrapper">
      <OnOrderProductHome>
        <Typography className="title">{configJSON.onOrderTxt}</Typography>
        <Typography className="seeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("OnOrder")}>{configJSON.seeAllTxt}</Typography>
      </OnOrderProductHome>
      {this.state.onOrderData.length > 0 ? (

         <OnOrderContainerHome className="categoryScroll">
        <OnOrderContainerContainerHome>
           {this.state.onOrderData.map((item: any) => (
            <OrderCard
            tag={item.attributes.on_order ? "On Order" : ""}
            tagColor={item.attributes.on_order ? "#FFEB3B" : ""}
            image={item.attributes.product_images ? item.attributes.product_images[0].url : ''}
            productName={item.attributes.title.substring(0, 9) + '...'}
            price={item?.attributes?.selling_price}
            originalPrice={item?.attributes?.mrp}
            storeImage={item.attributes?.host_image}
            storeName={item.attributes?.store?.business_name.substring(0, 15) + '...'}
            tagTextColor={item.attributes.on_order ? "#2E2E2E" : ""}
            handleOnOrderRequestModal={this.handleOnOrderRequestModal}
            prodID={item.id}
            isOnOrder={true}
            data-test-id="onOrderCompTestID"
            isFromTopSelling={false}
            handleTopSellingOnOrderReq={this.handleTopSellingOnOrderReq}
            handleNavigate={this.handleNavigate}
          />
        ))}
        </OnOrderContainerContainerHome>
               </OnOrderContainerHome>
      ) :(
        <Box className={classes.noDataFoundHomeImgBox}>
        <img src={noRecord} className={classes.noDataHomeFoundImg} />
        <Typography><strong>No Record was Found</strong></Typography>
        <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
      </Box>
      )}
        </Box>
    )
  }
  
  renderOnOrderRequestModal = () => {
    const { selectedFiles, imgUploadErr, quantity, descriptionErr } = this.state;
    return (
      <CustomModal
        open={this.state.isRequestModal}
        onClose={this.toggleOnOrderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="mainModalContainer">
          <Box className="modalHeaderBox">
            <Box className="modaltitle">
              <Typography className="orderDetailsText">{configJSON.orderDetailsTxt}</Typography>
            </Box>
            <img src={cancelIcon} data-test-id="cancelBtnTestId" onClick={this.toggleOnOrderModal} />
          </Box>
          <Typography className="selectedTitlText">{configJSON.selectedItemTxt} <span className="onOrderTagTxt">{configJSON.onOrderTagText}</span></Typography>
          <Box className="productDetailsBox">
            <Box className="productImageBox"><img src={`${this.state.selectedOnOrder?.productImageUrl}`} className="prodImg" /></Box>
            <Box className="productRightBox">
              <Typography className="modalTitleTxt">{this.truncatedTitle(String(this.state.selectedOnOrder?.title))}<span className="orderIdTxt">#{this.state.selectedOnOrder?.productId}</span></Typography>
              <Typography className="prodSubtitleText">{this.truncatedDescription(String(this.state.selectedOnOrder?.description))}</Typography>
            </Box>
          </Box>
          <Typography className="selectedTitlText">{configJSON.detailsTxt}</Typography>
          <TextField multiline maxRows={2} minRows={2} className="detailsTextField" placeholder="A customised abstract effect on the head phone" InputProps={{ disableUnderline: true }} value={this.state.description} onChange={this.handleDescriptionChange} data-test-id="descriptionInputTestId" />
          {descriptionErr && <Typography className="imgUploadingError">{"*Description cannot be empty."}</Typography>}
          <Typography className="selectedTitlText">{configJSON.attachmentTxt}</Typography>
          <Box className="attachmentMainContainer">
            <input type="file" multiple hidden accept=".jpeg, .jpg, .png" data-test-id="fileInputTest" className="fileInput" ref={this.fileInputRef} onChange={this.handleFileSelect} />
            <Box className="attachmentBox" onClick={this.handleUploadClick} data-test-id="attachmentBoxTestId">
              <img className="uploadImgIcon" src={uploadIcon} />
              <Typography className="uploadTxt">{configJSON.uploadImgTxt}</Typography>
              <Typography className="sizeLimitTxt">{configJSON.maxMbTxt}</Typography>
            </Box>
            {selectedFiles.length > 0 && <Box className="selectedImagesBox" data-test-id="selectedImgBoTestId">
              {selectedFiles.map((imgUrl, imgIndex) => {
                return (
                  <Box className="imgPreviewBox" key={imgIndex}>
                    <button className="removeIcon" data-test-id={`removeIconTestId${imgIndex}`} onClick={() => this.handleRemoveImage(imgIndex)}><img src={cancelIcon} className="closeIconImg" /></button>
                    <img src={URL.createObjectURL(imgUrl)} className="previewImg" />
                  </Box>
                )

              })}
            </Box>}
          </Box>
          {imgUploadErr && <Typography className="imgUploadingError">{imgUploadErr}</Typography>}

          <Box className="quantityContainer">
            <Typography className="selectedTitlText">{"Quantity"}</Typography>
            <Box className="quantityBox">
              <Box className="quantityBtnBox">
                <RemoveCircleOutlineIcon className="quantityIcons" data-test-id="deleteBtnTestId" onClick={() => this.updateOrDeleteItem(quantity, false)} />
                <Typography data-test-id="quantityTxtTestId">{quantity}</Typography>
                <AddCircleOutlineIcon data-test-id="addQuantityBtnTestId" className="quantityIcons" onClick={() => this.updateOrDeleteItem(quantity, true)} />
              </Box>
            </Box>
          </Box>

          <Typography className="selectedTitlText">{configJSON.expectedDatetxt}</Typography>
          <DatePicker
            selected={this.state.expectedDate}
            onChange={this.handleDateChange}
            minDate={new Date()}
            dateFormat="dd/MM/yyyy"
            customInput={
              <TextField
                variant="outlined"
                placeholder="Select date"
                className="inputDateField"
                value={this.state.expectedDate ? this.state.expectedDate.toLocaleDateString() : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton >
                        <img src={calendarIc} alt="" className="calenderIcon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            popperPlacement="top-start"
            placeholderText="Select Date"
          />
          <Box className="submitBtnBox">
            <Button className="submitOrderBtn" data-test-id="submitTestId" onClick={this.handleOnClickSubmit}>{configJSON.submitTxt}</Button>
          </Box>
        </Box>
      </CustomModal>
    )
  };

  renderSuccessModal = () => {
    return (
      <SuccessCustomModal
        open={this.state.isSuccessModal}
        onClose={this.toogleSuccessModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="mainSuccessModalContainer">
          <Box className="successModalHeader">
            <Box>
              <Typography className="modalTitle">{configJSON.requestSuccessTxt}</Typography>
            </Box>
            <IconButton className="cancelIconBox">
              <img src={cancelIcon} data-test-id="cancelBtnTestId" onClick={this.toogleSuccessModal} />
            </IconButton>
          </Box>
          <Box className="bottomModalBox">
            <Box className="tickBox"><img src={checkIcon} /></Box>
            <Box className="modalBodyBox">
              <Typography className="modalBodyTxt">{configJSON.modalBodyTxt}</Typography>
            </Box>
            <Button className="goToChatBtn" data-test-id="goToChatTestId" onClick={() => this.handleNavigateToChat()}>{configJSON.goToChatTxt}</Button>
          </Box>
        </Box>
      </SuccessCustomModal>
    )
  };

  renderOnBargainSellingProduct = () => {
    const { classes } = this.props;
    return (
      <Box style={{marginTop:"38px"}} className="categoryHomeWrapper">
      <BargainProductHome>
        <Typography className="title">{configJSON.onBargainTxt}</Typography>
        <Typography className="seeAll"  data-test-id="categorySeeAllId" onClick={() => this.handleNavigateToSeeAll("Bargain")}>{configJSON.seeAllTxt}</Typography>
      </BargainProductHome>
      {this.state?.bargainData?.length > 0 ? (
        <ScrollBargainContainerHome className="categoryScroll">
        <BargainContainerHome>
           {this.state.bargainData.map((item: any) => (
            <span data-test-id={`navigateProdTestId${item.id}`} onClick={()=> this.handleNavigate(item.id)}>
            <OrderCard
            tag={item.attributes.bargain ? "Bargain" : ""}
            tagColor={item.attributes.bargain ? "#000000" : ""}
            image={item.attributes.product_images ? config.baseURL + item.attributes.product_images[0].url : ''}
            productName={item.attributes.title.substring(0, 9) + '...'}
            price={item?.attributes?.selling_price}
            originalPrice={item?.attributes?.mrp}
            storeImage={item.attributes?.host_image}
            storeName={item.attributes?.store?.business_name.substring(0, 15) + '...'}
            tagTextColor={item.attributes.bargain ? "#FFFFFF" : ""}
            handleOnOrderRequestModal={this.handleOnOrderRequestModal}
            prodID={item.id}
            isOnOrder={false}
            isFromTopSelling={false}
            handleTopSellingOnOrderReq={this.handleTopSellingOnOrderReq}
            handleNavigate={this.handleNavigate}
          />
          </span>
        ))}
        </BargainContainerHome>
      </ScrollBargainContainerHome>
      ):(
        <Box className={classes.noDataFoundHomeImgBox}>
        <img src={noRecord} className={classes.noDataHomeFoundImg} />
        <Typography><strong>No Record was Found</strong></Typography>
        <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
      </Box>
      )}
        </Box>
    )
  }
  

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { loading, message, messageModel } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div id="myref"></div>
        <Loader loading={loading} />
        <Box className={classes.homePage}>
          {this.renderSideBar()}
          <Box className={classes.contentHomeContainer} data-test-id="mainContainer">
            {
              this.renderMainView()
            }
             {this.showImageSlider()}
             {this.renderCategories()}
             {this.renderCreatarMall()}
             {this.renderRecommendProduct()}
             {this.renderLiveProduct()}
             {this.renderTopSellingProduct()}
             {this.renderOnOrderSellingProduct()}
             {this.renderOnBargainSellingProduct()}
             {this.renderOnOrderRequestModal()}
             {this.renderSuccessModal()}
          </Box>
        </Box>
        <MessageModalWeb
          displayPopup={messageModel}
          closeErrorPopup={this.closeMessageModal}
          errorMessage={message} />
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const images = [
  { src: createrMall, alt: "Image 1" },
  { src: createrMall, alt: "Image 2" },
  { src: createrMall,  alt: "Image 3" },
  { src: createrMall, alt: "Image 4" },
  { src: createrMall, alt: "Image 5" },
];
const LeftSidebar = styled(Box)(({ theme }) => ({
  width: "15em",
  borderRight: "5px solid #D9D9D94D",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  flexShrink: 0,
  textAlign: "center",
  display: "block",
  "& .feedBtnLeft": {
    backgroundColor: "#F5EA16",
    color: "#000",
    borderRadius: "36px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "4px",
    width: "194px",
    height: "33px",
    marginTop: "45px",
  },
  [theme.breakpoints.down("xs")]: {
    display: "none",
  },
}));

const CurrentLocationLeft = styled(Box)({
  "& .currentLocationTexLeft":{
      fontFamily: 'Poppins !important',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '18px',
      marginBottom:"1em"
     
  },
  "& .currentLocationBoxLeft":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    cursor:"pointer"
  },  "& .cityLeft":{
    marginRight:"1.1em",
    marginLeft:"0.32em"
  },

  
})

const CarouselContainerHome = styled(Box)({
  position: "relative",
  width: "95%",
  height: "300px",
  marginBottom: "30px",
  marginTop: "61px",
  "@media(max-width: 1150px)":{
    marginTop: "20px"
  },
  padding: "0 0px",
  "& .slick-dots":{
    bottom: "12px"
  },
  "& .slick-dots li":{
    width: "11px",
    height: "8px",
  },
  "& .slick-dots .slick-active":{
    width: "23px",
  },
  "& .slick-dots li button::before":{
    content: "''",
    backgroundColor: "#D9D9D9",
    width: "100%",
    height: "100%",
    borderRadius: "100px",
    opacity: 1
  },
  "& .slick-dots li.slick-active button::before":{
    backgroundColor: "#2E2E2E",
  }
})

const SlideHome = styled(Box)({
  width: "100%",
  height: "300px",
  borderRadius: "20px",
  overflow: "hidden",
  "& .bannerImg":{
    objectFit: "fill",
    height: "100%",
    width: "100%"
  },
  "& .bannerContent":{ 
    position: "absolute", 
    top: "20px", 
    padding: "20px", 
    marginLeft: "20px" 
  },
  "& .bannerTitle":{ 
    fontSize: "24px", 
    fontWeight: 700, 
    color: "#FFF", 
    width: 400, 
    lineHeight: "36px" 
  },
  "& .bannerSubTitle":{ 
    fontSize: "18px", 
    fontWeight: 400, 
    color: "#FFF", 
    lineHeight: "16px", 
    marginTop: 10 
  },
  "& .shopNowContainer":{ 
    display: "flex", 
    alignItems: "center", 
    gap: "2px",
    fontSize: "18px", 
    lineHeight: "27px", 
    marginTop: 30, 
    fontWeight: 700, 
    color: "#FFF" 
  }
})

const CategoriesHeaderHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const RecommendProductHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})


const LiveProductHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const TopSellingProductHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const BargainProductHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const OnOrderProductHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .title":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .seeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const CreaterHeaderHome = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width:"95%",
  gap: "20px",
  "& .createrTitle":{
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: 600,
    color: "#2E2E2E"
  },
  "& .createrseeAll":{
    textDecoration: "underline",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    color: "#2E2E2E",
    cursor: "pointer"
  }
})

const ScrollContainerHome = styled(Box)({
  overflowX: "hidden",
  "&:hover":{
    overflowX: "scroll",
  },
  "&::-webkit-scrollbar": {
    height: "6px", 
  },
  scrollbarWidth: "thin",
  marginTop: "20px",
})

const ScrollRecommendContainerHome = styled(Box)({
    marginTop: "20px",
    width: "100%", 
    display: "flex", 
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
    overflowX: "hidden",
    "&:hover":{
      overflowX: "scroll",
    },
    scrollbarWidth: "thin",
})

const ScrollLiveContainerHome = styled(Box)({
    marginTop: "20px",
    width: "100%", 
    display: "flex", 
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
    overflowX: "hidden",
    "&:hover":{
      overflowX: "scroll",
    },
    scrollbarWidth: "thin",
})

const ScrollTopSellingContainerHome = styled(Box)(() => ({
  marginTop: "20px",
  width: "100%", 
  display: "flex", 
  gap: "15px",
  paddingBottom: "20px",
  boxSizing: "border-box",
  overflowX: "hidden",
  "&:hover":{
    overflowX: "scroll",
  },
  scrollbarWidth: "thin",
}));

const ScrollBargainContainerHome = styled(Box)({
  marginTop: "20px",
  width: "100%", 
  display: "flex", 
  gap: "15px",
  paddingBottom: "20px",
  boxSizing: "border-box",
  overflowX: "hidden",
  "&:hover":{
    overflowX: "scroll",
  },
  scrollbarWidth: "thin",
})

const OnOrderContainerHome = styled(Box)({
  marginTop: "20px",
  width: "100%", 
  display: "flex", 
  gap: "15px",
  paddingBottom: "20px",
  boxSizing: "border-box",
  overflowX: "hidden",
  "&:hover":{
    overflowX: "scroll",
  },
  scrollbarWidth: "thin",
})

const BargainContainerHome = styled(Box)({
  display: "flex",
  gap: "20px",
  padding: "2px",
  whiteSpace: "nowrap",
  flexWrap: "nowrap", 
})

const RecommendBox = styled(Box)({
  display: "flex", 
  overflowX: "hidden",
  "&:hover":{
    overflowX: "scroll",
  },
  scrollbarWidth: "thin",
  gap: "16px",
  padding: "16px",
  whiteSpace: "nowrap",
  flexWrap: "nowrap",
})  

 

const CategoryContainerHome = styled(Box)({
  display: "grid",
  gap: "15px",
  gridTemplateColumns: "repeat(5 , 1fr)",
  paddingBottom: "20px",
  minWidth: "1392px"
})

const CreaterContainerHome = styled(Box)({
  display: "grid",
  gap: "15px",
  gridTemplateColumns: "repeat(5 , 1fr)",
  paddingBottom: "20px",
  minWidth: "1392px"
})


const Category = styled(Box)({
  width: "100%",
  marginBottom: "15px",
  "& .categoryImg":{
    width: "100%",
    height: "140px",
    borderRadius: "8px"
  },
  "& .categoryName":{
    textAlign: "center",
    marginTop: "12px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#4E4E4E"
  }
})


const RecoProd = new Array(4).fill({
  discountText: '20% OFF' ,
  imageUrl: `https://images.rawpixel.com/image_800/cHJpdmF0ZS9zY3Iv...`, 
  title: "Headphone v2.01",
});

const StoreInfoContainer = styled(Box) ({
  width:"542px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F6F6F6",
  borderRadius: "8px",
  marginBottom: "16px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  padding:"9px"
});

const RecommendBoxOuter = styled(Box)({
  display: "flex", 
  flexDirection:"column",
  gap: "16px",
  padding: "16px",
  whiteSpace: "nowrap",
  flexWrap: "nowrap",
  minWidth:"574px",
  border:"1px solid #6E6E6E33",
  borderRadius:"12px"
}) 

const LiveCard = styled(Box)({
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '12px',
  overflow: 'hidden',
});

const LiveBadge = styled(Chip)({
  position: "absolute",
  top: "12px",
  left: "10px",
  backgroundColor: "#e63946",
  color: "#fff",
  fontWeight: 700,
  width:"57px", height:"24px"
});


const CardFooter = styled(Box)({
   display: "flex",
   flexDirection:"column",
  justifyContent: "flex-start",
  width:"100%"
})


const UserInfo = styled(Box)({
 marginLeft: "8px"
})
 

const LiveContainerHome = styled(Box)({
    display: "flex",
    gap: "16px",
    padding: "2px",
    whiteSpace: "nowrap",
    flexWrap: "nowrap", 
  minWidth: "1596px",
})

const TopSellingContainerHome = styled(Box)({
  display: "flex",
  gap: "20px",
  padding: "2px",
  whiteSpace: "nowrap",
  flexWrap: "nowrap", 
});

const OnOrderContainerContainerHome = styled(Box)({
  display: "flex",
  gap: "20px",
  padding: "2px",
  whiteSpace: "nowrap",
  flexWrap: "nowrap", 
})

const CitiesDailogBox = styled(Dialog)({

  "&. MuiDialog-paperWidthSm":{
  maxWidth:"1030px !important",
  },
 "& .citiesInnerContainer":{
  height:"29em",
  width: "600px",
  padding:'1em'
 },
 "& .searchTextField":{
  width:"100% !important",
},
"& .useCurrentLocationBox":{
  display:"flex",
  margin:"1em 0em 2em 0em",
  cursor: "pointer"
},
"& .useCurrentLocationText":{
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    textDecoration:"underline",
    marginLeft:"0.5em"
},
"& .popularCities":{
  fontFamily: 'Poppins !important',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  
},
"& .otherCities":{
  fontFamily: 'Poppins !important',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  marginTop:"1em"
},
"& .eachOtherCity": { 
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    margin: "0.5em",
    cursor:"pointer"
  },
"& .eachCity":{
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  padding:"0.5em 1em",
  borderRadius:"8px",
  border:"1px solid lightgray",
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
   margin:"0.5em",
  boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
  cursor:"pointer"
},

"& .flexWrap":{
  display:"flex",
  flexWrap:"wrap"
}


}) 

const CustomModal = styled(Modal)({
  "& .mainModalContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    maxWidth: "350px",
    width:"100%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    borderRadius: "14px",
    padding: "10px 24px",
    height: "610px",
    overflowY: "scroll",
    scrollbarWidth: "none"
  },
  "& .mainModalContainer::-webkit-scrollbar": {
    width: "0px"
  },
  "& .modalHeaderBox":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginTop:"10px"
  },
  "& .orderDetailsText":{
    fontSize:"16px",
    fontWeight:600
  },
  "& .productDetailsBox":{
    display:"flex",
    gap:"12px",
    marginTop:"16px"
  },
  "& .productRightBox":{
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    gap:"6px"
  },
  "& .productImageBox":{
    width:"100%",
    height:"100%",
    maxWidth:"100px",
  },
  "& .prodImg":{
    width:"100%",
    height:"100%",
    objectFit:"contain",
    maxWidth:"100px",
    maxHeight:"100px"
  },
  "& .modalTitleTxt":{
    fontWeight: 500,
  },
  "& .selectedTitlText":{
    fontWeight: 600,
    marginTop:"16px"
  },
  "& .onOrderTagTxt": {
    padding: "4px 8px 4px 8px",
    background: "#FFD70233",
    fontSize: "12px",
    lineHeight: "18px",
    borderRadius: "4px",
    color: "#555555B2",
    fontWeight: 500,
    marginLeft: "6px"
  },
  "& .orderIdTxt":{
    fontWeight:400,
    color: "#55555580",
    marginLeft:"22px",
    fontSize:"12px",
  },
  "& .prodSubtitleText":{
    color:" #8A8A8A",
    fontWeight:400,
    fontSize:"12px"
  },
  "& .detailsTextField":{
    borderRadius:"8px",
    border: "0.5px solid #000000",
    width:"100%",
    marginTop:"10px",
    padding:"6px 8px",
    "& .MuiInputBase-inputMultiline":{
      color: "#444444CC",
      fontSize:"12px",
    },
    outline:"none"
  },
  "& .attachmentBox":{
    border: "0.5px dashed #000000",
    maxWidth:"107px",
    minHeight:"101px",
    borderRadius:"8px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    gap: "6px",
    marginTop: "10px",
    width: "100%",
  },
  "& .attachmentMainContainer": {
    display: "flex",
    alignItems: "center",
    gap: "28px"
  },
  "& .selectedImagesBox": {
    display: "flex",
    gap: "14px",
    flexWrap: "wrap",
    width: "fit-content",
    scrollbarWidth: "none"
  },
  "& .previewImg": {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  "& .imgPreviewBox": {
    position: "relative",
    width: "60px",
    height: "60px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    objectFit: "cover",
  },
  "& .removeIcon": {
    border: "1px solid #E6E6E6",
    borderRadius: "50%",
    position: "absolute",
    backgroundColor: "#F3F3F3",
    top: "-15px",
    right: "-12px",
    "&:hover": {
      backgroundColor: "#CCC6C6",
    },
    padding:"4px"
  },
  "& .closeIconImg":{
    width:"12px",
    height:"12px"
  },
  "& .imgUploadingError": {
    color: "red",
    fontSize: "12px",
    marginTop: "6px"
  },
  "& .quantityContainer": {
    display: "flex",
    alignItems: "center",
    padding: "4px 0px",
    justifyContent: "space-between"
  },
  "& .quantityBtnBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    borderRadius: '35px',
    maxWidth: '100px',
    width: '100%',
    marginTop: "13px",
    gap: "6px"
  },
  "& .uploadTxt":{
    fontSize:"12px",
    fontWeight:600,
    color:"#555555",
    textDecoration:"underline",
    cursor:"pointer"
  },
  "& .sizeLimitTxt":{
    fontSize:"10px",
    fontWeight:500,
    color:"#555555",
    textDecoration:"underline",
    cursor:"pointer"
  },
  "& .uploadImgIcon":{

  },
  "& .submitOrderBtn":{
    minWidth:"187px",
    minHeight:"40px",
    borderRadius:"50px",
    background:"#F5EA16",
    textTransform:"none",
    marginTop:"6px",
    '&:hover': {
      backgroundColor: '#F5EA16',
      cursor:"pointer"
    },
  },
  "& .submitBtnBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginTop:"16px",
    marginBottom:"6px"
  },
  "& .inputDateField":{
    marginTop:"10px",
    border: "1px solid #000000",
    borderRadius:"8px",
    minHeight:"48px",
    '&:hover': {
      border: "1px solid #000000",
      cursor:"pointer"
    },
  },
  "& .calenderIcon":{
    width:"24px",
    height:"24px"
  }
});
const SuccessCustomModal = styled(Modal)({
  "& .mainSuccessModalContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    maxWidth: "331px",
    width: "100%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    borderRadius: "14px",
    padding: "20px 30px",
  },
  "& .successModalHeader": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderBottom: "1px solid #E9E9E9",
    paddingBottom: "18px"
  },
  "& .modalTitle": {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
  },
  "& .cancelIconBox": {
    marginLeft: "30px"
  },
  "& .bottomModalBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    gap: "22px"
  },
  "& .tickBox": {
    width: "42px",
    height: "42px",
    borderRadius: "100px",
    backgroundColor: "#F5EA16",
    marginTop: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .modalBodyTxt": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    margin: "0px 10px"
  },
  "& .goToChatBtn": {
    maxWidth: "167px",
    maxHeight: "40px",
    width: "100%",
    height: "100%",
    borderRadius: "50px",
    background: "#F5EA16",
    textTransform: "none",
    marginTop: "6px",
    '&:hover': {
      backgroundColor: '#F5EA16',
      cursor: "pointer"
    },
  },
});

export default withStyles(styles)(withRouter(LandingPageLatest))
export { LandingPageLatest }
// Customizable Area End
