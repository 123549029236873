import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  whatWeAreData: any;
    performanceCounterData: any;
    aboutPlateformData: any;
    categoriesData: any;
    meetTheCreatersData: any;
    landingPageBannerData: any;
    bottomImage:any;
    isVisibleModal: boolean;
    isOpen: boolean;
    modalOpen: boolean;
    displaySection: string;
    videoTutorial: any;
    selectedVideo: string;
    faqData: any;
    openModalVideoPlayer: boolean
    analyticsData: any;
    sellerEmailVerified: boolean;
    sellerPhoneVerified: boolean;
    sellerAdminVerified: boolean;
    sellerBusinessPopup: boolean;
    sellerActivities: boolean;
    sellerBusinessData: any;
    sellerAddress: any;
    sellerBankDetail: any;
    sellerProfileName: string;
    sellerBusinessName: string;
    sellerProfilePercentage: number;
    sellerProfileRedirect: string;
    videoTitleWrapText: string;
    generalFaqData: any;
    generalAboutData: any;
    generalPrivacyData: any;
    generalTermsData: any;
    openTunedBox: boolean;
    isNotificationBoxOpen: boolean;
    allNotifications: any;
    readNotificatons: any;
    openNotificationBox:boolean
    selectedTab: number;
    allNotification: boolean;
    emailNotification: boolean;
    pushNotification: boolean;
    policyOpen:boolean
    policyOrTermsData:Object
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetWhatWeAre: any;
  apiGetPerformanceCounter: any;
  apiAboutPlateform: any;
  apiGetCategories: any;
  apiMeetTheCreaters: any;
  apiLandingPageBanner: any;
  apiBottomImage:any;
  apiGetVideoTutorial: any;
  apiGetSellerFaq: any;
  apiGetSellerAnalytics: any;
  apiGetSellerProfileData: any;
  apiUpdateBusinessPopup: any;
  apiGetGeneralFaq: any;
  apiGetAboutData: any;
  apiGetPrivacyData: any;
  apiGetTermsData: any;
  getAllNotifiction: any;
  readAllNotification: any;
  sellerPolicyId:string=""
  enableOrDisablePostnotificationsId:string=""
  getNotificationSettingsId:string=""
  getSupportId: string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      
      // Customizable Area Start
      whatWeAreData: [],
      enableField: true,
      performanceCounterData: '',
      aboutPlateformData: [],
      categoriesData: [],
      meetTheCreatersData: [],
      landingPageBannerData: [],
      bottomImage: [],
      isVisibleModal: true,
      isOpen: true,
      modalOpen: false,
      displaySection: '',
      videoTutorial: [],
      selectedVideo: "",
      faqData: [],
      openModalVideoPlayer: true,
      analyticsData: [],
      sellerEmailVerified: true,
      sellerPhoneVerified: true,
      sellerAdminVerified: true,
      sellerBusinessPopup: true,
      sellerActivities: true,
      sellerBusinessData: null,
      sellerAddress: null,
      sellerBankDetail: null,
      sellerProfileName: '',
      sellerBusinessName: '',
      sellerProfilePercentage: 0,
      sellerProfileRedirect: '',
      generalFaqData: [],
      generalAboutData: null,
      videoTitleWrapText: '',
      generalPrivacyData: null,
      generalTermsData: null,
      openTunedBox: true,
      isNotificationBoxOpen: false,
      allNotifications: [],
      readNotificatons:[],
      openNotificationBox: true,
      selectedTab: 1,
      allNotification: true,
      emailNotification: true,
      pushNotification: true,
      policyOpen:false,
      policyOrTermsData:{},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.sellerPolicyId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (apiResponse?.data) {
        this.setState({
            policyOrTermsData:apiResponse?.data?.attributes,
           
        })
      }
    }
    if (this.getNotificationSettingsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (apiResponse?.notifications_settings) {
        const { push_notification, email_notification } = apiResponse.notifications_settings;
        this.setState({
          pushNotification: push_notification,
          emailNotification: email_notification,
          allNotification: push_notification && email_notification
        })
      }
    }
    if (this.enableOrDisablePostnotificationsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (apiResponse?.notifications_settings) {
       this.getNotificationSettingsData()
      }
    }
    if (this.getSupportId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (apiResponse?.data) {
        this.setState({
          faqData:apiResponse?.data
        })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

getAllNotification = async () => {
    const headers = {
        "Content-Type": "application/json",
        token: await getStorageData('singupLogin')
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotifiction = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAllNotifications}`
    );


    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.receive = this.receive.bind(this);
}
async componentDidMount() {
 this.getAllNotification() //to maintain session management temporary 
 this.getNotificationSettingsData()
 this.getSupportData()
}

toggleSideBar = () => {
  this.setState({ isOpen: !this.state.isOpen })
}


handleLogout=()=>{
  try {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("profileCompleted", "false");
    this.props.navigation.navigate("GetStartedLogin");

    history.replaceState(null, "", "/");
    setTimeout(() => {
      history.pushState(null, "", "/");
    }, 100);
    window.onpopstate = () => {
      history.pushState(null, "", "/");
    };
  } catch (error) {
    console.error("Failed to sign out", error);
  }
  window.addEventListener('popstate', (event) => {
    history.pushState(null, "", "/");
  });
}

handleOnOff = (fieldName: keyof S) => (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const updatedState: Partial<S> = {};

  if (fieldName === "allNotification") {
    if (event.target.checked) {
      // If the first switch is turned on, turn on switches 2 and 3
      updatedState.allNotification = true;
      updatedState.emailNotification = true;
      updatedState.pushNotification = true;
    } else {
      // If the first switch is turned off, turn off switches 2 and 3
      updatedState.allNotification = false;
      updatedState.emailNotification = false;
      updatedState.pushNotification = false;
    }
  } else {
    // For switches 2 and 3
    if (!event.target.checked) {
      // If either switch 2 or switch 3 is turned off, turn off the first switch
      updatedState[fieldName] = false;
      updatedState.allNotification = false;
    } else {
      // If either switch 2 or switch 3 is turned on
      updatedState[fieldName] = true;
      // If both switches 2 and 3 are turned on, turn on the first switch
      if (fieldName === "emailNotification" && this.state.pushNotification) {
        updatedState.allNotification = true;
      }
      if (fieldName === "pushNotification" && this.state.emailNotification) {
        updatedState.allNotification = true;
      }
    }
  }

  this.setState(updatedState as Pick<S, keyof S>,()=>{
    this.handlePostCallForNotification()
  });
  
};

handlePostCallForNotification =async()=>{

  const singupLogin =  await getStorageData("singupLogin");
  const headers = {token: singupLogin};
  const {emailNotification,pushNotification} = this.state

  const formData = new FormData()
  formData.append(`push_notification`,pushNotification + "");  
  formData.append(`email_notification`,emailNotification + "");  


  const postCallForNotificationMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.enableOrDisablePostnotificationsId = postCallForNotificationMsg.messageId;

  postCallForNotificationMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.notificationsSettingsEndpoint
  );
  postCallForNotificationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    postCallForNotificationMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  postCallForNotificationMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  runEngine.sendMessage(postCallForNotificationMsg.id, postCallForNotificationMsg);
  this.receive = this.receive.bind(this);
}

  
handlePolicyorTermsClose = () => {
  this.setState({ policyOpen: false })
}

handlePolicyOrTerms =async (value:string) =>{
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const endpoint = value === "policy"?configJSON.sellerPolicyEndPoint:configJSON.sellerTermsEndPoint
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.sellerPolicyId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    headers
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.receive = this.receive.bind(this);
  this.setState({ policyOpen: true });
}

 getNotificationSettingsData = async () => {
  const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('singupLogin')
  };
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getNotificationSettingsId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationSettingEndPoint
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.receive = this.receive.bind(this);
}

getSupportData = async () => {
  const headers = {
      "Content-Type": "application/json",  
  };
  const requestSupportMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSupportId = requestSupportMessage.messageId;

  requestSupportMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSellerSupportEndpoint
  );
  requestSupportMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
  );
  requestSupportMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestSupportMessage.id, requestSupportMessage);
  this.receive = this.receive.bind(this);
}

  handleHelpAndSupport = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "HelpAndSupportForm");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  // Customizable Area End
}
