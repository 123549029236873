import React from "react";
// Customizable Area Start
import AddProductController, { ProductData, Props, configJSON } from "./AddProductController";
import { withStyles, Theme, styled } from "@material-ui/core/styles";
import { Button, Typography, Box, TextField, Dialog, DialogTitle, DialogContent, InputAdornment } from "@material-ui/core";
import { backgroundBanner, close, group } from "./assets";
import { backButton } from "../../InventoryManagement3/src/assets";
import { imgSearch } from "../../CustomisedOrderStatus/src/assets";
import { Pagination } from "@material-ui/lab";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";
import { success } from "../../interactivefaqs/src/assets";
let config = require('../../../framework/src/config');
export const webStyles = (theme: Theme) => ({
    uploadImagesMainContainer: {
        padding: "0em 5em",
        minHeight:"70vh",
    },
    noProductContainer: {
        Font: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#444444"
    },
    backIcon:{
        marginRight:"12px"
    },
    saveButton: {
        width: '21em',
        height: '3em',
        background: 'white',
        borderRadius: "25px",
        color: '#444',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        border: "1px solid #444",
        pointer: "cursor",
        textTransform: "none" as const,
        margin: "20px 0"
    },
    addProductContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2em 0"
    },
    productTextContainer: {
        Font: "Poppins",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#555555"
    },
    backButtonNavigation: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent:"center",
        cursor: "pointer",
    },
    popUpContent: {
        color: 'black',
        fontSize: '18px',
        padding: '10px',
        textAlign: 'center' as 'center'
    },
    dialogBoxContainer: {
        cursor: 'pointer',
        position: 'absolute' as 'absolute',
        top: '16px',
        right: '16px'
    },
    outlinedInputStyle: {
        maxWidth: '525px',
        width: '100%',
        height: '48px',
        fontSize: '14px',
        color: '#44444480',
        boxShadow: '-7px 5px 9px -1px rgba(0, 0, 0, 0.10)',
        borderRadius: '70px',
        '& .MuiOutlinedInput-input': {
            padding: '16px 14px 16px 0px',
            fontSize: '14px',
            color: '#444444',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
            border: 'none'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
    },
});
// Customizable Area End

export class AddProduct extends AddProductController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderAddProduct = () => {
            const { productData, totalPages, pageNumber } = this.state;
        return (
            <Box>
                <InventoryContainer data-test-id="invSectionTestID">
                    <InventoryList>
                        {productData.length > 0 ? productData.map((prodItem, prodIdx) => (
                            <li key={prodIdx}>
                                <StyledCheckBox>
                                    <input
                                        checked={!!this.state.checkedItems[prodItem.id]}
                                        type="checkbox"
                                        data-test-id={`inputCheckTestId${prodIdx}`}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>)=> this.handleSelectProd(event, prodItem, prodItem.id)}
                                    />
                                    <span></span>
                                </StyledCheckBox>
                                <Box className="inventoryImage">
                                    <img src={ config.baseURL +prodItem.attributes.product_images[0].url} alt={""} />
                                </Box>
                                <Box className="inventoryDetails">
                                    <Typography className="inventoryName" data-test-id="inventoryTitleID">{prodItem.attributes.title}</Typography>
                                    <Typography className="inventoryDesc">{prodItem.attributes.description}</Typography>
                                </Box>
                                <Box className="priceBox">
                                    <Typography className="inventoryPrice">${prodItem.attributes.selling_price}</Typography>
                                </Box>
                            </li>
                        )) :
                        <Box className="noDataBox">
                          <Typography className="noDataTxt">No catalogue found!</Typography>
                        </Box>
                        }
                    </InventoryList>
                    <Box className="paginationBox">
                        { totalPages !== 0 && <Pagination onChange={this.handleChangePagiation} count={totalPages} page={pageNumber} className='pagination' data-test-id="paginationTestID" size="medium" />}
                    </Box>
                    <Box className="buttonBox">
                        <Button variant="contained" disableElevation data-test-id={'sendProductTestId'} onClick={this.handleAddSaveModal} disabled={Object.keys(this.state.checkedItems).length === 0} >Add & Save</Button>
                    </Box>
                </InventoryContainer>
            </Box>
        )
    };

    renderProductListing = (mallItem : ProductData, mallIdx: number) => {
        return (
            <Box className="cardBox" key={mallIdx}>
                <Box className="imageBox">
                    <img src={ config.baseURL + mallItem.attributes.product_images[0].url} alt={""} className="productImage" />
                </Box>

                <Box className="productInfoBox">
                    <Typography className="titleText">{mallItem.attributes.title}</Typography>
                    <Typography className="priceText">₹{mallItem.attributes.selling_price}<span className="strikeTxt">{mallItem.attributes.mrp}</span></Typography>
                    <Button className="removeBtn" onClick={()=> this.handleRemoveProduct(mallItem.id)}>
                        Remove
                    </Button>
                </Box>
            </Box>
        )
    };

    successDialogBox = () => {
        const { successDialog } = this.state;
        return (
          <Dialog
            open={successDialog}
            onClose={this.handleSuccessDialogBox}
            data-test-id="close-succcess-dialog"
            PaperProps={{
              style: {
                borderRadius: 20,
                maxWidth: '526px',
                minHeight: '228px',
                width: '100%',
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
              },
            }}
            className={"dialogBackground"}
          >
            <CustomDialogContent className={"dialog"}>
              <Box className="innerContainer">
                <Box className={"markBox"}>
                  <img src={success} className="successImage" />
                </Box>
                <Typography className="titleTxt">Successfully added</Typography>
                <Typography className="subTitle">Your Products have been successfully added.</Typography>
              </Box>
            </CustomDialogContent>
          </Dialog>
        )
      }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { mallProducts } = this.state;
        return (
            <Box className={classes.uploadImagesMainContainer} data-test-id="UploadProductMainContainer">
                <MessageModalWeb
                    data-test-id="errorMessage"
                    displayPopup={this.state.errorModal}
                    closeErrorPopup={this.errorModalToggleClose}
                    errorMessage={this.state.errorMessage}
                />
                <Loader loading={this.state.isLoadingApp} />
                {this.successDialogBox()}
                <StyleWrapper>
                { this.state.isDataRes && (mallProducts.length > 0 ? <span>
                    <Typography className={classes.productTextContainer}>{configJSON.productText}</Typography>
                <ProductListingWrapper>
                    <Box className="cardMainContainer">
                        {mallProducts.map((mallItem ,mallIdx)=>(
                                this.renderProductListing(mallItem, mallIdx)
                        ))}
                    </Box>

                    <Box className="saveBtnBox">
                    <Button data-test-id="addProductTestId" className="addProdBtn" onClick={this.handleAddProductDialog}>
                        {configJSON.addProducts}
                    </Button>
                        <Button className="saveContinueBtn" onClick={this.saveProduct}>Save & Continue</Button>
                    </Box>
                </ProductListingWrapper>
                </span> : 
                
                <div className={classes.addProductContainer}>
                    <img src={backgroundBanner} alt="image" width="240px" height="250px" />
                    <Typography className={classes.noProductContainer}>{configJSON.noProduct}</Typography>
                    <Typography className={classes.noProductContainer}>{configJSON.startNowText}</Typography>
                    <Button
                        data-test-id="addProductTestId"
                        className={classes.saveButton}
                        onClick={this.handleAddProductDialog}
                    >
                        {configJSON.addProducts}
                    </Button>
                </div>
               )}
                </StyleWrapper>
                <Dialog
                    open={this.state.isAddProduct}
                    onClose={this.handleAddProductDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {
                            borderRadius: "10px",
                            width: "796px"
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box className={classes.backButtonNavigation}>
                                <Box className={classes.noProductContainer}><img className={classes.backIcon} src={backButton} alt="backButton" onClick={() => { }} /></Box>
                                <Typography className={classes.noProductContainer}>Add Product</Typography>
                            </Box>
                            <img src={close} alt='close' onClick={this.handleAddProductDialog} className={classes.dialogBoxContainer}/>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            className={classes.outlinedInputStyle}
                            data-test-id="searchInputTestId"
                            placeholder="Search Products"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={imgSearch} />
                                    </InputAdornment>
                                ),
                            }}
                            value={this.state.searchValue}
                            onChange={this.handleSearchValue}
                            onKeyDown={this.handleKeyPress}
                        />
                          {this.renderAddProduct()}
                    </DialogContent>
                </Dialog>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const ProductListingWrapper = styled(Box)({
    "& .cardMainContainer":{
        display:"flex",
        marginTop:"16px",
        overflow: "auto",
        scrollbarWidth: "thin",
        gap : "20px",
        marginBottom:"20px"
    },
    "& .cardBox":{
        border:"1px solid #6E6E6E1A",
        maxWidth:"193px",
        width:"100%",
        height:"100%",
        minWidth:"190px"
    },
    "& .imageBox":{
        width: "100%",
        maxWidth: "193px",
        height: "174px",
        alignItems: "center",
        display: "flex",
        borderRadius: "4px",
        justifyContent: "center",
    },
    "& .productImage":{
        height: "100%",
        width: "100%",
        objectFit: "contain",
        borderRadius: "4px",
    },
    "& .titleText":{
        fontSize:"18px",
        fontWeight: 500,
        paddingBottom:"8px"
    },
    "& .priceText": {
        fontWeight: 600,
        fontSize: "16px",
        color: "#4E4E4E",
        paddingBottom:"19px"
    },
    "& .strikeTxt": {
        fontWeight: 400,
        fontSize: "16px",
        color: "#6E6E6E",
        textDecoration: "line-through",
        paddingLeft: "8px"
    },
    "& .removeBtn":{
        textTransform: "none",
        border:"1px solid #DC2626",
        borderRadius:"50px",
        width:"100%",
        fontSize:"12px",
        fontWeight: 500,
        color: "#DC2626"
    },
    "& .productInfoBox":{
        padding:"8px",
        paddingBottom:"21px",
    },
    "& .saveContinueBtn":{
        borderRadius:"50px",
        textTransform:"none",
        background:"#F5EA16",
        width:"100%",
        maxWidth:"240px",
        height:"50px"
    },
    "& .addProdBtn":{
        borderRadius:"50px",
        textTransform:"none",
        background:"white",
        width:"100%",
        maxWidth:"240px",
        height:"50px",
        border:"1px solid black"
    },
    "& .saveBtnBox":{
        display:"flex",
        justifyContent:"flex-end",
        marginBottom:"10px",
        gap:"10px"
    }
})

const InventoryList = styled("ul")({
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    padding:"0px",
    "& .custom-checkbox-pseudo input": {
        position: "absolute",
        cursor: "pointer",
        opacity: 0,
    },
    "& .boxShadowVarient": {
        boxShadow: "none !important",
    },
    "& .boxShadowVarientimg": {
        objectFit: "unset" as "unset",
        width: "56.73px !important",
        height: "34.46px !important",
        margin: "0 !important"
    },
    "& li": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        gap: "12px",
        flexWrap:"wrap",
        borderRadius: "20px",
        boxShadow: "0 0 25px rgba(0,0,0,0.2)",
        marginBottom: "20px",
        "& .selectedImages": {
            width: "unset !important",
            height: "inset !important",
            padding: "0 8px 0 0 !important",
            objectFit: "unset" as "unset",
        },
        "& .inventoryImage": {
            width: "85px",
            height: "85px",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0 0 5px rgba(0,0,0,0.4)",
            boxSizing: "border-box",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            "& img": {
                objectFit: "cover",
                width: "81px",
                height: "84px",
            },
        },
        "& .inventoryDetails": {
            width:"100%",
            maxWidth:"250px",
            wordWrap:"break-word",
            "& .inventoryName": {
                fontWeight: 500,
                color: "rgba(68, 68, 68, 1)",
                fontSize: "16px",
                lineHeight: "24px",
            },
            "& .inventoryDesc": {
                color: "rgba(68, 68, 68, 0.7)",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
            }
        },
        "& .inventoryPrice": {
            color: "rgba(68, 68, 68, 1)",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "30px",
        },
        "& .priceBox":{
            display:"flex",
            justifyContent:"flex-end"
        },
        "& .highlighted": {
            background: "yellow",
        }
    }
});

const InventoryContainer = styled(Box)({
    marginTop: "28px",
    paddingBottom: "31px",
    width: "100%",
    "& .pagination": {
        marginLeft: "20px"
    },
    "& .paginationBox":{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "& img": {
        marginLeft: "auto",
        cursor: "pointer",
        display: "block"
    },
    "& .MuiButton-contained": {
        backgroundColor: "rgba(245, 234, 22, 1)",
        maxWidth: "194px",
        width:"100%",
        display: "block",
        height: "47px",
        borderRadius: "40px",
        fontWeight:600,
        color:"#555555"
    },
    "& .MuiButton-label": {
        textTransform: "capitalize"
    },
    "& .variantButton": {
        width: "100%"
    },
    "& .buttonBox": {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
});

const StyledCheckBox = styled("label")({
    position: "relative",
    borderRadius: "4px",
    border: "1px solid rgba(161, 161, 161, 1)",
    height: "24px",
    width: "25px",
    "&.checkedStyle": {
        border: "1px solid transparent"
    },
    "& input:checked + span::before": {
        borderWidth: "0px 2px 2px 0px",
        content: "''",
        width: "6px",
        border: "1px solid",
        position: "absolute",
        transform: "rotate(45deg)",
        left: "8px",
        height: "14px",
        top: "2px"
    },
    "& input": {
        visibility: "hidden"
    },
    "& input:checked + span": {
        backgroundColor: "rgba(245, 234, 22, 1)"
    },
    "& span": {
        position: "absolute",
        width: "108%",
        height: "108%",
        top: "-2px",
        borderRadius: "4px",
        left: "-1px"
    }
});

const CustomDialogContent = styled(DialogContent)({
    "& .MuiDialogContent-root":{
        flex: 0
    },
    "& .dialogBackground": {
        "& .MuiBackdrop-root": {
            background: '#0000004D',
        }
    },
    "& .innerContainer": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'relative'
    },
    "& .titleTxt": {
        fontSize: '20px',
        fontWeight: 600,
        color: "#555555",
        textAlign: 'center'
    },
    "& .subTitle": {
        fontSize: '14px',
        fontWeight: 400,
        color: "#55555599",
        textAlign: 'center'
    },
    "& .dialog": {
        display: 'flex',
        justifyContent:"center",
        alignItems:"center",
        flexDirection: 'column',
    },
    "& .successImage":{
        width:"28px"
    },
    "& .markBox": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        border: "1px solid #000000",
        background: '#4BB543',
        marginTop:"40px",
        marginBottom:"18px", 
    }
});

const StyleWrapper = styled(Box)({
    minHeight:"400px"
})
export default withStyles(webStyles)(AddProduct)
// Customizable Area End