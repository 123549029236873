import React, { ChangeEvent } from "react";
// Customizable Area Start
import { twoWave, App_Logo, Pwd_visible_eye, indiaFlag, hidePassword} from "./assets";
import { createTheme, Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import SellerTermsAndCondtion from './SellerTermsAndCondtion.web'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AccountRegistrationController, { DataSource, Props, configJSON } from "./AccountRegistrationController";
import Loader from "../../../components/src/Loader.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
import {
    Box,
    Grid,
    Typography,
    TextField,
    styled,
    MenuItem,
    InputLabel,
    Select,
    Button,
    Checkbox,
    Stepper,
    Step,
    StepLabel,
    FormControl
} from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

class CreatorSignUpScreen extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getSteps() {
        const { creatorActiveStep } = this.state;
        return [
            { label: 1, complete: creatorActiveStep >= 0 },
            { label: 2, complete: creatorActiveStep >= 1 },
            { label: 3, complete: creatorActiveStep >= 2 }
        ];
    };

    renderCreatorSignupForm = () => {
        const { classes } = this.props
        return (
            <Box>
                <Box
                    component="form"
                    className={'signupFormWrapper'}
                    data-test-id="sellerSignupForm"
                >
                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            type="text"
                            data-test-id="fullName"
                            placeholder={configJSON.creatorNamePlaceHolder as string}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            label="Creator's Name*"
                            variant="outlined"
                            name="fullName"
                            value={this.state.fullName}
                            onKeyPress={this.handleNameKeyPress}
                            onChange={this.handleNameLength}
                            inputProps={{
                                maxLength: 30,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.fullNameError !== "" && (
                            <ErrorTag>{this.state.fullNameError}</ErrorTag>
                        )}
                    </Box>

                    <Box className={'inputFeild'}>
                        <TextField
                            id="outlined-textarea"
                            label="Username*"
                            data-test-id="userName"
                            name="userName"
                            placeholder={configJSON.userNamePlaceholder}
                            onChange={this.handleSelectUserMaxLength}
                            error={this.state.userNameError !== ""}
                            helperText={this.state.userNameError}
                            variant="outlined"
                            value={this.state.selectedUsername.trim()}
                            inputProps={{
                                maxLength: 30,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {this.state.username.length > 0 ?
                            (
                                <>
                                    <Box style={{ display: "flex", gap: "5px", fontFamily: 'Poppins-Medium', color: "white", marginTop: 8 }}>
                                        <Typography style={{ fontSize: '12px', fontFamily: 'Poppins-Medium', lineHeight: 'normal' }}>Available:</Typography>
                                        <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                            {this.state.username.map((item: string, index: number) => <Typography key={index} data-test-id={`uname${index}`} onClick={() => {
                                                this.setState({ selectedUsername: item })
                                            }} style={{ color: "yellow", cursor: "pointer", fontSize: '12px', fontFamily: 'Poppins-Medium', lineHeight: 'normal' }} >{item}</Typography>)}
                                        </Box>
                                    </Box>
                                </>)
                            : ""}
                    </Box>

                    <Box className="inputFeild">
                        <Box sx={{ display: 'flex', gridGap: '15px' }}>
                            <div>
                                <div className={classes.countryCodeFeild} >
                                    <label className={'countryCodeLable'}>Code</label>
                                    <div className={'countryCodeSelect'} data-test-id="countryCode" onClick={this.handleIsSelect} >
                                        <p style={{ paddingRight: '10px', transform: "scale(2)" }}
                                            defaultValue={"in"}>{this.state.selectedValue?.label}</p>
                                        {this.state.selectedValue?.value}
                                        {this.state.isSelect ? <ExpandLessIcon style={{ marginRight: '-15px' }} /> : <ExpandMoreIcon style={{ marginRight: '-15px' }} />}

                                    </div>
                                    {this.state.isSelect &&
                                        <CountryDiv style={{
                                            position: 'absolute', top: '65px', width: '114px', listStyle: 'none', background: 'black',
                                            borderRadius: 10, border: '1px solid rgba(255, 255, 255, 0.50)', zIndex: 11, height: 150, overflowY: 'scroll'
                                        }}>
                                            {this.state.dataSource.map((item: DataSource, index: number) => (
                                                <li data-test-id={`countryValue${index}`} key={index} onClick={this.selectCountryFlag.bind(this, item)} style={{ color: 'white', padding: '7px 14px', borderBottom: '1px solid rgba(255, 255, 255, 0.50)' }}>
                                                    <span style={{ paddingRight: 8, display: 'inline-block' }}>{item.label}</span>
                                                    {item?.value}</li>
                                            ))}
                                        </CountryDiv>}
                                </div>
                            </div>

                            <TextField
                                type="number"
                                id="outlined-textarea"
                                data-test-id="mobileNo"
                                placeholder={configJSON.mobileNumberLabel}
                                label="Mobile number*"
                                name="mobileNumber"
                                variant="outlined"
                                value={this.state.phoneNumber}
                                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.handlePhoneChange(event.target.value)}
                                error={this.state.mobileNumberError != ""}
                                helperText={this.state.mobileNumberError}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>

                    <Box className={'inputFeild'}>
                        <TextField
                            type="email"
                            id="outlined-textarea"
                            data-test-id="email"
                            label="Email Id*"
                            variant="outlined"
                            placeholder={configJSON.email}
                            onChange={this.handleChangeEmailSeller}
                            value={this.state.emailSellerSignup}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <ErrorTag>{this.state.emailError}</ErrorTag>
                    </Box>

                    <InputGrid item xs={12}>
                        <Box style={{ position: 'relative' }}>
                            <InputTagPwd
                                id="outlined-number"
                                label="Create Password*"
                                data-test-id="password"
                                placeholder={configJSON.placeHolderP}
                                onKeyDown={this.handleKey}
                                type="text"
                                value={this.state.showPassword ? this.state.copyPassword : "*".repeat(this.state.password.length)}
                                className={this.state.showPassword ? "" : "showStar"}
                                onChange={this.handlePwdChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: 'off',
                                }}
                                variant="outlined"
                            />
                            <EyeImage src={this.state.showPassword ? Pwd_visible_eye : hidePassword} alt="" onClick={this.toggleShowPassword} className={this.state.showPassword ? "showStar" : ""} data-test-id="eyeBtnId" />
                        </Box>
                        {<ErrorTag>{this.state.pwdError}</ErrorTag>}
                    </InputGrid>

                    <InputGrid item xs={12}>
                        <Box style={{ position: 'relative' }}>
                            <InputTagPwd
                                id="outlined-number"
                                label="Confirm Password*"
                                data-test-id="confirmPassword"
                                placeholder={configJSON.confirmPassword}
                                type="text"
                                onKeyDown={this.handleKey1}
                                value={this.state.showPassword1 ? this.state.copyPassword1 : "*".repeat(this.state.reTypePassword.length)}
                                className={this.state.showPassword1 ? "" : "showStar"}
                                onChange={this.handleConfirmPwdChange}
                                onBlur={this.validatePassword}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: 'off',
                                }}
                                variant="outlined"
                            />

                            <EyeImage src={this.state.showPassword1 ? Pwd_visible_eye : hidePassword} alt="" onClick={this.toggleShowPassword1} className={this.state.showPassword1 ? "showStar" : ""} data-test-id="conEyeBtnId" />
                        </Box>
                        {<ErrorTag>{this.state.reTypepasswordError}</ErrorTag>}
                    </InputGrid>

                    <Box style={{ display: 'flex', gap: "4px" }}>
                        <Checkbox
                            data-test-id="check"
                            color="primary"
                            style={{
                                padding: 0,
                                backgroundColor: this.state.isChecked ? "transparent" : "white",
                                width: "17px",
                                height: " 17px",
                                borderRadius: "5px",
                                marginTop: "2px",
                                marginRight: "4px"
                            }}
                            checked={this.state.isChecked}
                            onChange={this.handleIsChecked}
                            value={this.state.isChecked}

                        />
                        <InputLabel style={{
                            color: 'white',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '19px',
                            fontFamily: "Poppins-Regular",
                            textAlign: 'start',
                        }}>
                            Agree with our
                            < Button
                                onClick={this.sellerTermsAndCondition}
                                data-test-id='SellerTermsAndCondition'
                                style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                            >
                                &nbsp;Terms and Conditions&nbsp;
                            </Button>
                            <span>&amp;&nbsp;</span>
                            <Button
                                data-test-id='sellerPrivacyPolicy'
                                onClick={this.sellerPrivacyPolicy}
                                style={{ textTransform: "initial", color: "#1A4CFF", padding: '0px', fontSize: '16px', lineHeight: '19px', fontFamily: 'Poppins-Regular' }}
                            >Privacy Policy</Button>
                            <span>*</span>
                        </InputLabel>
                    </Box>
                    <Box>
                        {this.state.checkedError === true && <ErrorTag style={{ marginTop: '-26px' }}>Terms and Conditions not accepted</ErrorTag>}
                    </Box>
                    <Box className={classes.submitBtn}>
                        <Button variant="contained"
                            data-test-id="signUpBtn"
                            onClick={this.sellerSignUp}
                        >
                            {configJSON.signUp}
                        </Button>
                    </Box>
                    <Box className={classes.horizontalLine}>
                        <p>or</p>
                    </Box>
                </Box>
                <Box>
                    <span
                        style={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#ffffff',
                        }}>Already have an account&nbsp;
                    </span>
                    <span>
                        <Link
                            to={'CreatorLogin'}
                            style={{
                                fontFamily: 'Poppins-Medium',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#F5EA16',
                            }}>Login</Link>
                    </span>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                        <img src={indiaFlag} alt="" />
                        <Typography style={{ fontFamily: 'Poppins-Regular', fontSize: '16px', color: 'white', margin: '0px' }}>Made in Bharat</Typography>
                    </Box>
                </Box>
            </Box>
        )
    };

    renderAddressDetails() {
        const { classes } = this.props;
        return (
            <>
                <Box className={'formHeading'}>
                    <Typography variant="h6" component={'h6'} className={classes.sectionTitle}>{configJSON.creatorsAddress}</Typography>
                    <Typography component={'p'}>{'Enter your Address details'}</Typography>
                </Box>
                <Box
                    data-test-id="sellerSignupForm"
                    component="form"
                    className={'signupFormWrapper'}
                >
                    <Box className={'inputFeild'}>
                        <TextField
                            label="Address line 1*"
                            id="outlined-number"
                            name="business_Name"
                            variant="outlined"
                            data-test-id="businessName"
                            onChange={this.handleAddressOne}
                            value={this.state.addressOne}
                            autoComplete="off"
                            placeholder={configJSON.addressLineOnePlaceholder}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {
                            this.state.addressOneError !== "" && (
                                <ErrorTag>
                                    {this.state.addressOneError}
                                </ErrorTag>
                            )
                        }
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            data-test-id="fullName"
                            variant="outlined"
                            id="outlined-textarea"
                            name="fullName"
                            value={this.state.addressTwo}
                            onChange={this.handleAddressTwo}
                            label="Address line 2"
                            autoComplete="off"
                            placeholder={configJSON.addressLineTwoPlaceholder}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box className={'inputFeild'}>
                        <TextField
                            label="Pin code*"
                            id="outlined-textarea"
                            name="userName"
                            data-test-id="zipcodeTestId"
                            value={this.state.pinCode}
                            placeholder={configJSON.pincodePlaceholder}
                            onChange={this.handlePincode}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {
                            this.state.pinCodeError !== "" && (
                                <ErrorTag>
                                    {this.state.pinCodeError}
                                </ErrorTag>
                            )
                        }
                    </Box>
                    <Box style={{ position: 'relative' }} className={'inputField'}>
                        <InputLabel shrink={true} className={'BusinessLabel textWhite customShrink'} htmlFor="country-select">
                            {"Country*"}
                        </InputLabel>
                        <Autocomplete
                            options={this.state.countryList.map((item: { name: string }) => item.name)}
                            id="combo-box-demo"
                            getOptionLabel={(option) => option}
                            value={this.state.country}
                            onChange={this.handleCountryChange}
                            className="auto-complete-list"
                            data-test-id="countryTestId"
                            classes={{
                                paper: classes.paper,
                                option: classes.option,
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select Country"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <ExpandMoreIcon className={classes.expandIcon} />
                                        ),
                                    }}
                                    className={classes.autocompleteTxtField}
                                />
                            )}
                            autoHighlight
                        />
                        {
                            this.state.countryError !== "" && (
                                <ErrorTag>
                                    {this.state.countryError}
                                </ErrorTag>
                            )
                        }
                    </Box>
                    <Box className={'inputFeild'}>
                        <InputLabel shrink={true} className={'BusinessLabel textWhite customShrink'} htmlFor="state-select">
                            {"State*"}
                        </InputLabel>
                        <Autocomplete
                            options={this.state.stateList.map((item: { name: string }) => item.name)}
                            id="combo-box-demo"
                            classes={{
                                paper: classes.paper,
                                option: classes.option,
                            }}
                            getOptionLabel={(option) => option}
                            disabled={this.state.stateList.length === 0}
                            onChange={this.handleStateChange}
                            data-test-id="stateTestId"
                            className="auto-complete-list"
                            value={this.state.state}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select State"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <ExpandMoreIcon className={classes.expandIcon} />
                                        ),
                                    }}
                                    className={classes.autocompleteTxtField}
                                />
                            )}
                            autoHighlight
                        />
                        {
                            this.state.stateError !== "" && (
                                <ErrorTag>
                                    {this.state.stateError}
                                </ErrorTag>
                            )
                        }
                    </Box>
                    <Box className={'inputFeild'}>
                        <InputLabel shrink={true} className={'BusinessLabel textWhite customShrink'} htmlFor="city-select">
                            {"City*"}
                        </InputLabel>
                        <Autocomplete
                            classes={{
                                paper: classes.paper,
                                option: classes.option,
                            }}
                            autoHighlight
                            options={this.state.cityList}
                            id="combo-box-demo"
                            onChange={this.handleCityChange}
                            getOptionLabel={(option: string) => option}
                            data-test-id="cityTestId"
                            className="auto-complete-list"
                            disabled={this.state.cityList.length === 0}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select City"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <ExpandMoreIcon className={classes.expandIcon} />
                                        ),
                                    }}
                                    className={classes.autocompleteTxtField}
                                />
                            )}
                            value={this.state.city}
                        />
                        {
                            this.state.cityError !== "" && (
                                <ErrorTag>
                                    {this.state.cityError}
                                </ErrorTag>
                            )
                        }
                    </Box>
                    <Box className={classes.submitBtn}>
                        <Button
                            variant="contained"
                            onClick={this.handleNextClick}
                            data-test-id="next-btn"
                        >
                            Next
                        </Button>
                    </Box>
                    <LoginFailModalWeb
                        closeErrorPopup={this.closeAlert}
                        titleMessage={'Error'}
                        errorMessage={this.generateErrorMessage()}
                        displayPopup={this.state.showAlert}
                    />
                </Box>
            </>
        )
    };

    renderInputLabel = () => {
        return this.state.slectText && <span className="placeholderStyle"> Select Category</span>;
    };

    renderCategoryError = () => {
        if (this.state.selectCategoryError !== "") {
            return <ErrorTag>{this.state.selectCategoryError}</ErrorTag>
        }
    };

    renderCreatorForm = () => {
        const { classes } = this.props
        const today = new Date().toISOString().split('T')[0];
        const minDate = '1900-01-01';
        return (
            <Box
                component="form"
                className={'signupFormWrapper'}
                data-test-id="sellerSignupForm"
                padding={"10px"}
            >
                <Box>
                    <InputLabel
                        className={'BusinessLabel textWhite customShrink'}
                        shrink={true}
                        htmlFor="bank-type-label"
                    >
                        {configJSON.gender}
                    </InputLabel>
                    <Select
                        className={`${this.state.gender ? "selectActive" : "selectActiveNot"}`}
                        labelId="bank-type-label"
                        id="bank-type-label"
                        data-test-id="genderSelectTestId"
                        value={this.state.gender || configJSON.selectAnyone}
                        onChange={this.handleGenderChange}
                        variant="outlined"
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: selectStylePaper
                            },
                        }}
                    >
                        <MenuItem
                            className={classes.accountTypeLabel}
                            value={configJSON.selectAnyone}
                        >
                            {configJSON.selectAntoneLabel}
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItems}
                            value={configJSON.male}
                        >
                            {configJSON.male}
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItems}
                            value={configJSON.female}
                        >
                            {configJSON.female}
                        </MenuItem>
                        <MenuItem
                            className={classes.menuItems}
                            value={configJSON.notReveal}
                        >
                            {configJSON.notReveal}
                        </MenuItem>
                    </Select>
                    <ErrorTag>{this.state.genderError}</ErrorTag>
                </Box>
                <Box className={'inputFeild'}>
                    <TextField
                        id="outlined-number"
                        label="Date of Birth"
                        variant="outlined"
                        data-test-id="dobTestId"
                        placeholder={configJSON.selectDob}
                        type="date"
                        value={this.state.dateOfBirth}
                        onFocus={this.handleFocusDob}
                        onBlur={this.handleBlurDob}
                        onChange={this.handleDob}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: minDate,
                            max: today,
                        }}
                        autoComplete="off"
                    />
                    {this.state.dateOfBirthError !== "" && (
                        <ErrorTag data-test-id="dobError">{this.state.dateOfBirthError}</ErrorTag>
                    )}
                </Box>
                <CustomFormControl variant="outlined">
                    <InputLabel
                        className={'BusinessLabel textWhite customShrink'}
                        shrink={true}
                        htmlFor="demo-simple-select"
                        data-test-id="businessType"
                    >
                        <span> {configJSON.category} </span>
                    </InputLabel>
                    {this.renderInputLabel()}
                    <Box width='100%' maxWidth='100%' >
                        <Select
                            multiple
                            onOpen={this.handleOpenCat}
                            className={'selectActive textWhite'}
                            labelId="demo-simple-select"
                            id="demo-simple-select"
                            data-test-id="category-select"
                            value={this.state.selectedCategories}
                            onChange={this.changebusinessCategory}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: {
                                        backgroundColor: 'black',
                                        border: '2px solid rgba(255, 255, 255, 0.50)',
                                        borderRadius: '0px 0px 12px 12px',
                                        borderTop: 'none',
                                        maxHeight: '300px',
                                        width: 'auto'
                                    }
                                }
                            }}
                            renderValue={this.renderCategoryValue}
                        >
                            <MenuItem disabled value="default">
                                <Box color='#ffffff'>{configJSON.selectAntoneLabel}</Box>
                            </MenuItem>
                            {this.state.businessCategories && this.state.businessCategories.map((item: { attributes: { id: number, name: string } }) =>
                                <MenuItem key={item.attributes.id} value={item.attributes.id}>
                                    <Checkbox
                                        checked={Array.isArray(this.state.selectedCategories) && this.state.selectedCategories.includes(item.attributes.id)}
                                        style={{ color: '#ffffff' }}
                                    />
                                    <Box color='#ffffff'>{item.attributes.name}</Box>
                                </MenuItem>
                            )}
                        </Select>
                    </Box>
                    {this.renderCategoryError()}
                </CustomFormControl>
                <Box className={'inputFeild'}>
                    <TextField
                        id="outlined-textarea"
                        label="Instagram"
                        data-test-id="instagramLink"
                        name="instagramLink"
                        placeholder={configJSON.instagramPlaceholder}
                        onChange={this.handleInstagram}
                        variant="outlined"
                        value={this.state.instagramUrl}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {this.state.instagramUrlError !== "" && (
                        <ErrorTag>{this.state.instagramUrlError}</ErrorTag>
                    )}
                </Box>
                <Box className={'inputFeild'}>
                    <TextField
                        id="outlined-textarea"
                        label="Youtube"
                        data-test-id="youtubeLink"
                        name="youtubeLink"
                        placeholder={configJSON.youtubePlaceholder}
                        onChange={this.handleYoutubeLink}
                        variant="outlined"
                        value={this.state.youtubeLink}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {this.state.youtubeLinkError !== "" && (
                        <ErrorTag>{this.state.youtubeLinkError}</ErrorTag>
                    )}
                </Box>
                <Box className={classes.submitBtn}>
                    <Button variant="contained"
                        data-test-id="registerBtn"
                        onClick={this.handleAddInfoSubmit}
                    >
                        {configJSON.register}
                    </Button>
                </Box>
            </Box>
        );
    };

    renderCreatorCreated = () => {
        const { classes } = this.props
        return (
            this.state.creatorCreated &&
            <Box className={'formHeading'}
                padding={"10px"}>
                <Typography
                    className={classes.sectionTitle}
                    variant="h6"
                    component={'h6'}>
                    {configJSON.regSbmtTxt}
                </Typography>
                <Typography
                    component={'p'}>
                    {configJSON.regSbmtSubTxt}
                </Typography>
            </Box>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { activeStep } = this.state;
        const steps = this.getSteps();
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <section className={classes.sectionSellerSignUp}>
                    <Box className={classes.SignupBgWrapper}>
                        <Box className={'overlayImg'}>
                            <img src={twoWave} alt="" />
                        </Box>
                        <Box className={classes.formWrapper}>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={12} xs={12}>
                                    <Box className={'leftColumn'}>
                                        <img src={App_Logo} alt="" />
                                        <Typography variant="h6" component={'h6'}>{'Live shopping & market place'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                    <Box className={'rightColumn'}>
                                        <Box className={'rightColumnInner'}>
                                            <Box style={{ width: "100%" }}>
                                                <CustomStepper activeStep={activeStep} alternativeLabel>
                                                    {steps.map((item, index) => (
                                                        <Step key={item.label} completed={item.complete && index !== activeStep} disabled={!item.complete && index !== activeStep}>
                                                            <StepLabel>{item.label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </CustomStepper>
                                                {
                                                    this.state.creatorActiveStep === 0 &&
                                                    <Box className={'formHeading'}>
                                                        <Typography variant="h6" component={'h6'}>{configJSON.basicInfo}</Typography>
                                                        <Typography component={'p'}>{configJSON.enterSignUpInfo}</Typography>
                                                    </Box>
                                                }
                                            </Box>
                                            {
                                                this.state.creatorActiveStep === 0 && this.renderCreatorSignupForm()
                                            }
                                            {
                                                this.state.creatorActiveStep === 1 && this.renderAddressDetails()
                                            }
                                            {
                                                (this.state.creatorActiveStep === 2 && !this.state.creatorCreated) && this.renderCreatorForm()
                                            }

                                            {
                                                this.state.creatorCreated && (
                                                    <>
                                                        {this.renderCreatorCreated()}
                                                        <Box className={classes.submitBtn}>
                                                            <Button
                                                                variant="contained"
                                                                data-test-id="continueBtn"
                                                                style={{ width: "100%" }}
                                                                onClick={this.handleCreatorContinue}
                                                            >
                                                                {configJSON.continueText}
                                                            </Button>
                                                        </Box>
                                                    </>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </section>
                <SellerTermsAndCondtion sellerTermsConditionData={this.state.termsConditionPrivacyPolicyData} navigation={undefined} id={""} sellerModalOpen={this.state.sellerModalOpen} handleSellerModalClose={this.handleSellerModalClose} showModal={false} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const selectStylePaper = {
    backgroundColor: 'black',
    border: '2px solid rgba(255, 255, 255, 0.50)',
    borderRadius: '0px 0px 12px 12px',
    borderTop: 'none',
    "& .MuiSvgIcon-root": {
        fill: "white"
    }
};
const styles = (theme: Theme) => ({
    businessTypeLabel: {
        display: "none"
    },
    sectionSellerSignUp: {
        width: '100%',
        height: '100%',
        color: '#ffffff !important',
        '& p': {
            marginLeft: '3px !important',
            marginTop: '5px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '16px',
            lineHight: '18px',
            color: 'rgba(255, 255, 255) !important',
            padding: '0 5px',
            backgroundColor: 'black',
        },
        '& .MuiCheckbox-colorPrimary': {
            color: "#ffffff",
            height: 'max-content',
            marginTop: '-2px',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: 'yellow',
        },
    },
    SignupBgWrapper: {
        width: '100%',
        minHeight: '1530px',
        backgroundColor: '#000000',
        position: 'relative' as const,
        '& .overlayImg': {
            position: 'absolute',
            width: '100%',
            bottom: '0',
            left: '0',
            '& img': {
                width: '100%',
                height: '280px',
                objectFit: 'cover',
            },
        },
    },
    accountTypeLabel: {
        display: "none",
        color: "white",
        opacity: 0.5
    },
    menuItems: {
        backgroundColor: '#000000',
        "&:hover": {
            backgroundColor: '#fbfbfb30'
        },
        borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
        color: '#ffffff',
        opacity: 1,
        "& .MuiSelect-select": {
            opacity: 1
        },
        fontSize: "14px"
    },
    formWrapper: {
        padding: '100px',
        position: 'relative' as const,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
        '& .leftColumn': {
            marginTop: '56%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0%',
            },
            '& img': {
                maxWidth: '156px',
                lineHeight: '62px',
            },
            '& h6': {
                fontFamily: 'Poppins-Medium',
                color: '#ffffff',
                fontSize: '20px',
                lineHeight: '30px',
            },
        },
        '& .rightColumn': {
            minHeight: '600px',
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: '20px',
            padding: '32px 16px',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '660px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                padding: '20px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '10px',
            },

            '& .rightColumnInner': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gridGap: '40px',
                maxWidth: '542px',
                width: '100%',
                "& .stepOneImgBox": {
                    marginBottom: "30px"
                },
                '& .formHeading': {
                    paddingBottom: "30px",
                    '& h6, & p': {
                        color: 'white',
                    },
                    '& h6': {
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '32px',
                        lineHieght: '48px',
                        fontWeight: 600
                    },
                    '& p': {
                        fontFamily: 'Poppins-Regular',
                        fontSize: '18px',
                        lineHeight: '27px',
                        opacity: '0.5',
                    },
                },
                '& .signupFormWrapper': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gridGap: '30px',
                    '& .MuiTextField-root': {
                        width: '100%',
                    },
                    '& .MuiInputLabel-outlined': {
                        color: '#ffffff',
                    },
                    '& input': {
                        color: '#ffffff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        color: '#ffffff',
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '12px',
                        height: '56px',

                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#ffffff',
                        opacity: 0.5,
                        fontSize: '14px'
                    },
                    '& .MuiInputBase-input': {
                        '&:focus': {
                            caretColor: 'white',
                        },
                    },
                    '& .BusinessLable': {
                        color: '#ffffff',
                        maxWidth: 'max-content',
                        background: 'black',
                        height: '18px',
                        marginLeft: '16px',
                        marginBottom: '-12px',
                        zIndex: 999,
                        position: 'relative',
                        padding: '0 3px',
                        fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                        width: '100%',
                    },
                    "& .selectActive": {
                        "& .MuiSvgIcon-root": {
                            fill: "white"
                        },
                        "& .MuiSelect-select": {
                            opacity: 1,
                            fontSize: "14px"
                        }
                    },
                    "& .selectActiveNot": {
                        "& .MuiSvgIcon-root": {
                            fill: "white"
                        },
                        "& .MuiSelect-select": {
                            opacity: 0.5,
                            fontSize: "14px"
                        }
                    },
                    '& .MuiSelect-select': {
                        color: '#ffffff',
                        opacity: 1,
                        textAlign: 'left',
                    },
                    '& .MuiSelect-select:before': {
                        color: '#ffffff',
                        opacity: 0.5,
                    },
                    '& .customShrink': {
                        position: 'absolute',
                        fontSize: '16px',
                        color: 'rgba(255, 255, 255)',
                        transform: "translate(14px, -6px) scale(0.75)",
                        height: '14px',
                        backgroundColor: '#000000',
                        padding: '0 5px',
                        zIndex: '9',
                        '& .MuiInputBase-input::placeholder': {
                            color: '#ffffff',
                            opacity: 0.5,
                        },
                    },
                    '& .BusinessSelect': {
                        '& svg': {
                            color: "white",
                        },
                    },
                    '& .inputFeild': {
                        '& .MuiFormLabel-root': {
                            fontFamily: 'Poppins-Regular !important',
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: "#ffffff"
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: '#ffffff',
                            opacity: 0.5,
                        },
                        '& .BusinessLable.textWhite': {
                            fontSize: '16px',
                            lineHeight: '15px',
                            color: "#ffffff"
                        },
                    },
                    "& .placeholderStyle": {
                        position: "absolute",
                        fontSize: "14px",
                        width: "100%",
                        left: "17px",
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "57px",
                        opacity: 0.5
                    }
                },
            },
        }
    },
    customCheckBox: {
        "& .MuiCheckbox-root": {
            color: "white !important",
            padding: '0px !important',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16 !important",
            padding: '0px !important',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
            lienHeight: '18px',
        },
    },
    countryCodeFeild: {
        position: 'relative' as const,
        '& .countryCodeLable': {
            backgroundColor: '#000000',
            color: 'rgba(255, 255, 255)',
            fontSize: "12px",
            position: 'absolute',
            top: "-8px",
            left: "15px",
            display: 'inline-block',
            padding: '0px 5px',
        },
        '& .countryCodeSelect': {
            width: '129px',
            height: '52px',
            border: '1px solid rgba(255, 255, 255, 0.50)',
            borderRadius: 10,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: '4px',
            '& p': {
                marginTop: '15px !important',
            },
        },
    },
    submitBtn: {
        marginBittom: '25px',
        width: "100%",
        '& .MuiButton-contained': {
            width: '100%',
            height: '56px',
            backgroundColor: '#F5EA16',
            borderRadius: '24px',
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'Poppins-Medium',
            color: '#444444',
            textTransform: 'capitalize',
            fontWeight: 600
        },
    },
    horizontalLine: {
        width: '100%',
        height: '2px',
        position: 'relative' as const,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        '& p': {
            textAlign: 'center',
            backgroundColor: 'black',
            marginTop: '-9px',
            width: 'max-content',
            margin: 'auto',
            padding: '0px 7px',
            fontFamily: 'Poppins-Regular',
            fontSize: '12px',
            position: "absolute" as const,
            left: '47%' as const,
            lineHieght: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
            postion: "absolute" as const,
        }
    },
    passwordError: {
        fontSize: "12px",
        textAlign: 'left' as const,
        marginTop: "-27px",
        marginLeft: "14px",
        color: 'red',
    }
});
const InputGrid = styled(Grid)({
    '& img.showStar': {
        filter: 'brightness(15)',
        width: 24
    }
});
const InputTagPwd = styled(TextField)({
    width: '100%',
    borderRadius: '12px',
    color: 'white',
    height: 55,
    fontWeight: 400,
    backgroundColor: 'transparent',
    '& ::placeholder': {
        color: 'rgba(0, 0, 0)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: 'rgba(255, 255, 255, 0.30)',
        fontSize: '12px',
        lineHeight: '18px',
        fontFamily: 'Poppins-Regular',
    },
    '& .MuiOutlinedInput-input': {
        fontSize: "18px!important",
        position: "unset",
        padding: "18.5px",
        maxWidth: 'unset'
    },
    '&.showStar .MuiOutlinedInput-input': {
        fontSize: "18px!important",
        position: "absolute",
        top: "0px",
        maxWidth: '95%',
        '&::placeholder': {
            color: '#ffffff',
            opacity: 0.5,
        }
    },
    '& .svg': {
        color: '#ffffff',
    },

});
const EyeImage = styled('img')({
    position: "absolute",
    right: 20,
    top: 13,
    zIndex: 1,
    height: "24px",
    width: '24px !important',
    cursor: 'pointer'
});
const ErrorTag = styled(Typography)({
    fontSize: 12,
    color: 'red',
    textAlign: 'left' as const
});
const CountryDiv = styled('div')({
    "&::-webkit-scrollbar": {
        display: "none",
    },
    "scrollbar-width": "none",
    "-ms-overflow-style": "none",
});
const CustomStepper = styled(Stepper)({
    alignItems: 'center !important',
    background: 'transparent',
    '@media(min-width: 1400px)': {
        justifyContent: 'space-between',
        paddingInline: 0,
        '& .MuiStep-alternativeLabel': {
            flex: 'unset',
        },
    },
    '@media(max-width: 1399px)': {
        transform: 'scale(1.2)'
    },
    '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
        padding: 5,
        borderRadius: '50%',
        border: '1px dashed #F5EA167A',
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        display: 'none'
    },
    '& .MuiStepLabel-active': {
        display: 'none',
    },
    '& .MuiStep-completed .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
        display: 'none'
    },
    '& .MuiStepLabel-root.Mui-disabled .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
        padding: 12,
        border: 'none',
    },
    '& .MuiStep-completed .MuiStepLabel-alternativeLabel': {
        padding: '0px',
        border: 'none',
        '& .MuiStepLabel-completed': {
            width: '55px',
            height: '55px',
            margin: 'auto',
            fontSize: '27px',
            borderRadius: '50%',
            background: '#F5EA16',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media(max-width: 600px)': {
                fontSize: '14px',
                height: '30px',
                width: '30px',
            }
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            display: 'none'
        },
    },
    '& .MuiStepIcon-root': {
        fontSize: 55,
        color: '#55555566',
        '@media(max-width: 600px)': {
            fontSize: 30,
        },
        '& .MuiStepIcon-text': {
            fill: 'white'
        }
    },
    '& .MuiStepConnector-completed': {
        top: '29px !important'
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
        border: '1px dashed',
        fontSize: 55,
        borderRadius: '50%',
        padding: 5,
        color: '#F5EA16',
        '@media(max-width: 600px)': {
            fontSize: 30,
        },
        '& .MuiStepIcon-text': {
            fill: 'black'
        },
    },
    '& .MuiStepConnector-alternativeLabel': {
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
        position: 'absolute',
        top: '40px',
        '@media(min-width: 1400px)': {
            left: 'calc(-50% + -100px)',
        },
        '@media(max-width: 600px)': {
            right: 'calc(50% + 27px)',
            left: 'calc(-50% + 27px)',
            top: 25
        }
    },
    '& .MuiStepConnector-active': {
        top: '34px !important'
    },
    '& .MuiStepConnector-alternativeLabel.MuiStepConnector-completed': {
        right: 'calc(50% + 40px)',
        left: 'calc(-50% + 40px)',
        position: 'absolute',
        top: '40px',
        '@media(min-width: 1400px)': {
            left: 'calc(-50% + -140px)',
        },
        '@media(max-width: 600px)': {
            right: 'calc(50% + 27px)',
            left: 'calc(-50% + 27px)',
            top: 25
        }
    },
    '& .MuiStepConnector-alternativeLabel.MuiStepConnector-active': {
        right: 'calc(50% + 40px)',
        left: 'calc(-50% + 40px)',
        position: 'absolute',
        top: '40px',
        '@media(min-width: 1400px)': {
            left: 'calc(-50% + -140px)',
        },
        '@media(max-width: 600px)': {
            right: 'calc(50% + 27px)',
            left: 'calc(-50% + 27px)',
            top: 25
        }
    },
});
const CustomFormControl = styled(FormControl)({
    width: '100%',
    '& .MuiFormLabel-root': {
        color: 'white'
    },
    '& .MuiSelect-icon': {
        color: 'white',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
        textAlign: 'start',
        color: 'white',
        borderRadius: 4,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'unset'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'unset'
    },
    '& .Mui-Focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.5) !important'
    }
});

export default withStyles(styles)(CreatorSignUpScreen);
export { CreatorSignUpScreen }
// Customizable Area End
