import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    IconButton,
    Typography,
    Avatar,
    Button,
    CircularProgress,
    TextField,
    Modal,
    Snackbar,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { arrowDownIcon, avatarImg, instagramLogo } from "./assets";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PostCard from "../../../components/src/PostCard.web";
import BuyerFeedController, { configJSON, Props, PostDataPayload } from "./BuyerFeedController.web";
import { conditionalRenderer } from "../../../components/src/utils";
import StoryListing from "../../../components/src/CustomStoryListing.web";
import {  
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    TelegramIcon,
    TelegramShareButton
 } from "react-share";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { cancelIcon, profileImg } from "../../landingpage/src/assets";
import Alert from '@material-ui/lab/Alert';
import ContentLoader from "react-content-loader";
import { crossIcon } from "../../LiveStreaming/src/assets";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End



export default class BuyerFeed extends BuyerFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSuggetionSection = () => {
        return (
            <>
                <Box className="suggestionBox">
                    <Typography className="suggestionForTxt">Suggested for you</Typography>
                    <Pagination siblingCount={0} data-test-id="paginationTestID" count={this.state.suggetionTotalPageBuyer} onChange={this.handlePageBuyerChange} />
                    <Box className="suggestionMainContainer">
                        {this.state.suggetionBuyer.map((items, itemIndex) => (
                            <Box className="suggestedUserBox" key={itemIndex}>
                                <Box className="suggestionLeftBox">
                                    <Box className="avatarSuggestionBox">
                                        <Avatar src={items.attributes.account.profile_url} className="suggestionAvatar" />
                                    </Box>
                                    <Box className="suggestionInfoBox" onClick={()=> this.handleNavigateCommon(items.attributes.account)}>
                                        <Typography className="userNameTxt">{items.attributes.account.user_name} <span className="userRole">{items.attributes.account.role}</span></Typography>
                                        <Typography className="userFullNameTxt">{items.attributes.account.full_name}</Typography>
                                    </Box>
                                </Box>
                                <Box className="followBox">
                                    <Button data-test-id="suggetionFollowTestId" onClick={() => this.handleCreatorFollow(items.attributes.account.id, "")} className={items.attributes.account.is_following ? "followUnfollowBtn followBack" : "followUnfollowBtn"}>{items.attributes.account.is_following ? "Following" : "Follow"}</Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </>
        )
    }

    parseAndHighlightMentionsBuyer = (comment: string ) => {
        const mentionRegexBuyer = /(@\w+)/g;
        const partsBuyer = (comment || '').split(mentionRegexBuyer);
    
        return partsBuyer.map((partItem, indexBuyer) => {
          if (partItem.startsWith('@')) {
            const usernameBuyer = partItem.slice(1);
              return (
                <span key={indexBuyer} className="mention" data-username={usernameBuyer} style={{color: 'rgb(0, 55, 107)' }}>
                  {partItem}
                </span>
              );
          }
          // Return as plain text
          return <span key={indexBuyer}>{partItem}</span>;
        });
      };

    renderSuggestionsBuyer() {
        const { isSuggestionVisibleBuyer, suggestionsTagListBuyer } = this.state;
    
        if (!isSuggestionVisibleBuyer) return null;
    
        return (
            <div
                className={`suggestion-box ${this.state.suggestionPositionBuyer === 'above' ? 'above-input' : 'below-input'
                    }`}
            >
                {suggestionsTagListBuyer.map((suggestionBuyer,index) => (
                    <div
                        key={index}
                        data-test-id="tagItemTestID"
                        onClick={() => this.handleTagSelectionBuyer(suggestionBuyer.user_name)}
                        className="suggestion-item"
                    >
                        <Avatar
                            alt="User Avatar"
                            className="avatar"
                            src={suggestionBuyer.profile_photo}
                        />
                        <p>
                            {suggestionBuyer.user_name}
                        </p>
                    </div>
                ))}
            </div>
        );
    }
    renderStorySection = () => {
        return (
            <div
                className="storyViewContainer" ref={this.storyBoxRef} >
                {this.state.showLeftStories &&
                    <IconButton className="leftArrow" data-test-id="prevBtnTestID" onClick={this.scrollStoriesLeft}>
                        <ArrowBackIos />
                    </IconButton>}
        {this.state.allStoryHeader.map((item, userIndex) => (
            <>
            
                   {this.state.loader &&
                    <>
                        <ContentLoader
                            height={100}
                            width={100}
                            speed={1}
                            viewBox="0 0 90 90"
                        >
                            <circle cx="40" cy="40" r="40" />
                        </ContentLoader>
                    </>}
            {
            <Box
                className="feedBtn"
                data-test-id={`openStoryTestID${userIndex}`}
                key={userIndex}
                onClick={() => this.handleClickOpen(userIndex)}>
                <Box
                    className={item.attributes.seen ? "storyBorderSeen storyBorder" : "storyBorder"}>
                    <img
                        data-test-id={`imgUserTestID${userIndex}`}
                        className="story"
                        src={item.attributes.creator_details.profile_url ?item.attributes.creator_details.profile_url : profileImg} />
                </Box>
            </Box>
    }
    </>
        ))}
        {this.state.showRightStories && <IconButton data-test-id="nextBtnTestID" className="rightArrow" onClick={this.scrollStoriesRight}>
            <ArrowForwardIos />
        </IconButton>}
        <StoryListing
            allStoryHeader={this.state.allStoryHeader}
            toggleMute={this.toggleMute}
            storyMuted={this.state.storyMuted}
            likeStory={this.likeStory}
            calculateHours={this.calculateHours}
            data-test-id="storyModalTestId"
            currentStoryIndex={this.state.currentStoryIndex}
            setSwiperRef={this.setSwiperRef}
            showNextButton={this.showNextButton}
            userStory={this.state.userStory}
            changeStory={this.changeStory}
            showPrevButton={this.showPrevButton}
            instaModalIndex={this.state.instaModalIndex}
            instaModal={this.state.instaModal}
            handleNext={this.handleNext}
            handleClose={this.handleClose}
            handlePrevNext={this.handlePrevNext}
            handlePrev={this.handlePrev}
            storyId={this.handleStoryId}
            commentMessage={this.handleMessage}
            isMessageSent={this.state.channelUrl}
        />
    </div>)};

    renderShareModalView = () => {
        const { shareUrl, isShareModal } = this.state;
        return (
            <CustomShareModal open={isShareModal} onClose={this.handleShareModalToogle}>
                <Box className="shareModalContainer">
                    <Box className="shareModalHeader">
                        <Typography className="headingModal">{configJSON.shareTxt}</Typography>
                        <Box className="cancelIconBox">
                            <IconButton onClick={this.handleShareModalToogle}><img src={cancelIcon} /></IconButton>
                        </Box>
                    </Box>
                    <Box className="shareButtonContainer">
                        <Box className="shareButtonBox">
                            <FacebookShareButton className="socialButton" url={shareUrl} quote="Check out this Flixoo Live Post!!">
                                <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>
                            <Typography className="shareLabelTxt">{configJSON.facebookTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <WhatsappShareButton className="socialButton" url={shareUrl} title="Check out this Flixoo Live Post!!">
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <Typography className="shareLabelTxt">{configJSON.whatsappTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <IconButton className="customSocialBtn">
                                <img src={instagramLogo} alt="Instagram" className="instagramIcon" />
                            </IconButton>
                            <Typography className="shareLabelTxt">{configJSON.instagramTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <TelegramShareButton className="socialButton" url={shareUrl} title="Check out this Flixoo Live Post!!">
                                <TelegramIcon size={50} round={true} />
                            </TelegramShareButton>
                            <Typography className="shareLabelTxt">{configJSON.telegramTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <IconButton className="customSocialBtn" onClick={this.handleCopyLink} data-test-id="copyLinkTestId">
                                <FileCopyOutlinedIcon className="copyIcon" />
                            </IconButton>
                            <Typography className="shareLabelTxt">{configJSON.copyLink}</Typography>
                        </Box>
                    </Box>
                </Box>
            </CustomShareModal>
        )
    };

    renderCommentCntainer = () => {
        return (
            <CommentContainer>
                <Avatar
                    className="avatar"
                    src={this.state.listCommentData.meta.current_user.profile_photo}
                    alt="User Avatar"
                />
                <div style={{ position: "relative" }}>
                    <TextField
                        variant="outlined"
                        placeholder="Add a Comment"
                        className="input"
                        fullWidth
                        data-test-id="changeValueTestId"
                        value={this.state.addComment}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDownBuyer}
                    />
                    {this.renderSuggestionsBuyer()}
                </div>
                <Button
                    variant="outlined"
                    className={this.state.addComment.length === 0 || this.state.addComment.trim() === '' || this.state.addComment.trim() === '@' ? "desibleButton" : "postButton"}
                    onClick={this.state.setReplyId === "" ? this.postCommentApiCall : this.postCommentOnCommentApiCall}
                    disabled={!this.state.addComment || this.state.addComment.trim() === '' || this.state.addComment.trim() === '@'}
                >
                    POST
                </Button>
            </CommentContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <PostListingStyleWrapper>
                {this.renderShareModalView()}
                <Loader loading={this.state.isLoading} />
                <Box className="mainContainer">
                    <Snackbar data-test-id="snackbarTestId" open={this.state.isToastOpen} autoHideDuration={6000} onClose={this.handleToggleToast} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                        <Alert severity={this.state.severity} onClose={this.handleToggleToast}>
                            {this.state.toastMessage}
                        </Alert>
                    </Snackbar>
                    <Box className="gridBox">
                        
                    </Box>
                    <Box className="middleGridBox">
                        {this.renderStorySection()}
                        <PostListContainer>
                            <div className="postMainContainer" ref={this.postContainerRef}>
                                <Box className="postContainer">
                                    {this.state.buyerPostData.map((postData: PostDataPayload, postIndex: number) => {
                                        return (
                                            <PostCard
                                                data-test-id={`postCardCompTestId${postIndex}`}
                                                item={postData}
                                                postKeyId={postIndex}
                                                postId={postData.id}
                                                handleToggleLikePost={this.handleToggleLikePost}
                                                handleToggleComment={this.handleToggleComment}
                                                clickFollowButton={this.handleCreatorFollow}
                                                handlePostShareToggle={this.handlePostShareToggle}
                                                clickTageListButton={this.clickTageListButtonBuyer}
                                                closeTagUserList={this.closeTagUserListBuyer}
                                                varientProductClick = {this.handleClickBuyerProductVarient}
                                                handleNavigateToProfile= {this.handleNavigateToProfile}
                                            />
                                        )
                                    })}
                                    {(this.state.isFeedLoader && this.state.hasMorePosts ) ? <CircularProgress />: ''}
                                </Box>
                            </div>
                        </PostListContainer>
                    </Box>

                    {
                        this.state.showCommentBox === "commentbox" ?
                            <Box className="suggestionCommentBox">
                                <Box className="heade-txt-comment" >
                                    <Typography className="suggestionForTxt">Comments</Typography>
                                    <img
                                        style={{ cursor: "pointer" }}
                                        src={crossIcon}
                                        alt="Close"
                                        onClick={this.closeComment}
                                    />
                                </Box>
                                <Box className="suggestionMainCommentContainer">
                                    {this.state.listCommentData.data.map((item, itemIndex) => (
                                        <Box className="suggestedUserBox" key={itemIndex} >
                                            <Box className="suggestionLeftBoxForComment">
                                                <Box className="avatarSuggestionBox">
                                                    <Avatar src={item?.attributes?.details?.profile_photo} className="suggestionAvatar" />
                                                </Box>
                                                <Box className="suggestionInfoBox2">
                                                    <Typography className="userNameTxt cursorPointer" onClick={()=> this.handleNavigateToProfile(String(item.attributes.details.id), item.attributes.details.role)}>{item?.attributes?.details?.full_name}</Typography>
                                                    <Typography className="userFullNameTxt">
                                                        {this.parseAndHighlightMentionsBuyer(
                                                            String(item?.attributes?.details?.comment || '')
                                                        )}
                                                    </Typography>

                                                    <Box className="suggestionLinkComment">
                                                        <Typography className="userNameTxt">{item?.attributes?.details?.created_at}</Typography>
                                                        <Typography
                                                            data-test-id="likeComment"
                                                            onClick={() => this.likeCommentApiCall(item.id)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: conditionalRenderer((item.attributes.details.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                            }}
                                                            className="userFullNameTxt">{item?.attributes?.details?.like_count} Likes
                                                        </Typography>
                                                        <Typography className="userFullNameTxt" style={{ cursor: 'pointer', color: item.id === this.state.setReplyId ? "rgb(245, 234, 22)" : "" }} data-test-id="doubleTap" onClick={() => this.clickOnReplyForGetId(item.id)}>Reply</Typography>
                                                    </Box>

                                                    {item?.attributes?.details?.replies_count > 0 &&
                                                        <ReplyContainer>
                                                            <div className="blankLine"></div>
                                                            <Typography variant="body2" data-test-id='clickReply' className="text" onClick={() => this.viewDetilsExpandable(parseInt(item?.id))}>
                                                              {!this.state.clickReply.includes(parseInt(item.id))  ? "View" : "Hide"} {item?.attributes?.details?.replies_count} replies
                                                            </Typography>
                                                        </ReplyContainer>}

                                                    {
                                                        this.state.clickReply.includes(parseInt(item.id)) &&
                                                        item.attributes.details.sub_post_comments.map((value, itemIndex) => (
                                                            <Box className="suggestionLeftBoxForComment" key={itemIndex}>
                                                                <Box className="avatarSuggestionBox">
                                                                    <Avatar src={value.profile_photo} className="suggestionAvatar" />
                                                                </Box>
                                                                <Box className="suggestionInfoBox2">
                                                                    <Typography className="userNameTxt cursorPointer" onClick={()=> this.handleNavigateToProfile(String(value.id), value.role)}>{value.full_name}</Typography>
                                                                    <Typography className="userFullNameTxt">
                                                                        {this.parseAndHighlightMentionsBuyer(
                                                                            String(value.comment || '')
                                                                        )}
                                                                    </Typography>

                                                                    <Box className="suggestionLinkComment">
                                                                        <Typography className="userNameTxt" style={{ marginTop: '4px' }}>{value.created_at}</Typography>
                                                                        <Typography data-test-id="replyCmmntId"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                color: conditionalRenderer((value.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                                            }}
                                                                            onClick={() => this.likeReplyCommentApiCall(value?.sub_comment_id)} className="userFullNameTxt">
                                                                            {value.like_count} Likes
                                                                        </Typography>
                                                                    </Box>

                                                                </Box>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                {this.renderCommentCntainer()}
                            </Box> :
                            this.renderSuggetionSection()
                    }
                </Box>
            </PostListingStyleWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PostListingStyleWrapper = styled(Box)({
    marginTop: "104px",
    "& .suggestion-box": {
        background: "white",
        position: "absolute",
        borderRadius: "4px",
        border: "1px solid #ddd",
        width: "100%",
        zIndex: 1000,
        marginBottom: "4px",
        bottom: "100%",
    },
    "& .suggestion-item": {
        cursor: "pointer",
        padding: "8px",
        alignItems: "center",
        display: "flex",
        gap: "8px"
    },
    "& .suggestion-box.below-input": {
        top: "100%",
        marginTop: "8px",
    },
    "& .suggestion-box.above-input": {
        bottom: "100%",
        marginBottom: "8px",
    },
    "& .suggestion-item:hover": {
        backgroundColor: "#f0f0f0",
    },
    "@media(max-width: 1150px)": {
        marginTop: "71px",
    },
    "& .mainContainer":{
        display:"flex"
    },
    "& .gridBox": {
        width: "100%",
        maxWidth:"244px",
        borderRight:"3px solid #D9D9D94D",
        padding:"0px 20px",
        backgroundColor:" #000000",
        "@media(max-width:700px)": {
            display:"none"
        },
    },
    "& .middleGridBox": {
        width: "100%",
        position: "relative",
        overflowX:"hidden",
        display: "flex",
        flexDirection: "column"
    },
    "& .suggestionBox": {
        borderLeft: "1px solid #44444433",
        width: "100%",
        maxWidth:"427px",
        marginTop: "30px",
        paddingLeft: "24px",
        paddingRight: "60px",
        "@media(max-width:800px)": {
            display:"none"
        },
    },
    "& .suggestionCommentBox": {
        borderLeft: "1px solid #44444433",
        width: "100%",
        maxWidth:"440px",
        marginTop: "30px",
        paddingLeft: "24px",
        paddingRight: "60px",
        "@media(max-width:800px)": {
            display:"none"
        },
    },
    "& .suggestionMainCommentContainer":{
        overflowY: 'scroll'
    },
    "& .storyViewContainer": {
        width:"100%",
        display: "flex",
        padding: "0px 20px",
        boxSizing: "border-box",
        gap: "24px",
        overflowX: "scroll",
        scrollbarWidth: "none",
        marginTop: "46px",
        paddingBottom: "12px",
        scrollBehavior: "smooth",
        marginBottom: "20px",
        "&:hover": {
            "& .leftArrow, & .rightArrow": {
                display: "flex"
            }
        },
        "&::-webkit-scrollbar": {
            height: "4px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    "& .feedBtn": {
        cursor: "pointer",
    },
    "& .storyBorder": {
        width: "74px",
        height: "74px",
        borderRadius: "60px",
        display: "flex",
        padding: "2.46px",
        background: "linear-gradient(130.56deg, #EB3AA7 9.42%, #6649D9 87%)",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .storyBorderSeen": {
        background: "grey !important"
    },
    "& .story": {
        borderRadius: "60px",
        padding: "4.07px",
        background: "#FFFFFF",
        width: "70px",
        height: "70px",
    },
    "& .leftArrow, & .rightArrow": {
        position: "absolute",
        top: "84px",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "28px",
        height: "28px",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px"
    },
    "& .rightArrow": {
        right: 10
    },
    "& .leftArrow": {
        left:10
    },
    "& .heade-txt-comment": {
        display:"flex",
        alignItems: "center",
        justifyContent:"space-between"
    },
    "& .suggestionForTxt": {
        fontWeight: 500,
        fontSize: "16px",
        color: "#000000",
    },
    "& .userNameTxt": {
        color: "#444444",
        fontWeight: 500,
        fontSize: "14px",
    },
    "& .cursorPointer":{
        cursor:"pointer"
    },
    "& .userRole":{
        color:"grey",
        fontSize:"12px",
        marginLeft:"2px"
    },
    "& .suggestionInfoBox":{
        cursor:"pointer"
    },
    "& .userFullNameTxt": {
        color: "#44444480",
        fontWeight: 400,
        fontSize: "14px",
        marginTop: "4px"
    },
    "& .suggestedUserBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "16px"
    },
    "& .suggestionLeftBox": {
        display: "flex",
        gap: "12px",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .followUnfollowBtn": {
        textTransform: "none",
        border: "1px solid #F5EA16",
        borderRadius: "50px",
        padding: "6px 16px"
    },
    "& .followBack": {
        background:"#f5ea16"
    },
    "& .categoryTxt":{
        color:"#9695A1",
        fontSize:"16px",
        fontWeight:400,
        paddingLeft:"20px"
    },
    "& .headerCategoryBox":{
        paddingBottom:"10px",
        borderBottom:"1px solid #F2F3F6",
        marginTop:"40px"
    },
    "& .categoryInnerBox":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:"27px",
        marginLeft:"20px"
    },
    "& .categoriesBox":{
        marginTop:"14px"
    },
    "& .categoryTitleTxt":{
        fontSize:"12px",
        fontWeight:400,
        color:"#000000"
    },
    "& .suggestionLeftBoxForComment":{
        display: "flex",
        gap: "12px",
        // justifyContent: "center",
        alignItems: "start",
        marginTop: '10px'
    },
    "& .suggestionLinkComment":{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '15px'
    }
});
const PostListContainer = styled(Box)({
    "& .postMainContainer": {
        height: "calc(100vh - 152px)",
        overflowY: "auto",
        scrollBehavior: "smooth",
    },
    "& .postContainer": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
})

const CommentContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '50px',
    // padding: '10px',
    maxWidth: '500px',
    marginRight: '10px',
    marginTop: '20px',
    "& .avatar":{
        marginLeft: '10px',
    },
    "& .input": {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
            borderColor: 'transparent',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
    },
    "& .postButton": {
        marginLeft: '10px',
        borderRadius: '30px',
        fontWeight: 'bold',
        padding: '6px 16px',
        border: 'none',
        color: "blue"
      },
      "& .desibleButton": {
        borderRadius: '30px',
        border: 'none',
        fontWeight: 'bold',
        padding: '6px 16px',
        marginLeft: '10px',
        color: "default"
    }
})

const ReplyContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    '& .blankLine': {
        width: '50px',
        height: '3px',
        background: '#B5B3A76E',
        marginRight: '10px'
    },
    '& .text': {
        color: '#1E1A00', // light grey color
        cursor: 'pointer', // Change cursor to pointer on hover
        '&:hover': {
            textDecoration: 'underline',
        },
    },
})

const CustomShareModal = styled(Modal)({
    "& .shareModalContainer": {
        top: "50%",
        position: "absolute",
        border: "none",
        left: "50%",
        padding: "10px 20px",
        transform: "translate(-50%, -50%)",
        background: "white",
        borderRadius: "24px",
        maxWidth: "fit-content",
        width: "100%",
    },
    "& .shareButtonBox": {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .shareButtonContainer": {
        gap: "25px",
        display: "flex",
        flexWrap: "wrap",
        padding: "30px",
        alignItems: "center",
        paddingTop:"14px",
        justifyContent: "center",
    },
    "& .shareModalHeader": {
        justifyContent: "space-between",
        display: "flex",
        padding: "0px 2px",
        marginTop: "10px",
        alignItems: "center",
    },
    "& .headingModal": {
        fontWeight: 600,
        fontSize: "18px",
    },
    "& .socialButton": {
        padding: "12px !important"
    },
    "& .customSocialBtn":{
        outline: "none", 
        background: "transparent", 
        border: 'none', 
    },
    "& .copyIcon":{
        height:"50px",
        width:"50px",
    },
    "& .instagramIcon":{
        borderRadius: '50%' ,
        width: '50px', 
        height: '50px', 
    },
    "& .shareLabelTxt":{
        fontWeight:600,
        fontSize:"16px",
        color:"grey",
    }
});
// Customizable Area End
