import React from "react";
// Customizable Area Start
import { ScrollView, StyleSheet } from "react-native";
// Customizable Area End

import SellersNotificationController, {
    Props,
} from "./SellersNotificationController";

export default class SellersNotification extends SellersNotificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ScrollView
                keyboardShouldPersistTaps="always"
                style={styles.container}>
            </ScrollView>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = StyleSheet.create({
    container: {
        flex: 1
    }
});
// Customizable Area End
