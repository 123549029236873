// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
export const configJSON = require("./config");
import { load } from "@cashfreepayments/cashfree-js";
const baseURL = require("../../../framework/src/config.js").baseURL

interface CashfreeResultPayload {
    error?: string;
    redirect?: string
}

interface PaymentPayloadType {
  order_id: string,
  payment_session_id: string;
}

interface InitiatePaymentApiResponse {
  data : PaymentPayloadType
}

interface BankDetails {
  bankAccountNumber: string;
  bankName: string;
  ifscCode: string;
  receiversName: string;
}

interface ErrorItem {
  [key: string]: string; 
};

export interface SnackBarItem{
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
};

interface WalletResponse {
  data : {
    success: boolean,
    wallet: {
        balance: string;
        wallet_id: string;
        last_updated_at: string;
    }
  }
}

interface TransactionInter {
  id: number;
  account_id: number;
  cashfree_wallet_id: number;
  amount: string;
  transaction_type: string;
  status: string;
  reference_id: string;
  transaction_id: string;
  withdrawal_id: number | null;
  remarks: string;
  utr_number: string | null;
  request_data: {}; 
  response_data: {
    status: string;
  };
  processed_at: string;
  created_at: string;
  updated_at: string;
}

interface TransactionRes {
  total_count: number;
  page: number;
  per_page: number;
  data :{
    transactions: Array<TransactionInter>
  }
}

interface WithdrawalResponse {
  data: {
    success: boolean;
    message: string;
    transaction_id: string;
    withdrawal_id: string;
    amount: number;
    new_balance: number;
    reference_id: string;
    transfer_type: string;
    status: string;
  };
}
// Customizable Area End


export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTransferOpen: boolean;
  isAddOpen: boolean;
  transferAmt: number;
  addAmount: number;
  isLoading: boolean;
  snackBarProps: SnackBarItem;
  walletId: string | null;
  walletAmt: string;
  totalCount: number;
  currentPage: number;
  transactionDetails: Array<TransactionInter>;
  errorMessage: string;
  errorModal: boolean;
  isBankDetailsModal: boolean;
  bankDetails: BankDetails;
  errors: BankDetails;
  addAmountError: string;
  transferError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class Cfflixoolivewallet0Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMySelfWalletApiCallId: string = "";
  getWalletTransactionsApiCallId: string = "";
  postCreateDepositeApiCallId: string = "";
  postSelfWithdrawApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isTransferOpen: false,
      isAddOpen: false,
      transferAmt: 0,
      addAmount: 0,
      snackBarProps: {
        isMessage: "",
        isOpen: false,
        isSeverity: "success",
      },
      walletAmt: "",
      isLoading: false,
      walletId: null,
      transactionDetails: [],
      totalCount: 0,
      currentPage: 0,
      errorMessage: "",
      errorModal: false,
      isBankDetailsModal: false,
      bankDetails: {
        bankAccountNumber: '',
        bankName: '',
        ifscCode: '',
        receiversName: '',
      },
      errors: {
        bankAccountNumber: '',
        bankName: '',
        ifscCode: '',
        receiversName: '',
      },
      addAmountError:"",
      transferError:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getMyWalletApiCall();
    this.getMyTransactionDataApiCall();
  };

  // Customizable Area Start
  async receive(from: string, message: Message) {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiREqCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (responseJson) {
      switch (apiREqCallId) {
        case this.getMySelfWalletApiCallId:
          this.getSelfWalletResponse(responseJson);
          break;
        case this.getWalletTransactionsApiCallId:
          this.getTransactionResponse(responseJson);
          break;
        case this.postCreateDepositeApiCallId:
          this.getCreateDepositeResponse(responseJson);
          break;
        case this.postSelfWithdrawApiCallId:
          this.getTransferAmountResponse(responseJson);
        default:
          break;
      }
    }
    // Customizable Area End
  }

  apiCall = async (apiRequestData: { contentType?: string; method: string; endPoint: string; body?: object | string; type?: string; }) => {
    const { method, endPoint, body, type, contentType } = apiRequestData;
    const userToken = await getStorageData("buerLoginToken");
    const header = {
      "Content-Type": contentType,
      token: userToken,
    };
    const apiReqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiReqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiReqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    apiReqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
    apiReqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : apiReqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(apiReqMsg.id, apiReqMsg);
    return apiReqMsg.messageId;
  };

  handleAllApiErrors = (responseJson: { errors: Array<ErrorItem> }) => {
    let apiError = responseJson.errors[0];
    let errorMsg = apiError?.token ? apiError?.token : apiError?.message;
    this.setState({
      snackBarProps: {
        isMessage: errorMsg ? errorMsg : "Something went wrong!",
        isOpen: true,
        isSeverity: "error",
      },
      isLoading: false
    });
  };

  postDepositeApiCall = async () => {
    const { addAmount } = this.state;
    if(addAmount === 0){
      this.setState({addAmountError: 'Please enter a valid amount'});
      return;
    }
    this.setState({isLoading: true});
    this.postCreateDepositeApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.createDepositeOrder + `?amount=${addAmount}`,
    });
  };

  getCreateDepositeResponse = (responseJson: InitiatePaymentApiResponse & { errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      this.handleCashfreePayment(responseJson.data.payment_session_id, responseJson.data.order_id);
    } else if (responseJson.errors) {
      this.handleAllApiErrors(responseJson);
    }
  };

  handleCashfreePayment = async (sessionId: string, orderId: string) => {
    this.setState({isLoading: false});
    await setStorageData('isCheckout', true);
    const returnBaseUrl = baseURL.replace('ruby', 'react');
    const cashfree = await load({
      mode: "sandbox",
    });
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${returnBaseUrl}/Payment/Cashfree/Status?orderId=${orderId}&role=buyer`
    };
    cashfree.checkout(checkoutOptions).then((result: CashfreeResultPayload) => {
      if (result.error) {
        this.setState({ snackBarProps: { isSeverity: "error", isMessage: "Something Went wrong!!", isOpen: true, } });
      }
    });
  };

  showError = (errorMessage: string) => {
    this.setState({ errorMessage: errorMessage, errorModal: true });
  };

  errorModalToggleClose = () => {
    this.setState({ errorMessage: "", errorModal: false });
  };

  toggleBankDetailsModal = () => {
    this.setState({isBankDetailsModal: !this.state.isBankDetailsModal});
  };

  openDetailsModal = () => {
    const {transferAmt} = this.state;
    if(transferAmt === 0){
      this.setState({transferError: 'Please enter a valid amount'});
      return;
    }
    this.setState({isBankDetailsModal: !this.state.isBankDetailsModal});
  }

  validateForm = (): boolean => {
    const newErrors: BankDetails = {
      bankAccountNumber: '',
      bankName: '',
      ifscCode: '',
      receiversName: '',
    };
    let isValid = true;

    const isValidBankAccountNumber = (accountNumber: string): boolean =>
      accountNumber.length >= 8 && /^[0-9]+$/.test(accountNumber);

    const isValidBankName = (bankName: string): boolean =>
      bankName.length >= 2 && !/\d/.test(bankName);

    const isValidIfscCode = (ifscCode: string): boolean =>
      /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/.test(ifscCode);

    const isValidReceiversName = (receiversName: string): boolean =>
      receiversName.length >= 2 && !/\d/.test(receiversName);

    if (!isValidBankAccountNumber(this.state.bankDetails.bankAccountNumber)) {
      newErrors.bankAccountNumber = 'Invalid account number';
      isValid = false;
    }
    if (!isValidBankName(this.state.bankDetails.bankName)) {
      newErrors.bankName = 'Invalid bank name';
      isValid = false;
    }
    if (!isValidIfscCode(this.state.bankDetails.ifscCode)) {
      newErrors.ifscCode = 'Invalid IFSC code';
      isValid = false;
    }
    if (!isValidReceiversName(this.state.bankDetails.receiversName)) {
      newErrors.receiversName = 'Invalid receiver\'s name';
      isValid = false;
    }

    this.setState({ errors: newErrors });
    return isValid;
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      bankDetails: {
        ...prevState.bankDetails,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: '', 
      },
    }));
  };

  handleSubmit = () => {
    if (this.validateForm()) {
      this.postSelfWithdrawApiCall()
    }
  };

  postSelfWithdrawApiCall = async () => {
    this.setState({ isLoading: true, isBankDetailsModal: false });
    this.postSelfWithdrawApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.selfWithdrawEndPoint,
      body: {
        "amount": this.state.transferAmt,
        "account_number": this.state.bankDetails.bankAccountNumber,
        "ifsc_code": this.state.bankDetails.ifscCode,
        "account_holder_name": this.state.bankDetails.receiversName,
        "bank_name": this.state.bankDetails.bankName
      }
    });
  };

  getTransferAmountResponse = (responseJson: WithdrawalResponse & { errors: Array<ErrorItem> }) => {
    this.setState({ isLoading: false, bankDetails: { bankAccountNumber: '', bankName: '', ifscCode: '', receiversName: '' }, transferAmt: 0, isTransferOpen: false });
    if (responseJson?.data) {
      if (responseJson.data.success) {
        this.setState({ errorMessage: "Transfer initiated successfully. Funds will be processed and reflected in your account shortly.", errorModal: true }, ()=> {
          this.getMyWalletApiCall();
          this.getMyTransactionDataApiCall();
        });
      }else {
        this.setState({
          snackBarProps: {
            isMessage: "Something went wrong!",
            isOpen: true,
            isSeverity: "error",
          },
        });
      }
    }else if (responseJson.errors) {
      this.handleAllApiErrors(responseJson)
    }
  };

  getMyWalletApiCall = async () => {
    this.setState({ isLoading: true });
    this.getMySelfWalletApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.selfWalletEndPoint,
    });
  };

  getMyTransactionDataApiCall = async () => {
    this.getWalletTransactionsApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.transactionEndPoint,
    });
  };

  getSelfWalletResponse = (response: WalletResponse & { errors: Array<ErrorItem> }) => {
    this.setState({ isLoading: false });
    if (response?.data) {
      this.setState({ walletAmt: response.data.wallet.balance, walletId: response.data.wallet.wallet_id });
    } else if (response?.errors) {
      this.handleAllApiErrors(response);
    }
  };

  getTransactionResponse = (response: TransactionRes & { errors: Array<ErrorItem> }) => {
    if (response?.data) {
      this.setState({ transactionDetails: response?.data.transactions });
    } else if (response?.errors) {
      this.handleAllApiErrors(response);
    }
  };

  handleCloseSnack = () => {
    this.setState({ snackBarProps: { isSeverity: "success", isMessage: "", isOpen: false, } });
  };

  handleTransferToogle = () => {
    this.setState({ isTransferOpen: !this.state.isTransferOpen });
  };

  handleAmountToogle = () => {
    this.setState({ isAddOpen: !this.state.isAddOpen });
  };

  handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const regex = configJSON.amountRegex;
    if (regex.test(value)) {
      this.setState({ addAmount: Number(value), addAmountError: '' });
    }
  };

  handleChangeTransferAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const regex = configJSON.amountRegex;
    if (regex.test(value)) {
      this.setState({ transferAmt: Number(value), transferError: '' });
    }
  };

  returnSign = (transactionStatus: string, status: string) => {
    if (transactionStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'completed') {
      return '+';
    } else if (transactionStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'failed') {
      return '';
    } else if (transactionStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'pending') {
      return '';
    } else if (transactionStatus.toLowerCase() === 'bank_transfer' && status.toLowerCase() === 'pending') {
      return '';
    }
    return '-';
  };

  checkDeposite = (transactionStatus: string, status: string) => {
    if (transactionStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'completed') {
      return '#0DAD01';
    } else if (transactionStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'failed') {
      return '#ED2224';
    } else if (transactionStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'pending') {
      return '#F5EA16';
    } else if (transactionStatus.toLowerCase() === 'bank_transfer' && status.toLowerCase() === 'completed') {
      return '#0DAD01';
    } else if (transactionStatus.toLowerCase() === 'bank_transfer' && status.toLowerCase() === 'pending') {
      return '#F5EA16';
    }
    return '#ED2224';
  };
  // Customizable Area End
}
