import React from "react";

// Customizable Area Start
import SellerWalletController, {
    Props,
} from "./SellerWalletController";
import { Box, Button, styled, Typography } from "@material-ui/core";
import { failedLottie, successLottie } from "./assets";
import Lottie from "lottie-react";
import { whiteAppLogo } from "../../landingpage/src/assets";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import Loader from "../../../components/src/Loader.web";
import { Link } from 'react-router-dom';
// Customizable Area End

export default class SellerWallet extends SellerWalletController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderSuccessModal = () => {
    return (
      <Box className="innerContainer">
        <Lottie animationData={successLottie} loop={false} className="successAnimation" />
        <Box className="succesContentBox">
          <Typography className="titleText">Transaction Successful!!</Typography>
          <Typography className="subContent">Your wallet balance :- ₹{this.state.newWalletBalance}</Typography>
          <Typography className="subContent">Order Id :- {this.state.transactionId}</Typography>
          {this.renderGoToWalletButton()}
        </Box>
      </Box>
    )
  };

  renderFailModal = () => {
    return (
      <Box className="innerFailContainer">
        <Lottie animationData={failedLottie} loop={false} className="failedAnimation" />
        <Box className="failContentBox">
          <Typography className="titleText">Transaction Failed!!</Typography>
          <Box className="failedTextBox">
              <Typography className="subContent">Your payment failed. This could be due to insufficient funds, incorrect card details, or a connection error. Please verify your information and try again.</Typography>
          </Box>
          <Typography className="subContent">Order Id :- {this.state.transactionId}</Typography>
          {this.renderGoToWalletButton()}
        </Box>
      </Box>
    )
  };

  renderGoToWalletButton = () => {
    return (
      <Button className="goToWalletBtn" onClick={()=> this.navigateToWalletScreen(this.state.userRle)}>Go To Wallet</Button>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <StyleWrapper>
          <Box className="headerBox">
            <Box className="logoBox">
                <img src={whiteAppLogo} alt="" className="appLogo" />
            </Box>
          </Box>
          <Box className="mainContainer">
              {this.state.isStatusRes && (this.state.isSuccess ? this.renderSuccessModal(): this.renderFailModal())}
          </Box>
          <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.snackBarItems} handleCloseSnack={this.handleCloseSnack} />
          <Loader loading={this.state.walletLoader} />
          <Button id='navigateButtonLink' component={Link} to={this.state.navigatePath} disableRipple className="hiddenButtno"></Button>
        </StyleWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
  minHeight:"100vh",
  "& .headerBox":{
    width: '100%',
    height: '100px',
    backgroundColor: '#000000',
  },
  "& .logoBox":{
    marginLeft: '64px',
  },
  "& .mainContainer":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"70%",
  },
  "& .successAnimation":{
    width:"200px",
    height:"200px"
  },
  "& .failedAnimation":{
    width:"300px",
  },
  "& .innerContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column",
    textAlign:"center"
  },
  "& .innerFailContainer":{
    justifyContent: "center",
    display: "flex",
    flexDirection:"column",
    gap:"10px",
    alignItems: "center",
    textAlign:"center"
  },
  "& .titleText":{
    fontSize:"26px",
    fontWeight:500
  },
  "& .failedTextBox":{
    width:"500px"
  },
  "& .subContent":{
    marginTop:"5px",
    color:"#909090"
  },
  "& .hiddenButtno":{
    display:"hidden"
  },
  "& .goToWalletBtn":{
    backgroundColor: "#F5EA16",
    padding: "12px 8px",
    width: "100%",
    minWidth: "163px",
    maxWidth: "163px",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#444444",
    borderRadius: "8px",
    marginTop: "30px"
  }
});
// Customizable Area End
