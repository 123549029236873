import React, { Component } from "react";
import { Grid, Box, Typography, Avatar, Button,styled } from "@material-ui/core";
import { CreatorMalls } from "../../blocks/landingpage/src/ViewAllProductsController";

const ScrollContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  padding: "10px 0",
  gap: "16px",
  "&::-webkit-scrollbar": {
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
});

const ImageCard = styled(Box)({
  flex: "0 0 auto",
  width: "158px",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  position: "relative",
  "& img": {
    width: "100%",
    height: "120px",
    objectFit: "cover",
  },
  "& button": {
    width:"132px",
    position: "absolute",
    bottom: "19px",
    left: "42%",
    transform: "translateX(-50%)",
    background: "none",
    color: "#fff",
    fontSize: "16px",
    padding: "4px 8px",
    fontWeight: 500,
    borderRadius: "16px",

  },
});

interface HeaderProps {
  title: string;
  description: string;
  avatarUrl: string;
  images: Array<CreatorMalls>;
  buttonLabel?: string;
  onButtonClick: (event:  React.MouseEvent<HTMLElement, MouseEvent>, index: number) => void;
  handleCardClick: () => void;
}

class Header extends Component<HeaderProps> {
  static defaultProps = {
    buttonLabel: "Visit Store →",
    onButtonClick: () => {},
  };

  render() {
    const { title, description, avatarUrl, images, buttonLabel, handleCardClick, onButtonClick } = this.props;

    return (
      <Box onClick={handleCardClick} sx={{ width: "574px", margin: "0 auto", padding: "1px 24px", border: "1px solid #6E6E6E33", borderRadius: "12px" }} style={{cursor: "poniter"}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar src={avatarUrl} alt="Profile"  style={{width:"64px", height:"64px"}} />
          </Grid>
          <Grid style={{marginTop:"25px"}} item xs>
            <Typography style={{fontSize:"18px", fontWeight: 600, color:"#2E2E2E"}}>{title}</Typography>
            <Typography style={{fontSize:"14px", fontWeight: 400, color: "##6E6E6E33"}}>
              {description}
            </Typography>
          </Grid>
        </Grid>

        <ScrollContainer>
          {images?.map((image: CreatorMalls, index: number) => (
            <ImageCard key={index}>
              <img src={image.attributes.banner_thumbnail} alt={`image${index}`} />
              <Button onClick={(event) => onButtonClick(event,JSON.parse(image.id))}>{buttonLabel}</Button>
            </ImageCard>
          ))}
        </ScrollContainer>
      </Box>
    );
  }
}

export default Header;
