import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

// Customizable Area Start
interface ErrorItem {
  [key: string]: string; 
};

export interface SnackBarP{
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
};

interface ProductImage {
  id: number;
  url: string;
}

interface Store {
  id: number;
  name: string;
  business_name: string;
  phone_number: string;
  email: string;
  address: null | string;
  profile_photo: string;
  background_image: string;
}

export interface Product {
  id: string;
  type: string;
  attributes: {
    brand: null | string;
    title: string;
    description: string;
    status: string;
    mrp: number;
    selling_price: number;
    ask_price: number;
    bargain: boolean;
    on_order: boolean;
    is_ask_price: boolean;
    is_brand: boolean;
    tags: [];
    reviews: [];
    sku: string;
    is_affiliated: boolean;
    affiliated_discount: string;
    affiliated_price: string;
    affiliated_link: null | string;
    images: null | string;
    host_name: string;
    is_variant: boolean;
    host_bio: null | string;
    host_image: string;
    product_images: ProductImage[];
    product_videos: null | string;
    product_detail: null | string;
    average_rating: number;
    store: Store;
  };
}

interface ApiResponse {
  data: {
    [key: string]: Product[];
  };
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  snackBarItems: SnackBarP;
  creatorLoader : boolean;
  productData : null | ApiResponse;
  categoryNames : Array<string>;
  isProdData: boolean;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class AffiliateMarketPlaceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAffiliateDataApiCallId : string = "";
  getAffiliatedLinkApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: true,
      snackBarItems: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      creatorLoader: false,
      productData : null,
      categoryNames : [],
      isProdData: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (responseJson) {
      if(apiRequestCallId === this.getAffiliateDataApiCallId ){
        this.getAffiliateDataApiResponse(responseJson);
      } else if(apiRequestCallId === this.getAffiliatedLinkApiCallId){
        this.affiliateLinkResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  async componentDidMount() {
    this.getAffiliateMarketPlaceData();
  }

  apiCall = async (apiReqData: { contentType?: string; method: string; endPoint: string; body?: object | string; type?: string; }) => {
    const { contentType, method, endPoint, body, type, } = apiReqData;
    const token = await getStorageData("buerLoginToken");
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getAffiliateMarketPlaceData = async (isFromGenLink: boolean = false) => {
    this.setState({creatorLoader: !isFromGenLink});
    this.getAffiliateDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAffiliateMarketPlaceEndPoint,
      type: ""
    });
  };


  generateAffiliateLink = async (catalogueId : string) => {
    this.setState({creatorLoader: true});
    this.getAffiliatedLinkApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postGenerateAffiliateLinkEndPoint,
      body: {
        "affiliated_product": {
          "catalogue_id": catalogueId
        }
      },
      type: ""
      
    });
  };

  getAffiliateDataApiResponse = (responseJson : ApiResponse & { errors: Array<ErrorItem> }) => {
    if(responseJson.data){
      const categoryNames = Object.keys(responseJson.data);
      this.setState({productData: responseJson, categoryNames : categoryNames, isProdData: true, creatorLoader: false});
    } else if(responseJson.errors){
      this.setState({isProdData: true});
      this.handleAllApiErrors(responseJson);
    }
  };


  affiliateLinkResponse = (responseJson: {data : {} ,errors: Array<ErrorItem> }) => {
    if(responseJson.data){
      this.getAffiliateMarketPlaceData(true);
      setTimeout(()=>{
        this.setState({
          snackBarItems: {
            isOpen: true,
            isSeverity: "success",
            isMessage: "Affiliate link has been generated successfully"
          },
        });
      }, 1000)
    } else if(responseJson.errors){
      this.handleAllApiErrors(responseJson);
    }
  };

  handleAllApiErrors = (responseJson: { errors: Array<ErrorItem> }) => {
    let error = responseJson.errors[0];
    let errMsg = error?.token ? error?.token : error?.message;
    this.setState({
      snackBarItems: {
        isOpen: true,
        isSeverity: "error",
        isMessage: errMsg ? errMsg : "Something went wrong!"
      },
      creatorLoader: false
    });
  };

  handleCloseSnack = () => {
    this.setState({ snackBarItems: { isOpen: false, isSeverity: "success", isMessage: "" }});
  };

  handleCopyAffiliateLink = async (linkToCopy : string) => {
    await navigator.clipboard.writeText(linkToCopy);
    this.setState({ snackBarItems: { isOpen: true, isSeverity: "info", isMessage: "Link copied to clipboard !!" }})
  };

  navigateToFn = (path: string, screenId: string | number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `${path}`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    screenId !== "" && message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenId);
    this.send(message);
  };

  truncatedProductName = (prodName: string) => {
    if (prodName.length >= 14) {
      return prodName.substring(0, 14) + "...";
    }
    return prodName;
  };
  // Customizable Area End
}