// Customizable Area Start
import React from "react";

import {
    // Customizable Area Start
    Tabs,
    Tab,
    Box,
    Avatar,
    styled,
    Dialog,
    DialogContent,
    Button,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../assets/css/styleSeller.css'
import '../assets/css/style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bannerImageBlankSeller, group, noRecordFound, } from "./assets";
import StayTuned from "../../../components/src/StayTuned.web";
import ViewUserProfileController, {
    Props,
    PostData,
    baseURL
} from "./ViewUserProfileController";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import StoryListing from "../../../components/src/CustomStoryListing.web";
import MessageModalDownloadWeb from "../../../components/src/MessageModalDownload.web";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Loader from "../../../components/src/Loader.web";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
// Customizable Area End

export default class ViewUserProfile extends ViewUserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPosts = () => {
        const { userPostsData, tabValue, buyerTabVal } = this.state;
        return (
            (tabValue === 0 && buyerTabVal === 0) && (
                <div className="seller-tabs-content">
                    <div className="seller-inner-tab-content">
                        <div className="seller-inner-tab-wrap">
                            <div className="">
                                <Box className="postInnerContainer1">
                                    {userPostsData && userPostsData.length > 0 ? userPostsData.map(
                                        (postData: PostData, postIndex: number) => {
                                            return (
                                                <PostCardBox key={postIndex}>
                                                    <Box className="postImageBox" >
                                                        {postData?.attributes?.images_and_videos?.length === 1 ? (
                                                            (() => {
                                                                const media = postData.attributes.images_and_videos[0];
                                                                if (media.type === 'video') {
                                                                    return (
                                                                        <ReactPlayer
                                                                            style={{ height: "unset !important" }}
                                                                            controls
                                                                            className="postImage1"
                                                                            url={media.url}
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <img
                                                                            src={media.url}
                                                                            alt="Post"
                                                                            className="postImage"
                                                                            onClick={() => this.handleOpenImage(media.url)}
                                                                        />
                                                                    );
                                                                }
                                                            })()
                                                        ) : (
                                                            this.renderCarouselData(postData?.attributes?.images_and_videos)
                                                        )}
                                                    </Box>
                                                    <Dialog
                                                        open={this.state.isPostOpen}
                                                        data-test-id="showPostTestId"
                                                        onClose={this.handleClosePostModal}
                                                    >
                                                        <DialogContent>
                                                            <img src={this.state.carousalUrl} width={"100%"} height={"100%"} alt="loading" />
                                                        </DialogContent>
                                                    </Dialog>
                                                </PostCardBox>
                                            );
                                        }
                                    )
                                        :
                                        (
                                            <Box className="noDataBox">
                                                <img src={noRecordFound} alt="" className="noDataImg" />
                                            </Box>
                                        )}
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    };

    renderCarouselData = (images_and_videos: Array<{ id: number; filename: string; url: string; type: string; }>) => {
        return (
            <Carousel
                showThumbs={false}
                swipeable={true}
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            className="nextArrowBtn"
                        >
                            <ArrowForwardIos className="postSlideBtn" />
                        </button>
                    )
                }
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            className="prevArrowBtn"
                        >
                            <ArrowBackIos className="postSlideBtn" />
                        </button>
                    )
                }
            >
                {images_and_videos.map((media, index) => (
                    <Box key={index} className="imageContainer">
                        {media.type === 'video' ? (
                            <ReactPlayer
                                style={{ height: "unset !important" }}
                                url={media.url}
                                controls
                                className="postImage1"
                            />
                        ) : (
                            <img
                                src={media.url}
                                alt={`Media ${index + 1}`}
                                className="postImage"
                                onClick={() => this.handleOpenImage(media.url)}
                            />
                        )}
                    </Box>
                ))}
            </Carousel>
        )
    };

    returnUserProfileTab = () => {
        let { profileType } = this.state;
        if (profileType === "seller") {
            return (
                <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChange}
                    className="custom-tabs-list"
                    data-test-id="sellerProfileTab"
                >
                    <Tab label="Posts" disableRipple />
                    <Tab label="Lives" disableRipple />
                    <Tab label="Top products" disableRipple />
                </Tabs>
            )
        } else if (profileType === "buyer") {
            return (
                <Tabs
                    value={this.state.buyerTabVal}
                    onChange={this.handleBuyerTabChange}
                    className="custom-tabs-list"
                    data-test-id="buyerProfileTab"
                >
                    <Tab label="Posts" disableRipple />
                </Tabs>
            )
        }
    };

    renderStoryModal = () => {
        return (
            <StoryListing
                allStoryHeader={this.state.allStoryHeader}
                data-test-id="storyModalTestID"
                toggleMute={this.toggleMute}
                storyMuted={this.state.storyMuted}
                likeStory={this.likeStory}
                calculateHours={this.calculateHours}
                currentStoryIndex={this.state.currentStoryIndex}
                setSwiperRef={this.setSwiperRef}
                userStory={this.state.userStory}
                showPrevButton={this.showPrevButton}
                showNextButton={this.showNextButton}
                instaModal={this.state.instaModal}
                changeStory={this.changeStory}
                handleClose={this.handleClose}
                instaModalIndex={this.state.instaModalIndex}
                handlePrev={this.handlePrev}
                handleNext={this.handleNext}
                storyId={() => { }}
                userID={Number(this.state.userProfileId)}
                isMessageSent={""}
                commentMessage={() => { }}
            />
        );
    };

    renderLiveStreaming = () => {
        const { sellerLiveStreamData } = this.state;
        return (
            this.state.tabValue === 1 && (
                <div className="seller-tabs-content">
                    <div className="seller-inner-tab-content">
                        <div className="seller-inner-tab-wrap">
                            <div className="post-cards-wrap">
                                {sellerLiveStreamData.length > 0 ? (sellerLiveStreamData.map((item) => {
                                    if (item.attributes?.catalogues.length) {
                                        return (
                                            (
                                                <div className="post-card" key={item.id} onClick={this.toggleLiveModal}>
                                                    <div className="inner-wrap">
                                                        <div className="img-wrap" >
                                                            <div className="inner-img-wrap">
                                                                <img src={`${baseURL}${item.attributes?.catalogues[0]?.images[0]?.url}`} alt="Card img" />
                                                            </div>
                                                            <div className="content-wrap-product">
                                                                <span className="product-title">{item.attributes?.catalogues[0]?.catalogue_title}</span>
                                                                <span className="product-content">{item.attributes.catalogues[0]?.catalogue_description}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }
                                })) :
                                    <Box className="noDataBox">
                                        <img src={noRecordFound} alt="" className="noDataImg" />
                                    </Box>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    };

    renderTopSellingData = () => {
        const { sellerTopProduct } = this.state;
        return (
            this.state.tabValue === 2 && (
                <div
                    className="seller-tabs-content">
                    <div
                        className="seller-inner-tab-content">
                        <div
                            className="seller-inner-tab-wrap">
                            <div
                                className="post-cards-wrap">
                                {sellerTopProduct.length > 0 ? sellerTopProduct.map((item, itemIndex) => (
                                    <div
                                        className="post-card" key={itemIndex} >
                                        <div
                                            className="inner-wrap">
                                            <div
                                                className="img-wrap" >
                                                <div
                                                    className="inner-img-wrap">
                                                    <img src={`${baseURL}${item.attributes.product_images[0].url}`}
                                                        alt="Card img" onClick={() => this.topProductClick(item.id)} />
                                                </div>
                                                <div
                                                    className="content-wrap-product">
                                                    <span className="product-title"
                                                        onClick={() => this.topProductClick(item.id)}>{item.attributes.title}</span>
                                                    <span
                                                        className="product-content">{item.attributes.description}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    <Box className="noDataBox">
                                        <img src={noRecordFound} alt="" className="noDataImg" />
                                    </Box>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { profilePic, backgroundImage, userBio, followers, following, fullName, userName, isResponse } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyleWrapper>
                <div className="main-div" >
                    <div id="myref"></div>
                    <section className="banner-section">
                        {backgroundImage ?
                            <div className="banner-img-wrap">
                                <img src={backgroundImage} alt="banner Img" />
                            </div>
                            :
                            <div className="banner-img-wrap no-bg">
                                <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                            </div>
                        }
                        <div className="profile-detail">
                            <div className="container">
                                <div className="profile-detail-inner">
                                    <div className="profile-photo-wrap">
                                        <Avatar src={String(profilePic)} className="profile-photo">
                                        </Avatar>
                                    </div>
                                    <div className="profile-description-wrap">
                                        <div className="name-wrap">
                                            <span className="name">{fullName}</span>
                                            <span><img src={group} alt="img" /></span>
                                            <span style={{ marginLeft: "30px", padding: "2px 10px", borderRadius: "50px", background: "yellow" }}>@{userName}</span>

                                        </div>
                                        <div className="following-followers-wrap">
                                            <div className="titleWrap"
                                                data-test-id="followers"
                                                onClick={() => this.openStayTunedBox()}>
                                                <span className="title">Followers</span>
                                                <span className="followersCount">{followers}</span>
                                            </div>
                                            <div className="titleWrap"
                                                data-test-id="following">
                                                <span className="title">Following</span>
                                                <span className="followersCount">{following}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="user-profile-section">
                        <div className="container">
                            <div className="bio-wrap">
                                <span className="bio">
                                    {userBio}
                                </span>
                                <div className="profileButtons">
                                    <Button className="custom-button primary-yellow seller-profile-yellow"> Share Profile </Button>
                                    <Button disabled={isResponse} endIcon={isResponse && <CircularProgress size="14" />} data-test-id="followButtonTestId" className={`followButton ${this.state.isFollowingUser === true && "notFollowing"}`} onClick={() => this.toggleFollowUnFollow()}>{this.state.isFollowingUser ? `Unfollow` : `Follow`}</Button>
                                </div>
                            </div>
                            <div className="stories-highlights-wrap">
                                <div className="inner-wrap">
                                    <Box className="storySep"></Box>
                                    {this.state.userStory.map((item, index) => {
                                        return (
                                            <>
                                                {
                                                    item?.attributes?.creator_details?.id == Number(this.state.userProfileId) &&
                                                    <div className="story"
                                                        data-test-id={`openStoryTestID${index}`}
                                                        onClick={() => { this.handleClickOpen(index) }}>
                                                        {
                                                            item.attributes.media.type === "image" ?
                                                                <Avatar
                                                                    src={item.attributes.media.url}
                                                                    data-test-id={`imgUserTestID${index}`} className="profile-photo" />
                                                                : <video src={item.attributes.media.url}
                                                                    width={"100%"}
                                                                    autoPlay={false}
                                                                    muted={this.state.storyMuted}
                                                                    playsInline
                                                                    height={"100%"} />
                                                        }
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                                {
                                this.state.allStoryHeader.length ? 
                                 this.renderStoryModal() : 
                                 <></>
                                 }
                            </div>
                        </div>
                    </section>

                    <section className="tabs-section">
                        <div className="container">
                            <div className="tabs-wrap">
                                {this.returnUserProfileTab()}
                                {this.renderPosts()}
                                {this.renderLiveStreaming()}
                                {this.renderTopSellingData()}
                            </div>
                        </div>
                    </section>
                    <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                    <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
                    <MessageModalDownloadWeb data-test-id="errorMessage" displayPopup={this.state.openLiveModal} closeErrorPopup={this.toggleLiveModal} errorMessage={""} />
                    <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                    <Loader loading={this.state.isLoading} />
                    <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.snackBarStates} handleCloseSnack={this.handleCloseSnack} />
                </div>
            </StyleWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyleWrapper = styled('span')({
    overflow: "none",
    "& .noDataBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "50%",
        height: "300px",
    },
    "& .noCreator": {
        fontSize: "20px",
        marginLeft: "10px"
    },
    "& .noDataImg": {
        width: "200px"
    },
    "& .followersCount": {
        textAlign: "center",
        color: "#555",
        fontSize: "16px",
        fontWeight: 600
    },
    "& .titleWrap": {
        textAlign: "center",
        marginRight: "42px"
    },
    "& .profileButtons": {
        marginBottom: "10px",
        display: "flex",
        gap: "10px",
        marginTop: "14px"
    },
    "& .followButton": {
        backgroundColor: "#f5ea16 !important",
        borderColor: " #f5ea16 !important",
        color: "#000 !important",
        fontSize: "14px !important",
        border: "1px solid transparent ",
        borderRadius: "20px",
        fontWeight: "600",
        textTransform: "none",
        minWidth: "115px",
        marginLeft: "5px"
    },
    "& .notFollowing": {
        backgroundColor: "white !important",
        border: "1px solid #f5ea16 !important"
    },
    "& .postInnerContainer1": {
        display: "flex",
        gap: "20px"
    },

})
const PostCardBox = styled(Box)({
    "& .postImageBox": {
        marginTop: "16px",
        maxWidth: "425px",
        width: "100%",
        maxHeight: "508px",
        borderRadius: "10px",
    },
    "& .postImage": {
        width: "100%",
        maxHeight: "508px",
        maxWidth: "425px",
        objectFit: "cover",
        borderRadius: "10px",
        height: "100%"
    },
    "& .postImage1": {
        width: "100% !important",
        maxHeight: "508px",
        borderRadius: "10px",
        height: "100% !important"
    },
    "& .nextArrowBtn": {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        zIndex: 2,
        padding: '4px',
        right: "15px"
    },
    "& .prevArrowBtn": {
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        color: '#fff',
        zIndex: 2,
        cursor: 'pointer',
        left: "15px",
        padding: '4px',
    },
    "& .postSlideBtn": {
        width: "20px",
        height: "20px"
    },
    "& .imageContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight: "508px"
    },
});
// Customizable Area End
// Customizable Area End