import React from "react";
// Customizable Area Start
import CreateCreatorStoryController, { Props, configJSON } from "./CreateCreatorStoryController";
import { createTheme, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, styled, Switch, Button, Avatar, TextField } from "@material-ui/core";
import { backButton, uploadIcon, noProfileImage, plusSquareImg, filterImg, searchModalIcon } from "../../LiveStreaming/src/assets";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import SuccessDialog from "../../../components/src/SuccesDailogMessage.web"
import Loader from "../../../components/src/Loader.web";
import Pagination from '@material-ui/lab/Pagination';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Vector } from "../../postcreation/src/assets";
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import CloseIcon from '@material-ui/icons/Close';
import { createGrayIc } from "../../landingpage/src/assets";
import SnackBarAlert from "../../../components/src/SnackBarAlert.web"
let config = require('../../../framework/src/config')
// Customizable Area End

export default class CreateCreatorStory extends CreateCreatorStoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderInventorySection = () => {
        return (
            <>
                {
                    this.state.productList.length ?
                        <InventoryContainer
                            data-test-id="invSectionTestID">
                            <Pagination
                                size="large"
                                count={this.state.invCurrentTotalPage}
                                onChange={this.handlePageChange}
                            />
                            <Typography
                                className="uploadHeading clearAllImage"
                                data-test-id="clearAllImagesTestID"
                                onClick={this.handleRemoveAllChecked}>
                                {configJSON.clearAllCheck}
                            </Typography>
                            <InventoryList>
                                {this.state.productList.map((inventory, index) => {
                                    return (
                                        <li key={inventory.id}>
                                            <StyledCheckBox
                                                className={inventory.checked ? "checkedStyle" : ""}
                                                htmlFor={"check" + inventory.id}>
                                                <input
                                                    checked={inventory.checked || this.state.checkedProductList.includes(inventory.id)}
                                                    onChange={(event) => this.handleProdChecked(event, inventory)}
                                                    type="checkbox" id={"check" + inventory.id}
                                                    value={inventory.id}
                                                    data-test-id={`inventoryCheckboxTestID${index}`}
                                                />
                                                <span></span>
                                            </StyledCheckBox>
                                            <Box
                                                className="inventoryImage">
                                                <img
                                                    src={inventory.attributes.product_images.length ? config.baseURL + inventory.attributes.product_images[0].url : noProfileImage}
                                                    alt={inventory.attributes.title} />
                                            </Box>
                                            <Box
                                                className="inventoryDetails">
                                                <Typography
                                                    className="inventoryName"
                                                    data-test-id={`inventoryTitleID${index}`}>
                                                    {inventory.attributes.title}
                                                </Typography>
                                                <Typography
                                                    className="inventoryDesc">
                                                    {inventory.attributes.description}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                className="inventoryPrice">
                                                {"$" + inventory.attributes.mrp}
                                            </Typography>
                                        </li>
                                    )
                                })}
                            </InventoryList>
                            <Button
                                variant="contained"
                                onClick={this.handleProdAddBtn}
                                data-test-id="inventoryAddBtnTestID"
                                disableElevation
                                disabled={!Boolean(this.state.checkedProductList.length > 0)}>
                                {configJSON.add}
                            </Button>
                        </InventoryContainer>
                        :
                        <Box data-test-id="noInventoryTestId"
                            className="noInventoryStyle">
                            <Typography>
                                {configJSON.noInventoryTxt}
                            </Typography>
                        </Box>
                }
            </>
        )
    };

    renderSelectedProduct = () => {
        return (
            <InventoryContainer> <InventoryList>
                {this.state.selectedProducts.map((selectedItem, index) => {
                    return (
                        <li key={selectedItem.id}
                            className="boxShadowVarient ulListVarientPadding">
                            <Box
                                className="inventoryImage boxShadowVarient selectedImages">
                                <img
                                    src={selectedItem.attributes.product_images.length ? config.baseURL + selectedItem.attributes.product_images[0]?.url : noProfileImage}
                                    alt={selectedItem.attributes.id.toString()}
                                    className="boxShadowVarientimg"
                                />
                            </Box>
                            <Box
                                className="inventoryDetails">
                                <Typography
                                    className="inventoryName"
                                    data-test-id={`selectedTitleTestId${index}`}>
                                    {this.findInventoryDetails("name", selectedItem.attributes.catalogue_id)}
                                </Typography>
                                <Typography
                                    className="inventoryDesc">
                                    {this.findInventoryDetails("desc", selectedItem.attributes.catalogue_id)}
                                </Typography>
                            </Box>
                            <Typography
                                className="inventoryPrice">
                                {"$" + selectedItem.attributes.price}
                            </Typography>
                        </li>
                    )
                })}
            </InventoryList></InventoryContainer>
        )
    };

    renderInventoryVarient = () => {
        return (
            <StyledModal>
                <Box
                    onClick={this.handleVarientModalClose}
                    className="liveModalOverlay"
                    data-test-id="modalOverlayTestID" />
                <ModalBody>
                    <>
                        <Typography
                            className={"mainPageTitle mainPageTitleChanged zeroMargin"}
                            data-test-id="addVariantTestId">
                            {configJSON.addVariant}
                        </Typography>
                        <Typography
                            className={"checkedInventory displayCenterVecor zeroMargin"}
                            data-test-id="inventoryTxtLiveID">
                            <img
                                src={Vector}
                                className="VectorImage"
                                alt="" />
                            {configJSON.selectProduct}
                        </Typography>
                        <InventoryContainer>
                            <InventoryList>
                                {this.state.inventoryVarient.map((inventoryVarient, index) => {
                                    return (
                                        <li key={inventoryVarient.id}
                                            className="boxShadowVarient ulListVarientPadding">
                                            <StyledCheckBox htmlFor={"check" + inventoryVarient.id} className={inventoryVarient.checked ? "checkedStyle" : ""}>
                                                <input
                                                    checked={inventoryVarient.checked || this.state.checkedVarientList.includes(inventoryVarient.id)}
                                                    onChange={(event) => this.handleInventoryVarientChecked(event, inventoryVarient)}
                                                    type="checkbox" id={"check" + inventoryVarient.id}
                                                    data-test-id={`inventoryVarientItemTestID${index}`}
                                                    value={inventoryVarient.id}
                                                />
                                                <span></span>
                                            </StyledCheckBox>
                                            <Box
                                                className="inventoryImage boxShadowVarient">
                                                <img
                                                    src={inventoryVarient.attributes.product_images.length ? config.baseURL + inventoryVarient.attributes.product_images[0]?.url : noProfileImage}
                                                    alt={inventoryVarient.attributes.id.toString()}
                                                    className="boxShadowVarientimg"
                                                />
                                            </Box>
                                            <Box
                                                className="inventoryDetails">
                                                <Typography
                                                    className="inventoryName"
                                                    data-test-id="inventoryTitleID">
                                                    {this.state.inveCurSelectedName}
                                                </Typography>
                                                <Typography
                                                    className="inventoryDesc">
                                                    {this.state.inveCurSelectedDes}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                className="inventoryPrice">
                                                {"$" + inventoryVarient.attributes.price}
                                            </Typography>
                                        </li>
                                    )
                                })}
                            </InventoryList>
                            <Button
                                onClick={this.handleVarientAddBtn}
                                className="addBtn"
                                variant="contained"
                                disableElevation
                                data-test-id="varientAddBtnTestID"
                                disabled={!Boolean(this.state.checkedVarientList.length > 0)}>
                                {configJSON.add}
                            </Button>
                        </InventoryContainer>
                    </>
                </ModalBody>
            </StyledModal >
        )
    };

    renderAlignment = () => {
        switch (this.state.storyTxtAlignment) {
            case "left":
                return <FormatAlignLeftIcon className="alignIcon" />;
            case "right":
                return <FormatAlignRightIcon className="alignIcon" />;
            case "center":
                return <FormatAlignCenterIcon className="alignIcon" />;
            case "justify":
                return <FormatAlignJustifyIcon className="alignIcon" />;
        }
    };

    renderImageStory = () => {
        const { textInStory, currentStoryTextIndex, isStoryTextOpen } = this.state;
        return (
            <>
                {isStoryTextOpen &&

                    <>
                        <Box className="fontEditBox" >
                            <Box className="editBtn" data-test-id="changeTextID" onClick={this.changeTxtAlign}>
                                {this.renderAlignment()}</Box>
                            <Box className="editBtn">
                                <Box width={"100%"}
                                    height={"100%"}
                                    borderRadius={"50%"}
                                    boxSizing={"border-box"}
                                    border="1px solid"
                                    borderColor={textInStory[currentStoryTextIndex]?.textColor}
                                    style={{
                                        background: `linear-gradient(to right, ${textInStory[currentStoryTextIndex]?.textColor} 50%, transparent 50%)`
                                    }}
                                    data-test-id="addColorTestID"
                                    onClick={this.addColorOnStory}
                                ></Box>

                            </Box>
                        </Box>
                    </>

                }
                {this.state.isStoryColorOpen && <Box className="colorList">
                    {this.state.colorList.map((item, index) => (
                        <Box className="colorListInner" data-test-id={`setColorTestId${index}`} width="38px" height="38px" borderRadius={"50%"} style={{ background: item, cursor: "pointer" }} onClick={() => this.setTextColor(item)}></Box>
                    ))}
                </Box>}
                {!isStoryTextOpen ?
                    <Box className="doneButton addTxtBtn" data-test-id="addTextID" onClick={this.addTxtOnStory}>Aa</Box>
                    :
                    <Button className="doneButton" data-test-id="closeTextID" onClick={this.closeTxtOnStory}>{configJSON.doneTxt}</Button>
                }
                {textInStory.map((item, index) => (
                    <>
                        {isStoryTextOpen && currentStoryTextIndex === index &&
                            <input
                                data-test-id="changestoryTestID"
                                className="storyInput"
                                value={item.text} onChange={this.changeStoryText}
                            />
                        }


                        <Box
                            onMouseDown={(event) => { this.state.isMouseDown && this.handleMouseDown(event, index) }}
                            onClick={() => { this.editText(index) }}
                            data-test-id="editTextID"
                            className="storyTxtBox"
                            style={{
                                left: item.x,
                                top: item.y,
                                color: item.textColor,
                                textAlign: this.state.storyTxtAlignment,
                            }}
                        >
                            {item.text}
                        </Box>

                    </>
                ))}
                <img src={this.state.previewUrl} alt="Uploaded" className="previewImg" />
            </>
        )
    };

    renderSpace3 = (thumbnailImage: null | File, showVideoWarning: string) => {
        return (
            <Box className="space3">
                {this.state.isInventoryOpen ?
                    this.renderInventorySection()
                    :
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12} lg={8}>
                            <Typography className="addThumbnail">{configJSON.uploadStoryTxt}</Typography>
                            <Box className="addThumbnailContainer">
                                {thumbnailImage ? (
                                    <>
                                        <Box className="thumbnailContainer" component="fieldset" data-test-id="thumbnailImagePreview">
                                            <div className="uploadedFileName" ref={this.storyRef}>
                                                {this.state.thumbnailImage?.type.startsWith('video/') ?
                                                    <video src={this.state.previewUrl} autoPlay muted={false} loop />
                                                    :
                                                    this.renderImageStory()}
                                                <CloseIcon className="crossIcon" onClick={this.handleRemoveImage} />
                                            </div>
                                        </Box>
                                    </>
                                ) : (<>
                                    <Box className="uploadContainer" component="fieldset">
                                        <label htmlFor="thumbnail-upload">
                                            <input
                                                type="file"
                                                accept=".jpg, .png, .mp4, .avi, .mov"
                                                onChange={this.handleImageChange}
                                                style={{ display: "none" }}
                                                id="thumbnail-upload"
                                                data-test-id="thumbnailImageInput"
                                            />
                                            <Box className="uploadIconContainer" >
                                                <img src={uploadIcon} alt="Upload Icon" className="uploadIcon" />
                                                <Typography className="uploadHereText">
                                                    <Typography component="span" className="uploadText">
                                                        {configJSON.upload}
                                                    </Typography>
                                                    {" "}
                                                    {configJSON.here}
                                                </Typography>
                                            </Box>
                                        </label>
                                        <video ref={this.videoRef} className="refVideo" />
                                    </Box>
                                </>
                                )}
                            </Box>
                            {showVideoWarning && <Typography className="uploadHereText">{showVideoWarning}</Typography>}
                            <Box className="imgCapContainer" pb={1}>
                                <Avatar
                                    alt="User Avatar"
                                    src={noProfileImage}
                                    className="avatarClass"
                                />
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    placeholder="Write a Title..."
                                    InputProps={{ disableUnderline: true }}
                                    value={this.state.storyTitle}
                                    onChange={this.changeStoryTitle}
                                    data-test-id="captionInputTestID"
                                />
                            </Box>
                            <Box className="tagPeopleBox" pb={1} >
                                <Typography className="uploadHeading headingcolorchange">{configJSON.tagPeople}</Typography>
                                <Autocomplete
                                    id="combo-box-demo"
                                    getOptionLabel={(option) => option.user_name}
                                    options={this.state.tagUserData}
                                    noOptionsText="Please enter a valid user name! No users found"
                                    onChange={(event, value) => this.handleTagUsers(value)}
                                    multiple={true}
                                    fullWidth
                                    data-test-id="tagUserTestId"
                                    renderOption={(option) => (
                                        <CustomGrid container alignItems="center" >
                                            <Grid item>
                                                <Avatar src={option.profile_photo ? option.profile_photo : noProfileImage} alt={option.user_name} />
                                            </Grid>
                                            <Grid item xs>
                                                <Box className="userInfoBox">
                                                    <Typography variant="body1">{option.user_name} <span className="roleTxt">{option.role.name}</span></Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {option.full_name}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </CustomGrid>
                                    )}
                                    renderInput={(params) => <TextField {...params} onChange={this.fetchUsers} fullWidth type="text" variant="outlined" className="searchUserInputField" placeholder="John Doe" label="Search User" />}
                                />
                            </Box>
                            <Box className="selectMainBox">
                                <Box className="headBox"
                                    data-test-id="addProductTestID"
                                    onClick={this.handleShownventory}
                                    display="flex"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    width="100%"
                                    borderBottom="1px solid #E0E0E0"
                                    pb={1}>
                                    <Typography
                                        className="uploadHeading headingcolorchange">{configJSON.addProducts}</Typography>
                                    <img className="uploadHeading headingcolorchange" src={createGrayIc} />
                                </Box>
                                <Box maxHeight={"500px"} overflow={"scroll"} width={"100%"}>
                                    {this.state.selectedProducts.length ? this.renderSelectedProduct() : <></>}
                                </Box>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <Box className="switchContainer">
                                <Box>
                                    <Typography className="switchTitle">
                                        {configJSON.storySwitchTitles.explicitContent}
                                    </Typography>
                                    <Typography className="switchDescription">
                                        {configJSON.storySwitchTitles.muteWords}
                                    </Typography>
                                </Box>
                                <IOSSwitch
                                    checked={this.state.explicitContent}
                                    onChange={this.handleSwitchChange("explicitContent")}
                                    data-test-id="handleSwitch"
                                />
                            </Box>
                        </Grid>
                        <Box
                            className="saveAndButtonContainer">
                            <Button
                                className="saveAndButton"
                                data-test-id="save"
                                disabled={!this.state.thumbnailImage}
                                onClick={this.postStory}>
                                {configJSON.share}
                            </Button>
                        </Box>
                    </Grid>
                }
                {this.state.isProdVarientOpen && this.renderInventoryVarient()}
            </Box>
        )
    };

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { thumbnailImage, loading, showVideoWarning } = this.state
        return (
            <StyledWrapper >
                <Loader loading={loading} />
                <Box className='scheduleLiveInnerContainer' data-test-id="scheduleLiveMainContainer">
                    <Box className="spaceBetween">
                        <Box className="backBtnNavigation" m={0}>
                            <img src={backButton} alt="backButton" data-test-id="backBtnTestId" onClick={() => this.handleBackNavigation()} />
                            {this.state.isInventoryOpen ?
                                <Box>
                                    <Typography
                                        className="screenTitle mainPageTitleChanged"
                                        data-test-id="inventoryTxtLiveID">
                                        {configJSON.inventoryTxt}
                                    </Typography>
                                    <Typography
                                        className="checkedInventory">
                                        {this.state.checkedProductList.length}
                                        {" of "}
                                        {this.state.invCurrentTotalCount ? this.state.invCurrentTotalCount : 0}
                                    </Typography>
                                </Box>
                                :
                                <Typography data-test-id="screenTitleTestId" className='screenTitle'>{configJSON.addToStroy}</Typography>
                            }  </Box>
                        <Box component='span' className="flexContainer">
                            {this.state.isInventoryOpen &&
                                <ModalSearch
                                    className="inventorySearch">
                                    <img
                                        src={searchModalIcon}
                                        alt="search" />
                                    <input
                                        onChange={this.handleInventoryPostSearch}
                                        type="text" placeholder={"Search for products"}
                                        value={this.state.searchProductText}
                                        data-test-id="inventorySearchTestID" />
                                    <img
                                        alt="filter"
                                        src={filterImg}
                                        className="inventoryFilter" />
                                </ModalSearch>
                            }
                            {this.state.isInventoryOpen &&
                                <Box component="span"
                                    className={"iconStyle"}>
                                    <img
                                        src={plusSquareImg}
                                        data-test-id="addPlusIconTestID"
                                        onClick={this.handleAddProductPost}
                                        alt="plus square icon"
                                    /></Box>
                            }
                        </Box>
                    </Box>
                    {this.renderSpace3(thumbnailImage, showVideoWarning)}
                </Box>
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.errorMessage} />
                <SuccessDialog
                    data-test-id="sucessMessage"
                    open={this.state.sucessModal}
                    closeFn={this.sucessMessageClose}
                    successTitle={configJSON.storyUploadedTitle}
                    successMessage={configJSON.storyUploaded}
                />
                <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.hasSuccessErrorForInv} handleCloseSnack={this.handleCloseStorySnack} />
            </StyledWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const StyledWrapper = styled(Box)({
    display: "flex",
    margin: "5em 0em 0em",
    "& .scheduleLiveInnerContainer": {
        paddingTop: "4%",
        width: "100%",
        padding: "0em 2em",
    },
    "& .backButton": {
        display: "flex",
        alignItems: "center" as const
    },
    "& .screenTitle": {
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft: "0.5em"
    },
    "& .mainPageTitleChanged": {
        color: '#444444 !important',
        fontWeight: "600 !important"
    },
    "& .zeroMargin": {
        margin: "0px !important"
    },
    "& .checkedInventory": {
        fontSize: "14px",
        color: "rgba(68, 68, 68, 0.7)",
        marginLeft: "0.5em"
    },
    "& .iconStyle": {
        width: '48px',
        height: '48px',
        borderRadius: '10px',
        border: '1px solid var(--0, #FFF)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        display: "flex",
        alignItems: "center" as const,
        justifyContent: "center" as const,
        margin: "0em 0.5em"
    },
    "& .uploadContainer": {
        height: "600px",
        width: "300px",
        border: "1px dashed rgba(68, 68, 68, 0.6)",
        display: "flex",
        alignItems: "center" as const,
        justifyContent: "center" as const,
        position: "relative" as const,
        borderRadius: "10px"
    },
    "& .refVideo": {
        display: "none"
    },
    "& .thumbnailContainer": {
        height: "600px",
        border: "0px",
        width: "300px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "10px"
    },
    "& .uploadedFileName": {
        display: "flex",
        alignItems: "center",
        background: "black",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        "& .previewImg": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        },
        "& video": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        }
    },
    "& .uploadIcon": {
        width: "48px",
        height: "48px"
    },
    "& .crossIcon": {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        color: "white",
        zIndex: 10,
        position: "absolute",
        top: "23px",
        left: "10px"
    },
    "& .doneButton": {
        color: "white",
        fontWeight: 600,
        zIndex: 10,
        fontSize: "14px",
        lineHeight: "21px",
        textTransform: "none",
        position: "absolute",
        top: "17px",
        right: "10px",
        fontFamily: "Poppins"
    },
    "& .storyInput": {
        position: 'absolute',
        left: "0px",
        bottom: "60px",
        padding: "10px !important",
        boxSizing: "border-box",
        width: "100%",
        color: "white",
        fontSize: "16px",
        outline: "none !important",
        border: "none !important",
        background: "transparent !important",
        fontFamily: "Poppins-Regular"
    },
    "& .storyTxtBox": {
        position: 'absolute',
        cursor: 'grab',
        width: "100%",
        fontSize: "24px",
        maxWidth: "100%",
        background: "transparent",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordSpacing: "normal",
        fontFamily: "Poppins-Regular"
    },
    "& .addTxtBtn": {
        padding: "5px !important",
        border: "1px solid white",
        width: "35px",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        boxSizing: "border-box",
        borderRadius: "50%"
    },
    "& .fontEditBox": {
        gap: "10px",
        display: "flex",
        zIndex: 10,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "15px"
    },
    "& .colorList": {
        zIndex: 10,
        display: "flex",
        padding: "10px",
        boxSizing: "border-box",
        scrollbarWidth: "thin",
        scrollbarColor: "#a0a0a0 #f0f0f0",
        alignItems: "center",
        position: "absolute",
        bottom: "15px",
        marginLeft: "25px",
        width: "100%",
        overflowX: "auto",

    },
    "& .colorListInner": {
        marginRight: "15px",
        flexShrink: 0,
        border: "2px solid #FFF"
    },
    "& .editBtn": {
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        boxSizing: "border-box",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid white"
    },
    "& .alignIcon": {
        color: "white"
    },
    "& .addThumbnail": {
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal'
    },
    "& .fileName": {
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft: "0.5em"
    },
    "& .fileSize": {
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        marginLeft: "0.5em"
    },
    "& .tickIcon": {
        width: "32px",
        height: "32px"
    },
    "& .uploadIconContainer": {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center"
    },
    "& .uploadHereText": {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    "& .uploadText": {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        textDecorationLine: 'underline',
    },
    "& .switchContainer": {
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1em"
    },
    "& .switchTitle": {
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        margin: "0.2em 0em"
    },
    "& .switchDescription": {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        margin: "0.2em 0em"
    },
    "& .saveAndButton": {
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer: "cursor",
        textTransform: "none" as const,
        "&.Mui-disabled": {
            color: '#9e9e9e',
            cursor: 'not-allowed'
        },
        "&:hover": {
            background: '#F5EA16',
        }
    },
    "& .imgCapContainer": {
        display: "none",
        padding: "10px",
        alignItems: 'center',
        width: "100%",
        borderBottom: "1px solid #E0E0E0",
        marginTop: "28px",
        "& .MuiInput-root": {
            fontSize: "1rem !important"
        },
        gap: '10px'
    },
    "& .avatarClass": {
        width: 40,
        height: 40,
        margin: "0px 5px 0 0 "
    },
    "& .editInput": {
        border: 'none',
        '&::placeholder': {
            color: '##555555',
            fontSize: '12px',
            fontWeight: 500
        }
    },
    "& .tagPeople": {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    "& .selectMainBox": {
        display: "none",
        flexDirection: "column",
        cursor: "pointer",
        "& .headBox": {
            marginTop: "28px",
            paddingBottom: "16px"
        }
    },
    "& .uploadHeading": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: "left",
        color: "#555555",
        marginTop: "28px"
    },
    "& .headingcolorchange": {
        color: "#000000",
        cursor: "pointer"
    },
    "& .clearAllImage": {
        cursor: "pointer",
        textDecoration: "underline"
    },
    "& .tagPeopleBox": {
        marginTop: "28px",
        paddingBottom: "16px",
        display: "none",
        width: "100%",
        borderBottom: "1px solid #E0E0E0",
        flexDirection: "column"
    },
    "& .searchUserInputField": {
        marginTop: "12px",
        borderRadius: "12px",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "grey"
        },
        "& .MuiChip-root": {
            backgroundColor: "yellow"
        }
    },
    "& .saveAndButtonContainer": {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        marginBottom: "2em",
        [theme.breakpoints.down('md')]: {
            margin: "1em"
        },
    },
    "& .spaceBetween": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .backBtnNavigation": {
        display: "flex",
        alignItems: "center"
    },
    "& .flexContainer": {
        display: "flex"
    },
    "& .space3": {
        marginTop: "2em",
        "& .noInventoryStyle": {
            height: "calc(100vh - 186px)",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
        }
    },
    "& .addThumbnailContainer": {
        margin: "0.75em 0em"
    },
})

export const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#FFFF00',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                backgroundColor: 'black',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
            backgroundColor: 'black !important',
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
)(({ classes, ...props }: any) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const ModalSearch = styled(Box)({
    display: "flex",
    backgroundColor: "#fff",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    boxShadow: "0 0 25px rgba(0,0,0,0.2)",
    borderRadius: "12px",
    "&.inventorySearch": {
        width: "482px",
        margin: "0 0.5em",
        height: "48px",
        "@media(max-width: 1200px)": {
            width: "100%",
            maxWidth: "460px"
        }
    },
    "& img": {
        cursor: "pointer"
    },
    "& input": {
        outline: "none",
        border: "none",
        flex: 1
    },
    "& .inventoryFilter": {
        borderLeft: "1px solid rgba(68, 68, 68, 0.1)",
        paddingLeft: "12px"
    }
});

const InventoryContainer = styled(Box)({
    marginTop: "28px",
    width: "100%",
    paddingBottom: "20px",
    "& img": {
        cursor: "pointer",
        marginLeft: "auto",
        display: "block"
    },
    "& .MuiButton-contained": {
        marginTop: "40px",
        backgroundColor: "rgba(245, 234, 22, 1)",
        display: "block",
        width: "342px",
        height: "47px",
        marginLeft: "auto",
        borderRadius: "40px"
    },
    "& .addBtn": {
        width: "100% !important"
    },
    "& .MuiButton-label": {
        textTransform: "capitalize"
    }
});

const InventoryList = styled("ul")({
    margin: 0,
    padding: 0,
    marginTop: "20px",
    "& .boxShadowVarient": {
        boxShadow: "none !important"
    },
    "& .custom-checkbox-pseudo input": {
        cursor: "pointer",
        position: "absolute",
        opacity: 0
    },
    "& li": {
        display: "flex",
        padding: "20px",
        alignItems: "center",
        borderRadius: "20px",
        gap: "12px",
        marginBottom: "20px",
        boxShadow: "0 0 25px rgba(0,0,0,0.2)",
        "& .inventoryImage": {
            height: "106px",
            width: "106px",
            borderRadius: "8px",
            padding: "8px",
            boxSizing: "border-box",
            boxShadow: "0 0 5px rgba(0,0,0,0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& img": {
                width: "81px",
                objectFit: "cover",
                height: "84px"
            }
        },
        "& .selectedImages": {
            padding: "0 8px 0 0 !important",
            height: "inset !important",
            width: "unset !important",
            objectFit: "unset" as "unset"
        },
        "& .inventoryDetails": {
            flex: 1,
            "& .inventoryName": {
                fontWeight: 500,
                fontSize: "16px",
                color: "rgba(68, 68, 68, 1)",
                lineHeight: "24px"
            },
            "& .inventoryDesc": {
                color: "rgba(68, 68, 68, 0.7)",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px"
            }
        },
        "& .inventoryPrice": {
            color: "rgba(68, 68, 68, 1)",
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "30px"
        }
    },
    "& .boxShadowVarientimg": {
        height: "34.46px !important",
        width: "56.73px !important",
        objectFit: "unset" as "unset",
        margin: "0 !important"
    }
});

const CustomGrid = styled(Grid)({
    "& .userInfoBox": {
        marginLeft: "20px"
    },
    "& .roleTxt": {
        color: "grey",
        fontSize: "12px",
        marginLeft: "4px"
    }
});

const StyledCheckBox = styled("label")({
    position: "relative",
    borderRadius: "4px",
    border: "1px solid rgba(161, 161, 161, 1)",
    height: "24px",
    width: "25px",
    "&.checkedStyle": {
        border: "1px solid transparent"
    },
    "& input:checked + span::before": {
        borderWidth: "0px 2px 2px 0px",
        content: "''",
        width: "6px",
        border: "1px solid",
        position: "absolute",
        transform: "rotate(45deg)",
        left: "8px",
        height: "14px",
        top: "2px"
    },
    "& input": {
        visibility: "hidden"
    },
    "& input:checked + span": {
        backgroundColor: "rgba(245, 234, 22, 1)"
    },
    "& span": {
        position: "absolute",
        width: "108%",
        height: "108%",
        top: "-2px",
        borderRadius: "4px",
        left: "-1px"
    }
});

const StyledModal = styled(Box)({
    top: "70px",
    width: "calc(100% - 290px)",
    position: "fixed",
    zIndex: 2,
    right: 0,
    height: "calc(100vh - 70px)",
    "@media(max-width: 991px)": {
        width: "100%"
    },
    "& .VectorImage": {
        width: "16px",
        height: "16px",
        marginRight: "4px"
    },
    "& .liveModalOverlay": {
        position: "absolute",
        height: "100%",
        display: "flex",
        width: "100%",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.8)",
        justifyContent: "center"
    },
    "& .displayCenterVecor": {
        alignItems: "center",
        display: "flex",
        marginTop: "12px !important"
    }
});

const ModalBody = styled(Box)({
    position: "relative",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: "50%",
    boxSizing: "border-box",
    padding: "40px 20px",
    borderRadius: "12px",
    maxHeight: "548px",
    maxWidth: "573px",
    backgroundColor: "#fff",
    width: "calc(100% - 60px)",
    overflow: "scroll"
});
// Customizable Area End