import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface Attachment {
    url: string;
  }
  
  interface ProductImage {
    id: number;
    url: string;
  }
  
  interface CatalogueAttributes {
    category_id: number;
    sub_category_id: number;
    title: string;
    description: string;
    status: string;
    mrp: number;
    selling_price: number;
    ask_price: number;
    bargain: boolean;
    on_order: boolean;
    is_ask_price: boolean;
    is_brand: boolean;
    sku: string;
    marked_destroy: boolean;
    product_images: ProductImage[];
    inventory_details: any; // You can replace 'any' with a specific type if details are known.
  }
  
  interface CatalogueData {
    id: string;
    type: string;
    attributes: CatalogueAttributes;
  }
  
  interface Catalogue {
    data: CatalogueData;
  }
  
  interface Account {
    id: number;
    first_name: string | null;
    full_phone_number: string;
    country_code: string | null;
    phone_number: string | null;
    email: string;
    activated: boolean;
    device_id: string | null;
    unique_auth_id: string;
    password_digest: string;
    created_at: string;
    updated_at: string;
    user_name: string;
    platform: string | null;
    user_type: string | null;
    app_language_id: number | null;
    last_visit_at: string | null;
    is_blacklisted: boolean;
    suspend_until: string | null;
    status: string;
    stripe_id: string | null;
    stripe_subscription_id: string | null;
    stripe_subscription_date: string | null;
    role_id: number;
    full_name: string;
    email_verified: boolean;
    phone_verified: boolean;
    business_name: string | null;
    business_type: string | null;
    admin_verified: boolean;
    updated_mobile_number: string | null;
    is_disabled: boolean;
    my_bio: string | null;
    updated_email: string | null;
    business_popup: boolean;
    password_digests: any[]; // You can replace 'any' with a specific type if details are known.
    shipment_type: string;
    same_drop_off: boolean;
    marked_destroy: boolean;
    upi_id: string | null;
    qr_generated: boolean;
    latitude: number;
    longitude: number;
    current_city: string;
    store_type: string;
  }
  
  interface Attributes {
    id: number;
    request_status: string;
    request_detail: string;
    quantity: number;
    cancellation_reason: string | null;
    price: number | null;
    expected_delivery: string;
    attachments: Attachment[];
    catalogue: Catalogue;
    account: Account;
  }
  
  interface OrderRequest {
    id: string;
    type: string;
    attributes: Attributes;
  }

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    drawerOpen: boolean;
    orderType: number;
    statusFilter: string;
    loading: boolean;
    openTunedBox: boolean;
    orderListData: any;
    searchValue: string;
    all: number;
    cancelled: number;
    onHold: number;
    pending: number;
    shipped: number;
    toShip: number;
    paginationCount: number;
    pageCount: number;
    onOrderListData: [];
    onOrderModal: boolean;
    particularOnOrderListData: OrderRequest;
    loadingRequest:boolean;
    isOrderData : boolean;
    isSellerVerified: boolean;
    isNotificationBoxOpen: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerOrderListController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getOrderListApiCallId: string = '';
    getOnOrderDataApiCallId: string = '';
    getparticularOnOrderDataApiCallId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            drawerOpen: true,
            orderType: 0,
            statusFilter: '',
            loading: false,
            openTunedBox: false,
            orderListData: [],
            searchValue: '',
            all: 0,
            cancelled: 0,
            onHold: 0,
            pending: 0,
            shipped: 0,
            toShip: 0,
            paginationCount: 0,
            pageCount: 1,
            onOrderListData: [],
            onOrderModal: false,
            loadingRequest:false,
            isOrderData: false,
            isSellerVerified: false,
            particularOnOrderListData: {
                id: "",
                type: "",
                attributes: {
                  id: 0,
                  request_status: "",
                  request_detail: "",
                  quantity: 0,
                  cancellation_reason: null,
                  price: null,
                  expected_delivery: "",
                  attachments: [],
                  catalogue: {
                    data: {
                      id: "",
                      type: "",
                      attributes: {
                        category_id: 0,
                        sub_category_id: 0,
                        title: "",
                        description: "",
                        status: "",
                        mrp: 0,
                        selling_price: 0,
                        ask_price: 0,
                        bargain: false,
                        on_order: false,
                        is_ask_price: false,
                        is_brand: false,
                        sku: "",
                        marked_destroy: false,
                        product_images: [{url:"",id:0}],
                        inventory_details: null,
                      },
                    },
                  },
                  account: {
                    id: 0,
                    first_name: null,
                    full_phone_number: "",
                    country_code: null,
                    phone_number: null,
                    email: "",
                    activated: false,
                    device_id: null,
                    unique_auth_id: "",
                    password_digest: "",
                    created_at: "",
                    updated_at: "",
                    user_name: "",
                    platform: null,
                    user_type: null,
                    app_language_id: null,
                    last_visit_at: null,
                    is_blacklisted: false,
                    suspend_until: null,
                    status: "",
                    stripe_id: null,
                    stripe_subscription_id: null,
                    stripe_subscription_date: null,
                    role_id: 0,
                    full_name: "",
                    email_verified: false,
                    phone_verified: false,
                    business_name: null,
                    business_type: null,
                    admin_verified: false,
                    updated_mobile_number: null,
                    is_disabled: false,
                    my_bio: null,
                    updated_email: null,
                    business_popup: false,
                    password_digests: [],
                    shipment_type: "",
                    same_drop_off: false,
                    marked_destroy: false,
                    upi_id: null,
                    qr_generated: false,
                    latitude: 0,
                    longitude: 0,
                    current_city: "",
                    store_type: "",
                  },
                },
              },
            isNotificationBoxOpen: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
    
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
    
            this.setState({ loading: false });
    
            if (apiRequestCallId === this.getOrderListApiCallId) {
                this.handleOrderListResponse(responseJson);
            }
    
            if (apiRequestCallId === this.getOnOrderDataApiCallId) {
                this.handleOnOrderDataResponse(responseJson);
            }

            if (apiRequestCallId === this.getparticularOnOrderDataApiCallId) {
                this.handleParticularOnOrderDataResponse(responseJson);
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const Verified = await getStorageData("isSellerVerified");
        this.setState({ isSellerVerified: Verified });
        this.getOrderList();
        this.getOnOrderData();
        // Customizable Area End
    }

    // Customizable Area Start
    handleOrderListResponse(responseJson: { data: any; filters_count: any; meta: any; }) {
        if (responseJson.data) {
            this.setState({
                orderListData: responseJson.data,
                isOrderData: true,
            });
        }
        this.handleFiltersCount(responseJson.filters_count, responseJson.meta);
    }
    
    handleFiltersCount(filtersCount: { all: any; cancelled: any; on_hold: any; pending: any; shipped: any; to_ship: any; }, meta: { total_pages: number; }) {
        if (filtersCount) {
            this.setState({
                all: filtersCount.all,
                cancelled: filtersCount.cancelled,
                onHold: filtersCount.on_hold,
                pending: filtersCount.pending,
                shipped: filtersCount.shipped,
                toShip: filtersCount.to_ship,
                paginationCount: Math.ceil(meta.total_pages), // Corrected meta access
            });
    
            if (this.state.paginationCount === 0) {
                this.setState({
                    // Handle paginationCount == 0 if needed
                });
            }
        }
    }
    
    handleOnOrderDataResponse(responseJson: { data: any; }) {
        if (responseJson.data) {
            this.setState({
                onOrderListData: responseJson.data,
                loadingRequest: false
            });
        }
    }

    handleParticularOnOrderDataResponse(responseJson: { data: any; }) {
        if (responseJson.data) {
            this.setState({
                particularOnOrderListData: responseJson.data,
                onOrderModal : true
            });
        }
    }
    
    closeDrawer = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }

    handleOrderTypeChange = (event: any, newValue: number) => {
        this.setState({
            orderType: newValue,
            openTunedBox: true
        });
    };

    handleStatusFilter = (status: string) => {
        this.setState({
            statusFilter: status,
            searchValue: '',
            pageCount: 1
        }, () => {
            this.getOrderList();
        })
    }

    handleChangePagiation = (event: any, value: number) => {
        this.setState({
            pageCount: value
        }, () => {
            this.getOrderList();
        });
    }

    HandleSingleOrderStatus = (itemId: any) => {
        this.props.navigation.push('SingleOrderStatus', `?item_id=${encodeURIComponent(itemId)}`, encodeURIComponent(itemId))
    }

    handleSearchValue = (event: any) => {
        this.setState({
            searchValue: event.target.value
        })
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.getOrderList();
        }
    }

    closeTunedBox = () => {
        this.setState({
            openTunedBox: false,
            orderType: 0
        });
    }

    getOrderList = async () => {
        this.setState({ loading: true });
        
        const { searchValue, statusFilter } = this.state;

        const queryParams = [];
        if (searchValue) queryParams.push(`search=${searchValue}`);
        if (statusFilter) queryParams.push(`status=${statusFilter}`);
        queryParams.push(`page=${this.state.pageCount}`);
        queryParams.push(`per_page=15`);
        const queryString = queryParams.length > 0 ? queryParams.join("&") : "";

        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOrderListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_shopping_cart/order_items?${queryString}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getOnOrderData = async () => {
        this.setState({ loadingRequest: true });
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOnOrderDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_productdescription/seller_requests_listing`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOpen = (id:number) => {
        this.getParticularOnOrderData(id)
    };

    handleClose = () => {
        this.setState({ onOrderModal : false});
    };

    getParticularOnOrderData = async (id:number) => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "token": await getStorageData("singupLogin") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getparticularOnOrderDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_productdescription/on_order_requests/${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    formatDate(dateString: string | number | Date) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JS
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
      }

      handleNavigation = () => {
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerChatScreen");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      };
      
      handleCloseModal = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerDashboard");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
      };
    // Customizable Area End
}
