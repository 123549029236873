import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, styled, Typography } from "@material-ui/core";
import AffiliateMarketPlaceController, { baseURL, Product, Props } from "./AffiliateMarketPlaceController";
// import '../assets/css/styleSeller.css'
import { arrowLeftIc, linkIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import { noDataFound } from "../../cfdigitalmall/src/assets";
// Customizable Area End


export default class AffiliateMarketPlace extends AffiliateMarketPlaceController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderProductCard = (item: Product, itemIdx: number) => {
        let discountP = item?.attributes?.mrp;
        return (
            <Box className="cardBox" key={itemIdx}>
                <Box className="imageBox" onClick={()=> this.navigateToFn('ProductDescription', item.id)}>
                    <img src={baseURL + item.attributes?.product_images[0]?.url} alt="" className="productImage" />
                </Box>

                <Box className="productInfoBox">
                    <Box className="productNamePriceBox">
                        <Typography className="prodNameTxt">{this.truncatedProductName(item.attributes.title)}</Typography>
                        <Typography className="priceText">₹{item.attributes.selling_price} <span className="strikeTxt">{discountP}</span></Typography>
                    </Box>

                    <Box className="wrapperBox">
                        <Box className="potentialEarningBox">
                            <Typography className="potentialEarningTxt">Potential Earning <span className="boldTxt">₹{item.attributes.affiliated_price}</span></Typography>
                        </Box>
                    </Box>

                    <Box className="sellerInfoBox" onClick={()=> this.navigateToFn('ViewSellerProfile', item.attributes.account.id )}>
                        <Avatar className="avatatImg" src={item.attributes.host_image} />
                        <Typography className="nameTxt">{item.attributes.store.name}</Typography>
                    </Box>

                    <Box className="affiliateLinkBox">
                        {item.attributes.affiliated_link === null ?
                            <Button className="affiliateLink" data-test-id={`generateTestId${itemIdx}`} onClick={() => this.generateAffiliateLink(item.id)}>
                                <img src={linkIcon} alt="" className="linkIcon" />
                                Affiliate link
                            </Button>
                            :
                            <Button className="affiliateLink copyAffiliateLink" data-test-id={`copyTestId${itemIdx}`} onClick={() => this.handleCopyAffiliateLink(String(item.attributes.affiliated_link))}>
                                <img src={linkIcon} alt="" className="linkIcon" />
                                Copy link
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {categoryNames , productData, isProdData} = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <AffiliateMarketPlaceWrapper>
                <Loader loading={this.state.creatorLoader} />
                <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.snackBarItems} handleCloseSnack={this.handleCloseSnack} />
                <Box className="screenHeader">
                    <img src={arrowLeftIc} alt="" className="backIcon" onClick={()=> this.props.navigation.goBack()} />
                    <Typography className="headerTitile">Affiliate Market Place</Typography>
                </Box>
                {isProdData && (categoryNames.length > 0 ? categoryNames.map((category, categoryIdx) => {
                    return (
                        <Box className="productListingBox" key={categoryIdx}>
                            <Typography className="productCategory">{category}</Typography>
                            <Box className="cardMainContainer">
                                {productData !== null && productData.data[category].map((item, itemIdx) => {
                                    return (
                                        this.renderProductCard(item, itemIdx)
                                    )
                                })}
                            </Box>
                        </Box>
                    ) 
                }) : 
                <Box className="noDataFound">
                    <img src={noDataFound} alt="" className="noDataImg" />
                </Box> 
            )}

            </AffiliateMarketPlaceWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const AffiliateMarketPlaceWrapper = styled(Box)({
    paddingLeft: "23px",
    paddingRight: "54px",
    width: "100%",
    marginTop: "133px",
    marginBottom: "20px",
    minHeight:"100vh",
    height:"fit-content",
    "@media(max-width:1150px)": {
        marginTop: "85px",
    },
    "@media(max-width:1000px)": {
        marginTop: "85px",
    },
    "& .screenHeader": {
        display: "flex",
        alignItems: "center",
        gap: "16px"
    },
    "& .headerTitile": {
        fontSize: "16px",
        fontWeight: 600,
        color: "#444444"
    },
    "& .cardBox": {
        border: "1px solid #6E6E6E1A",
        borderRadius: "4px",
        width: "100%",
        maxWidth: "193px",
        minWidth:"148px",
        height:"100%",
        marginTop: "16px",
        animation: 'fadeIn 1s',
        MozAnimation: 'fadeIn 1s',
        WebkitAnimation: 'fadeIn 1s',
        OAnimation: 'fadeIn 1s',
        msAnimation: 'fadeIn 1s',
    },
    "& .cardMainContainer": {
        display: "flex",
        gap: "26px",
        overflow: "auto",
        scrollbarWidth: "thin",
        paddingBottom: "4px"
    },
    "& .imageBox": {
        width: "100%",
        maxWidth: "193px",
        height: "174px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px"
    },
    "& .productImage": {
        width: "100%",
        height: "100%",
        borderRadius: "4px",
        objectFit: "contain",
    },
    "& .productListingBox": {
        marginTop: "26px",
    },
    "& .productCategory": {
        fontSize: "20px",
        fontWeight: 400,
        color: "#000000"
    },
    "& .productNamePriceBox": {
        display: "flex",
        justifyContent: "space-between",
        minHeight:"35px"
    },
    "& .prodNameTxt": {
        fontSize: "12px",
        fontWeight: 500,
        color: "#4E4E4E"
    },
    "& .priceText": {
        fontWeight: 600,
        fontSize: "12px",
        color: "#4E4E4E"
    },
    "& .strikeTxt": {
        fontWeight: 400,
        fontSize: "12px",
        color: "#6E6E6E",
        textDecoration: "line-through",
        paddingLeft: "8px"
    },
    "& .potentialEarningBox": {
        backgroundColor: "#BAFFBE",
        borderRadius: "6px",
        padding: "4px 16px",
        textAlign:"center",
        minHeight:"43px",
        display:"flex",
        justifyContent:"center"
    },
    "& .wrapperBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px"
    },
    "& .potentialEarningTxt": {
        fontSize: "12px",
        fontWeight: 500,
    },
    "& .boldTxt": {
        fontWeight: 600,
    },
    "& .sellerInfoBox": {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        marginTop: "10px",
        cursor:"pointer"
    },
    "& .avatatImg": {
        width: "24px",
        height: "24px",
    },
    "& .nameTxt": {
        fontSize: "11px",
        color: "#4C4C4C",
    },
    "& .productInfoBox": {
        padding: "8px 12px",
    },
    "& .affiliateLinkBox": {
        marginTop: "10px",
    },
    "& .affiliateLink": {
        textTransform: "none",
        width: "100%",
        maxWidth: "176px",
        height: "25px",
        backgroundColor: "#F5EA16",
        borderRadius: "50px",
        fontSize: "12px",
        color: "#000000",
        marginTop: "2px"
    },
    "& .copyAffiliateLink":{
        backgroundColor: "white",
        border:"1px solid #F5EA16"
    },
    "& .linkIcon": {
        width: "24px",
        marginRight: "8px"
    },
    "& .noDataFound":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100vh",
    },
    "& .noDataImg":{
        width:"200px",
    },
    "& .backIcon":{
        cursor:"pointer",
    }
});
// Customizable Area End