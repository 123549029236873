import React from "react";
// Customizable Area Start
import InventoryManagementController ,{Props, configJSON} from "./InventoryManagementController.web";
import { createTheme,  withStyles, Theme } from "@material-ui/core/styles";
import { Box,TextField ,Typography,Button,InputAdornment,IconButton} from "@material-ui/core";
import {addProductImage,bellIcon,plusIcon,searchIcon,hamburgerIc,filters} from "./assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import  InventoryListing from "./InventoryListing.web";
import Loader from "../../../components/src/Loader.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
import SellersNotification from "../../landingpage/src/SellersNotification.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    inventoryManagementContainer:{
      display:"flex",
      flexWrap:'wrap' as const,
      justifyContent:"space-between" as const,
      margin:"0em 1em",
      alignItems: 'center'
    },
    inventoryManagementTitle:{
        color: 'rgba(68, 68, 68, 0.80)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    inventoryManagementInnerContainer:{
        paddingTop:"4%",
        width:"100%",
        paddingLeft:"2em",
        },
    sideBar:{
        filter: 'drop-shadow(6px 0px 20px rgba(0, 0, 0, 0.05))',
        height:'100vh',
        background:"gray",
    },
    addProductMainSection:{
        height:"100vh",
        display:"flex",
        justifyContent:"center"  as const,
        alignItems:"center" as const
    },
    addProductInnerSection:{
        display:"flex",
        flexDirection:"column"  as const,
        alignItems:"center" as const
    },
    iconStyles:{
        width: '48px',
        height: '48px',
        borderRadius: '10px',
        border: '1px solid var(--0, #FFF)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        display:"flex",
        alignItems:"center"  as const,
        justifyContent:"center"  as const,
        margin:"0em 0.5em"
    },
    noProductsText:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    addProductsButton:{
        width: '21em',
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        marginTop:"2em",
        "&:hover":{
            background: '#F5EA16',
        }
    },
    addProductImage:{
        marginBottom:"2em"
    },
    inventoryManagementMainContainer:{
        margin:"5em 0em 0em"
    },
    searchInventory: {
        width:"100%",
        borderRadius: '10px',
        border: '1px solid #FFF',
        background: '#FFF',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        '& .MuiInput-underline:before': {
            borderBottom: 'none', 
          },
          '&:focus': {
            outline: 'none', 
          },
      },
      [`@media (min-width: 992px)`]: {
        hideHamburger: {
          display: 'none'
        },
        },
        hideSideHamburgerSection:{
            display:"flex",
            justifyContent:"end"
        },

    searchRight:{
        display:"flex"
    },
    searchBox:{
        width:"483px",
        marginRight:"10px"
    }
});
// Customizable Area End

export class InventoryManagement extends InventoryManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderAddProductSection =()=>{
        const {classes} = this.props
        const {searchProductText,inventoryManagementList} = this.state
        return(
        <Box className={classes.addProductMainSection}>
          <Box className={classes.addProductInnerSection}>
            <img src={addProductImage} alt="addProductImage" className={classes.addProductImage}/>
            {
           ( searchProductText && inventoryManagementList.length===0)
            ? <Typography className={classes.noProductsText}>{configJSON.nosearchProducts}</Typography>:
            <>
            <Typography className={classes.noProductsText}>{configJSON.noProducts}</Typography>
            <Typography className={classes.noProductsText}>{configJSON.startNow}</Typography>
            </>
          
           }
            
            <Button data-test-id="saveButton" className={classes.addProductsButton} onClick={()=>this.handleAddProduct()}>{configJSON.addProducts}</Button>
          </Box>
        </Box>)

    }

   

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {inventoryManagementList,searchProductText,loading} = this.state
        const {classes} = this.props
        return (
          <Box data-test-id="mainContainer" className={classes.inventoryManagementMainContainer} style={{display:"flex"}}>
                        <MessageModalWeb
                            data-test-id="messageModal"
                            displayPopup={this.state.isModalOpen}
                            closeErrorPopup={this.handleClosemessageModal}
                            errorMessage={this.state.modalMessage}
                        />
                        <div className="dashboard-outer">
                            <div className="dashboard-inner">
                              <SellerSidebar
                                navigation={""}
                                id={""}
                                classes={undefined}
                                isOpen={this.state.isOpen}
                                data-test-id="sellerSidebar"
                                activetabName= "inventory"
                                closeModal={this.toggleSideBar}
                                />
                            </div>
                        </div>

                        <Box className={classes.inventoryManagementInnerContainer}>
                        <Box className={classes.hideSideHamburgerSection}>
                                    <IconButton
                                        className={`${classes.hideHamburger}`}
                                        data-test-id="hamburger-btn"
                                        onClick={() => this.toggleSideBar()}
                                    >
                                        <img src={hamburgerIc} alt="hamburger" />
                                    </IconButton>
                        </Box>
                        <Box className={classes.inventoryManagementContainer}>
                            <Box className={classes.inventoryManagementTitle} component='span'>{configJSON.inventory}</Box>
                            <Box className={classes.searchRight}>
                            <Box className={classes.searchBox}>
                            <TextField
                                variant="outlined"
                                data-test-id="searchInventory"
                                className={classes.searchInventory}
                                value={searchProductText}
                                placeholder={configJSON.searchForProducts}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment  data-test-id="searchIcon" 
                                        position="start" 
                                        onClick={()=>{this.getInventoryList()}}>
                                            <img 
                                            style={{cursor:"pointer"}}
                                            src={searchIcon}
                                            alt="searchIcon"
                                              />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment  
                                        position="end">
                                            <img 
                                            style={{cursor:"pointer"}}
                                            src={filters}
                                            alt="searchIcon"
                                              />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleSearchInputChange}
                                InputLabelProps={{
                                        shrink: true, 
                                }}
                                onKeyPress={this.handleKeyPress}
                            />
                            </Box>
                            <Box component='span' style={{display:"flex"}}>
                                    <Box  component="span" className={classes.iconStyles}><img src={plusIcon} alt="plusIcon" onClick={()=>this.handleAddProduct()}/></Box>
                                <SellersNotification handleNotificationBoxToggle={this.state.isNotificationBoxOpen} data-test-id="notification-click" navigation={undefined} />
                            </Box>
                            </Box>
                        </Box>
                        <Loader loading={loading} />
                        {!inventoryManagementList.length && this.renderAddProductSection()}
                        {!!inventoryManagementList.length && 
                        <InventoryListing 
                        handleResponseDelete={this.handleResponseDelete} 
                        inventoryManagementList={inventoryManagementList}
                        navigation={this.props.navigation}
                        id={this.props.id}
                        page={this.state.page}
                        perPageCount={this.state.perPageCount}
                        handleChangePagination={this.handleChangePagination}
                        />}
                        </Box>    
                        <LoginFailModalWeb data-test-id="sellerModal" displayPopup={!this.state.isSellerVerified} closeErrorPopup={this.handleCloseModal} errorMessage={"Your account is not verifed by admin!"} />   
          </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(InventoryManagement)
// Customizable Area End