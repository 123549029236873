import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  data: object;
  meta: object;
}

export interface InvalidResponseType {
  errors: string;
}

export interface NotificationAttributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  is_read: boolean;
  read_at: string;
  notificationable_id: number;
  notificationable_type: string;
  created_at: string;
  updated_at: string;
  account_id: number;
}

interface ServiceCard {
  title: string;
  description: string;
  postCount: number;
  storiesCount: number;
  collaborationCount: number;
}

export interface CreateService {
  success: boolean;
  message: string;
  data: [
    {
      id: number;
      account_id: number;
      name: string;
      description: string;
      no_of_post: number;
      no_of_story: number;
      no_of_live_collaboration: number;
      created_at: string;
      updated_at: string;
    }
  ]
}

export interface Service {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      no_of_post: number;
      no_of_story: number;
      no_of_live_collaboration: number;
      account_id: number;
      created_at: string;
      updated_at: string;
    }
  }
}

export interface UpdateService {
  message: string;
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  selectedTab: number;
  accountListOpen: boolean;
  accountListAnchorEl: HTMLElement | null;
  token: string;
  createServiceData: CreateService;
  isLoading: boolean;
  serviceId: string;
  updateRes: string;
  serviceLenght: number;
  titleError: string;
  descriptionError: string;
  serviceCount: ServiceCard[];
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateServiceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postCreateServiceCallId: string = "";
  getServicesApiCallId: string = '';
  putServiceApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: true,
      selectedTab: 1,
      accountListOpen: false,
      accountListAnchorEl: null,
      token: "",
      createServiceData: {} as CreateService,
      isLoading: false,
      serviceId: "",
      updateRes: "",
      serviceLenght: 0,
      titleError: "",
      descriptionError: "",
      serviceCount: [{
        title: "",
        description: "",
        postCount: 0,
        storiesCount: 0,
        collaborationCount: 0,
      }]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      this.setState({ serviceId: chatData.id, serviceLenght: chatData.serviceLength + 1 }, () => this.getService());
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const creatorToken = await getStorageData("buerLoginToken");
    creatorToken && this.setState({ token: creatorToken });
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & CreateService & Service & UpdateService) => {
    if (apiRequestCallId === this.postCreateServiceCallId) {
      this.postCreateServiceSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getServicesApiCallId) {
      this.getServiceSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.putServiceApiCallId) {
      this.updateServicesSuccessCallBack(responseJson);
    }
  };

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = valueData;
    const token = this.state.token;
    const header = {
      "Content-Type": contentType,
      token,
    };
    let apiBody = body;
    if (type === "") {
      apiBody = JSON.stringify(body);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  getService = async () => {
    this.getServicesApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getAllServices}${this.state.serviceId}`
    }
    );
  };

  createServiceApi = async () => {
    const body = {
      "service_offer": this.state.serviceCount.map(service => ({
        "name": service.title,
        "description": service.description,
        "no_of_post": service.postCount,
        "no_of_story": service.storiesCount,
        "no_of_live_collaboration": service.collaborationCount
      }))
    };

    this.postCreateServiceCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.postCreateServiceEndPoint,
        body: body,
        type: ""
      }
    );
  };

  updateServiceApi = async () => {
    const body = {
      "service_offer": {
        "name": this.state.serviceCount[0].title,
        "description": this.state.serviceCount[0].description,
        "no_of_post": this.state.serviceCount[0].postCount,
        "no_of_story": this.state.serviceCount[0].storiesCount,
        "no_of_live_collaboration": this.state.serviceCount[0].collaborationCount
      }
    };

    this.putServiceApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putApiMothod,
      endPoint: `${configJSON.getAllServices}${this.state.serviceId}`,
      body: JSON.stringify(body)
    });
  };

  getServiceSuccessCallBack = (response: Service) => {
    const transformedData = {
      title: response.data.attributes.name,
      description: response.data.attributes.description,
      postCount: response.data.attributes.no_of_post,
      storiesCount: response.data.attributes.no_of_story,
      collaborationCount: response.data.attributes.no_of_live_collaboration
    }

    this.setState(prevState => {
      const updatedServiceCount = [...prevState.serviceCount];
      updatedServiceCount[0] = transformedData;
      return {
        serviceCount: updatedServiceCount
      };
    });
  };

  updateServicesSuccessCallBack = (response: UpdateService) => {
    this.setState({ isLoading: false, updateRes: response.message }, () => { this.navigateServiceOffered() })
  };

  postCreateServiceSuccessCallBack = (response: CreateService) => {
    this.setState({
      createServiceData: response,
      isLoading: false
    }, () => { this.navigateServiceOffered() });
  };

  navigateServiceOffered = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ServiceOffered");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      return;
    }
    this.setState({
      selectedTab: newValue,
    });
  };

  OpenAccountList = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      accountListAnchorEl: event.currentTarget,
      accountListOpen: !this.state.accountListOpen
    })
  };

  CloseAccountList = () => {
    this.setState({
      accountListAnchorEl: null,
      accountListOpen: !this.state.accountListOpen
    });
  };

  handleIncreasePostCount = (index: number) => {
    if (this.state.serviceCount[index].postCount < 1000) {
      const updatedServiceCount = [...this.state.serviceCount];
      updatedServiceCount[index].postCount = updatedServiceCount[index].postCount + 1;
      this.setState({ serviceCount: updatedServiceCount });
    }
  };

  handleDecreasePostCount = (index: number) => {
    if (this.state.serviceCount[index].postCount > 0) {
      const updatedServiceCount = [...this.state.serviceCount];
      updatedServiceCount[index].postCount = updatedServiceCount[index].postCount - 1;
      this.setState({ serviceCount: updatedServiceCount });
    }
  };

  handlePostChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newServiceCount = [...this.state.serviceCount];
    newServiceCount[index].postCount = parseInt(event.target.value);
    this.setState({ serviceCount: newServiceCount });
  };

  handleIncreaseStoriesCount = (index: number) => {
    if (this.state.serviceCount[index].storiesCount < 1000) {
      const updatedServiceCount = [...this.state.serviceCount];
      updatedServiceCount[index].storiesCount = updatedServiceCount[index].storiesCount + 1;
      this.setState({ serviceCount: updatedServiceCount });
    }
  };

  handleDecreaseStoriesCount = (index: number) => {
    if (this.state.serviceCount[index].storiesCount > 0) {
      const updatedServiceCount = [...this.state.serviceCount];
      updatedServiceCount[index].storiesCount = updatedServiceCount[index].storiesCount - 1;
      this.setState({ serviceCount: updatedServiceCount });
    }
  };

  handleStoriesChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newServiceCount = [...this.state.serviceCount];
    newServiceCount[index].storiesCount = parseInt(event.target.value);
    this.setState({ serviceCount: newServiceCount });
  };

  handleIncreaseCollaborationCount = (index: number) => {
    if (this.state.serviceCount[index].collaborationCount < 1000) {
      const updatedServiceCount = [...this.state.serviceCount];
      updatedServiceCount[index].collaborationCount = updatedServiceCount[index].collaborationCount + 1;
      this.setState({ serviceCount: updatedServiceCount });
    }
  };

  handleDecreasCollaborationCount = (index: number) => {
    if (this.state.serviceCount[index].collaborationCount > 0) {
      const updatedServiceCount = [...this.state.serviceCount];
      updatedServiceCount[index].collaborationCount = updatedServiceCount[index].collaborationCount - 1;
      this.setState({ serviceCount: updatedServiceCount });
    }
  };

  handleCollaborationChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newServiceCount = [...this.state.serviceCount];
    newServiceCount[index].collaborationCount = parseInt(event.target.value);
    this.setState({ serviceCount: newServiceCount });
  };

  addNewService = () => {
    if (this.state.serviceCount.length < 5) {
      this.setState(prevState => ({
        serviceCount: [...prevState.serviceCount,
        {
          title: "",
          description: "",
          postCount: 0,
          storiesCount: 0,
          collaborationCount: 0,
        }],
      }));
    }
    this.setState({serviceLenght: this.state.serviceLenght + 1})
  };

  deleteService = (index: number) => {
    let data = this.state.serviceCount;
    data.splice(index, 1);
    this.setState({ serviceCount: data, serviceLenght: this.state.serviceLenght - 1 });
  };

  handleTitle = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newServiceCount = [...this.state.serviceCount];
    newServiceCount[index].title = event.target.value;
    this.setState({ serviceCount: newServiceCount });
  };

  handleDescription = (index: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newServiceCount = [...this.state.serviceCount];
    newServiceCount[index].description = event.target.value;
    this.setState({ serviceCount: newServiceCount });
  };

  handleValidation = () => {
    const { serviceCount } = this.state;

    let valid = true;
    let titleError = '';
    let descriptionError = '';

    serviceCount.forEach((card, index) => {
      if (!card.title) {
        titleError = 'Title is required';
        valid = false;
      }

      if (!card.description) {
        descriptionError = 'Description is required';
        valid = false;
      }
    });

    this.setState({
      titleError,
      descriptionError
    });

    return valid;
  };

  handleCreateService = () => {
    const isValid = this.handleValidation();
    if (isValid) {
      this.setState({ isLoading: true });
      if (this.state.serviceId) {
        this.updateServiceApi()
      } else {
        this.createServiceApi()
      }
    }
    // this.state.serviceId ? this.updateServiceApi() : this.createServiceApi()
  };
  // Customizable Area End
}