export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg"); 
export const starImg = require("../assets/starImg.svg"); 
export const storeCheckImg = require("../assets/storeCheckImg.svg"); 
export const storeLocationImg = require("../assets/storeLocationImg.svg");
export const righArrowImg = require("../assets/righArrowImg.svg");
export const blackTickIcon = require("../assets/blackTickIcon.png");
export const ratingIcon = require("../assets/ratingIcon.png");

export const locationIcon = require("../assets/locationIcon.png");
export const phoneIcon = require("../assets/phoneIcon.png");
export const emailIcon = require("../assets/emailIcon.png");

export const codImg = require("../assets/codImg.png");
export const onOrderImg = require("../assets/onOrderImg.png");
export const bargainImg = require("../assets/bargainImg.png");

export const bookAppointmentIcon = require("../assets/bookAppointmentIcon.png");
export const liveCount  = require("../assets/liveCount.png");
export const location = require("../assets/location.png");
export const dropDown = require("../assets/dropDown.png");
export const searchIcon  = require("../assets/searchIcon.png");
export const locationBlackIcon =  require("../assets/locationBlackIcon.png");
export const CreatorBanner  = require("../assets/CreatorBanner.png");
export const like  = require("../assets/like.png");
export const Outlined  = require("../assets/Outlined.png");
export const Share  = require("../assets/share.png");
export const group  = require("../assets/group_.png");
export const backgroundBanner  = require("../assets/backgroundBanner.svg");
export const close  = require("../assets/close.svg");
export const AddGrayIc = require("../../CustomisableUserProfiles/assets/plus-gray.svg")
export const noDataFound  = require("../assets/noRecordImg.png");

