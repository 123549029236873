import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { ChangeEvent } from "react";
import { WithStyles } from "@material-ui/core";
import { setStorageData } from "framework/src/Utilities";
const navigation = require('react-navigation')
// Customizable Area End
export const configJSON = require("./config");

// Customizable Area Start
export interface InvalidResponseType {
  errors: string;
}

export interface ValidResponseType {
  meta: object;
  data: object;
}

interface LinkData {
  attributes: {
    instagram_url: string;
    youtube_url: string;
  }
}

interface LinkError {
  instagram_url: string;
  youtube_url: string;
}

interface LinkResponse {
  data: LinkData;
  errors: LinkError
}

export interface StateList {
  slug: string;
  name: string;
}

export interface SnackBarIn {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

interface ErrorItem {
  [key: string]: string;
};

interface CountriesData {
  countries: {
    [key: string]: string;
  }
}

interface StateData {
  county_code: string;
  states: {
    [key: string]: string;
  }
}

interface CitiesData {
  county_code: string;
  state_code: string;
  cities: Array<string>;
}

interface EmailOtpData {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      email: string;
      phone_number: string;
      account_exists: boolean;
    }
  },
  meta: {
    message: string;
    token: string;
  },
  errors: string;
}

interface MobileOtpData {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      email: string;
      phone_number: string;
      account_exists: boolean;
    }
  },
  meta: {
    message: string;
    token: string;
    bank_detail_token: string;
  },
  bank_detail: null,
  errors: string;
}

interface ChangePasswordRes {
  message: string;
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  page_names: [];
}

interface AddressData {
  id: string;
  type: string;
  attributes: {
    id: number;
    shipment_type: string;
    address_line_1: string;
    address_line_2: string;
    pin_code: number;
    city: string;
    state: string;
    country: string;
    latitude: number;
    longitude: number;
    address: string;
    address_type: string;
  }
}

interface Address {
  data: AddressData;
  meta: {
    message: string;
  }
}

export interface PostData {
  id: string;
  type: string;
  isTagList: boolean;
  attributes: {
    id: number;
    name: string;
    description: string;
    body: string;
    location: string;
    account_id: number;
    active_message: boolean;
    created_at: string;
    updated_at: string;
    images_and_videos: [
      {
        id: number;
        filename: string;
        url: string;
        type: string;
      }
    ],
    creator_details: {
      id: number;
      full_name: string;
      profile_url: string;
      role: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        page_names: []
      }
    },
    media: [],
    tag_list_details: [],
    product_detail: [],
    like_count: number;
    comment_count: number;
    is_following_seller: boolean;
    is_liked: boolean;
    last_liked_by: {
      id: number;
      profile_url: string;
      user_name: string;
    }
  }
}

interface Post {
  data: Array<PostData>;
}

interface SendbirdCred {
  id: number;
  user_id: string;
  nickname: string;
  token: string;
  account_id: number;
  created_at: string;
  updated_at: string;
  access_token: string;
  token_expires_at: string;
  profile_url: string;
}

interface Catagory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    slug: string;
    light_icon: string;
    created_at: string;
    updated_at: string;
    selected: boolean;
  }
}

interface ServiceData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    no_of_post: number;
    no_of_story: number;
    no_of_live_collaboration: number;
    account_id: number;
    amount: number;
    created_at: string;
    updated_at: string;
  }
}

interface CreatorProfileAttributes {
  country_code: string;
  upi_id: null;
  activated: boolean;
  full_name: string;
  qr_generated: boolean;
  full_phone_number: string;
  email: string;
  phone_number: string;
  user_name: string;
  unique_auth_id: string;
  device_id: string;
  type: null;
  role: Role;
  updated_mobile_number: string | null;
  my_bio: string | null;
  gender: string | null;
  is_disabled: boolean;
  instagram_url: string | null;
  date_of_birth: string | null;
  updated_email: string | null;
  youtube_url: string | null;
  background_photo: string | null;
  profile_photo: string | null;
  marketing_price: number;
  youtube_followers: number;
  instagram_followers: number;
  address: Address;
  sendbird_credentials: SendbirdCred;
  posts: Post;
  followers: number;
  category: Array<Catagory>;
  creator_malls: { data: Array<CreatorResponseData> };
  services: { data: Array<ServiceData> };
  is_follow: boolean;
}

interface CreatorProfileData {
  id: string;
  type: string;
  attributes: CreatorProfileAttributes;
}

interface CreatorProfileResponse {
  data: CreatorProfileData;
  meta: { token: string }
  errors: Array<ErrorItem>;
}

export interface CreatorResponseData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    city: string;
    state: string;
    country: string;
    pin_code: string;
    created_at: string;
    updated_at: string;
    banner: string;
    banner_thumbnail: string;
    like_count: number;
    catalogues?: null
  }
}

interface CreatorMallResponse {
  data: Array<CreatorResponseData>,
  errors: {
    message: string;
    status: number;
  }
}

interface CountryAttributes {
  name: string;
  emoji_flag: string;
  country_code: string;
}

interface CountryData {
  id: string;
  type: string;
  attributes: CountryAttributes;
}

interface AllCountryData {
  data: Array<CountryData>
}
interface FollowData {
    attributes: {
      account: {
        id: string | number;
        full_name: string
        profile_url: string;
        user_name: string;
      }
    }
}
interface FollowRes {
  data: Array<FollowData>;
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  snackBarStates: SnackBarIn;
  creatorProfileData: CreatorProfileData | null;
  loading: boolean;
  openTunedBox: boolean;
  openModalFollowers: boolean;
  creatorProfileImgFile: File | null;
  imageError: string;
  errorModal: boolean;
  creatorMallData: Array<CreatorResponseData>;
  errorInMall: string;
  creatorID: string | null;
  openModalUsername: boolean;
  profileUsernameError: string;
  email: string;
  emailCopy: string;
  emailError: string;
  openModalMobile: boolean;
  mobileNumber: string;
  mobileNumberCopy: string;
  phoneError: string;
  openModalBio: boolean;
  profileBioError: string;
  addressline1Error: string;
  addressline2Error: string;
  pincodeError: string;
  cityError: string;
  stateError: string;
  countryError: string;
  addressError: string;
  otpError: string;
  pwdError: string;
  currentPassword: string;
  currentPwdError: string;
  showCurrenPassword: boolean;
  newPassword: string;
  newPasswordCopy: string;
  newPwdError: string;
  showNewPassword: boolean;
  reNewPassword: string;
  reNewPasswordError: string;
  showReNewPassword: boolean;
  countrySelectedValue: { value: string };
  dataSource: Array<CountryData>;
  buyerProfileAddressData: AddressData;
  addressline1: string;
  addressline2: string;
  pincode: number;
  city: string | null;
  state: string | null | undefined;
  country: string | null;
  profileBio: string;
  profileUsername: string;
  otp: string;
  openModalDisableAccout: boolean;
  openModalDeleteAccount: boolean;
  openModalChangeEmail: boolean;
  openModalChangePassword: boolean;
  openModalChangeAddress: boolean;
  openModalEmailOtp: boolean;
  openModalMobileOtp: boolean;
  openModalEnterChangePassword: boolean;
  openModalPasswordSuccessfull: boolean;
  openModalAddressSuccessfull: boolean;
  countryList: Array<StateList>;
  stateList: Array<StateList>;
  cityList: Array<string>;
  isUserNameUpdate: boolean;
  isBioUpdate: boolean;
  otpStateEmail: string;
  isLoading: boolean;
  currentPasswordCopy: string;
  isMobileUpdate: boolean;
  isOtpSent: boolean;
  updateEmailToken: string;
  otpStateMobile: string;
  isEmailUpdate: boolean;
  username: string;
  updateMobileToken: string;
  password: string;
  buyerProfileImgFile: File | null;
  buyerProfileBannerImgFile: File | null;
  openModalLink: boolean;
  profileLinkError: string;
  profileInstaURL: string;
  profileYouTubeURL: string;
  linkDataSuccess: boolean;
  linksData: LinkData;
  linksError: LinkError,
  tabValue: number;
  setPostCommentId: string;
  carousalUrl: string;
  isPostOpen: boolean;
  isModalOpen: boolean;
  followers: Array<FollowData>;
  following: Array<FollowData>;
  isFollowsModal: boolean;
  followsTabValue: number;
  isFollowingUser: boolean;
  flixoFollowerCount: number;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatorProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCreatorProfileDataApiCallId: string = "";
  updateCreatorProfileImage: string = "";
  getAllCreatorMall: string = "";
  getCreatorDetailsApiCallId: string = "";
  countryCodeApiCallId: string = "";
  getCreatorProfileAddressApiId: string = "";
  updateCreatorProfileAddressApiId: string = "";
  getStateListApiCallId: string = "";
  getCityListApiCallId: string = "";
  updateMobileApiCallId: string = "";
  updateEmail: string = "";
  updateBuyerPassword: string = "";
  updateMobileTokenVerify: string = "";
  updateProfileUsername: string = "";
  updateProfileURLs: string = "";
  updateProfileBio: string = "";
  getCountryListApiCallId: string = "";
  updateBuyerProfileBannerImage: string = "";
  updateEmailTokenVerify: string = "";
  getFollowersApiCallId: string = "";
  getFollowingApiCallId: string = "";
  followUnFollowApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationScreenNameMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      snackBarStates: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      creatorProfileData: null,
      loading: false,
      openTunedBox: false,
      openModalFollowers: false,
      creatorProfileImgFile: null,
      imageError: "",
      errorModal: false,
      creatorMallData: [],
      errorInMall: "",
      creatorID: "",
      openModalUsername: false,
      profileUsernameError: "",
      email: "",
      emailCopy: "",
      emailError: "",
      mobileNumber: "",
      mobileNumberCopy: "",
      phoneError: "",
      openModalBio: false,
      profileBioError: "",
      addressline1Error: "",
      addressline2Error: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      countryError: "",
      addressError: "",
      otpError: "",
      pwdError: "",
      currentPassword: "",
      currentPwdError: "",
      showCurrenPassword: false,
      newPassword: "",
      newPasswordCopy: "",
      newPwdError: "",
      showNewPassword: false,
      reNewPassword: "",
      reNewPasswordError: "",
      showReNewPassword: false,
      countrySelectedValue: {
        value: ""
      },
      dataSource: [],
      buyerProfileAddressData: {} as AddressData,
      addressline1: "",
      addressline2: "",
      pincode: NaN,
      city: "",
      state: "",
      country: "",
      profileBio: "",
      profileUsername: "",
      otp: "",
      openModalMobile: false,
      openModalDisableAccout: false,
      openModalDeleteAccount: false,
      openModalChangeEmail: false,
      openModalChangePassword: false,
      openModalChangeAddress: false,
      openModalEmailOtp: false,
      openModalMobileOtp: false,
      openModalEnterChangePassword: false,
      openModalPasswordSuccessfull: false,
      openModalAddressSuccessfull: false,
      countryList: [],
      stateList: [],
      cityList: [],
      isUserNameUpdate: false,
      isBioUpdate: false,
      otpStateEmail: "",
      isLoading: false,
      currentPasswordCopy: "",
      isMobileUpdate: false,
      isOtpSent: false,
      updateEmailToken: "",
      otpStateMobile: "",
      isEmailUpdate: false,
      username: "",
      updateMobileToken: "",
      password: "",
      buyerProfileImgFile: null,
      buyerProfileBannerImgFile: null,
      openModalLink: false,
      profileLinkError: "",
      profileInstaURL: "",
      profileYouTubeURL: "",
      tabValue: 0,
      linkDataSuccess: false,
      linksData: {} as LinkData,
      linksError: {} as LinkError,
      setPostCommentId: "",
      carousalUrl: "",
      isPostOpen: false,
      isModalOpen: true,
      followers: [],
      following: [],
      isFollowsModal: false,
      followsTabValue: 0,
      isFollowingUser: false,
      flixoFollowerCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJsonData)) {
        this.apiSuccessCallBack(apiRequestCallId, responseJsonData);
      }

      if (apiRequestCallId === this.getFollowersApiCallId) {
        this.getFollowersApiResponse(responseJsonData)
      }

      if (apiRequestCallId === this.getFollowingApiCallId) {
        this.getFollowingApiResponse(responseJsonData)
      }

      if(apiRequestCallId === this.followUnFollowApiCallId) {
        this.getFollowUnfollowApiResponse(responseJsonData);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const locArray = location.pathname.split("/");
    const CreatorId = locArray[locArray.length - 1];

    if (locArray.length > 2) {
      this.setState({creatorID: CreatorId})
      this.getCreatorDetails(CreatorId);
    } else {
      this.getCreatorProfileData();
      this.getCountryList();
      this.getCreatorProfileAddressData();
      this.getCreatorMalls();
      this.makeRemoteRequest();
      this.getFollowers();
      this.getFollowing();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBack = (apiRequestCallId: string, responseJson: ValidResponseType & CreatorProfileResponse & CreatorMallResponse & AllCountryData & LinkResponse & CountriesData & StateData & CitiesData & Address & EmailOtpData & MobileOtpData & ChangePasswordRes) => {
    const apiHandlers: { [key: string]: (responseJson: ValidResponseType & CreatorProfileResponse & CreatorMallResponse & AllCountryData & LinkResponse & CountriesData & StateData & CitiesData & Address & EmailOtpData & MobileOtpData & ChangePasswordRes) => void } = {
      [this.getCreatorProfileDataApiCallId]: this.getCreatorProfileDataResponse,
      [this.updateCreatorProfileImage]: this.checkProfileUpdate,
      [this.getAllCreatorMall]: this.getAllMallResponse,
      [this.getCreatorDetailsApiCallId]: this.getCreatorDetailsResponse,
      [this.countryCodeApiCallId]: this.handlecountryCodeApiCallId,
      [this.getCreatorProfileAddressApiId]: this.handlegetCreatorProfileAddressApiId,
      [this.updateCreatorProfileAddressApiId]: this.handleupdateCreatorProfileAddressApiId,
      [this.getStateListApiCallId]: (responseJson: StateData) => {
        this.setStateList(responseJson);
        this.getCityList();
      },
      [this.getCityListApiCallId]: this.setCityList,
      [this.updateMobileApiCallId]: this.setUpdateMobiledata,
      [this.updateBuyerPassword]: this.checkPasswordUpdate,
      [this.updateEmail]: this.setUpdateEmailData,
      [this.updateMobileTokenVerify]: this.checkMobileOtpVerify,
      [this.updateProfileUsername]: this.checkProfileUpdate1,
      [this.updateProfileURLs]: this.linkDataResponse,
      [this.updateProfileBio]: this.checkProfileUpdate2,
      [this.getCountryListApiCallId]: this.setCountryList,
      [this.updateBuyerProfileBannerImage]: this.handleupdateBuyerProfileImage,
      [this.updateEmailTokenVerify]: this.checkEmailOtpVerify
    };

    const handler = apiHandlers[apiRequestCallId];

    if (handler) {
      handler(responseJson);
    }
  };

  apiCall = async (valueData: { method?: string; contentType?: string; body?: {}; type?: string; endPoint?: string; newToken?: string; }) => {
    let token = await getStorageData("buerLoginToken");
    const { contentType, method, endPoint, body, type, newToken } = valueData;
    const header = {
      token: newToken ? newToken : token,
      "Content-Type": contentType,
    };
    let apiBody = body;
    if (type === "") { apiBody = JSON.stringify(body); }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), apiBody);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getCreatorProfileData = async () => {
    this.getCreatorProfileDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.creatorProfileEndPoint,
      type: ""
    });
  };

  getCreatorProfileDataResponse = (responseJson: CreatorProfileResponse) => {
    if (responseJson.data) {
      this.setState({
        creatorProfileData: responseJson.data,
        flixoFollowerCount: responseJson.data.attributes.followers,
        loading: false,
      })
    } else if (responseJson.errors) {
      this.handleApiErrors(responseJson)
    }
  };

  linkDataResponse = (response: LinkResponse) => {
    if (response.data) {
      this.setState({ linksData: response.data, openModalLink: false, linkDataSuccess: true });
    }
    else {
      this.setState({ linksError: response.errors });
      this.setError("profileLinkError", this.state.linksError.instagram_url || this.state.linksError.youtube_url);
    }
  };

  closeUrlSuccesModal = () => {
    this.setState({ linkDataSuccess: false });
  };

  handleApiErrors = (responseJson: { errors: Array<ErrorItem> }) => {
    let error = responseJson.errors[0];
    let errMsg = error?.token ? error?.token : error?.message;
    this.setState({
      snackBarStates: {
        isOpen: true,
        isSeverity: "error",
        isMessage: errMsg
      },
      loading: false,
    });
  };

  handleNavigateServices = () => {
    const path = this.state.creatorID ? "ViewService" : "ServiceOffered";
    setStorageData('ServicesData', JSON.stringify(this.state.creatorProfileData?.attributes.services));

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  handleNavigateEditProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EditCreatorProfile");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  openStayTunedBox = () => {
    this.setState({
      openTunedBox: true
    });
  };

  closeTunedBox = () => {
    this.setState({
      openTunedBox: false
    })
  };

  handleOpenModalFollowers = () => {
    this.setState({ openModalFollowers: true });
  };

  handleCloseModalFollowers = () => {
    this.setState({ openModalFollowers: false });
  };

  handleCreatorProfileImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile: File | null = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      this.setState({
        creatorProfileImgFile: selectedFile
      }, () => {
        this.updateCreatorProfileImg()
      });
    }
  };

  updateCreatorProfileImg = async () => {
    if (this.state.creatorProfileImgFile) {
      const formdata = new FormData();
      formdata.append("profile_photo", this.state.creatorProfileImgFile);

      this.updateCreatorProfileImage = await this.apiCall({
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.updateCreatorProfileApi,
        body: formdata,
        type: "formdata"
      });
    }
  };

  checkProfileUpdate = (responseJson: CreatorProfileResponse) => {
    if (responseJson && responseJson.data) {
      this.getCreatorProfileData();
    }
    else {
      if (responseJson.errors[0]['Profile photo']) {
        this.setState({
          imageError: responseJson.errors[0]['Profile photo'],
          errorModal: true
        })
      } else {
        this.setState({
          imageError: responseJson.errors[0]['Background photo'],
          errorModal: true
        })
      }
    }
  };

  checkProfileUpdate1 = async (responseJson: CreatorProfileResponse) => {
    if (responseJson && responseJson.data) {
      this.setState({ isUserNameUpdate: true });
      this.getCreatorProfileData();
      this.handleCloseModalBio();
      this.handleCloseModalUsername();
      const buyerData = await getStorageData("Buyer_Data");
      buyerData.account.user_name = responseJson.data.attributes.user_name
      await setStorageData("Buyer_Data", JSON.stringify(buyerData));
    } else {
      this.setError("profileUsernameError", configJSON.updateUsernameError);
    }
  };

  checkProfileUpdate2 = async (responseJson: CreatorProfileResponse) => {
    if (responseJson && responseJson.data) {
      this.setState({ isBioUpdate: true });
      this.getCreatorProfileData();
      this.handleCloseModalBio();
      this.handleCloseModalUsername();
      const buyerData = await getStorageData("Buyer_Data");
      buyerData.account.user_name = responseJson.data.attributes.user_name
      await setStorageData("Buyer_Data", JSON.stringify(buyerData));
    } else {
      this.setError("profileUsernameError", configJSON.updateUsernameError);
    }
  };

  errorModalClose = () => {
    this.setState({ errorModal: !this.state.errorModal })
  };

  getCreatorMalls = async () => {
    this.getAllCreatorMall = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCreatorMallEndPoint,
      type: ""
    });
  };

  getAllMallResponse = (responseJson: CreatorMallResponse) => {
    if (responseJson.data) {
      this.setState({ creatorMallData: responseJson.data })
    } else {
      this.setState({ errorInMall: responseJson.errors.message })
    }
  };

  createMallNav = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CreateMall");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  navigateToCreateMall = (MallId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `ViewMall`);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), MallId);
    this.send(message);
  };

  getCreatorDetails = async (creatorId: string) => {
    let sellerToken = await getStorageData("singupLogin");
    this.setState({ loading: true });
    const locArray = location.pathname.split("/");
    locArray.length > 2 && sellerToken

    this.getCreatorDetailsApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType, 
      newToken: sellerToken,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getCreatorDetailsEndPoint}${creatorId}`,
      type: ""
    });
  };

  getCreatorDetailsResponse = (responseJson: CreatorProfileResponse) => {
    if (responseJson.data) {
      this.setState({
        creatorProfileData: responseJson.data,
        flixoFollowerCount: responseJson.data.attributes.followers,
        creatorMallData: responseJson.data.attributes.creator_malls.data,
        isFollowingUser: responseJson?.data?.attributes?.is_follow,
        loading: false,
      })
    } else if (responseJson.errors) {
      this.handleApiErrors(responseJson)
    }
  };


  handleBuyerBannerImage = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile: File | undefined = event.target.files?.[0];
    if (selectedFile) {
      const reader = new FileReader();
      this.setState({
        buyerProfileBannerImgFile: selectedFile,
      }, () => {
        this.updateBuyerProfileBannerImg()
      });
      reader.readAsDataURL(selectedFile);
    }
  };

  updateBuyerProfileBannerImg = async () => {
    if (this.state.buyerProfileBannerImgFile) {
      const formData = new FormData()
      formData.append("background_photo", this.state.buyerProfileBannerImgFile)

      this.updateBuyerProfileBannerImage = await this.apiCall({
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.apiEndPointUpdateBuyerProfile,
        body: formData,
        type: "formdata"
      });
    }
  };

  handleBackToProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CreatorProfile");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  handleOpenModalUsername = () => {
    this.setState({ openModalUsername: true });
  };

  handleCloseModalUsername = () => {
    this.setState({
      openModalUsername: false,
      profileUsernameError: ''
    });
  };

  handleOpenModalLink = () => {
    this.setState({ openModalLink: true });
  };

  handleCloseModalLink = () => {
    this.setState({
      openModalLink: false,
      profileLinkError: ''
    });
  };

  handleOpenModalChangeEmail = () => {
    this.setState({ openModalChangeEmail: true });
  };

  handleCloseModalChangeEmail = () => {
    this.setState({
      openModalChangeEmail: false,
      email: '',
      emailError: ''
    });
  };

  handleOpenModalMobile = () => {
    this.setState({ openModalMobile: true });
  };

  handleCloseModalMobile = () => {
    this.setState({
      openModalMobile: false,
      mobileNumber: '',
      phoneError: ''
    });
  };

  handleOpenModalBio = () => {
    this.setState({ openModalBio: true });
  };

  handleCloseModalBio = () => {
    this.setState({
      openModalBio: false,
      profileBioError: ''
    });
  };

  handleOpenModalChangeAddress = () => {
    this.setState({ openModalChangeAddress: true });
  };

  handleCloseModalChangeAddress = () => {
    this.setState({
      openModalChangeAddress: false,
      addressline1Error: '',
      addressline2Error: '',
      pincodeError: '',
      cityError: '',
      stateError: '',
      countryError: '',
      addressError: ''
    }, () => {
      this.getCreatorProfileAddressData()
    });
  };

  handleOpenModalEnterChangePassword = () => {
    this.setState({ openModalEnterChangePassword: true });
  };

  handleCloseModalEnterChangePassword = () => {
    this.setState({
      openModalEnterChangePassword: false,
      currentPassword: '',
      currentPwdError: '',
      showCurrenPassword: false,
      newPassword: '',
      newPwdError: '',
      showNewPassword: false,
      reNewPassword: '',
      reNewPasswordError: '',
      showReNewPassword: false
    });
  };

  handleChangeCountry = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    this.setState({ countrySelectedValue: { value: event.target.value as string } });
  };

  makeRemoteRequest = async () => {
    this.countryCodeApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.apiEndPointGetCountryCodes,
      type: ""
    });
  };

  handlecountryCodeApiCallId = (responseJson: AllCountryData) => {
    if (responseJson && responseJson.data) {
      this.setState({
        dataSource: responseJson.data,
        countrySelectedValue: this.countryCodesSelected(responseJson.data)
      })
    } else {
      this.setState({
        dataSource: [],
        countrySelectedValue: { value: "" }
      })
    }
  };

  countryCodesSelected = (data: Array<CountryData>) => {
    let defaultCountryCode = {
      value: "",
    }

    if (data.length) {
      const resultData = data.find((item) => item.attributes.country_code == "91")
      if (resultData && resultData.attributes.country_code) {
        defaultCountryCode.value = resultData.attributes.country_code
      }
    }
    return defaultCountryCode;
  };

  handlegetCreatorProfileAddressApiId = (responseJson: Address) => {
    if (responseJson && responseJson.data?.attributes) {
      this.setState({
        buyerProfileAddressData: responseJson.data,
        addressline1: responseJson.data.attributes.address_line_1 ? responseJson.data.attributes.address_line_1 : "",
        addressline2: responseJson.data.attributes.address_line_2 ? responseJson.data.attributes.address_line_2 : "",
        pincode: responseJson.data.attributes.pin_code ? responseJson.data.attributes.pin_code : NaN,
        city: responseJson.data.attributes.city ? responseJson.data.attributes.city : "",
        state: responseJson.data.attributes.state ? responseJson.data.attributes.state : "",
        country: responseJson.data.attributes.country ? responseJson.data.attributes.country : "",
      }, () => { this.getStateList(); })
    } else {
      this.setState({
        buyerProfileAddressData: {} as AddressData,
        addressline1: '',
        addressline2: '',
        pincode: NaN,
        city: '',
        state: '',
        country: '',
      })
    }
  };

  getCreatorProfileAddressData = async () => {
    this.getCreatorProfileAddressApiId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.buyerProfileAddressAPIEndpoint,
      type: ""
    });
  };

  handleCountryChange = (event: ChangeEvent<{}>, newValue: string | null) => {
    this.setState({
      country: newValue,
      state: '',
      stateList: [],
      city: '',
      cityList: [],
    }, () => {
      newValue && this.getStateList();
    });
    this.setState({ countryError: "" });
  };

  setCountryList = (responseJson: CountriesData) => {
    if (responseJson.countries) {
      this.setState({
        countryList: Object.entries(responseJson.countries).map(([slug, name]) => ({ slug, name })),
      });
    }
  };

  getCountryList = async () => {
    this.getCountryListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.countryList,
      type: ""
    });
  };

  getStateList = async () => {
    this.getStateListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.stateList}?country_code=${this.state.countryList.find((item: StateList) => item.name === this.state.country)?.slug}`,
      type: ""
    });
  };

  setStateList = (responseJson: StateData) => {
    if (responseJson.states) {
      this.setState({
        stateList: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })),
        state: Object.entries(responseJson.states).map(([slug, name]) => ({ slug, name })).find((state: StateList) => state.name === this.state.state)?.name
      });
    }
  };

  getCityList = async () => {
    const { country, countryList, stateList, state } = this.state;

    const countrySlug = countryList.find((item: StateList) =>
      item.name === country)?.slug;
    const stateSlug = stateList.find((item: StateList) =>
      item.name === state)?.slug;

    this.getCityListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.cityList}?country_code=${countrySlug}&state_code=${stateSlug}`,
      type: ""
    });
  };

  setCityList = (responseJson: CitiesData) => {
    if (responseJson.cities) {
      this.setState({
        cityList: responseJson.cities,
        city: this.state.city
      });
    }
  };

  handleMobileNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const limitedValue = value.slice(0, 10);
    this.setState({
      mobileNumber: limitedValue,
      mobileNumberCopy: limitedValue
    })
  };

  checkValidationModalMobile = () => {
    let phone = this.validatePhone();
    if (phone) {
      this.updateBuyerMobile()
    }
  };

  handleOpenModalDisableAccount = () => {
    this.setState({ openModalDisableAccout: true });
  };

  handleCloseModalDisableAccount = () => {
    this.setState({ openModalDisableAccout: false });
  };

  checkValidationDeleteAccount = () => {
    let password = this.validatePassword();
    if (password) {
      this.handleCloseModalDeleteAccount();
    }
  };

  handlePasswordDeleteAccount = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ password: value });
  };

  handleCloseModalDeleteAccount = () => {
    this.setState({
      openModalDeleteAccount: false,
      password: '',
      pwdError: ''
    });
  };

  handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      email: value,
      emailCopy: value
    })
  };

  checkValidationModalEmail = () => {
    let email = this.validateEmail();
    if (email) {
      this.updateBuyerEmail()
    }
  };

  updateBuyerEmail = async () => {
    const formData = new FormData()
    formData.append("updated_email", this.state.emailCopy)

    this.updateEmail = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.updateCreatorProfileApi,
      body: formData,
      type: "formdata"
    });
  };

  handleChangeAddressline1 = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ addressline1: value });
  };

  handleChangeAddressline2 = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ addressline2: value });
  };

  handleChangePincode = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ pincode: parseInt(value) });
  };

  handleStateChange = (event: ChangeEvent<{}>, newValue: string | null) => {
    this.setState({
      state: newValue,
      city: '',
      cityList: []
    }, () => {
      newValue && this.getCityList();
    });
    this.setState({ stateError: "" });
  };

  handleCityChange = (event: ChangeEvent<{}>, newValue: string | null) => {
    this.setState({
      city: newValue
    });
    this.setState({ cityError: "" });
  };

  checkEmailOtpVerify = (responseJson: EmailOtpData) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.setState({ isEmailUpdate: true });
      this.handleCloseModalEmailOtp();
      this.getCreatorProfileData();
    }
  };

  handleOTPStateEmail = (value: string) => {
    this.setState({ otpStateEmail: value, otp: value });
  };

  handleOTPStateMobile = (value: string) => {
    this.setState({ otpStateMobile: value, otp: value });
  };

  checkValidationOtpEmail = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.buyerUpdateEmailTokenVerify();
    }
  };

  buyerUpdateEmailTokenVerify = async () => {
    this.updateEmailTokenVerify = await this.apiCall({
      newToken: this.state.updateEmailToken,
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.updateEmailAPIEndPoint + `?pin=${this.state.otpStateEmail}&update=true`,
      type: ""
    });
  };

  closeEmailSuccesModal = () => {
    this.setState({
      isEmailUpdate: !this.state.isEmailUpdate,
      otpError: '',
      otpStateEmail: ''
    });
  };

  checkMobileOtpVerify = (responseJson: MobileOtpData) => {
    if (responseJson && responseJson.errors) {
      this.setError("otpError", configJSON.updateOTPError);
    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.setState({ isMobileUpdate: true });
      this.getCreatorProfileData();
      this.handleCloseModalMobileOtp();
    }
  };

  handleupdateBuyerProfileImage = (responseJson: CreatorProfileResponse) => {
    if (responseJson && responseJson.data) {
      this.getCreatorProfileData()
    } else {
      if (responseJson.errors[0]['Profile photo']) {
        this.setState({
          imageError: responseJson.errors[0]['Profile photo'],
          errorModal: true
        })
      } else {
        this.setState({
          imageError: responseJson.errors[0]['Background photo'],
          errorModal: true
        })
      }
    }
  }

  handleCloseModalMobileOtp = () => {
    this.setState({
      openModalMobileOtp: false,
      otpError: ''
    });
  };

  handleCloseModalEmailOtp = () => {
    this.setState({
      openModalEmailOtp: false,
      otpError: '',
      otpStateEmail: ''
    });
  };

  updateBuyerMobile = async () => {
    const formData = new FormData()
    formData.append("updated_mobile_number", this.state.countrySelectedValue.value + this.state.mobileNumberCopy)
    formData.append("country_code", this.state.countrySelectedValue.value)

    this.updateMobileApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.updateCreatorProfileApi,
      body: formData,
      type: "formdata"
    });
  };

  setUpdateMobiledata = (responseJson: CreatorProfileResponse) => {
    if (responseJson && responseJson.errors) {
      this.setError("phoneError", configJSON.updateMobileError);
    }
    if (responseJson && responseJson.meta) {
      this.setState({ updateMobileToken: responseJson.meta.token, isOtpSent: true });
      this.handleOpenModalMobileOtp();
      this.handleCloseModalMobile();
      setTimeout(() => {
        this.setState({ isOtpSent: false })
      }, 4000);
    } else {
      this.setError("phoneError", "Mobile number has already been taken");
    }
  };

  handleOpenModalMobileOtp = () => {
    this.handleCloseModalMobile();
    this.setState({ openModalMobileOtp: true });
  };

  buyerUpdateMobileTokenVerify = async () => {
    const pinData = { "pin": this.state.otpStateMobile, "update": true };

    this.updateMobileTokenVerify = await this.apiCall({
      newToken: this.state.updateMobileToken,
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.updateMobileAPIndPoint,
      body: pinData,
    })
  }
  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ followsTabValue: newValue });
  };

  handleToggleModal = () => {
    this.setState({isFollowsModal: !this.state.isFollowsModal});
  };
  
  toggleFollowUnFollow = () => {
    const { isFollowingUser } = this.state;
    isFollowingUser ? this.unFollowApi() : this.followApi();
    this.setState({isFollowingUser: !this.state.isFollowingUser});
  };
  
  getFollowers = async () => {
    this.getFollowersApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getFollowersEndPoint}`,
      type: ""
    });
  };

  followApi = async () => {
    let sellerToken = await getStorageData("singupLogin");
    this.followUnFollowApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      newToken: sellerToken,
      endPoint: configJSON.followUserEndPoint,
      type: "",
      body: {
        data: {
          attributes: {
            followable_id: Number(this.state.creatorID)
          }
        }
      }
    });
  };

  unFollowApi = async () => {
    let sellerToken = await getStorageData("singupLogin");
    this.followUnFollowApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethod,
      endPoint: configJSON.followUserEndPoint + `/${this.state.creatorID}`,
      type: "",
      newToken: sellerToken
    });
  };

  getFollowUnfollowApiResponse = (responseJson: { data: {}, message: string }) => {
    const { isFollowingUser, flixoFollowerCount } = this.state;
    if (responseJson.data || responseJson.message) {
      let newCnt = !isFollowingUser ? flixoFollowerCount - 1 : flixoFollowerCount + 1;
      this.setState({ flixoFollowerCount: newCnt });
    }
  };

  checkValidationOtpMobile = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.buyerUpdateMobileTokenVerify()
    }
  };

  setUpdateEmailData = (responseJson: CreatorProfileResponse) => {
    if (responseJson && responseJson.errors) {
      this.setError("emailError", configJSON.updateEmailError);
    }
    if (responseJson && responseJson.meta) {
      this.handleCloseModalChangeEmail();
      this.setState({ updateEmailToken: responseJson.meta.token, isOtpSent: true });
      this.handleOpenModalEmailOtp();
      setTimeout(() => {
        this.setState({ isOtpSent: false })
      }, 4000);
    } else {
      this.setError("emailError", configJSON.updateEmailError);
    }
  };

  handleOpenModalEmailOtp = () => {
    this.handleCloseModalChangeEmail();
    this.setState({ openModalEmailOtp: true });
  };

  closeMobileSuccesModal = () => {
    this.setState({
      isMobileUpdate: !this.state.isMobileUpdate,
      otpError: '',
      otpStateEmail: ''
    });
  };

  handleCurrentPasswordChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ currentPasswordCopy: value, currentPassword: value })
  };

  handleCurrenPasswordShow = () => {
    this.setState({ showCurrenPassword: !this.state.showCurrenPassword })
  };

  handleNewPasswordShow = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  };

  handleNewPasswordChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
      newPasswordCopy: value,
      newPassword: value
    })
  };

  handleReEnterNewPasswordChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ reNewPassword: value });
  };

  handleReNewPasswordShow = () => {
    this.setState({ showReNewPassword: !this.state.showReNewPassword })
  };

  checkValidationPasswordChange = () => {
    let currentPassword = this.validateCurrentPassword();
    let newPassword = false
    if (currentPassword) {
      newPassword = this.validateNewPassword();
    }
    if (currentPassword && newPassword) {
      this.setState({ isLoading: true })
      this.updateSellerChangePassword();
    }
  };

  handleCloseModalPasswordSuccessfull = () => {
    this.setState({ openModalPasswordSuccessfull: false });
  };

  handleChangeProfileBio = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ profileBio: value.trim() })
  };

  checkValidationBioChange = () => {
    let profileBio = this.validateProfileBio();
    if (this.state.profileBio === '') {
      this.setError("profileBioError", configJSON.profileBioError);
      return false;
    }
    if (profileBio) {
      this.updateSellerProfileBio()
    }
  };

  updateSellerProfileBio = async () => {
    const formData = new FormData()
    formData.append("my_bio", this.state.profileBio)

    this.updateProfileBio = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.sellerUpdateProfileAPIEndPoint,
      body: formData,
      type: "FormData"
    });
  }

  handleChangeProfileUsername = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ profileUsername: value.trim() })
  };

  checkValidationUsernameChange = () => {
    let profileUsername = this.validateProfileUsername();
    if (this.state.profileUsername === '') {
      this.setError("profileUsernameError", configJSON.profileUsernameError);
      return false;
    }
    if (profileUsername) {
      this.updateSellerProfileUsername()
    }
  };

  updateSellerProfileUsername = async () => {
    const formData = new FormData()
    formData.append("user_name", this.state.profileUsername)

    this.updateProfileUsername = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.sellerUpdateProfileAPIEndPoint,
      body: formData,
      type: "formdata"
    });
  };

  changeProfileInstaURL = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ profileInstaURL: value.trim() })
  };

  changeProfileYouTubeURL = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ profileYouTubeURL: value.trim() })
  };

  checkValidationURLChange = () => {
    let profileInstaURL = this.validateProfileURL();
    let profileYouTubeURL = this.validateProfileURL();
    if (this.state.profileInstaURL === '' && this.state.profileYouTubeURL === '') {
      this.setError("profileLinkError", configJSON.profileURLsError);
      return false;
    }
    if (profileInstaURL && profileYouTubeURL) {
      this.updateCreatorProfileURL()
    }
  };

  updateCreatorProfileURL = async () => {
    const formData = new FormData()
    formData.append("instagram_url", this.state.profileInstaURL)
    formData.append("youtube_url", this.state.profileYouTubeURL)

    this.updateProfileURLs = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.updateCreatorProfileApi,
      body: formData,
      type: "formdata"
    });
  };

  checkPasswordUpdate = (responseJson: ChangePasswordRes) => {
    this.setState({ isLoading: false });
    if (responseJson.message == "Password changed successfully") {
      this.handleCloseModalEnterChangePassword();
      this.handleOpenModalPasswordSuccessfull();
    } else {
      this.setState({ reNewPasswordError: responseJson.message })
    }
  };

  handleOpenModalPasswordSuccessfull = () => {
    this.setState({ openModalPasswordSuccessfull: true });
  };

  closeUsernameSuccesModal = () => {
    this.setState({
      isUserNameUpdate: !this.state.isUserNameUpdate,
      otpError: '',
      otpStateEmail: ''
    });
  };

  closeBioSuccesModal = () => {
    this.setState({
      isBioUpdate: !this.state.isBioUpdate,
      otpError: '',
      otpStateEmail: ''
    });
  };

  updateSellerChangePassword = async () => {
    const passwordData = {
      "current_password": this.state.currentPasswordCopy,
      "new_password": this.state.newPasswordCopy
    }

    this.updateBuyerPassword = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.updatePasswordAPIEndPoint,
      body: passwordData,
    })
  }

      getFollowing = async () => {
    this.getFollowingApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getFollowingEndPoint}`,
      type: ""
    });
  };

  updateCreatorProfileAddressData = async () => {
    let address = {};
    if (this.state.buyerProfileAddressData && this.state.buyerProfileAddressData.id) {
      address = {
        "id": this.state.buyerProfileAddressData.id,
        "address": {
          "address_line_1": this.state.addressline1,
          "address_line_2": this.state.addressline2,
          "pin_code": this.state.pincode,
          "city": this.state.city,
          "state": this.state.state,
          "country": this.state.country
        }
      }
    } else {
      address = {
        "address": {
          "address_line_1": this.state.addressline1,
          "address_line_2": this.state.addressline2,
          "pin_code": this.state.pincode,
          "city": this.state.city,
          "state": this.state.state,
          "country": this.state.country
        }
      }
    }

    this.updateCreatorProfileAddressApiId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: this.state.buyerProfileAddressData ? configJSON.putApiMothod : configJSON.exampleAPiMethod,
      endPoint: configJSON.buyerProfileAddressAPIEndpoint,
      body: address,
      type: ""
    });
  };

  handleupdateCreatorProfileAddressApiId = (responseJson: Address) => {
    if (responseJson?.meta?.message == "Address Updated Successfully" || responseJson?.meta?.message == "Address Created Successfully") {
      this.handleCloseModalChangeAddress()
      this.handleOpenModalAddressSuccessfull();
    } else {
      this.setState({
        addressError: configJSON.AddressError
      });
    }
  };

  handleOpenModalAddressSuccessfull = () => {
    this.setState({ openModalAddressSuccessfull: true });
  };

  handleCloseModalAddressSuccessfull = () => {
    this.setState({ openModalAddressSuccessfull: false });
  };

  checkValidationAddressChange = () => {
    let addressline1 = this.validateAddressline1();
    let addressline2 = this.validateAddressline2();
    let pincode = this.validatePincode();
    let city = this.ValidateCity();
    let state = this.ValidateState();
    let country = this.ValidateCountry();
    if (addressline1 && addressline2 && pincode && country && city && state) {
      this.updateCreatorProfileAddressData();
    }
  };

  validateNewPassword = () => {
    const { newPassword, reNewPassword } = this.state;
    if (newPassword === '') {
      this.setError("newPwdError", configJSON.errorPassword);
      return false;
    } if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(newPassword)) {
      this.setError("newPwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ newPwdError: "" });

    if (newPassword !== reNewPassword) {
      this.setError("reNewPasswordError", configJSON.confirmPwdError);
      return false;
    }
    this.setState({ reNewPasswordError: "" });

    return true;
  };

  validatePassword = () => {
    const { password } = this.state;
    if (password === '') {
      this.setError("pwdError", configJSON.errorPassword);
      return false;
    } if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(password)) {
      this.setError("pwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ pwdError: "" });
    return true;
  };

  validateCurrentPassword = () => {
    const { currentPassword } = this.state;
    if (currentPassword === '') {
      this.setError("currentPwdError", configJSON.errorPassword);
      return false;
    } if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/.test(currentPassword)) {
      this.setError("currentPwdError", configJSON.errorPasswordNotValid);
      return false;
    }
    this.setState({ currentPwdError: "" });
    return true;
  };

  validatePhone = () => {
    const { mobileNumber } = this.state;
    if (!mobileNumber || mobileNumber.trim().length !== 10) {
      this.setError("phoneError", configJSON.phoneError);
      return false;
    }
    this.setState({ phoneError: "" });
    return true;
  };

  validateEmail = () => {
    const { email } = this.state;
    if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      this.setError("emailError", configJSON.emailError);
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  };

  validateAddressline1 = () => {
    const { addressline1 } = this.state;
    if (!addressline1 || addressline1.trim().length < 3) {
      this.setError("addressline1Error", configJSON.addressline1Error);
      return false;
    }
    this.setState({ addressline1Error: "" });
    return true;
  };

  validateAddressline2 = () => {
    const { addressline2 } = this.state;
    if (!addressline2 || addressline2.trim().length < 3) {
      this.setError("addressline2Error", configJSON.addressline2Error);
      return false;
    }
    this.setState({ addressline2Error: "" });
    return true;
  };

  validatePincode = () => {
    const { pincode } = this.state;
    if (!pincode || pincode.toString().length < 5) {
      this.setError("pincodeError", configJSON.pincodeError);
      return false;
    }
    this.setState({ pincodeError: "" });
    return true;
  };

  validateProfileBio = () => {
    const { profileBio } = this.state;
    if (!profileBio || profileBio.length < 3) {
      this.setError("profileBioError", configJSON.profileBioError);
      return false;
    }
    this.setState({ profileBioError: "" });
    return true;
  };

  validateProfileUsername = () => {
    const { profileUsername } = this.state;
    if (!profileUsername || profileUsername.length < 3) {
      this.setError("profileUsernameError", configJSON.profileUsernameError);
      return false;
    }
    this.setState({ profileBioError: "" });
    return true;
  };

  validateProfileURL = () => {
    const { profileInstaURL, profileYouTubeURL } = this.state;
    if (!profileInstaURL || profileInstaURL.length < 3 && !profileYouTubeURL || profileYouTubeURL.length < 3) {
      this.setError("profileLinkError", configJSON.profileURLsError);
      return false;
    }
    this.setState({ profileLinkError: "" });
    return true;
  };

  ValidateCountry = () => {
    const { country } = this.state;
    if (!country) {
      this.setError("countryError", configJSON.countryError);
      return false;
    } else {
      this.setState({ countryError: "" });
      return true;
    }
  };

  ValidateState = () => {
    const { state } = this.state;
    if (!state) {
      this.setError("stateError", configJSON.stateError);
      return false;
    } else {
      this.setState({ stateError: "" });
      return true;
    }
  };

  ValidateCity = () => {
    const { city } = this.state;
    if (!city) {
      this.setError("cityError", configJSON.cityError);
      return false;
    } else {
      this.setState({ cityError: "" });
      return true;
    }
  };

  validateOtp = () => {
    const { otp } = this.state;
    const optNew = otp.trim();
    if (!optNew || optNew.length !== 6) {
      this.setError("otpError", configJSON.otpError);
      return false;
    }
    this.setState({ otpError: "" });
    return true;
  };

  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  };

  handleOpenImage = (url: string) => {
    this.setState({ carousalUrl: url, isPostOpen: true })
  };

  handleClosePostModal = () => {
    this.setState({ carousalUrl: "", isPostOpen: false })
  };

  getFollowersApiResponse = (responseJson: FollowRes) => {
    if (responseJson.data) {
      this.setState({ followers: responseJson.data });
    } else {
      this.setState({ followers: [] });
    }
  };

  getFollowingApiResponse = (responseJson: FollowRes) => {
    if (responseJson.data) {
      this.setState({ following: responseJson.data });
    } else {
      this.setState({ following: [] });
    }
  };
  // Customizable Area End
}