import React, { Component } from "react";
import { Box, Card, Typography, styled, Button, Avatar } from "@material-ui/core";

const StyledCard = styled(Box)({
  maxWidth: "193px",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
});

const ProductImage = styled("img")({
  width: "100%",
  height: "174px",
  objectFit: "cover",
});

const PriceContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginTop: 8,
});

const OriginalPrice = styled(Typography)({
  textDecoration: "line-through",
  color: "#999",
});

const StoreDetails = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: 8,
});

const StoreImage = styled(Avatar)({
  width: 40,
  height: 40,
  borderRadius: "50%",
  marginRight: 8,
});

const OnOrderButton = styled(Button)({
  width:"140px",
  height:"28px",
  borderRadius:"50px",
  background:"#F5EA16",
  textTransform:"none",
  '&:hover': {
    backgroundColor: '#F5EA16',
    cursor:"pointer"
  },
});

const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
  marginBottom:"10px",
  justifyContent:"center"
});

const EmptyBox = styled(Box)({
  height:"54px"
});

interface OrderCardProps {
  tag: string;
  tagColor: string;
  image: string;
  productName: string;
  price: number;
  originalPrice: number;
  storeImage: string | undefined;
  storeName: string;
  tagTextColor: string;
  handleOnOrderRequestModal: (item : string) => void;
  prodID: string;
  isOnOrder: boolean;
  isFromTopSelling: boolean;
  handleTopSellingOnOrderReq : (item : string) => void;
  handleNavigate : (prodId : number) => void;
}

class OrderCard extends Component<OrderCardProps> {
  render() {
    const {
      tag,
      tagColor,
      image,
      productName,
      price,
      originalPrice,
      storeImage,
      storeName,
      tagTextColor,
      prodID,
      handleOnOrderRequestModal,
      isOnOrder,
      isFromTopSelling,
      handleTopSellingOnOrderReq,
      handleNavigate
    } = this.props;

    return (
      <StyledCard>
        <Box  style={{position:"relative", width:"193px"}}>
        <span onClick={()=> handleNavigate(Number(prodID))}>
        <Box style={{
                width:"78px",
                height:"28px",
                 position: "absolute",
                backgroundColor: tagColor,
               color: tagTextColor,
                padding: "4px 8px",
                fontSize: "12px",
                fontWeight: 600,
                textTransform: "capitalize",
                display: "inline-block",
              }}
        >{tag}</Box>
        
        <ProductImage src={image} alt={productName} />
        <Box style={{padding:"10px"}}>
          <Typography  style={{fontSize:"18px", fontWeight: 500, color:"#4E4E4E"}}>
            {productName}
          </Typography>
          <PriceContainer>
            <Typography style={{fontSize:"16px", fontWeight: 700, color:"#4E4E4E"}}>
              ₹{price}
            </Typography>
            <OriginalPrice style={{fontSize:"16px", fontWeight: 400, color:"#6E6E6E80"}}>₹{originalPrice}</OriginalPrice>
          </PriceContainer>
          <StoreDetails>
            <StoreImage src={storeImage} alt={storeName} />
            <Typography style={{fontSize:"14px", fontWeight: 500, color:"#6E6E6E"}} >{storeName}</Typography>
          </StoreDetails>
        </Box>
        </span>
        {tag === "On Order" && isOnOrder ?
              <CustomBox>
                <OnOrderButton onClick={() => { isFromTopSelling ? handleTopSellingOnOrderReq(prodID) : handleOnOrderRequestModal(prodID) }}>Send Request</OnOrderButton>
              </CustomBox>
              :
              isFromTopSelling ? <EmptyBox></EmptyBox> : <span></span>
            }
        </Box>
      </StyledCard>
    );
  }
}

export default OrderCard;
