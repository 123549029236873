import React from "react";
// Customizable Area Start
import Cfflixoolivewallet0Controller, {
  Props,
} from "./Cfflixoolivewallet0Controller";
import { Box, styled, TextField, Typography, Button, Modal } from "@material-ui/core";
import { arrowLeftIc, closeIc } from "../../CustomisableUserProfiles/src/assets";
import SnackBarAlertWeb from "../../../components/src/SnackBarAlert.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";
import "../assets/css/walletstyle.css"
// Customizable Area End

export default class Cfflixoolivewallet0 extends Cfflixoolivewallet0Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTransferMoneyModal = () => {
    return (
      <CustomModal
        open={this.state.isBankDetailsModal}
        onClose={this.toggleBankDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="ModalMainContainer"> <Box>
          <Box className="modalHeader">
            <Typography className="titleText">Transfer To</Typography>
            <img src={closeIc} alt="" onClick={this.toggleBankDetailsModal} className="closeIcon" />
          </Box>

          <Box className="inputContainer">
            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                placeholder={"012345678190876"}
                label={"Bank A/C number"}
                variant="outlined"
                name="bankAccountNumber"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.bankAccountNumber}
                error={!!this.state.errors.bankAccountNumber} 
                helperText={this.state.errors.bankAccountNumber}
                onChange={this.handleChange}
              />
            </Box>

            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                placeholder={"HDFC"}
                label={"Bank name"}
                variant="outlined"
                name="bankName"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.bankName}
                error={!!this.state.errors.bankName}
                helperText={this.state.errors.bankName}
                onChange={this.handleChange}
              />
            </Box>

            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                data-test-id="fullName"
                placeholder={"HDFC000034"}
                label={"IFSC Code"}
                variant="outlined"
                name="ifscCode"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.ifscCode}
                error={!!this.state.errors.ifscCode}
                helperText={this.state.errors.ifscCode}
                onChange={this.handleChange}
              />
            </Box>

            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                data-test-id="fullName"
                placeholder={"John Doe"}
                label={"Receivers name"}
                variant="outlined"
                name="receiversName"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.receiversName}
                error={!!this.state.errors.receiversName}
                helperText={this.state.errors.receiversName}
                onChange={this.handleChange}
              />
            </Box>

            <Box>
              <Button className="transferFinalBtn" onClick={this.handleSubmit}>Transfer</Button>
            </Box>

          </Box>
        </Box>
        </Box>
      </CustomModal>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {isTransferOpen, isAddOpen, transferAmt, addAmount, walletAmt, transactionDetails} = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <WalletStyleWrapper >
        <Loader loading={this.state.isLoading} />
        <SnackBarAlertWeb snackBarProps={this.state.snackBarProps} handleCloseSnack={this.handleCloseSnack} />
        <MessageModalWeb
          data-test-id="errorMessage"
          displayPopup={this.state.errorModal}
          closeErrorPopup={this.errorModalToggleClose}
          errorMessage={this.state.errorMessage}
        />
        {this.renderTransferMoneyModal()}
        <Box className="mainContainer">
          {!isTransferOpen && !isAddOpen &&
            <span>
              <Box className="headingBox">
                <Typography className="headingTxt">Flixoo Wallet</Typography>
              </Box>

              <Box className="contentBodyBox">
                <Box className="walletInfoBox">
                  <Typography className="walletPoint">{Number(walletAmt)}</Typography>
                  <Typography className="pointTitle">Flixoo Points</Typography>
                  <Typography className="pointInfoTxt">1 Flixoo Point = ₹1 on Flixoo App</Typography>
                  <Box className="buttonBox">
                    <Button className="transferBtn btnClass" data-test-id="transferBtnTestId" onClick={this.handleTransferToogle}>
                      Transfer from Wallet
                    </Button>

                    <Button className="addWalletBtn btnClass" data-test-id="addBtnTestId" onClick={this.handleAmountToogle}>
                      Add to Wallet
                    </Button>
                  </Box>

                  <Box className="historyBox">
                    <Box className="historyTitleBox">
                      <Typography className="titleHistory">Your Transaction</Typography>
                    </Box>
                    <Box className="transactionDetailBox">
                      {transactionDetails.length > 0 ? transactionDetails.map((item, itemIdx) => (
                        <Box className="transactionDetails" key={itemIdx}>
                          <Box className="reasonBox" ><Typography className="transactionReason">{item.remarks}</Typography></Box>
                          <Box className="statusBox" > <Typography className="tranStatus">{`${item.status}`}</Typography></Box>
                          <Box className="amountBox" ><Typography className={`transactionAmt`} style={{ color: this.checkDeposite(item.transaction_type, item.status) }}>{this.returnSign(item.transaction_type, item.status)} {item.amount}</Typography></Box>
                        </Box>
                      )) : <Box className="noTransactionContainer">
                        No transaction found
                      </Box>
                      }
                    </Box>
                  </Box>

                </Box>
              </Box>
            </span>
          }

          {isTransferOpen &&
            <Box className="transferBox">
              <Box className="headerBox">
                <img src={arrowLeftIc} className="backIconImg" data-test-id="backTransferTestId" onClick={this.handleTransferToogle} />
                <Typography className="headingTxt">Transfer from Wallet</Typography>
              </Box>

              <Box className="moneyContainer">
                <Typography className="infoTitle">Amount to be Transferred: </Typography>
                <Box className="textFieldBox">
                <TextField value={transferAmt === 0 ? "" : transferAmt} data-test-id="inputTransferTestId" onChange={this.handleChangeTransferAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                <span className="errorTxt">{this.state.transferError}</span>
                </Box>
                <Box>
                  <Button className="saveButton" onClick={this.openDetailsModal}>Transfer</Button>
                </Box>
              </Box>
            </Box>
          }

          {isAddOpen &&
            <Box className="transferBox">
              <Box className="headerBox">
                <img src={arrowLeftIc} className="backIconImg" data-test-id="backAddrTestId" onClick={this.handleAmountToogle} />
                <Typography className="headingTxt">Add money to Wallet</Typography>
              </Box>

              <Box className="moneyContainer">
                <Typography className="infoTitle">Amount to be Added: </Typography>
                <Box className="textFieldBox">
                <TextField value={addAmount === 0 ? "" : addAmount} data-test-id="inputAddTestId" onChange={this.handleChangeAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                <span className="errorTxt">{this.state.addAmountError}</span>
                </Box>
                <Box> <Button className="saveButton" onClick={()=> this.postDepositeApiCall()}>Proceed to Payment</Button></Box>
              </Box>
            </Box>
          }
        </Box>
      </WalletStyleWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WalletStyleWrapper = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  marginTop: "135px",
  padding: "0px 24px",
  "& .headingTxt": {
      fontSize: "20px",
      fontWeight: 500,
      color: "#444444CC"
  },
  "& .walletInfoBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "60px",
  },
  "& .walletPoint": {
      fontSize: "60px",
      fontWeight: 700,
      color: "#444444"
  },
  "& .pointTitle": {
      fontSize: "14px",
      fontWeight: 500,
      color: "#444444"
  },
  "& .pointInfoTxt": {
      fontSize: "12px",
      fontWeight: 500,
      color: "#44444466",
      marginTop: "12px"
  },
  "& .buttonBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      marginTop: "32px",
  },
  "& .btnClass": {
      backgroundColor: "#F5EA16",
      padding: "12px 8px",
      width: "100%",
      minWidth: "163px",
      maxWidth: "163px",
      textTransform: "none",
      fontSize: "12px",
      fontWeight: 600,
      color: "#444444",
      borderRadius: "8px"
  },
  "& .historyBox": {
      width: "100%",
      maxWidth: "580px",
      marginTop: "48px"
  },
  "& .historyTitleBox": {
      borderBottom: "1px solid #4444441A",
      paddingBottom: "10px"
  },
  "& .transactionDetailBox": {
      height: "100%",
      maxHeight: "500px",
      overflowY: "scroll",
      scrollbarWidth: "thin"
  },
  "& .transactionDetails": {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #4444441A",
      padding: "20px 0px"
  },
  "& .credited": {
      color: "#0DAD01"
  },
  "& .debited": {
      color: "#ED2224"
  },
  "& .transactionAmt": {
      fontSize: "14px",
      fontWeight: 400,
      marginRight: "4px"
  },
  "& .noTransactionContainer":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"30px",
    color: "#7c7c7cf0",
    fontSize:"22px",
  },
  "& .transactionReason": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#444444"
  },
  "& .tranStatus":{
      fontSize: "14px",
      fontWeight: 400,
      color: "#444444",
      textTransform:"capitalize"
  },
  "& .reasonBox": {
    width: "220px"
  },
  "& .statusBox": {
    width: "100px",
    textAlign: "center"
  },
  "& .amountBox": {
    width: "120px",
    textAlign: "right"
  },
  "& .titleHistory": {
      fontSize: "16px",
      fontWeight: 600,
      color: "#444444",
  },
  "& .headerBox": {
      display: "flex",
      alignItems: "center",
      gap: "8px"
  },
  "& .backIconImg": {
      width: "25px",
      cursor: "pointer"
  },
  "& .moneyContainer":{
      marginTop:"72px"
  },
  "& .infoTitle":{
      fontSize:"16px",
      fontWeight: 500,
      color: "#000000"
  },
  "& .amountInput": {
      marginTop: "40px",
      border: "1px solid #44444433",
      borderRadius: "8px",
      background: "white",
      padding: "12px 16px",
      width: "100%",
      maxWidth: "130px",
      "& ::placeholder": {
          fontSize: "12px",
          fontWeight: 400,
          color: "#44444480"
      }
  },
  "& .errorTxt":{
    color: 'red',
    fontSize:"12px",
    marginTop:"4px"
  },
  "& .textFieldBox":{
    display:"flex",
    flexDirection:"column"
  },
  "& .saveButton":{
      textTransform:"none",
      fontSize:"12px",
      fontWeight: 600,
      color:"#444444",
      background:"#F5EA16",
      padding:"12px 16px",
      width:"100%",
      maxWidth:"342px",
      borderRadius:"8px",
      marginTop:"40px"
  },
});

const CustomModal = styled(Modal)({
  "& .ModalMainContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    maxWidth: "390px",  
    width: "100%",  
    transform: "translate(-50%, -50%)",
    overflow:"auto",
    maxHeight:"550px",
    display:"flex",
    padding:"24px",
    borderRadius:"16px"
  },
  "& .modalHeader":{
    display:"flex",
    justifyContent:"space-between"
  },
  "& .inputContainer":{
    display:"flex",
    flexDirection:"column",
    gap:"30px",
    marginTop:"24px"
  },
  "& .transferFinalBtn":{
    fontSize:"12px",
    textTransform:"none",
    color:"#444444",
    fontWeight: 600,
    padding:"12px 16px",
    background:"#F5EA16",
    maxWidth:"342px",
    width:"100%",
    marginTop:"16px",
    borderRadius:"8px",
  },
  '& .inputFieldBox': {
    '& .MuiFormLabel-root': {
        fontFamily: 'Poppins-Regular !important',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#44444480"
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#44444480',
        opacity: 0.5,
        fontSize: '14px',
      },
},

});
// Customizable Area End
