Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.postMethodType = "POST";
exports.patchMethodType = "PATCH"
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleAPiMethodPut = "PUT";
exports.deleteMethodType = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InventoryManagement3";
exports.labelBodyText = "InventoryManagement3 Body";
exports.shipmentDetailApiEndPoint = "inventory_details";
exports.shipmentBackButtonDataApiEndPoint ="bx_block_inventorymanagement2/inventories?catalogue_id=";
exports.shipmentApiendPoint = "bx_block_inventorymanagement2/inventories";
exports.pakageDetailsApiendPoint = "bx_block_order_management/packaging_details";
exports.getPakageDetailsApiendPoint = "bx_block_order_management/packaging_details?catalogue_id=";
exports.postManufacturingApiEndPoint="bx_block_inventorymanagement2/manufacturing_detail";
exports.getManufacturingApiEndPoint="bx_block_inventorymanagement2/manufacturing_detail?catalogue_id="
exports.btnExampleTitle = "CLICK ME";

exports.getCategoriesEndpoint = "bx_block_categories/categories"
exports.getSubCategoriesEndpoint = "bx_block_categories/sub_categories"
exports.catalogueEndpoint ="catalogue/catalogues"
exports.uploadImagesEndpoint  = "bx_block_catalogue/catalogues/upload_images"
exports.getSellerCatalogues = "bx_block_catalogue/catalogues/get_seller_catalogues"
exports.deleteAPIEndpoint = "bx_block_catalogue/catalogues/destroy_catalogues"
exports.getProductDetails = "catalogue/catalogues/"
exports.getSellerCatalogueDescription  = "bx_block_catalogue/catalogues/seller_catalogue_description" 
exports.getSellerCatalogueVariants = "bx_block_catalogue/catalogues"
exports.countryList = "bx_block_address/get_country_list"
exports.createnewcatalouge = "bx_block_catalogue/catalogues"
exports.productDescription = "Product Description"
exports.mrp = "MRP"
exports.yourSellingPrice = "Your selling price"
exports.brand = "Brand"
exports.stockQuantity = "Stock quantity"
exports.fulfilmentBy = "Fulfilment by"
exports.procurementType = "Procurement type"
exports.ProcurementSLA = "Procurement SLA"
exports.taxcode = "Tax code"
exports.shipmentProvider = "Shipment provider"
exports.hsn = "HSN"
exports.editProduct = "Edit Product"
exports.editVaraint = "Edit Variant"
exports.colors = "Colour"
exports.returnAndExchange   = "Return and exchange "
exports.Yes = "Yes"
exports.No = "No"


exports.noProducts = "Sorry you have no products added."
exports.nosearchProducts ="There are no products available related to your search!"
exports.startNow = "Start now!'"
exports.addProducts = "Add Products"
exports.inventory = "Inventory"
exports.steps = [{id:"1",label:"Upload"},{id:"2",label:"Listing"},{id:"3",label:"Shipment"},{id:"4",label:"Tax"},{id:"5",label:"Product"}]
exports.uploadImages  = "Upload Images "
exports.uploadVideos  = "Upload Videos "
exports.imageLimit = "6"
exports.videoLimit =  "2"
exports.upload = "Upload"
exports.here = "here"
exports.preview = "Preview"
exports.sizeMax  = "Size max : "
exports.imageSizeValue  = "5 mb"
exports.videoSizeValue  = "10 mb"
exports.imageRatio  = "Image ratio : "
exports.imageRatioValue  = "1:1 , 3:4"
exports.format  = "Format : "
exports.imageFormatValue  = "JPG, PNG"
exports.videoFormatValue = "MP4"
exports.videoAdditionalInfo  = "You can publish the listing while the video is processing"
exports.resolution = "Resolution : "
exports.resolutionValue= "1280 px X 1280 px"
exports.save = "Save"
exports.title = "New Product"
exports.saveAndContinue = "Save"
exports.listingTitle = "Listing details"
exports.productTitle = "Product Title"
exports.editTitle = "Edit Product"
exports.skuId ="SKU Id"
exports.selectYourPrimaryCategory =  "Select your primary category"
exports.selectYourSubCategory =  "Select your sub category"
exports.specifications = "Specifications"

exports.imageErr = "In order to proceed with the deletion of the existing image, it is necessary to replace it with at least one new image. Kindly add an additional image and save the changes accordingly to continue with the process"

exports.switchTitles = {
  bargain: "Bargain",
  onOrder: "On order",
  askPrice: "Ask price",
  Affiliate: "Affiliate"
};
exports.statusDetails =[{ label: 'in_progress', value: 'In Progress',id:"1" }, { label: 'on_hold', value: 'On Hold',id:"2" }]

exports.switchDescriptions = {
  bargain: "Turn this on if you are ready for a bargain",
  onOrder: "Turn this on if you provide customized on order",
  askPrice: "Turn this on to make a deal/offer for limited products",
  Affiliate: "Turn this on if you want to generate affliate link"
}
exports.rupeeSymbol = "₹"
exports.areYouWantToDelte = "Are you sure you want to delete this ?"
exports.deleteDescription = "Deleting this will remove the product and all its variations from the inventory."
exports.validationForsellingPrice = "Selling price must be lower than MRP"
exports.brandValidation = "Brand is required"
exports.cancel="No, Cancel"
exports.delete="Delete"
exports.edit = "Edit"
exports.addVariant = "Add variant"
exports.editVariant = "Edit variant"
exports.searchForProducts= "Search for products" 
exports.askPriceLabel = "Ask price";
exports.nobrandName = "This product doesn't have a brand name"
exports.selectOne = " Select one"
exports.inventoryApiendPoint = "/inventory_details"
exports.inventoryDetails = "Inventory Details"
exports.fulfilmentBy= "Fulfilment by"
exports.procurementType= "Procurement Type"
exports.select = "Select one"
exports.save = "Save"
exports.time = "Time you will need to ensure the product is ready for shipping."
exports.charges = "Shipment Charges"
exports.packagingDetails = "Packaging Details"
exports.taxDetails = "Tax Details"
exports.copy = "Copy"
exports. hsnCode = "HSN code"
exports.codeDetails = "from this list according to your product and paste it here."
exports.packagingDetailsError = "Please don't leave it blank."
exports. manufacturingDetails =" Manufacturing Details"
exports.addEdit ="Add "
exports.variant="variant"
exports.returnExchange="Return & Exchange"
exports.provide="Turn this on if you provide exchange"
exports.returnRefund="Return & Refund"
exports.chart="Upload size chart"
exports.upload="upload"
exports.here="here"
exports.ave="Save"
exports.kb="KB"
exports.sucessMessage = "Successfully Deleted"
exports.deleteInventoryMessage = "The Product has been successfully deleted from the inventory"
exports.productDetails= "Product Details"
exports.samePriceText = "Turn this on to set same price for all variation"
// Customizable Area End 