// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
export const configJSON = require("./config");
import { load } from "@cashfreepayments/cashfree-js";
const baseURL = require("../../../framework/src/config.js").baseURL

interface CashFreeResultPayload {
    error?: string;
    redirect?: string
}

interface PaymentPayload {
  order_id: string,
  payment_session_id: string;
}

interface InitiateResponse {
  data : PaymentPayload
}

interface BankDetails {
  bankAccountNumber: string;
  bankName: string;
  ifscCode: string;
  receiversName: string;
}

interface ErrorItem {
  [key: string]: string; 
};

export interface SnackBarItem{
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
};

interface WalletResponse {
  data : {
    success: boolean,
    wallet: {
        balance: string;
        wallet_id: string;
        last_updated_at: string;
    }
  }
}

interface TransactionData {
  id: number;
  account_id: number;
  cashfree_wallet_id: number;
  amount: string;
  transaction_type: string;
  status: string;
  reference_id: string;
  transaction_id: string;
  withdrawal_id: number | null;
  remarks: string;
  utr_number: string | null;
  request_data: {}; 
  response_data: {
    status: string;
  };
  processed_at: string;
  created_at: string;
  updated_at: string;
}

interface TransactionResponse {
  total_count: number;
  page: number;
  per_page: number;
  data :{
    transactions: Array<TransactionData>
  }
}


interface WithdrawalResponse {
  data: {
    success: boolean;
    message: string;
    transaction_id: string;
    withdrawal_id: string;
    amount: number;
    new_balance: number;
    reference_id: string;
    transfer_type: string;
    status: string;
  };
}
// Customizable Area End


export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  transferOpen: boolean;
  addProductOpen: boolean;
  tranferAmount: number;
  addPrice: number;
  isDrawer: boolean;
  snackBarItems: SnackBarItem;
  walletLoader: boolean;
  walletAmount: string;
  walletId: string | null;
  transactionData: Array<TransactionData>;
  totalCount: number;
  currentPage: number;
  isErrorModal: boolean,
  errorMsg: string;
  isBankDetailsModal: boolean;
  bankDetails: BankDetails;
  errors: BankDetails;
  transactionId: string | null;
  isTransactionModal: boolean;
  newWalletBalance: string;
  isStatusRes: boolean;
  isSuccess: boolean;
  navigatePath: string;
  userRle: string;
  transAmtError: string;
  addAmtError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class SellerWalletController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSelfWalletApiCallId: string = "";
  getWalletTransactionsApiCallId: string = "";
  postDepositeApiCallId: string = "";
  postSelfWithdrawApiCallId: string = "";
  getTransactionStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      transferOpen: false,
      addProductOpen: false,
      tranferAmount: 0,
      addPrice: 0,
      isDrawer: false,
      snackBarItems: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      walletLoader: false,
      walletAmount: "",
      walletId: null,
      transactionData: [],
      totalCount: 0,
      currentPage: 0,
      isErrorModal: false,
      errorMsg: "",
      isBankDetailsModal: false,
      bankDetails: {
        bankAccountNumber: '',
        bankName: '',
        ifscCode: '',
        receiversName: '',
      },
      errors: {
        bankAccountNumber: '',
        bankName: '',
        ifscCode: '',
        receiversName: '',
      },
      transactionId: null,
      isTransactionModal: false,
      newWalletBalance: "",
      isStatusRes: false,
      isSuccess: false,
      navigatePath: '',
      userRle: 'seller',
      transAmtError: '',
      addAmtError: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    let isfromCheckout = await getStorageData('isCheckout');
    const urlParams = new URLSearchParams(window.location.search);
    const idParam = urlParams.get('orderId');
    const roleParam = urlParams.get('role');
    if (idParam !== null && roleParam !== null) {
      this.setState({ userRle: roleParam });
      if(!!isfromCheckout === true){
        this.setState({ transactionId: String(idParam), isTransactionModal: true}, ()=> {
          this.getTransactionStatus(roleParam);
        });
        setTimeout(()=>{
          removeStorageData('isCheckout')
        },2000)
      }else {
        this.navigateToWalletScreen(roleParam);
      }
    }else {
      const sellerToken = await getStorageData("singupLogin");
      if(sellerToken){
        this.getSelfWalletApiCall();
        this.getTransactionDetails();
      }
    }
  };

  // Customizable Area Start
  async receive(from: string, message: Message) {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (responseJson) {
      switch (apiRequestCallId) {
        case this.getSelfWalletApiCallId:
          this.getSelfWalletResponse(responseJson);
          break;
        case this.getWalletTransactionsApiCallId:
          this.getTransactionResponse(responseJson);
          break;
        case this.postDepositeApiCallId:
          this.getDepositeCreateResponse(responseJson);
          break;
        case this.getTransactionStatusApiCallId:
          this.getTransactionStatuResponse(responseJson);
          break;
        case this.postSelfWithdrawApiCallId:
          this.getTransferAmountResponse(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  apiCall = async (apiReqData: { contentType?: string; method: string; endPoint: string; body?: object | string; type?: string; newToken?: string}) => {
    const { contentType, method, endPoint, body, type, newToken} = apiReqData;
    const token = await getStorageData("singupLogin");
    const header = {
      "Content-Type": contentType,
      token: newToken? newToken : token,
    };
    const apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  handleAllApiErrors = (responseJson: { errors: Array<ErrorItem> }) => {
    let error = responseJson.errors[0];
    let errMsg = error?.token ? error?.token : error?.message;
    this.setState({
      snackBarItems: {
        isOpen: true,
        isSeverity: "error",
        isMessage: errMsg ? errMsg : "Something went wrong!"
      },
      walletLoader: false
    });
  };

  handleTransferToogle = () => {
    this.setState({ transferOpen: !this.state.transferOpen });
  };

  handleAmountToogle = () => {
    this.setState({ addProductOpen: !this.state.addProductOpen });
  };

  handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let amountValue = event.target.value;
    const amountRegex = configJSON.amountRegex;
    if (amountRegex.test(amountValue)) {
      this.setState({ addPrice: Number(amountValue), addAmtError: '' });
    }
  };

  handleChangeTransferAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let transferValue = event.target.value;
    const transferRegex = configJSON.amountRegex;
    if (transferRegex.test(transferValue)) {
      this.setState({ tranferAmount: Number(transferValue), transAmtError: '' });
    }
  };

  toogleDrawer = () => {
    this.setState({isDrawer: !this.state.isDrawer})
  };

  postDepositeApiCall = async () => {
    const { addPrice } = this.state;
    if (addPrice === 0) {
      this.setState({ addAmtError: 'Please enter a valid amount'});
      return;
    }
    this.setState({walletLoader: true});
    this.postDepositeApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.createDepositeOrder + `?amount=${addPrice}`,
    });
  };

  getDepositeCreateResponse = (responseJson: InitiateResponse & { errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      this.handleCashfreeCheckout(responseJson.data.payment_session_id, responseJson.data.order_id);
    } else if (responseJson.errors) {
      this.handleAllApiErrors(responseJson);
    }
  };

  handleCashfreeCheckout = async (sessionId: string, orderId: string) => {
    this.setState({walletLoader: false});
    await setStorageData('isCheckout', true);
    const returnMainUrl = baseURL.replace('ruby', 'react');
    const cashfreeCheckout = await load({
      mode: "sandbox",
    });
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${returnMainUrl}/Payment/Cashfree/Status?orderId=${orderId}&role=seller`
    };
    cashfreeCheckout.checkout(checkoutOptions).then((result: CashFreeResultPayload) => {
      if (result.error) {
        this.setState({ snackBarItems: { isSeverity: "error", isMessage: "Something Went wrong!!", isOpen: true, } });
      }
    });
  };

  showError = (errorMsg: string) => {
    this.setState({ errorMsg: errorMsg, isErrorModal: true });
  };

  errorModalClose = () => {
    this.setState({ errorMsg: "", isErrorModal: false });
  };

  toggleBankDetailsModal = () => {
    this.setState({ isBankDetailsModal: !this.state.isBankDetailsModal });
  };

  openDetailsModal = () => {
    const { tranferAmount } = this.state;
    if (tranferAmount === 0) {
      this.setState({ transAmtError: 'Please enter a valid amount'});
      return;
    }
    this.setState({ isBankDetailsModal: !this.state.isBankDetailsModal });
  }

  validateForm = (): boolean => {
    const newErrors: BankDetails = {
      bankAccountNumber: '',
      bankName: '',
      ifscCode: '',
      receiversName: '',
    };
    let isValid = true;

    const isValidBankAccountNumber = (accountNumber: string): boolean =>
      accountNumber.length >= 8 && /^[0-9]+$/.test(accountNumber);

    const isValidBankName = (bankName: string): boolean =>
      bankName.length >= 2 && !/\d/.test(bankName);

    const isValidIfscCode = (ifscCode: string): boolean =>
      /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/.test(ifscCode);

    const isValidReceiversName = (receiversName: string): boolean =>
      receiversName.length >= 2 && !/\d/.test(receiversName);

    if (!isValidBankAccountNumber(this.state.bankDetails.bankAccountNumber)) {
      newErrors.bankAccountNumber = 'Invalid account number';
      isValid = false;
    }
    if (!isValidBankName(this.state.bankDetails.bankName)) {
      newErrors.bankName = 'Invalid bank name';
      isValid = false;
    }
    if (!isValidIfscCode(this.state.bankDetails.ifscCode)) {
      newErrors.ifscCode = 'Invalid IFSC code';
      isValid = false;
    }
    if (!isValidReceiversName(this.state.bankDetails.receiversName)) {
      newErrors.receiversName = 'Invalid receiver\'s name';
      isValid = false;
    }

    this.setState({ errors: newErrors });
    return isValid;
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      bankDetails: {
        ...prevState.bankDetails,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  handleSubmit = () => {
    if (this.validateForm()) {
      this.postSelfWithdrawApiCall();
    }
  };

  postSelfWithdrawApiCall = async () => {
    this.setState({ walletLoader: true, isBankDetailsModal: false });
    this.postSelfWithdrawApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.selfWithdrawEndPoint,
      body: {
        "amount": this.state.tranferAmount,
        "account_number": this.state.bankDetails.bankAccountNumber,
        "ifsc_code": this.state.bankDetails.ifscCode,
        "account_holder_name": this.state.bankDetails.receiversName,
        "bank_name": this.state.bankDetails.bankName
      }
    });
  };

  getTransferAmountResponse = (responseJson: WithdrawalResponse & { errors: Array<ErrorItem> }) => {
    this.setState({ walletLoader: false, bankDetails: { bankAccountNumber: '', bankName: '', ifscCode: '', receiversName: '' }, tranferAmount: 0, transferOpen: false });
    if (responseJson?.data) {
      if (responseJson.data.success) {
        this.setState({ errorMsg: "Transfer initiated successfully. Funds will be processed and reflected in your account shortly.", isErrorModal: true }, () => {
          this.getSelfWalletApiCall();
          this.getTransactionDetails();
        });
      } else {
        this.setState({
          snackBarItems: {
            isMessage: "Something went wrong!",
            isOpen: true,
            isSeverity: "error",
          },
        });
      }
    } else if (responseJson.errors) {
      this.handleAllApiErrors(responseJson)
    }
  };

  getSelfWalletApiCall = async () => {
    this.setState({walletLoader: true});
    this.getSelfWalletApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.selfWalletEndPoint,
    });
  };

  getTransactionDetails = async () => {
    this.getWalletTransactionsApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.transactionEndPoint,
    });
  };

  getTransactionStatus = async (role: string) => {
    const sellerToken = await getStorageData("singupLogin");
    const buyerToken = await getStorageData("buerLoginToken");
    let newToken = role === 'buyer' ? buyerToken : sellerToken;
    this.setState({ walletLoader: true });
    this.getTransactionStatusApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getTransactionStatusEndPoint + `?order_id=${this.state.transactionId}`,
      newToken: newToken,
    });
  };

  getTransactionStatuResponse = (responseJson: { data: { success: boolean, new_balance: string }, errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      if (responseJson.data.success) {
        this.setState({ newWalletBalance: responseJson.data.new_balance, isSuccess: true, isStatusRes: true, walletLoader: false })
      } else {
        this.setState({ isSuccess: false, walletLoader: false, });
      }
    } else if (responseJson.errors) {
      this.setState({ isSuccess: false, isStatusRes: true });
      this.handleAllApiErrors(responseJson);
    }
  };

  getSelfWalletResponse = (response: WalletResponse & { errors: Array<ErrorItem> }) => {
    this.setState({walletLoader: false});
    if (response?.data) {
      this.setState({ walletAmount: response.data.wallet.balance, walletId: response.data.wallet.wallet_id });
    } else if (response?.errors) {
      this.handleAllApiErrors(response);
    }
  };

  getTransactionResponse = (response: TransactionResponse & { errors: Array<ErrorItem> }) => {
    if (response?.data.transactions) {
      this.setState({ transactionData: response?.data.transactions });
    } else if (response?.errors) {
      this.handleAllApiErrors(response);
    }
  };

  handleCloseSnack = () => {
    this.setState({ snackBarItems: { isOpen: false, isSeverity: "success", isMessage: "" }});
  };

  navigateToWalletScreen = (userRoute: string) => {
    let path = userRoute === 'seller' ? '/SellerFlixooWallet' : '/FlixooWallet';
    this.setState({ navigatePath: path }, () => {
      const myButton = document.getElementById('navigateButtonLink');
      if (myButton) {
        myButton.click();
      }
    })
  };

  returnSign = (transStatus: string, status: string) => {
    if (transStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'completed') {
      return '+';
    } else if (transStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'failed') {
      return '';
    } else if (transStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'pending') {
      return '';
    } else if (transStatus.toLowerCase() === 'bank_transfer' && status.toLowerCase() === 'pending') {
      return '';
    } 
    return '-';
  };

  checkDeposite = (transStatus: string, status: string) => {
    if (transStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'completed') {
      return '#0DAD01';
    } else if (transStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'failed') {
      return '#ED2224';
    } else if (transStatus.toLowerCase() === 'deposit' && status.toLowerCase() === 'pending') {
      return '#F5EA16';
    } else if (transStatus.toLowerCase() === 'bank_transfer' && status.toLowerCase() === 'completed') {
      return '#0DAD01';
    } else if (transStatus.toLowerCase() === 'bank_transfer' && status.toLowerCase() === 'pending') {
      return '#F5EA16';
    }
    return '#ED2224';
  };
  // Customizable Area End
}
