import React from "react";
// Customizable Area Start
import SellerWalletController, {
  Props,
} from "./SellerWalletController";
import { Box, styled, TextField, Typography, Button, IconButton, Modal } from "@material-ui/core";
import { arrowLeftIc, closeIc, hamburgerIc } from "../../CustomisableUserProfiles/src/assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import Loader from "../../../components/src/Loader.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import "../assets/css/walletstyle.css"
// Customizable Area End

export default class SellerWallet extends SellerWalletController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTransferMoneyModal = () => {
    return (
      <CustomSellerModal
        open={this.state.isBankDetailsModal}
        onClose={this.toggleBankDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="ModalMainContainer"> <Box>
          <Box className="modalHeader">
            <Typography className="titleText">Transfer To</Typography>
            <img src={closeIc} alt="" onClick={this.toggleBankDetailsModal} className="closeIcon" />
          </Box>

          <Box className="inputContainer">
            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                placeholder={"012345678190876"}
                label={"Bank A/C number"}
                variant="outlined"
                name="bankAccountNumber"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.bankAccountNumber}
                error={!!this.state.errors.bankAccountNumber}
                helperText={this.state.errors.bankAccountNumber}
                onChange={this.handleChange}
              />
            </Box>

            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                placeholder={"HDFC"}
                label={"Bank name"}
                variant="outlined"
                name="bankName"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.bankName}
                error={!!this.state.errors.bankName}
                helperText={this.state.errors.bankName}
                onChange={this.handleChange}
              />
            </Box>

            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                data-test-id="fullName"
                placeholder={"HDFC000034"}
                label={"IFSC Code"}
                variant="outlined"
                name="ifscCode"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.ifscCode}
                error={!!this.state.errors.ifscCode}
                helperText={this.state.errors.ifscCode}
                onChange={this.handleChange}
              />
            </Box>

            <Box className="inputFieldBox">
              <TextField
                id="outlined-textarea"
                type="text"
                data-test-id="fullName"
                placeholder={"John Doe"}
                label={"Receivers name"}
                variant="outlined"
                name="receiversName"
                fullWidth
                className="inputFieldText"
                value={this.state.bankDetails.receiversName}
                error={!!this.state.errors.receiversName}
                helperText={this.state.errors.receiversName}
                onChange={this.handleChange}
              />
            </Box>

            <Box>
              <Button className="transferFinalBtn" onClick={this.handleSubmit}>Transfer</Button>
            </Box>

          </Box>
        </Box>
        </Box>
      </CustomSellerModal>
    )
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { transferOpen, addProductOpen, tranferAmount, addPrice, walletAmount, transactionData } = this.state;

    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="main-div">
        <div className="dashboard-outer">
          <div className="dashboard-inner">
          <Loader loading={this.state.walletLoader} />
          <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.snackBarItems} handleCloseSnack={this.handleCloseSnack} />
            <MessageModalWeb
              data-test-id="errorMessage"
              displayPopup={this.state.isErrorModal}
              closeErrorPopup={this.errorModalClose}
              errorMessage={this.state.errorMsg}
            />
            {this.renderTransferMoneyModal()}
            <SellerSidebar
              navigation={"/SellerFlixooWallet"}
              id={""}
              classes={undefined}
              isOpen={this.state.isDrawer}
              data-test-id="sellerSidebar"
              activetabName="payments"
              closeModal={() => this.toogleDrawer()}
            />
            <SellerWalletWrapper >
              <Box className="mainWrapper">
                {!transferOpen && !addProductOpen &&
                  <span>
                    <Box className="headingContainer"> <Typography className="headingText">Flixoo Wallet</Typography> <IconButton onClick={()=> this.toogleDrawer()} className="drawerIcon" ><img src={hamburgerIc} alt=""/></IconButton></Box>

                    <Box className="contentBox">
                      <Box className="walletInfoContainer">
                        <Typography className="flixooPointTxt">{Number(walletAmount)}</Typography>
                        <Typography className="pointTxt">Flixoo Points</Typography>
                        <Typography className="pointInformation">1 Flixoo Point = ₹1 on Flixoo App</Typography>
                        <Box className="buttonContainer">
                          <Button className="transferBtn buttonClass" data-test-id="transferBtnTestId" onClick={this.handleTransferToogle}> Transfer from Wallet </Button>

                          <Button className="addWalletBtn buttonClass" data-test-id="addBtnTestId" onClick={this.handleAmountToogle}> Add to Wallet </Button>
                        </Box>

                        <Box className="historyContainer">
                          <Box className="historyInnerContainer">
                            <Typography className="historyTitle">Your Transaction</Typography>
                          </Box>
                          <Box className="transactionContainer">
                            {transactionData.length > 0 ? transactionData.map((item, itemIdx) => (
                              <Box className="detailBox" key={itemIdx}>
                                <Box className="reasonBox" ><Typography className="reasonText">{item.remarks}</Typography></Box>
                                <Box className="statusBox" > <Typography className="tranStatus">{`${item.status}`}</Typography></Box>
                                <Box className="amountBox" ><Typography className={`amountText`} style={{color: this.checkDeposite(item.transaction_type, item.status)}}>{this.returnSign(item.transaction_type, item.status)} {item.amount}</Typography></Box>
                              </Box>
                            )) : <Box className="noTransacetionBox">
                              No transaction found
                            </Box>
                            }
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </span>
                }

                {transferOpen &&
                  <Box className="transferScreen">
                    <Box className="innerHeaderBox">
                      <img src={arrowLeftIc} className="backIcon" data-test-id="backTransferTestId" onClick={this.handleTransferToogle} />
                      <Typography className="headingText">Transfer from Wallet</Typography>
                    </Box>

                    <Box className="subInnerContainer">
                      <Typography className="subInnerHeading">Amount to be Transferred: </Typography>
                      <Box className="textFieldBox">
                      <TextField value={tranferAmount === 0 ? "" : tranferAmount} data-test-id="inputTransferTestId" onChange={this.handleChangeTransferAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                      <span className="errorText">{this.state.transAmtError}</span>
                      </Box>
                      <Box>
                        <Button className="saveButtonStyle" onClick={this.openDetailsModal}>Transfer</Button>
                      </Box>
                    </Box>
                  </Box>
                }

                {addProductOpen &&
                  <Box className="transferScreen">
                    <Box className="innerHeaderBox">
                      <img src={arrowLeftIc} className="backIcon" data-test-id="backAddrTestId" onClick={this.handleAmountToogle} />
                      <Typography className="headingText">Add money to Wallet</Typography>
                    </Box>

                    <Box className="subInnerContainer">
                      <Typography className="subInnerHeading">Amount to be Added: </Typography>
                      <Box className="textFieldBox">
                      <TextField value={addPrice === 0 ? "" : addPrice} data-test-id="inputAddTestId" onChange={this.handleChangeAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                      <span className="errorText">{this.state.addAmtError}</span>
                      </Box>
                      <Box> <Button className="saveButtonStyle" data-test-id="proceedToSave" onClick={this.postDepositeApiCall}>Proceed to Payment</Button></Box>
                    </Box>
                  </Box>
                }
              </Box>
            </SellerWalletWrapper>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SellerWalletWrapper = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  marginTop: "25px",
  padding: "0px 24px",
  "& .headingText": {
    fontSize: "20px",
    fontWeight: 500,
    color: "#444444CC"
  },
  "& .walletInfoContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "60px",
  },
  "& .noTransacetionBox":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"30px",
    color: "#7c7c7cf0",
    fontSize:"22px",
  },
  "& .flixooPointTxt": {
    fontSize: "60px",
    fontWeight: 700,
    color: "#444444"
  },
  "& .pointTxt": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#444444"
  },
  "& .pointInformation": {
    fontSize: "12px",
    fontWeight: 500,
    color: "#44444466",
    marginTop: "12px"
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: "32px",
  },
  "& .buttonClass": {
    backgroundColor: "#F5EA16",
    padding: "12px 8px",
    width: "100%",
    minWidth: "163px",
    maxWidth: "163px",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#444444",
    borderRadius: "8px"
  },
  "& .historyContainer": {
    width: "100%",
    maxWidth: "580px",
    marginTop: "48px"
  },
  "& .errorTxt":{
    color: 'red',
    fontSize:"12px",
    marginTop:"4px"
  },
  "& .textFieldBox":{
    display:"flex",
    flexDirection:"column"
  },
  "& .historyInnerContainer": {
    borderBottom: "1px solid #4444441A",
    paddingBottom: "10px"
  },
  "& .transactionContainer": {
    height: "100%",
    maxHeight: "500px",
    overflowY: "scroll",
    scrollbarWidth: "thin"
  },
  "& .detailBox": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #4444441A",
    padding: "20px 0px"
  },
  "& .credited": {
    color: "#0DAD01"
  },
  "& .debited": {
    color: "#ED2224"
  },
  "& .amountText": {
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "4px"
  },
  "& .reasonText": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#444444",
  },
  "& .reasonBox": {
    width: "220px"
  },
  "& .statusBox": {
    width: "100px",
    textAlign: "center"
  },
  "& .amountBox": {
    width: "120px",
    textAlign:"right"
  },
  "& .tranStatus":{
    textTransform:"capitalize",
    fontSize: "14px",
    fontWeight: 400,
    color: "#444444",
  },
  "& .historyTitle": {
    fontSize: "16px",
    fontWeight: 600,
    color: "#444444",
  },
  "& .innerHeaderBox": {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  "& .backIcon": {
    width: "25px",
    cursor: "pointer"
  },
  "& .subInnerContainer": {
    marginTop: "72px"
  },
  "& .subInnerHeading": {
    fontSize: "16px",
    fontWeight: 500,
    color: "#000000"
  },
  "& .amountInput": {
    marginTop: "40px",
    border: "1px solid #44444433",
    borderRadius: "8px",
    background: "white",
    padding: "12px 16px",
    width: "100%",
    maxWidth: "130px",
    "& ::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#44444480"
    }
  },
  "& .saveButtonStyle": {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#444444",
    background: "#F5EA16",
    padding: "12px 16px",
    width: "100%",
    maxWidth: "342px",
    borderRadius: "8px",
    marginTop: "40px"
  },
  "& .headingContainer":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  "& .drawerIcon":{
    display:"none",
    "@media(max-width:991px)":{
      display:"block"
    }
  }
});

const CustomSellerModal = styled(Modal)({
  "& .ModalMainContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    maxWidth: "390px",
    width: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    maxHeight: "550px",
    display: "flex",
    padding: "24px",
    borderRadius: "16px"
  },
  "& .modalHeader": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "24px"
  },
  "& .transferFinalBtn": {
    fontSize: "12px",
    textTransform: "none",
    color: "#444444",
    fontWeight: 600,
    padding: "12px 16px",
    background: "#F5EA16",
    maxWidth: "342px",
    width: "100%",
    marginTop: "16px",
    borderRadius: "8px",
  },
  '& .inputFieldBox': {
    '& .MuiFormLabel-root': {
      fontFamily: 'Poppins-Regular !important',
      fontSize: '14px',
      lineHeight: '18px',
      color: "#44444480"
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#44444480',
      opacity: 0.5,
      fontSize: '14px',
    },
  },

});

// Customizable Area End
