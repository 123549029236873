import React from "react";
// Customizable Area Start
import {
  Badge,
  Box,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { notificationIc } from "./assets";
import { NotificationBox } from "./SellerDashboard.web";
// Customizable Area End
import SellersNotificationController from "./SellersNotificationController";

class SellersNotification extends SellersNotificationController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isNotificationBoxOpen, allNotifications, selectedTab, readNotificatons } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderCartBox onClick={this.handleNotificationBoxToggle} style={{ position: "relative" }} data-test-id="notification-click">
          {
            readNotificatons.message ?
              <Badge color="secondary">
                <img src={notificationIc} alt="notification" />
              </Badge> :
              <Badge color="secondary" variant="dot">
                <img src={notificationIc} alt="notification" />
              </Badge>
          }
        </HeaderCartBox>

        <NotificationPopup>
          {isNotificationBoxOpen && (
            <NotificationBox
              data-test-id="notificationBox"
              closeNotificationBox={this.handleNotificationBoxToggle}
              allNotifications={allNotifications}
              markAllAsRead={this.markAllNotificationsAsRead}
              selectedTab={selectedTab}
              handleTabChange={this.handleTabChange}
            />
          )}
        </NotificationPopup>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HeaderCartBox = styled(Box)({
  cursor: 'pointer',
  borderRadius: '10px',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 20px 1px rgba(0,0,0,0.1)',
  marginLeft: '24px',
  overflow: 'hidden',
  height: '48px',
  width: '48px',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  padding: '12px',
  '& img': {
    width: '24px',
    height: '24px',
  },
  '& .NotificationBox': {
    headerCartBox: {
      cursor: 'pointer',
      borderRadius: '10px',
      backgroundColor: '#fff',
      boxShadow: '0px 0px 20px 1px rgba(0,0,0,0.1)',
      marginLeft: '24px',
      overflow: 'hidden',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      padding: '12px',
      '& img': {
        width: '24px',
        height: '24px',
      },
      '& .NotificationBox': {
        position: "fixed",
        top: "80px",
        right: "40px",
        maxWidth: "475px",
        borderRadius: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
        zIndex: 1,
        ['@media (min-width: 1150px)']: {
          top: "150px",
          right: "140px",
        },
        ['@media (min-width: 720px)']: {
          top: '150px',
          right: '6px',
          marginLeft: '10px',
        },
      },
    },
    right: "40px",
    maxWidth: "475px",
    borderRadius: "20px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
    zIndex: 1,
    ['@media (min-width: 1150px)']: {
      top: "150px",
      right: "140px",
    },
    ['@media (min-width: 720px)']: {
      top: '150px',
      right: '6px',
      marginLeft: '10px',
    },
  },
});

const NotificationPopup = styled(Box)({
  width: '470px',
  position: 'absolute',
  top: "205px",
  right: "60px",
  zIndex: 1
});

export default SellersNotification
// Customizable Area End
