// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
import SwiperCore, { Navigation } from 'swiper';
export const baseURL = require("../../../framework/src/config.js").baseURL;
const navigation = require('react-navigation');

interface AllStoryResponse {
    data: Array<StoryUploaders>
}

export interface StoryUploaders {
    type: string;
    id: string;
    attributes: StoryAttributes;
}

interface StoryAttributes {
    title: string;
    id: number;
    duration: number;
    description: string;
    location: string | null;
    seen: boolean;
    activated: boolean;
    color: string | null;
    updated_at: string;
    created_at: string;
    media: StoryMedia;
    creator_details: CreatorDetails;
    liked: boolean;
    duration_with_seconds: string;
}

interface CreatorDetails {
    full_name: string;
    id: number;
    profile_url: string | null;
}

interface StoryMedia {
    filename: string;
    id: number;
    type: string;
    url: string;
}

export interface PostData {
    id: string;
    type: string;
    isTagList: boolean;
    attributes: {
        id: number;
        name: string;
        description: string;
        body: string;
        location: string;
        account_id: number;
        active_message: boolean;
        created_at: string;
        updated_at: string;
        images_and_videos: [
            {
                id: number;
                filename: string;
                url: string;
                type: string;
            }
        ],
        creator_details: {
            id: number;
            full_name: string;
            profile_url: string;
            role: {
                id: number;
                name: string;
                created_at: string;
                updated_at: string;
                page_names: []
            }
        },
        media: [],
        tag_list_details: [],
        product_detail: [],
        like_count: number;
        comment_count: number;
        is_following_seller: boolean;
        is_liked: boolean;
        last_liked_by: {
            id: number;
            profile_url: string;
            user_name: string;
        }
    }
}

interface Post {
    data: Array<PostData>;
}

interface ApiPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
    newToken?: string;
}

export interface SnackBar {
    isOpen: boolean;
    isSeverity: 'success' | 'info' | 'warning' | 'error';
    isMessage: string;
}

interface ErrorItem {
    [key: string]: string;
};

interface AccountPayload {
    data: {
        attributes: {
            full_name: string;
            user_name: string;
            business_name: string;
            business_type: string;
            profile_photo: string | null;
            background_photo: string;
            my_bio: string;
        }
    }
}

interface BuyerProfileRes {
    account: AccountPayload;
    followers_count: number;
    following_count: number;
    is_following_user: boolean;
}


interface LiveStreamAttributes {
    id: number;
    title: string;
    description: string;
    catalogues: [
        {
            images: [
                { url: string }
            ],
            catalogue_title: string;
            catalogue_description: string;

        }
    ]
}

export interface LiveStream {
    id: string;
    type: "live_stream";
    attributes: LiveStreamAttributes;
}

interface Lives {
    data: Array<LiveStream>;
}

interface Catalogue {
    id: number;
    attributes: {
        title: string;
        description: string;
        selling_price: number;
        mrp: number;
        catalogue_image: string | null;
        on_order: boolean;
        bargain: boolean;
        product_images: [
            { url: string }
        ]
    }
}

interface TopSelling {
    data: {
        catalogue: {
            data: Array<Catalogue>;
        }
    }
}


interface SellerProfileRes {
    account: AccountPayload;
    follower: number;
    following: number;
    is_following_seller: boolean;
    is_following_user: boolean;
    posts?: Post;
    lives: Lives;
    top_selling: TopSelling;
    stories: {
        data: Array<StoryUploaders>;
    }
}

interface FollowData {
    attributes: {
        account: {
            id: string | number;
            full_name: string
            profile_url: string;
            user_name: string;
        }
    }
}
interface FollowRes {
    data: Array<FollowData>;
}

SwiperCore.use([Navigation]);
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    // Customizable Area Start
    navigation: typeof navigation;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isOpen: boolean;
    tabValue: number;
    isViewProfile: boolean;
    openTunedBox: boolean;
    userProfileId: string;
    snackBarStates: SnackBar;
    errorModal: boolean;
    imageError: string;
    openLiveModal: boolean;
    userName: string;
    fullName: string;
    businessName: string;
    userBio: string;
    profilePic: string | null;
    backgroundImage: string;
    followers: number;
    following: number;
    userPostsData: Array<PostData>;
    carousalUrl: string;
    isPostOpen: boolean;
    profileType: string;
    buyerTabVal: number;
    isLoading: boolean;
    isFollowingUser: boolean;
    sellerLiveStreamData: Array<LiveStream>;
    userStory: Array<StoryUploaders>;
    allStoryHeader: Array<StoryUploaders>;
    storyMuted: boolean;
    goToSlide: string;
    currentSlide: number;
    currentStoryIndex: number;
    storyPaused: boolean;
    instaModal: boolean;
    instaModalIndex: number;
    tempStoryId: number;
    sellerTopProduct: Array<Catalogue>;
    isResponse: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class ViewUserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserProfileApiCallId: string = "";
    getBuyerProfileApiCallId: string = "";
    followUnFollowApiCallId: string = "";
    viewStoryCallId: string = "";
    swiperInstance: SwiperCore | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isOpen: true,
            tabValue: 0,
            isViewProfile: true,
            openTunedBox: false,
            userProfileId: "",
            snackBarStates: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            },
            errorModal: false,
            imageError: "",
            openLiveModal: false,
            userName: "",
            businessName: "",
            userBio: "",
            profilePic: "",
            backgroundImage: "",
            followers: 0,
            following: 0,
            fullName: "",
            userPostsData: [],
            carousalUrl: "",
            isPostOpen: false,
            profileType: "seller",
            buyerTabVal: 0,
            isLoading: false,
            isFollowingUser: false,
            sellerLiveStreamData: [],
            goToSlide: "",
            userStory: [],
            allStoryHeader: [],
            storyPaused: false,
            storyMuted: false,
            currentSlide: 0,
            currentStoryIndex: 0,
            instaModal: false,
            instaModalIndex: 0,
            tempStoryId: 0,
            sellerTopProduct: [],
            isResponse: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.getUserProfileApiCallId:
                    this.getUserProfileApiCallRes(responseJson);
                    break;
                case this.getBuyerProfileApiCallId:
                    this.getBuyerProfileApiResponse(responseJson);
                    break;
                case this.followUnFollowApiCallId:
                    this.getFollowUnfollowApiResponse(responseJson);
                    break;
                case this.viewStoryCallId:
                    this.viewStorySuccessCallBack(responseJson);
                    break;
                default:
                    break;
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.checkPath();
    };

    handleApiErrors = (responseJson: { errors: Array<ErrorItem> }) => {
        let error = responseJson.errors[0];
        let errMsg = error?.token ? error?.token : error?.message;
        this.setState({
            snackBarStates: {
                isOpen: true,
                isSeverity: "error",
                isMessage: errMsg ? errMsg : "Something went wrong!!"
            },
            isLoading: false,
        });
    };

    viewStorySuccessCallBack = (response: AllStoryResponse) => {
        let { goToSlide } = this.state;
        this.setState({ instaModal: true, userStory: response.data }, () => {
            if (goToSlide === "next") {
                this.swiperInstance?.slideNext();
            } else if (goToSlide === "prev") {
                this.swiperInstance?.slidePrev();
            }
            this.setState({ goToSlide: "" });
        });
    };

    calculateHours = (createdAt: string) => {
        const createdTime = new Date(createdAt).getTime();
        const currentTime = Date.now();
        const timeDifference = currentTime - createdTime;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        if (hours > 0) {
            return `${hours}h`;
        } else if (minutes > 0) {
            return `${minutes}m`;
        } else {
            return `${seconds}s`;
        }
    };

    toggleMute = () => {
        this.setState({ storyMuted: !this.state.storyMuted });
    };

    likeStory = async (storyDetails: StoryAttributes) => {
        this.setState({ tempStoryId: storyDetails.id });
    };

    showNextButton = (indexUser: number) => {
        const { 
            allStoryHeader, 
            userStory, 
            currentStoryIndex 
        } = this.state;
        if (indexUser + 1 < allStoryHeader.length || (indexUser + 1 === allStoryHeader.length && currentStoryIndex + 1 < userStory.length)) {
            return true;
        }
        return false
    };

    showPrevButton = (indexUser: number) => {
        const { currentStoryIndex } = this.state;
        if (indexUser > 0 || (indexUser === 0 && currentStoryIndex !== 0)) {
            return true;
        }
        return false
    };

    changeStory = (direction: "next" | "prev") => {
        let { currentStoryIndex, userStory } = this.state;
        if (userStory.length === 0) return;
        let newIndex = currentStoryIndex;
        if (direction === 'next') {
            newIndex = currentStoryIndex + 1;
        } else if (direction === 'prev') {
            newIndex = currentStoryIndex - 1;
        }
        if (newIndex === userStory.length || newIndex < 0) {
            this.handleClose();
            newIndex = 0;
        }

        this.setState({ currentStoryIndex: newIndex });
    };

    handleClose = () => {
        this.setState({ instaModal: false });
    };

    handleNext = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
        event?.stopPropagation();
        if (this.state.currentStoryIndex + 1 < this.state.userStory.length && !direct && !isNaN(userIndex)) {
            this.changeStory("next");
        }
    };

    setSwiperRef = (swiper: SwiperCore) => {
        this.swiperInstance = swiper;
    };

    handlePrev = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
        event?.stopPropagation();
        if (this.state.currentStoryIndex - 1 >= 0 && !direct && !isNaN(userIndex)) {
            this.changeStory("prev");
        }
    };

    checkPath = () => {
        const pathArray = location.pathname.split("/");
        const sellerId = pathArray[pathArray.length - 1];
        const path = location.pathname.toLowerCase();
        if (pathArray.length > 2) {
            this.setState({ isViewProfile: true, userProfileId: sellerId }, () => {
                this.loadUserData(path);
            });
        }
    };

    loadUserData = (path: string) => {
        if (path.includes('buyer')) {
            this.setState({ profileType: 'buyer' }, () => {
                this.getBuyerProfileApiCall();
            });
        } else if (path.includes('seller')) {
            this.setState({ profileType: 'seller' }, () => {
                this.getUserProfileApiCall();
            });
        }
    };

    viewStory = async () => {
        let newUserToken = await getStorageData("buerLoginToken");
        this.viewStoryCallId = await this.apiCall(
            {
                contentType: configJSON.validationApiContentType,
                method: configJSON.validationApiMethodType,
                endPoint: configJSON.viewStoryEndPoint + `user_id=${this.state.userProfileId}`,
                type: "",
                newToken: newUserToken
            }
        );
    };

    apiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type, newToken } = apiReqData;
        let token = await getStorageData("singupLogin");
        const header = {
            "Content-Type": contentType,
            token: newToken ? newToken : token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        body && type != 'formData' ?
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getUserProfileApiCall = async () => {
        this.setState({ isLoading: true });
        const { userProfileId } = this.state;
        let newUserToken = await getStorageData("buerLoginToken");
        this.getUserProfileApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getUserProfileEndPoint}` + `?id=${userProfileId}`,
            type: '',
            newToken: newUserToken
        })
    };

    getBuyerProfileApiCall = async () => {
        this.setState({ isLoading: true });
        const { userProfileId } = this.state;
        let newUserToken = await getStorageData("buerLoginToken");
        this.getBuyerProfileApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getUserProfileEndPoint}` + `?id=${userProfileId}`,
            type: '',
            newToken: newUserToken
        })
    };

    getBuyerProfileApiResponse = (responseJson: BuyerProfileRes & { errors: Array<ErrorItem> }) => {
        this.setState({ isLoading: false });
        if (responseJson && !responseJson.errors) {
            this.setState({
                userName: responseJson.account.data.attributes.user_name,
                businessName: responseJson.account.data.attributes.business_name,
                userBio: responseJson.account.data.attributes.my_bio,
                profilePic: responseJson.account.data.attributes.profile_photo,
                backgroundImage: responseJson.account.data.attributes.background_photo,
                followers: responseJson.followers_count,
                following: responseJson.following_count,
                fullName: responseJson.account.data.attributes.full_name,
                isFollowingUser: responseJson.is_following_user
            });
        } else if(responseJson.errors){
            this.handleApiErrors(responseJson);
        }
    };

    getUserProfileApiCallRes = (responseJson: SellerProfileRes & { errors: Array<ErrorItem> }) => {
        this.setState({ isLoading: false });
        if (responseJson && !responseJson.errors) {
            this.setState({
                userName: responseJson.account.data.attributes.user_name,
                businessName: responseJson.account.data.attributes.business_name,
                userBio: responseJson.account.data.attributes.my_bio,
                profilePic: responseJson.account.data.attributes.profile_photo,
                backgroundImage: responseJson.account.data.attributes.background_photo,
                followers: responseJson.follower,
                following: responseJson.following,
                fullName: responseJson.account.data.attributes.full_name,
                isFollowingUser: responseJson.is_following_user,
            });
            if (responseJson?.posts) {
                this.setState({
                    userPostsData: responseJson.posts.data
                })
            }
            if (responseJson?.stories) {
                this.setState({
                    userStory: responseJson.stories.data,
                    allStoryHeader: responseJson.stories.data,
                })
            }
            if (responseJson?.lives) {
                this.setState({
                    sellerLiveStreamData: responseJson.lives.data
                })
            }
            if (responseJson?.top_selling) {
                this.setState({
                    sellerTopProduct: responseJson.top_selling.data.catalogue.data
                })
            }
        } else if(responseJson.errors){
            this.handleApiErrors(responseJson)
        }
    };

    toggleDrawer = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue })
    };

    handleBuyerTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ buyerTabVal: newValue })
    };

    openStayTunedBox = () => {
        this.setState({ openTunedBox: true });
    };

    closeTunedBox = () => {
        this.setState({ openTunedBox: false })
    };

    errorModalClose = () => {
        this.setState({ errorModal: !this.state.errorModal });
    };

    toggleLiveModal = () => {
        this.setState({ openLiveModal: !this.state.openLiveModal });
    };

    handleCloseSnack = () => {
        this.setState({ snackBarStates: { isOpen: false, isSeverity: "success", isMessage: "" } });
    };

    handleOpenImage = (url: string) => {
        this.setState({ carousalUrl: url, isPostOpen: true })
    };

    handleClosePostModal = () => {
        this.setState({ carousalUrl: "", isPostOpen: false })
    };

    toggleFollowUnFollow = () => {
        const { isFollowingUser } = this.state;
        this.setState({ isResponse: true });
        isFollowingUser ? this.unFollowApi() : this.followApi();
        this.setState({ isFollowingUser: !this.state.isFollowingUser });
    };

    followApi = async () => {
        let token = await getStorageData("buerLoginToken");
        this.followUnFollowApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.exampleAPiMethod,
            newToken: token,
            endPoint: configJSON.followUserEndPoint,
            type: "",
            body: {
                data: {
                    attributes: {
                        followable_id: Number(this.state.userProfileId)
                    }
                }
            }
        });
    };

    unFollowApi = async () => {
        let token = await getStorageData("buerLoginToken");
        this.followUnFollowApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.deleteApiMethod,
            endPoint: configJSON.followUserEndPoint + `/${this.state.userProfileId}`,
            type: "",
            newToken: token
        });
    };

    getFollowUnfollowApiResponse = (responseJson: { data: {}, message: string }) => {
        const { isFollowingUser, followers } = this.state;
        if (responseJson.data || responseJson.message) {
            let newCnt = !isFollowingUser ? followers - 1 : followers + 1;
            this.setState({ followers: newCnt, isResponse: false });
        }
    };

    handleClickOpen = (index: number) => {
        this.viewStory();
        this.setState({ instaModalIndex: index, currentSlide: 0, currentStoryIndex: index })
    };

    topProductClick = (itemId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), `ProductDescription`);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), itemId);
        this.send(message);
    };
    // Customizable Area End
}