import BuyerShoppingCartController from "../../blocks/shoppingcart/src/BuyerShoppingCartController.web";
import EmailAccountRegistrationController from "../../blocks/email-account-registration/src/AccountRegistrationController";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";


export const checkLocationPermission = (innerState: {setState:Function}) => {
    if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                innerState.setState({ permissionStatus: 'Granted' })
            } else if (result.state === 'prompt') {
                innerState.setState({ permissionStatus: 'Prompting' });
            } else if (result.state === 'denied') {
                innerState.setState({
                    imageError: 'Location access denied. Please enable location access in your browser settings.',
                    permissionStatus: 'Denied',
                    errorModal: true,
                });
            }

            result.onchange = () => {
                if (result.state === 'granted') {
                    innerState.setState({ permissionStatus: 'Granted' });
                } else if (result.state === 'denied') {
                    innerState.setState({
                        imageError: 'Location access denied. Please enable location access in your browser settings.',
                        permissionStatus: 'Denied',
                        errorModal: true,
                    });
                }
            };
        });
    } else {
        innerState.setState({
            imageError: 'Geolocation is not supported by this browse',
            permissionStatus: 'Denied',
            errorModal: true,
        });
    }
};

export const getLocation = (instance: { setState: Function }) => {
    if (!navigator.permissions || !navigator.geolocation) {
        instance.setState({
            imageError: 'Geolocation is not supported by this browser.',
            errorModal: true,
            loading: false
        });
        return;
    }
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            instance.setState({ loading: true });
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    instance.setState({
                        latitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString(),
                        imageError: '',
                        loading: false
                    });
                },
                (error) => {
                    instance.setState({ imageError: error.message + 'Please check location permission', errorModal: true, loading: false });
                }
            );
        } else {
            instance.setState({
                imageError: 'Geolocation permission is denied. Please allow location permission.',
                errorModal: true,
                loading: false
            });
        }
    });
};



export const conditionalRenderer = (condition: boolean, trueStamt: string, falseStmt: string) => {
    return condition ? trueStamt : falseStmt
}