import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Catalogue, OnOrderApiResponse, OnOrderData, TopSelling } from "./LandingPageLatestController";
import { setStorageData } from "framework/src/Utilities";
import { Store } from "../../cfdigitalmall/src/CfdigitalmallController";
const navigation = require("react-navigation");

export interface ValidResponseType {
    data: object;
    meta: object;
}

export interface InvalidResponseType {
    errors: string;
}

export interface Category {
    type: string;
    id: number;
    attributes: {
        name: string;
        id: number;
        light_icon: string
        slug: string;
        updated_at: string;
        selected: boolean
        created_at: string;
    }
}

export interface Pagination {
    total_pages: number,
    total_counts: number,
    current_page: number,
    per_page: number
}

interface CategoryResponse {
    data: Array<Category>;
    meta: Pagination;
}

export interface LiveStreamData {
    id: string;
    type: string;
    attributes: {
        id: number;
        title: string;
        thumbnail: string;
        host_name: string;
        host_image: string;
    }
}

interface LiveStreamRes {
    live_streams: {
        data: Array<LiveStreamData>;
    }
    meta: Pagination;
}


export interface RecommentedProductsData {
    account: {
        id: number;
        name: string;
        business_name: string;
        email: string;
        address: {
            id: number;
            address_line_1: string;
            address_line_2: string;
            pin_code: number;
            city: string;
            state: string;
            country: string;
            latitude: number;
            longitude: number | null;
            account_id: number;
            created_at: string;
            updated_at: string;
            distance: number | null;
        };
        profile_photo: string;
        background_image: string;
    },
    catalogues: [
        {
            id: number;
            discount?: string;
            imageUrl: string;
            title: string;
            catalogue_image: string;
            selling_price: string;
            mrp: string;
        }
    ]
}

interface RecommendedProductsArray {
    recommended_products: Array<RecommentedProductsData>,
    city: string;
    meta: Pagination;
}

interface CatalogueData {
    id: number;
    title: string;
    discount: string;
    selling_price: number;
    mrp: number;
    catalogue_image: string;
    catalogue_variant_ids: number[];
    on_order: boolean;
    bargain: boolean;
}

export interface TopSellingData {
    catalogue: CatalogueData;
    store: {
        id: number;
        name: string;
        business_name: string;
        email: string;
        address: {
            id: number;
            address_line_1: string;
            address_line_2: string;
            pin_code: number;
            city: string;
            state: string;
            country: string;
            latitude: number;
            longitude: number | null;
            account_id: number;
            created_at: string;
            updated_at: string;
            distance: number | null;
        };
        profile_photo: string;
        background_image: string;
    };
}

interface TopSellingRes {
    top_selling: Array<TopSellingData>;
    meta: Pagination;
}

export interface BargainData {
    id: string;
    type: string;
    attributes: {
        title: string;
        mrp: number;
        selling_price: number;
        bargain: boolean;
        host_image: string;
        product_images: [
            {
                id: number;
                url: string;
            }
        ],
        store: {
            id: number;
            business_name: string;
        }
    }
}

interface BargainRes {
    data: Array<BargainData>;
    meta: Pagination;
}

export interface CreatorMalls {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        description: string;
        banner: string;
        banner_thumbnail: string;
        catalogues: {
            data: [
                {
                    id: string;
                    type: string;
                    attributes: {
                        category: null,
                        sub_category: {
                            id: number;
                            name: string;
                            parent_id: number;
                        },
                        title: string;
                        description: string;
                        account: {
                            id: number;
                            first_name: string;
                            full_name: string;
                            my_bio: string;
                        },
                        host_bio: string;
                        host_image: string;
                        product_images: string;
                        product_videos: string;
                    }
                }
            ]
        }
    }
}

export interface CreatorMallData {
    id: string;
    type: string;
    attributes: {
        id: number;
        full_name: string;
        user_name: string;
        my_bio: string;
        role: {
            id: number;
            name: string;
        },
        profile_photo: string;
        creator_malls: {
            data: Array<CreatorMalls>;
        }
    }
}

export interface CreatorMallRes {
    data: Array<CreatorMallData>;
    meta: Pagination;
}

export interface CategoryDetailsData {
    id: string;
    type: string;
    attributes: {
        category: {
            id: number;
            name: string;
            slug: string;
        },
        sub_category: {
            id: number;
            name: string;
        },
        brand: {
            id: number;
            name: string;
        },
        title: string;
        description: string;
        status: string;
        mrp: number;
        selling_price: number;
        ask_price: number;
        bargain: boolean;
        on_order: boolean;
        is_ask_price: boolean;
        is_brand: boolean;
        tags: [],
        sku: string;
        account: {
            id: number;
            first_name: null,
            full_phone_number: string;
            country_code: number;
            phone_number:number;
            email: string;
            activated: boolean;
            user_name: string;
            status: string;
            role_id: number;
            full_name: string;
            email_verified: boolean;
            phone_verified: boolean;
            business_name: string;
            business_type: string;
            admin_verified: boolean;
            is_disabled: boolean;
            my_bio: null,
            business_popup: boolean;
            password_digests: [],
            shipment_type: string;
            same_drop_off: boolean;
            marked_destroy: boolean;
            upi_id: string;
            qr_generated: boolean;
            latitude: number;
            longitude: number;
            current_city: string;
            store_type: string;
            can_livestream: boolean;
            category_ids: [],
        },
        is_affiliated: boolean;
        affiliated_discount: string;
        affiliated_price: string;
        affiliated_link: string;
        images: string;
        host_name: string;
        is_variant: boolean;
        host_bio: string;
        host_image: string;
        product_images: [
            {
                id: number;
                url: string;
            }
        ],
        product_videos: string;
        inventory_details: {
            id: number;
            fullfilment_by: string;
            procurement_type: string;
            procurement_sla: string;
            account_id: number;
            stock_quantity: number;
            catalogue_id: number;
        },
        product_detail: null,
        average_rating: number;
    }
}

interface CategoryDetailsRes {
    data: Array<CategoryDetailsData>,
    meta: Pagination
}

interface StoreRes {
    stores: Array<Store>,
    meta: Pagination
}

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    classes: Record<string, string>;
    // Customizable Area End
}
// Customizable Area End

export const configJSON = require("./config");



interface S {
    // Customizable Area Start
    loading: boolean;
    categoryData: Array<Category>;
    recommendData: Array<RecommentedProductsData>;
    liveStreamData: Array<LiveStreamData>;
    topSellingData: TopSelling;
    prodOnOrder: Array<OnOrderData>;
    onOrderData: Array<Catalogue>;
    bargainData: Array<BargainData>;
    CreatorMallData: Array<CreatorMallData>;
    categoryDetailsData: Array<CategoryDetailsData>;
    stories: Array<Store>,
    pagination: Pagination;
    isRequestModal: boolean;
    selectedFiles: File[];
    description: string;
    descriptionErr: boolean;
    imgUploadErr: string;
    selectedOnOrder: OnOrderData | null;
    urlParam: string;
    page: number;
    searchProductText: string;
    categoryId: string;
    openLiveModal: boolean;
    currentLocation: string;
    latitude: string;
    longitude: string;
    // Customizable Area End
}

interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class ViewAllProductsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getCategoryDataApiID: string = "";
    getRecommendApiID: string = "";
    getLiveStreamApiID: string = "";
    getTopSellingApiID: string = "";
    getOnOrderDataApiID: string = "";
    getBargainApiID: string = "";
    getCreatorMallApiID: string = "";
    getCategoriesDetailsApiID: string = "";
    getStoriesApiID: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            loading: false,
            categoryData: [],
            recommendData: [],
            liveStreamData: [],
            topSellingData: [],
            prodOnOrder: [],
            onOrderData: [],
            bargainData: [],
            CreatorMallData: [],
            categoryDetailsData: [],
            stories: [],
            pagination: {} as Pagination,
            isRequestModal: false,
            selectedFiles: [],
            description: "",
            descriptionErr: false,
            imgUploadErr: "",
            selectedOnOrder: null,
            urlParam: '',
            page: 1,
            searchProductText: '',
            categoryId: '',
            openLiveModal: false,
            currentLocation: '',
            latitude: '',
            longitude: '',
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        const urlParams = location.pathname.split('/').pop();
        const CategoryId = await getStorageData('CategoryId');
        urlParams && this.setState({ urlParam: urlParams, categoryId: CategoryId });

        const currentCity = await getStorageData("userCity")
        if (currentCity) {
            this.setState({ currentLocation: currentCity })
        }

        if (urlParams === "Categories") {
            this.getCategories();
        } else if (urlParams === "CreatorMall") {
            this.getCreatorMallData();
        } else if (urlParams === "RecommendProduct") {
            this.getRecommendData();
        } else if (urlParams === "LiveStreaming") {
            this.getLiveStreamData();
        } else if (urlParams === "TopSelling") {
            this.getTopSellingData();
        } else if (urlParams === "OnOrder") {
            this.getOnOrderData();
        } else if (urlParams === "Bargain") {
            this.getBargainData();
        } else if (urlParams === "Category") {
            this.getCategoriesDetailsApi(CategoryId);
        } else if (urlParams === "Stores") {
            this.getStories();
        }
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return responseJson;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & CategoryResponse & RecommendedProductsArray & LiveStreamRes & TopSellingRes & OnOrderApiResponse & BargainRes & CreatorMallRes & CategoryDetailsRes & StoreRes) => {
        if (apiRequestCallId === this.getCategoryDataApiID) {
            this.getCategoryDataCallBack(responseJson);
        }
        if (apiRequestCallId === this.getRecommendApiID) {
            this.handleRecommendSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getLiveStreamApiID) {
            this.liveStreamSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getTopSellingApiID) {
            this.topSellingProductSuccess(responseJson);
        }
        if (apiRequestCallId === this.getOnOrderDataApiID) {
            this.onOrderApiSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getBargainApiID) {
            this.handleBargainDataSuccess(responseJson);
        }
        if (apiRequestCallId === this.getCreatorMallApiID) {
            this.handleCreatorMallSuccess(responseJson);
        }
        if (apiRequestCallId === this.getCategoriesDetailsApiID) {
            this.handleCategoriesDetailsSuccess(responseJson);
        }
        if (apiRequestCallId === this.getStoriesApiID) {
            this.handleStoriesSuccess(responseJson);
        }
    };

    apiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
    }) => {
        const { contentType, method, endPoint, body, type } = valueData;
        const token = (await getStorageData("buerLoginToken")) || "";
        const header = {
            "Content-Type": contentType,
            token,
        };
        let apiBody = body;
        if (type === "") {
            apiBody = JSON.stringify(body);
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiBody
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getCategories = async () => {
        this.setState({ loading: true });
        this.getCategoryDataApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getCategoriesEndPoint}?page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`
        })
    };

    getCategoryDataCallBack = (response: CategoryResponse) => {
        this.setState({
            categoryData: response.data,
            pagination: response.meta,
            loading: false
        })
    };

    getRecommendData = async () => {
        this.getRecommendApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getRecommendEndPoint}?page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        });
    };

    handleRecommendSuccessResponse = (response: RecommendedProductsArray) => {
        this.setState({
            recommendData: response.recommended_products,
            pagination: response.meta
        })
    };

    getLiveStreamData = async () => {
        this.getLiveStreamApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getLiveStreamEndPoint}&page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        });
    };

    liveStreamSuccessResponse = (response: LiveStreamRes) => {
        this.setState({
            liveStreamData: response.live_streams.data,
            pagination: response.meta
        })
    };

    handleNavigate = (path: string, prodId?: number | string, id?: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), path);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), prodId);
        this.send(message);

        id && setStorageData('CategoryId', id);
    };

    handleNavigateTo = (event:  React.MouseEvent<HTMLElement, MouseEvent>, path: string, prodId: number | string) => {
        event.stopPropagation();
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), path);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), prodId);
        this.send(message);
    };

    getTopSellingData = async () => {
        this.getTopSellingApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getTopSellingEndPoint}&page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        });
    };

    topSellingProductSuccess = (response: TopSellingRes) => {
        this.setState({
            topSellingData: response.top_selling,
            pagination: response.meta
        })
    };

    getOnOrderData = async () => {
        this.getOnOrderDataApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getOnOrderCollectionEndPoint}&page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        })
    };

    onOrderApiSuccessCallBack = (responseJson: OnOrderApiResponse) => {
        let onOrderSet: OnOrderData[] = [];
        responseJson.catalogues.data.forEach((item) => {
            onOrderSet.push({
                sellerId: item.attributes.account.id,
                description: item.attributes.description,
                title: item.attributes.title,
                accountFullName: item.attributes.account.full_name,
                productId: item.id,
                productImageUrl: item.attributes?.product_images ? item.attributes?.product_images[0]?.url : '',
                hostImage: item.attributes.host_image || '',
            })
        });
        this.setState({ prodOnOrder: onOrderSet, onOrderData: responseJson.catalogues.data, pagination: responseJson.meta });
    };

    handleOnOrderRequestModal = (prodID: string) => {
        this.handleNavigate("GetStartedLogin");
        const selectedData = this.state.prodOnOrder.filter((item) => item.productId === prodID);
        this.setState({ isRequestModal: !this.state.isRequestModal, selectedFiles: [], description: "", descriptionErr: false, imgUploadErr: "", selectedOnOrder: selectedData[0] });
    };

    handleTopSellingOnOrderReq = (prodID: string) => {
        this.handleNavigate("GetStartedLogin");
        let productSet: OnOrderData[] = [];
        const selectedProd = this.state.topSellingData.filter((item) => item.catalogue.id === Number(prodID));
        selectedProd.forEach((item) => {
            productSet.push({
                description: item.catalogue.title,
                accountFullName: item.store.name,
                title: item.catalogue.title,
                productId: String(item.catalogue.id),
                sellerId: item.store.id,
                productImageUrl: item.catalogue?.catalogue_image ? item.catalogue?.catalogue_image : '',
                hostImage: item.store.profile_photo || '',
            })
        });
        this.setState({ selectedFiles: [], isRequestModal: !this.state.isRequestModal, description: "", descriptionErr: false, imgUploadErr: "", selectedOnOrder: productSet[0] });
    };

    getTagDetails = (catalogue: CatalogueData) => {
        if (catalogue.on_order) {
            return {
                tag: "On Order",
                tagColor: "#FFEB3B",
                tagTextColor: "#2E2E2E",
            };
        } else if (catalogue.bargain) {
            return {
                tag: "Bargain",
                tagColor: "#000000",
                tagTextColor: "#FFFFFF",
            };
        }
        return {
            tag: "",
            tagColor: "",
            tagTextColor: "",
        };
    };

    getBargainData = async () => {
        this.getBargainApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getBargainDataEndPoint}?page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        });
    };

    handleBargainDataSuccess = (response: BargainRes) => {
        if (response.data) {
            this.setState({
                bargainData: response.data,
                pagination: response.meta
            });
        }
    };

    getCreatorMallData = async () => {
        this.getCreatorMallApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getCreatorMallEndPoint}?page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        });
    };

    handleCreatorMallSuccess = (response: CreatorMallRes) => {
        if (response.data) {
            this.setState({
                CreatorMallData: response.data,
                pagination: response.meta
            });
        }
    };

    handleCommonFunction = () => {
        if (this.state.urlParam === "Categories") {
            this.getCategories();
        } else if (this.state.urlParam === "CreatorMall") {
            this.getCreatorMallData();
        } else if (this.state.urlParam === "RecommendProduct") {
            this.getRecommendData();
        } else if (this.state.urlParam === "LiveStreaming") {
            this.getLiveStreamData();
        } else if (this.state.urlParam === "TopSelling") {
            this.getTopSellingData();
        } else if (this.state.urlParam === "OnOrder") {
            this.getOnOrderData();
        } else if (this.state.urlParam === "Bargain") {
            this.getBargainData();
        } else if (this.state.urlParam === "Category") {
            this.getCategoriesDetailsApi(this.state.categoryId);
        } else if (this.state.urlParam === "Stores") {
            this.getStories();
        }
    };

    handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ page: value }, () => { this.handleCommonFunction() });
    };

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchProductText = event.target.value;

        this.setState({ searchProductText }, () => {
            if (searchProductText !== '' || searchProductText.length === 0) {
                this.handleCommonFunction();
            }
        });
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.handleCommonFunction();
        }
    };

    getCategoriesDetailsApi = async (categoryId: string) => {
        this.getCategoriesDetailsApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getCategoryDetailsEndPoint}?id=${categoryId}&page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`,
        });
    };

    handleCategoriesDetailsSuccess = (response: CategoryDetailsRes) => {
        if (response.data) {
            this.setState({
                categoryDetailsData: response.data,
                pagination: response.meta
            });
        }
    };

    openLiveModal = () => {
        this.setState({ openLiveModal: true })
    };

    closeLiveModal = () => {
        this.setState({ openLiveModal: false })
    };

    getStories = async (currentLocation?: string) => {
        this.getStoriesApiID = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getStoresEndPoint}?latitude=${this.state.latitude}&longitude=${this.state.longitude}&city=${this.state.currentLocation}&page=${this.state.page}&per_page=${30}&search=${this.state.searchProductText}`
        })
    };

    handleStoriesSuccess = (response: StoreRes) => {
        this.setState({
            stories: response.stores,
            pagination: response.meta
        });
    };

    goToStore = (path: string, storeId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), path);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), storeId);
        this.send(message)
    };
    // Customizable Area End
}