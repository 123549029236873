// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { setStorageData } from "../../../framework/src/Utilities";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
const navigation = require('react-navigation');
let config = require('../../../framework/src/config');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    handleActiveStep: (id: number) => void;
    classes: Record<string, string>;
    // Customizable Area End
}

// Customizable Area Start

interface ApiPayloadType{
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
}

interface CreatorMallAttributes {
    id: number;
    name: string;
    description: null | string;
    city: null | string;
    state: null | string;
    country: null | string;
    pin_code: null | number;
    created_at: string;
    updated_at: string;
    banner: string;
    banner_thumbnail: string;
    like_count: number;
}

interface CreatorMallData {
    id: string;
    type: string;
    attributes: CreatorMallAttributes;
}

interface CreatorMallResponse {
    data: CreatorMallData;
}
export interface ProdData {
    id: string;
    attributes: {
        description: string;
        title: string;
        selling_price: number;
        mrp: number;
    }
}

interface ProdRes {
    catalogues: { data: ProdData[]; },
    meta: {
        total_pages: number;
        total_counts: number;
    }
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    selectedVideos: File | null;
    errorModal: boolean,
    errorMessage: string,
    edit: boolean,
    editCatalougeId: string,
    mallsName: string;
    isLoading : boolean;
    creatorMallId : string;
    isFromEdit: boolean;
    fileUrl : string;
    isFileUploaded: boolean;
    hasProductAffiliate: boolean;
    modalTitle: string | null;
    isNoProdModal: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class UploadMallController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    readonly inputRef: React.RefObject<HTMLInputElement>;
    readonly videoInputRef: React.RefObject<HTMLInputElement>;
    imageVideoUploadMsgId: string = "";
    videoDeleteId: string = "";
    existingFileIds: string[] = [];
    existingVideoFileIds: string[] = [];
    postCreateMallApiCallId: string = "";
    getCreatorMallApiCallId : string = "";
    getProductApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.inputRef = React.createRef()
        this.videoInputRef = React.createRef()
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            selectedVideos: null,
            errorModal: false,
            errorMessage: "",
            edit: false,
            editCatalougeId: "",
            mallsName: "",
            isLoading: false,
            creatorMallId: "",
            isFromEdit: false,
            fileUrl : "",
            isFileUploaded: false,
            hasProductAffiliate: false,
            modalTitle : null,
            isNoProdModal: false,
            // Customizable Area End
        };

    }


    // Customizable Area Start    
    async receive(from: string, message: Message) {
        // Customizable Area Start
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (responseJson) {
          if(apiRequestCallId === this.postCreateMallApiCallId ){
            this.getCreateMallApiResponse(responseJson);
          } else if(apiRequestCallId === this.getCreatorMallApiCallId){
            this.creatorMallResponse(responseJson);
          } else if(apiRequestCallId === this.getProductApiCallId){
            this.productLisApiResponse(responseJson)
          }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        await setStorageData('activeStep', 0);
        let mallId = await getStorageData("mallId");
        const array = location.pathname.split("/");
        const pathMallId = array[array.length - 1];
        this.getProductListing();
        if (array.length > 2) {
            this.setState({ isFromEdit: true, creatorMallId: pathMallId });
            this.getCreatorMallData();
        } else if (mallId) {
            this.setState({ creatorMallId: mallId, isFromEdit: true }, () => {
                this.getCreatorMallData();
            });
        }
    }

    getProductListing = async () => {
        this.getProductApiCallId = await this.creatorApiCall({
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getProductListingEndPoint + `?page=1&per_page=1`
        });
    };

    productLisApiResponse = (responseJson: ProdRes) => {
        const data = responseJson?.catalogues?.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({ hasProductAffiliate: false });
        } else {
            this.setState({ hasProductAffiliate: true });
        }
    };

    getCreatorMallData = async () => {
        this.setState({ isLoading: true });
        const { creatorMallId } = this.state;
        this.getCreatorMallApiCallId = await this.creatorApiCall({
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.updateMallApiEndpoint + `/${creatorMallId}`,
        });
    };

    creatorMallResponse = (responseJson: CreatorMallResponse) => {
        this.setState({ fileUrl: responseJson.data.attributes.banner, isFileUploaded: true, mallsName: responseJson.data.attributes.name, isLoading: false});
    };


    showErrorModal = (errorMessage: string) => {
        this.setState({
            errorModal: true,
            errorMessage: errorMessage,
        });
    };

    handleVideoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.length) return;
        const file = event.target.files[0] as Blob;
        if (!file) return;
        if (file.size > 100 * 1024 * 1024) {
            this.showErrorModal(`Video exceeds the maximum size of 100 MB.`);
            return null;
        }

        if (file.type !== "video/mp4") {
            this.showErrorModal(`File is not an MP4 video.`);
            return null;
        }
        let reader = new FileReader();
        reader.onload = (readerEvent) => {
            this.setState({ fileUrl: readerEvent.target?.result as string });
        };
        reader.readAsDataURL(file);
        this.setState({ selectedVideos: event.target.files[0], isFileUploaded: true });
        this.resetVideoInput();
    };

    resetVideoInput = () => {
        if (this.videoInputRef.current) {
            this.videoInputRef.current.value = "";
        }
    };

    creatorApiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        body && type != 'formData' ?
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    handleAddVideoClick = (event: React.MouseEvent<HTMLLabelElement>) => {
        event.preventDefault()
        this.videoInputRef?.current?.click();
    };

    createMallApiCall = async () => {
        const { fileUrl, mallsName, isFromEdit, selectedVideos, creatorMallId } = this.state;
        if (fileUrl === "" || mallsName === "") {
            this.showErrorModal("Please upload the required details");
            return;
        }
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append("[data][attributes][name]", this.state.mallsName);
        selectedVideos && formData.append("[data][attributes][banner]", selectedVideos);

        let apiEndpoint = isFromEdit ? `${configJSON.createMallEndPoint}/${creatorMallId}` : configJSON.createMallEndPoint
        this.postCreateMallApiCallId = await this.creatorApiCall({
            method: isFromEdit ? configJSON.patchMethodType : configJSON.exampleAPiMethod,
            endPoint: apiEndpoint,
            type: "formData",
            body: formData,
        });
    };

    getCreateMallApiResponse = async (responseJson: CreatorMallResponse & { errors: Array<{ [key: string]: string; }> }) => {
        if (responseJson.data) {
            this.setState({ isLoading: false });
            await setStorageData("mallId", responseJson.data.id);
            this.props.handleActiveStep(1);
        }else if(responseJson.errors){
            this.handleApiErrors(responseJson.errors);
        }
    };

    handleApiErrors = (errors: Array<{ [key: string]: string; }>) => {
        let error = errors[0];
        let errMsg = error?.token ? error?.token : error?.message;
        this.showErrorModal(errMsg ? errMsg : `Something went wrong!`);
    };

    handleSaveButton = async () => {
        if (this.state.hasProductAffiliate) {
            this.showNoProdModal();
        } else {
            this.createMallApiCall();
        }
    };

    showNoProdModal = () => {
        this.setState({ isNoProdModal: !this.state.isNoProdModal });
    };

    handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const value = event.target.value;
        this.setState({ mallsName: value });
    };

    errorModalClose = () => {
        this.setState({ errorModal: false, errorMessage: '' })
    };

    handleRemoveVideo = () => {
        this.setState({ fileUrl: "", isFileUploaded: false });
    };

    navigateToFn = (path: string, screenId?: string | number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), `${path}`);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        screenId !== "" && message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenId);
        this.send(message);
    };
    // Customizable Area End
}