// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.description='description : '
exports.price='price : '
exports.rupee='$'
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "";
exports.title='Trending list';
exports.exampleAPiEndPointPost = "";
exports.getCreatorMarketPlaceEndPoint = "account_block/accounts/creator_market_place";
exports.getAffiliateMarketPlaceEndPoint = "bx_block_catalogue/catalogues/affiliated_product";
exports.getFollowersEndPoint = "bx_block_followers/follows/followers";
exports.getFollowingEndPoint = "bx_block_followers/follows";
exports.postGenerateAffiliateLinkEndPoint = "bx_block_profile/affiliated_products"
// Customizable Area End
