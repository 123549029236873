import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";
import { post, story } from "../../LiveStreaming/src/assets";
const theme = createTheme({});
import CreatePostOrStoryController, { Props, configJSON } from "./CreatePostOrStoryController";
// Customizable Area End


export default class CreatePostOrStory extends CreatePostOrStoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.liveStreaming_Main_Container}>
          <Box sx={webStyle.liveStreaming_Options_Main_Container}>
            <Box sx={webStyle.liveStreaming_Options_Heading_Container}>
              <Typography component={Box} sx={webStyle.liveStreaming_Options_Heading}>{configJSON.create}</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={webStyle.liveStreaming_Options_Box} onClick={() => this.handleNavigateTo("PostCreationForCreator")} data-testid="createPostTestId">
                  <img src={post} alt="Image" style={{ width: "24px", height: "24px" }} />
                  <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.post}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Box sx={webStyle.liveStreaming_Options_Box} onClick={() => this.handleNavigateTo("CreateCreatorStory")} data-testid="CreateStoryTestId">
                  <img src={story} alt="Image" style={{ width: "24px", height: "24px" }} />
                  <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.story}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  liveStreaming_Main_Container: {
    display: "flex",
    height: "100vh",
    marginTop: "103px",
    "@media (max-width: 1149px)": {
      marginTop: "70px"
    }
  },
  liveStreaming_Options_Main_Container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "50px 60px 0px 24px",
    gap: "30px",
    "@media (max-width: 991px)": {
      padding: "50px 60px 50px 24px"
    },
    "@media (max-width: 550px)": {
      padding: "50px 3% 50px"
    }
  },
  liveStreaming_Options_Heading_Container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  liveStreaming_Options_Heading: {
    color: "rgba(68, 68, 68, 0.80)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  liveStreaming_Options_Container: {
    display: "flex",
    gap: "22px",
    flexWrap: "wrap",
    "@media (max-width: 789px)": {
      justifyContent: "center"
    }
  },
  liveStreaming_Options_Box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "8px",
    width: "100%",
    height: "116px",
    borderRadius: "10px",
    border: "1px solid rgba(68, 68, 68, 0.20)",
    background: "#FFF",
    cursor: "pointer",
    "@media (max-width: 400px)": {
      width: "100%"
    }
  },
  liveStreaming_Options_Box_Text: {
    color: "#444",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    cursor: "pointer"
  }
};
// Customizable Area End
