import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const navigation = require("react-navigation");

export interface ValidResponseType {
    data: object;
    meta: object;
}

export interface InvalidResponseType {
    errors: string;
}

interface NotificationData {
    id: string;
    type: string;
    attributes: {
        id: number;
        created_by: number;
        headings: string;
        contents: string;
        app_url: string;
        is_read: boolean;
        read_at: string;
        notificationable_id: number;
        notificationable_type: string;
        created_at: string;
        updated_at: string;
        account_id: number;
        auction_bidding: string;
    }
}

interface NotificationRes {
    data: Array<NotificationData>;
    meta: {
        message: string;
        total_pages: number;
    }
}

interface ReadAllNotification {
    message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    handleNotificationBoxToggle: boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isNotificationBoxOpen: boolean;
    allNotifications: Array<NotificationData>;
    readNotificatons: ReadAllNotification;
    selectedTab: number;
    openTunedBox: boolean;
    // Customizable Area End
}

interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellersNotificationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAllNotifiction: string = "";
    readAllNotification: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            isNotificationBoxOpen: false,
            allNotifications: [],
            readNotificatons: {} as ReadAllNotification,
            selectedTab: 1,
            openTunedBox: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    isValidResponse = (responseJson: ValidResponseType) => {
        return responseJson;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & NotificationRes & ReadAllNotification) => {
        if (apiRequestCallId === this.getAllNotifiction) {
            this.getAllNotificationSuccess(responseJson);
        }
        if (apiRequestCallId === this.readAllNotification) {
            this.markAllNotificationsAsReadSuccess(responseJson);
        }
    };

    apiCall = async (valueData: { method?: string; contentType?: string; endPoint?: string; }) => {
        const { method, contentType, endPoint } = valueData;
        const token = (await getStorageData("singupLogin")) || "";
        const header = { "Content-Type": contentType, token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    handleNotificationBoxToggle = () => {
        this.setState((prevState) => ({ isNotificationBoxOpen: !prevState.isNotificationBoxOpen }));
        this.getAllNotification();
        this.markAllNotificationsAsRead();
    };

    getAllNotification = async () => {
        this.getAllNotifiction = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getAllNotifications
        });
    };

    getAllNotificationSuccess = (response: NotificationRes) => {
        this.setState({
            allNotifications: response.data
        });
    };

    markAllNotificationsAsRead = async () => {
        this.readAllNotification = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.readAllNotifications
        });
    };

    markAllNotificationsAsReadSuccess = (response: ReadAllNotification) => {
        this.setState({ readNotificatons: response });
    };

    handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
        if (newValue === 0) {
            return;
        }
        this.setState({
            selectedTab: newValue,
            openTunedBox: true
        });
    };
    // Customizable Area End
}