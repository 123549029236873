import React from "react";
// Customizable Area Start
import CreateCreatorStoryController from "./CreateCreatorStoryController";
// Customizable Area End


export default class CreateCreatorStory extends CreateCreatorStoryController {
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                Create Creator Story
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
