import React from "react";
// Customizable Area Start
import UploadMallController, { Props, configJSON } from "./UploadMallController";
import { createTheme, withStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography, Box, Grid, TextField, styled, Modal } from "@material-ui/core";
import { exclamatoryIcon, uploadIcon, removeIcon, videoAddButton } from "../../InventoryManagement3/src/assets"
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";
import { App_Logo } from "../../email-account-registration/src/assets";
import { linkIcon } from "../../CustomisableUserProfiles/src/assets";

let config = require('../../../framework/src/config')

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    imageSection: {
        height: "auto",
        border: '1px dashed rgba(68, 68, 68, 0.60)',
        borderRadius: "8px",
        padding: "1em",
        maxWidth: "100%"
    },
    inputSection: {
        height: "auto",
        borderRadius: "8px",
        maxWidth: "100%"
    },
    inputText: {
        width: "100%"
    },
    validtionInfoContainer: {
        background: 'rgba(245, 234, 22, 0.10);',
        marginTop: "1em",
        borderRadius: "8px",
        padding: "1.5em 0.75em"
    },
    infoIcon: {
        marginTop: "0.25rem"
    },
    sizeText: {
        marginLeft: "0.35rem"
    },
    uploadImagesMainContainer: {
        padding: "0em 5em"
    },
    imageDefaultPreviewCard: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    uploadHereText: {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    uploadText: {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        textDecorationLine: 'underline',
    },
    previewVideosSection: {
        position: "relative" as const,
        margin: "0em 0.5em"
    },
    eachvideoPreviewImage: {
        width: "100%",
        height: "8.5em",
        objectFit: "cover" as const
    },
    removeIcon: {
        position: "absolute" as const,
        top: "50%",
        right: "50%"
    },
    infoTitle: {
        color: 'rgba(68, 68, 68, 0.70)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    infoValue: {
        color: 'rgba(68, 68, 68, 0.70)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
    },
    uploadTitle: {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginTop: "3em",
        marginBottom: "1em"
    },
    uploadTitle1: {
        color: '#555',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginTop: "4em",
        marginBottom: "1em"
    },
    flexContainer: {
        display: 'flex'
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "8em",
        paddingBottom: "2em",
        width: "100%"
    },
    saveButton: {
        width: '21em',
        height: '3em',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer: "cursor",
        textTransform: "none" as const,
        "&:hover": {
            background: '#F5EA16',
        }
    },
    defaultAddButton: {
        marginTop: "0.5em"
    }
});
// Customizable Area End

export class UploadMall extends UploadMallController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderNoProductModal = () => {
        return (
            <CustomModal
                open={this.state.isNoProdModal}
                onClose={this.showNoProdModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box className="mainModalContainer">
                    <Box className="innerContainer">
                        <img src={App_Logo} alt="" className="appLogo" />
                        <Box className="textBox innerContainer">
                            <Typography className="titleText">OOps!</Typography>
                            <Typography className="subTitleText">It seems you haven't generated an affiliate llink for the product</Typography>
                            <Button className="affiliateNavBtn" onClick={()=> this.navigateToFn('AffiliateMarketPlace')} data-test-id="affiliateLinkTestId">
                                <img src={linkIcon} alt="" className="linkIcon" />
                                Affiliate link
                            </Button>
                        </Box>
                    </Box>
                    
                </Box>
            </CustomModal>
        )
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { isFileUploaded, fileUrl } = this.state
        return (
            <Box className={classes.uploadImagesMainContainer} data-test-id="UploadProductMainContainer">
                <Loader loading={this.state.isLoading} />
                {this.renderNoProductModal()}
                <Box>
                    <Typography className={classes.uploadTitle}>{configJSON.uploadVideos}* ({configJSON.videoLimit})</Typography>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Box className={classes.imageSection}>
                                    <input
                                        ref={this.videoInputRef}
                                        type="file"
                                        accept="video/*"
                                        style={{ display: "none" }}
                                        onChange={this.handleVideoChange}
                                        id="videoInput"
                                        multiple
                                        data-test-id="videoInputTestId"
                                    />
                                    {isFileUploaded === false &&
                                        <>
                                            <label htmlFor="videoInput">
                                                <Box className={classes.imageDefaultPreviewCard}>
                                                    <Box>
                                                        <img src={uploadIcon} />
                                                        <Typography className={classes.uploadHereText}>
                                                            <Typography component="span" className={classes.uploadText}>
                                                                {configJSON.upload}
                                                            </Typography>
                                                            {" "}
                                                            {configJSON.here}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </label>
                                        </>}
                                    {isFileUploaded && (
                                        <Box>
                                            <Grid container spacing={2} data-test-id="videoGrid">
                                                <Grid item xs={12} md={4} lg={4}>
                                                    <Box className={classes.previewVideosSection}>
                                                        <video
                                                            src={fileUrl}
                                                            className={classes.eachvideoPreviewImage}
                                                        />
                                                        <Box className={classes.removeIcon} onClick={()=> this.handleRemoveVideo()}>
                                                            <img src={removeIcon} alt="removeIcon" />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.validtionInfoContainer} >
                                    <Box className={classes.flexContainer}>
                                        <Box component="span">
                                            <img src={exclamatoryIcon} className={classes.infoIcon} />
                                        </Box>
                                        <Box>
                                            <Box className={classes.sizeText}>
                                                <Typography component="span" className={classes.infoTitle}>{configJSON.sizeMax}</Typography>
                                                <Typography component="span" className={classes.infoValue}>{configJSON.videoSizeValue}</Typography>
                                            </Box>
                                            <Box className={classes.sizeText}>
                                                <Typography component="span" className={classes.infoTitle}>{configJSON.imageRatio}</Typography>
                                                <Typography component="span" className={classes.infoValue}>{configJSON.imageRatioValue}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography component="span" className={classes.infoTitle}>&nbsp;{configJSON.format}</Typography>
                                                <Typography component="span" className={classes.infoValue}>{configJSON.videoFormatValue}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box>
                    <Typography className={classes.uploadTitle1}>{configJSON.visitorMall}</Typography>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Box className={classes.inputSection}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        data-test-id="mallNameTestId"
                                        label={"Enter your mall name*"}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        value={this.state.mallsName}
                                        className={classes.inputText}
                                        onChange={this.handleChangeName}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box className={classes.buttonContainer}>
                    <Button
                        data-test-id="saveButton"
                        className={classes.saveButton}
                        onClick={() => this.handleSaveButton()}
                    >
                        {configJSON.save}
                    </Button>
                </Box>
                <MessageModalWeb
                    data-test-id="errorMessage"
                    displayPopup={this.state.errorModal}
                    closeErrorPopup={this.errorModalClose}
                    errorMessage={this.state.errorMessage}
                />
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const CustomModal = styled(Modal)({
    "& .mainModalContainer": {
      position: "absolute",
      top: "50%",
      left: "50%",
      background: "white",
      border: "none",
      width:"100%",
      maxWidth: "450px",
      transform: "translate(-50%, -50%)",
      borderRadius: "14px",
      padding: "30px 40px",
    },
    "& .innerContainer":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        textAlign:"center"
    },
    "& .appLogo":{
        maxWidth:"160px"
    },
    "& .titleText":{
        fontSize:"20px",
        color: "#444444",
        fontWeight: 600
    },
    "& .subTitleText":{
        fontSize:"16px",
        marginTop:"8px",
        color : "#444444",
        fontWeight: 400
    },
    "& .textBox":{
        marginTop:"12px"
    },
    "& .affiliateNavBtn":{
        textTransform: "none",
        width: "100%",
        maxWidth: "200px",
        height: "40px",
        backgroundColor: "#F5EA16",
        borderRadius: "50px",
        fontSize: "12px",
        color: "#000000",
        marginTop: "24px"
    },
    "& .linkIcon":{
        width: "24px",
        marginRight: "8px"
    }
});
export default withStyles(webStyles)(UploadMall)
// Customizable Area End