import React from "react";
// Customizable Area Start
import {
    Box,
    createStyles,
    styled,
    Switch,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";

import UserSettingsController, {
  IosProps,
    Props,
    configJSON
} from "./UserSettingsController";

export const OnOffSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#FFFF00', 
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: 'black', 
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      backgroundColor: 'black !important', 
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: IosProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
// Customizable Area End

export default class UserSettings extends UserSettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <StyledWrapper>
                <Box className="mainContainer">
                    <Typography className="screenNameTitle">Settings</Typography>

                    <Box className="notificationContainer">
                        <Typography className="notificationTitle">
                            {configJSON.notifications}
                        </Typography>
                        <Box className="notificationDetailBox">
                            <Typography className="notificationLabel">{configJSON.allNotification}</Typography>
                            <OnOffSwitch
                                  checked={this.state.allNotification}
                                  onChange={this.handleOnOff("allNotification")}
                                data-test-id="allNotification"
                            />
                        </Box>
                        <Box className="notificationDetailBox">
                            <Typography className="notificationLabel">{configJSON.emailNotification}</Typography>
                            <OnOffSwitch
                                  checked={this.state.emailNotification}
                                  onChange={this.handleOnOff("emailNotification")}
                                data-test-id="emailNotification"
                            />
                        </Box>
                        <Box className="notificationDetailBox">
                            <Typography className="notificationLabel">{configJSON.pushNotification}</Typography>
                            <OnOffSwitch
                                  checked={this.state.pushNotification}
                                  onChange={this.handleOnOff("pushNotification")}
                                data-test-id="pushNotification"
                            />
                        </Box>
                    </Box>
                    
                    <Box className="otherNotificationBox">
                        <Typography className="notificationTitle">
                            Other Notifications
                        </Typography>

                        <Box className="notificationDetailBox">
                            <Box className="innerBox" >
                                <Typography className="notificationLabel">Receive Direct Messages</Typography>
                                <Typography className="subContentTxt">You can turn this option on to receive Direct message</Typography>
                            </Box>
                            <OnOffSwitch />
                        </Box>
                        <Box className="notificationDetailBox">
                            <Box className="innerBox">
                                <Typography className="notificationLabel">Activity Status</Typography>
                                <Typography className="subContentTxt">You and followers who follow you back can see each other activity when both have this on</Typography>
                            </Box>
                            <OnOffSwitch />
                        </Box>

                    </Box>
                </Box>
            </StyledWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
    width: "100%",
    marginTop: "135px",
    padding: "0px 24px",
    height:"100%",
    marginBottom:"20px",
    "& .mainContainer":{
        flex: 1,
        height:"100%",
        marginBottom:"20px"
    },
    "& .screenNameTitle": {
        fontSize:"24px",
        fontWeight: 500,
        color: "#000000cc"
    },
    "& .notificationContainer":{
        marginTop:"30px",
        width:"100%",
        maxWidth:"400px"
    },
    "& .otherNotificationBox":{
        marginTop:"30px",
        width:"100%",
        maxWidth:"400px"
    },
    "& .notificationDetailBox":{
        display:"flex",
        gap:"20px",
        alignItems:"center",
        marginTop:"10px",
        justifyContent:"space-between"
    },
    "& .notificationTitle":{
        color: '#000000cc',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    },
    "& .notificationLabel": {
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
    },
    "& .subContentTxt":{
        fontSize: "12px",
        color: "#4a4a4acc",
        width:"100%",
        maxWidth:"250px"
    }
});
// Customizable Area End
