import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import SendbirdChat, { SendbirdChatWith, FileCompat } from "@sendbird/chat";
import { GroupChannel, GroupChannelFilter, GroupChannelListOrder, GroupChannelModule } from "@sendbird/chat/groupChannel";
import { UserMessageCreateParams, FileMessageCreateParams } from "@sendbird/chat/message";
export const SENDBIRD_INFO = { appId: '901B5A29-B245-467F-B597-4D8A317B343C' };
export const baseConfig = require('../../../framework/src/config');
const baseUrl = baseConfig.baseURL;
import eventEmitter from '../../../../packages/components/src/StoreCount';
import moment from "moment";
export interface SizeDataI{
		id: string;
		type: string;
		attributes: {
			id: string;
			catalogue_id: string;
			catalogue_variant_size: {
				id: number;
				name: string;
				created_at: string;
				updated_at: string
			} | null;
			price: string;
			stock_qty: number;
			created_at: string;
			updated_at: string
		}
}

export interface ValidResponseType {
  id: string
  data: [];
  message: ""
};

interface CreateDataPayload{
  attributes: {
    id: string
  }
}
interface CreateOnOrderApiRes{
channel_url: string;
data: CreateDataPayload;
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type: string;
}

interface DataPayloadType {
  id: string,
  attributes: {
    id: string;
    catalogue:{
      data:{
        id:string;
      }
    }
  }
}

interface MakeOfferApiPayload{
  channel_url: string,
  data: DataPayloadType;
}

export interface CataglogueI {
  catalogue_variant_color_id: number;
  catalogue_variant_images: [
    {
      id: number;
      url: string;
    }
  ]
  catalogue_variant_videos: [
    {
      id: number;
      url: string;
    }
  ];
  sizes_data: Array<SizeDataI>
}

interface AttributesRes{
  inventory_details:{
    stock_quantity: number;
  }
};

export interface OnOrderData {
  title: string;
  description: string;
  sellerId: number;
  accountFullName: string;
  hostImage: string;
  productImageUrl: string;
  productId: string,
}

interface CatalogueDetail {
  data: {
    id: string;
    type: string;
    attributes: Attributes;
  };
}

interface Attributes {
  brand: Brand;
  category: Category;
  sub_category: SubCategory;
  title: string;
  description: string;
  status: string;
  mrp: number;
  selling_price: number;
  ask_price: number;
  bargain: boolean;
  on_order: boolean;
  is_ask_price: boolean;
  is_brand: boolean;
  tags: string[];
  reviews: string[];
  sku: string;
  account: Account;
  host_name: string;
  host_bio: string | null;
  host_image: string;
  product_images: ProductImage[];
  inventory_details: InventoryDetails;
  shipment_charge: ShipmentCharge;
  packaging_detail: PackagingDetail;
  manufacturing_detail: ManufacturingDetail;
  product_videos: null;
  catalogue_variants: Record<string, CatalogueVariant>;
  catalogues_specifications: null;
}

interface Brand {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface Category {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: string | null;
  rank: string | null;
  light_icon_active: IconDetails;
  light_icon_inactive: IconDetails;
  dark_icon: IconDetails;
  dark_icon_active: IconDetails;
  dark_icon_inactive: IconDetails;
  identifier: string | null;
  slug: string;
}

interface IconDetails {
  url: string | null;
}

interface SubCategory {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: string | null;
  rank: string | null;
}

interface Account {
  id: number;
  first_name: string | null;
  full_phone_number: string;
  email: string;
  user_name: string;
  user_type: string | null;
  store_type: string;
  full_name: string;
  business_name: string;
  business_type: string;
  my_bio: string | null;
  upi_id: string;
  qr_generated: boolean;
  is_following_seller: boolean;
}

interface ProductImage {
  id: number;
  url: string;
}

interface InventoryDetails {
  id: number;
  fullfilment_by: string;
  procurement_type: string;
  procurement_sla: string;
  account_id: number;
  stock_quantity: number;
  catalogue_id: number;
  created_at: string;
  updated_at: string;
}

interface ShipmentCharge {
  id: number;
  local_delivery_charge: string;
  zonal_delivery_charge: string;
  national_delivery_charge: string;
  account_id: string;
  catalogue_id: string;
  created_at: string;
  updated_at: string;
}

interface PackagingDetail {
  id: number;
  package_length: string;
  package_weight: string;
  package_breadh: string;
  package_height: string;
  catalogue_id: number;
  account_id: number;
  hsn: string;
  tax_code: string;
  created_at: string;
  updated_at: string;
}

interface ManufacturingDetail {
  data: {
    id: string;
    type: string;
    attributes: ManufacturingAttributes;
  };
}

interface ManufacturingAttributes {
  country: string;
  manufacture_details: string;
  packer_details: string;
  return_and_exchange: boolean;
  return_and_refund: boolean;
  manufactures_product_details: null;
  size_chart: string | null;
  size_chart_filename: string | null;
  size_chart_filesize: string | null;
}

interface CatalogueVariant {
  catalogue_variant_color_id: number;
  catalogue_variant_images: Array<ProductImage>;
  catalogue_variant_videos: null;
  sizes_data: SizeData[];
}

interface SizeData {
  id: string;
  type: string;
  attributes: SizeAttributes;
}

interface SizeAttributes {
  id: string;
  catalogue_id: number;
  catalogue_variant_size: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  };
  price: string;
  stock_qty: number;
  created_at: string;
  updated_at: string;
}

export interface ProductDescriptionDetail {
  bargain: boolean,
  account: {
      id: number,
      first_name: null,
      full_phone_number: string,
      email: string,
      user_name: string,
      user_type: string,
      store_type: string,
      full_name: string,
      business_name: string,
      business_type: string,
      my_bio: string,
      upi_id: string,
      qr_generated: boolean,
      is_following_seller: boolean
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any
  classes?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  productDescription: any;
  selectedImage: any;
  selectedVideo: any;
  isShowingVideo: boolean;
  relatedProduct: any;
  token: any;
  buyerToken: any;
  deliveryInfo: any;
  pincode: any;
  catalogueVariants: any;
  selectedVariant: any;
  isAddingToCart: boolean
  productId: string
  messageModel: boolean;
  loading: boolean;
  message: string;
  wishListProduct: any;
  subCategoryIds: string
  uniqueProductSize: any;
  colorBasedOnSize: any;
  productPrice: string;
  openTunedBox: boolean
  tokenPresentOrNot: null | string
  sizeChartopen: boolean;
  showCatalogue: boolean;
  colorList: Array<string>;
  productMrp: string;
  activeSize: {
    sizeID: string;
    sizeName: string | null
  };
  isMakeOfferModal: boolean;
  offerValue: number | string;
  percentageStep: number;
  offerValueError : string;
  channel: GroupChannel | null
  stockQuantity:number,
  imgUploadErr: string;
  selectedReqFiles: File[];
  descriptionErr: boolean;
  quantity: number;
  isRequestModal: boolean;
  selectedOnOrder: OnOrderData | null;
  description: string;
  expectedDate: Date | null;
  isSuccessModal: boolean;
  onOrderData: OnOrderData;
  channelUrl: string;
  isFromRef: boolean;
  refUserId: string | null;
  referralId: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productDescriptionAPICallId: any;
  relatedCatalogueAPICallId: any;
  getNewSellerTokenCallId: any;
  getNewBuyerTokenCallId: any;
  deliveryInforCallId: any;
  addToCartCallId: any;
  similarAddToCartCallId: any;
  apiAddToWishList: string = ''
  apiDeleteFromWishList: string = ''
  apiTopSearch: string = ''
  makeOfferApiCallId: string = "";
  apiGetcartitemscount: string = "";
  postOnOrderDataApiId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement> = createRef();
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  followSellerApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false
      // Customizable Area Start
      ,productDescription: null,
      selectedImage: null,
      selectedVideo: null,
      isShowingVideo: false,
      relatedProduct: null,
      token: null,
      deliveryInfo: null,
      pincode: "",
      catalogueVariants: null,
      selectedVariant: null,
      buyerToken: null,
      isAddingToCart: false,
      productId: '',
      messageModel: false,
      loading: false,
      message: '',
      wishListProduct: [],
      subCategoryIds: '',
      uniqueProductSize: [],
      colorBasedOnSize: [],
      productPrice: '',
      openTunedBox: false,
      tokenPresentOrNot:null,
      sizeChartopen:false,
      showCatalogue: false,
      colorList: [],
      productMrp: "",
      activeSize: {
        sizeID: "",
        sizeName: ""
      },
      isMakeOfferModal: false,
      offerValue: "",
      percentageStep: 0,
      offerValueError: "",
      channel: null,
      stockQuantity:0,
      imgUploadErr: "",
      selectedReqFiles: [],
      descriptionErr: false,
      quantity: 1,
      isRequestModal: false,
      selectedOnOrder: null,
      description: "",
      expectedDate: new Date(),
      isSuccessModal: false,
      onOrderData: {} as OnOrderData,
      channelUrl: "",  
      isFromRef: false,
      refUserId: "",
      referralId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


    if (this.isValidResponse(responseJson)) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  closeMessageModal = () => {
    this.setState({
      messageModel: !this.state.messageModel
    })
  }

  // Customizable Area Start
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: CreateOnOrderApiRes) => {
    if (this.postOnOrderDataApiId === apiRequestCallId) {
      this.createOnOrderSuccessCallBack(responseJson)
    }
  }

  StayTunedBox = () => {
    this.setState({
      openTunedBox: !this.state.openTunedBox
    });
  }

  getProductDescription = async() => {
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productDescriptionAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/catalogue_details?id=${this.state.productId}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: buyerToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRelatedCalogue = async(subCategoryId: any) => {
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.relatedCatalogueAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/related_catalogue?sub_category_id=${subCategoryId}&catalogue_id=${this.state.productId}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: buyerToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTopSearchData = async (subCateQueryUrl: string) => {
    this.setState({ loading: true });
    const headers = {"Content-Type": configJSON.validationApiContentType,
      token: await getStorageData('buerLoginToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTopSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getTopSearchEndPoint}${subCateQueryUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.similarAddToCartCallId:
          this.setStateCart(responseJson)
          this.getCartItemsCount()
          break;
        case this.getNewSellerTokenCallId:
          const token = responseJson.meta.token;
          this.setState({ token });
          break;
        case this.getNewBuyerTokenCallId:
          this.getProductDescription();
          break;
        case this.productDescriptionAPICallId:
          this.productionDescripionSuccessData(responseJson);
          break;
        case this.relatedCatalogueAPICallId:
          this.setState({ relatedProduct: responseJson.data });
          break;
        case this.deliveryInforCallId:
          responseJson.message === "Data Fetch successfully"
            ? this.setState({ deliveryInfo: responseJson })
            : this.setState({ deliveryInfo: null });
          break;
        case this.addToCartCallId:
          this.setStateCart(responseJson)
          this.getCartItemsCount()
          break;
        case this.apiAddToWishList:
          this.checkItemAddedWishList(responseJson);
          break;
        case this.apiDeleteFromWishList:
          this.checkItemRemovedWishList(responseJson);
          break;
        case this.apiTopSearch:
          this.setState({ loading: false, wishListProduct: responseJson?.wishlist_items?.product_ids })
          break;
        case this.makeOfferApiCallId:
          this.handleMakeOfferApiCallBack(responseJson);
          break;
        case this.apiGetcartitemscount:
          if (responseJson) {
            localStorage.setItem("cartItemsCount", responseJson.total_items_in_cart);
            eventEmitter.emit('cartUpdated', responseJson.total_items_in_cart);
          }
          case this.followSellerApiCallID:
            this.getFollowSellerCallback(responseJson);
            break;
          break;
        default:
          break;
      }
    }
  }

  productionDescripionSuccessData = (responseJson: CatalogueDetail) => {
    if (responseJson.data) {
      const newOrderData: OnOrderData = {
        title: responseJson.data.attributes.title,
        description: responseJson.data.attributes.description,
        sellerId: responseJson.data.attributes.account.id,
        accountFullName: responseJson.data.attributes.account.full_name,
        hostImage: responseJson.data.attributes.host_image || '',
        productImageUrl: responseJson.data.attributes.product_images ? responseJson.data.attributes.product_images[0].url : '',
        productId: responseJson.data.id
      };

      this.getRelatedCalogue(
        responseJson.data.attributes.sub_category.id
      );
      const toNestedArray: Array<[string, CatalogueVariant]> = Object.entries(responseJson.data.attributes.catalogue_variants);
      this.setState({
        onOrderData: newOrderData,
        stockQuantity: this.checkStockQty(responseJson.data.attributes),
        productDescription: responseJson.data,
        selectedImage: toNestedArray[0][1].catalogue_variant_images[0],
        catalogueVariants: toNestedArray,
        colorList: Object.keys(responseJson.data.attributes.catalogue_variants),
        subCategoryIds: responseJson.data.attributes.sub_category.id,
        productPrice: toNestedArray[0][1].sizes_data[0].attributes.price,
        selectedVariant: toNestedArray[0][1],
        activeSize: {
          sizeID: toNestedArray[0][1].sizes_data[0].attributes.id,
          sizeName: toNestedArray[0][1].sizes_data[0].attributes.catalogue_variant_size
            ? toNestedArray[0][1].sizes_data[0].attributes.catalogue_variant_size?.name : null
        }
      });
    }
  };

  setStateCart = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        loading: false,
        message: responseJson.message,
        messageModel: true,
        isAddingToCart: false
      })
    } else if (responseJson.errors) {
      this.setState({
        loading: false,
        message: responseJson.errors,
        messageModel: true
      })
    }
  }

  checkStockQty = (responseJson: AttributesRes) => {
    return responseJson.inventory_details ? responseJson.inventory_details.stock_quantity : 0;
  };

  checkItemAddedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.getRelatedCalogue(this.state.subCategoryIds);
      this.closeMessageModal();
      this.setState({ message: responJson.message })
    } else {
      this.closeMessageModal();
      this.setState({ message: responJson.errors })
    }
  }

  checkItemRemovedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.getRelatedCalogue(this.state.subCategoryIds);
      this.closeMessageModal();
      this.setState({ message: responJson.message })
    } else {
      this.closeMessageModal();
      this.setState({ message: responJson.errors[0] })
    }
  }

  checkProductStatus = (producArray: any, ID: any) => {
    const index = producArray?.findIndex((item: any) => item.product_id === ID);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  deleteProductItem = (producArray: any, ID: any) => {
    const index = producArray.find((item: any) => item.product_id === ID).favourite_id;

    if (index) {
      this.deleteWishListItem(index)
    } else {
      return false;
    }
  }

  getNewSellerToken = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewSellerTokenCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_login/logins/seller_login`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      key: configJSON.exampleSellerEmail,
      password: configJSON.exampleSellerPassword
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNewBuyerToken = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewBuyerTokenCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_login/logins/buyer_login`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      key: configJSON.exampleBuyerEmail,
      password: configJSON.exampleBuyerPassword
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  getDeliveryInfo = async(pincode: string, token: any, event: any) => {
    event.preventDefault();
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deliveryInforCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/catalogue_pincode?pincode=${pincode}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: buyerToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatDate = (dateString: string) => {
    let date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dayName = days[date.getDay()];
    let day = date.getDate();
    let monthName = months[date.getMonth()];
    return `${dayName}, ${day} ${monthName}`;
  };

  async componentDidMount() {
    const array = window.location.pathname.split("/");
    const prodId = array[array.length - 1];
    const tokenPresentOrNot =  await getStorageData('buerLoginToken')
    this.setState({tokenPresentOrNot:tokenPresentOrNot})
    this.getTopSearchData('')
        if(prodId !== null){
          this.setState({ productId: prodId });
        }
    if(tokenPresentOrNot !== null){
      this.initializeSendBirdSDK();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const idParam = urlParams.get('ref');
    if (idParam !== null) {
      this.setState({ referralId: String(idParam), isFromRef: true });
    }
  } 

  initializeSendBirdSDK = async () => {
    const buyerData = await getStorageData("Buyer_Data");
    const buyerObj = JSON.parse(buyerData);
    let buyerId = await buyerObj?.sendbird_credential?.user_id;
    let buyerToken = await buyerObj?.sendbird_credential?.access_token;
    const sendbirdChat = SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()]
    });
    try {
      await sendbirdChat.connect(buyerId, buyerToken);
    } catch (error) {
    }
    await sendbirdChat.setChannelInvitationPreference(true);
    this.sendBird = sendbirdChat; 
  };

  checkTokenPresentOrNot=()=>{
    if(this.state.tokenPresentOrNot === null){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
    }
  }

  loadChannels = async (channelURL: string, requestId: string, bargainId: string) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (channels && channels.length > 0) {
      let channelIndex = channels.findIndex((channel) => channel.url === channelURL);
      this.setState({ channel: channels[channelIndex] }, () => {
        this.sendMessageSendBird(requestId,bargainId);
      });
    }
  };

  sendMessageSendBird = async (requestId: string, bargainId:string) => {
    const { channel, selectedVariant, productDescription } = this.state;
    if (!channel) return;
    try {
      const messageData = JSON.stringify({bargainId: bargainId, productId: requestId, bargainStatus: "pending", productTitle: productDescription?.attributes.title, productDesc: productDescription?.attributes.description , askPrice: this.state.offerValue, productImage: baseUrl + selectedVariant?.catalogue_variant_images[0].url});
      const param: UserMessageCreateParams = {
        message: messageData,
        data: JSON.stringify({ delivered: false, read: false }),
        customType: 'bargain',
      };
      channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
        if (sentMessage) {
          const params = {
            customType: 'bargain',
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateUserMessage(sentMessage.messageId, params);
          } catch (error) {
          }
          sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
        }
      });
      this.setState({ offerValue: '', offerValueError: '' }, ()=> {
        this.navigateToChatScreen();
      });
    } catch (error) {
    }
  };

  handlePaginationChange = () => {
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollIntoView({behavior: 'smooth'})
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.productId !== prevState.productId) {
      this.getProductDescription()
    }
  }

  handleSelectedImage = (img: any) => {
    this.setState({ selectedImage: img, isShowingVideo: false });
  };

  handleSelectedVideo = (vid: any) => {
    this.setState({ selectedVideo: vid, isShowingVideo: true });
  };

  handleSelectVariant = (variant: [string, CataglogueI]) => {
    this.setState({
      selectedVariant: variant[1],
      selectedImage: variant[1].catalogue_variant_images[0],
      activeSize:  {
        sizeID: variant[1].sizes_data[0].attributes.id,
        sizeName: variant[1].sizes_data[0].attributes.catalogue_variant_size 
        ?  variant[1].sizes_data[0].attributes.catalogue_variant_size?.name : null
      },
      productPrice: variant[1].sizes_data[0]?.attributes.price,
      showCatalogue: true,
      isShowingVideo: false
    })
  }
  checkToken=()=>{
    if(this.state.tokenPresentOrNot === null){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
    }
  }

  handleAddToCart = async() => {
    this.checkToken()
    this.setState({ loading: true });
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToCartCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_order_management/add_item_to_cart`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": buyerToken
    };

    const refBody = {
      catalogue_id: this.state.productDescription.id,
      catalogue_variant_id: this.state.activeSize.sizeID,
      quantity: 1,
      unique_code: this.state.referralId,
    };

    const body = {
      catalogue_id: this.state.productDescription.id,
      catalogue_variant_id: this.state.activeSize.sizeID,
      quantity: 1
    };

    let cartBody = this.state.isFromRef === true ? refBody : body;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cartBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({isAddingToCart: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSimilarAddToCart = async(catalogue_id: number, catalogue_variant_id: number) => {
    this.checkToken()
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.similarAddToCartCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_order_management/add_item_to_cart`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": buyerToken
    };

    const body = {
      catalogue_id: catalogue_id,
      catalogue_variant_id: catalogue_variant_id,
      quantity: 1
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({isAddingToCart: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateWishListItem = async (id: number, type: string) => {
    this.checkToken()
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
        "Content-Type": "application/json"
      };
      if (type == "catalogue") {
        type = "product"
      }
      const body = {
        "data": {
          "type": type,
          "favouriteable_id": id
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAddToWishList = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouriteItemEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  deleteWishListItem = async (id: number) => {
    this.checkToken()
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "Content-Type": "application/json",
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiDeleteFromWishList = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.favouriteItemEndPoint}/${id}`
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleSizeChartClose=()=>{
    this.setState({sizeChartopen:false})
  }
  handleOpenSizeChart = () => {
    this.setState({ sizeChartopen: true })
  }

  handleSize = (sizeData:SizeDataI) => {
    this.setState({
      productPrice: sizeData.attributes.price,
      activeSize: {
        sizeID: sizeData.attributes.id,
        sizeName: sizeData.attributes.catalogue_variant_size ? sizeData.attributes.catalogue_variant_size?.name : null
      }
    });
  }
  handleProdNavigate = (prodId: number) =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),"ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), prodId);
    this.send(message)
  };

  pincodeValidator = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberReg = configJSON.pincodeRegx;
    if (numberReg.test(event.target.value) || event.target.value === "") {
      this.setState({
        pincode: event.target.value,
      });
    }
  }

  handleModalToggle = () => {
    this.checkToken();
    this.setState({ isMakeOfferModal: !this.state.isMakeOfferModal });
  };

  handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { productPrice } = this.state;
    const value = event.target.value;
    const regex = configJSON.offerValueRegex;
    if (regex.test(value)) {
      const numericValue = parseFloat(value);
    const numericProductPrice = parseFloat(productPrice); 
      if (numericValue > numericProductPrice) {
        this.setState({ offerValueError: "Your price cannot be greater than base price" });
      }else{
        this.setState({ offerValueError: "" });
      }
      this.setState({ offerValue: value, percentageStep: 0 });
    }
  };

  handleOfferApply = (value: number, percentage: number) => {
    const { productPrice, percentageStep } = this.state;
    if (percentageStep !== value) {
      const discountPrice = parseFloat((Number(productPrice) - (Number(productPrice) * percentage) / 100).toFixed(2));
      this.setState({ percentageStep: value, offerValue: discountPrice, offerValueError: '' });
    } else {
      this.setState({ percentageStep: 0, offerValue: '', offerValueError: '' });
    }
  };

  postMakeOfferApiCall = async () => {
    this.makeOfferApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.makeOfferApiEndPoint,
      body: {
        data:{
          "catalogue_id": Number(this.state.productId),
          "ask_price": this.state.offerValue
        }
      },
      type: "",
    });
  };

  handleMakeOffer = () => {
    this.checkToken();
    if(this.state.offerValueError !== "" || this.state.offerValue === '' || Number(this.state.offerValue) === 0){
      this.setState({offerValueError: "Please enter a valid offer price"});
      return;
    }
    this.postMakeOfferApiCall();
  };

  handleMakeOfferApiCallBack = (responseJson: MakeOfferApiPayload) => {
    this.setState({ loading: false });
    this.loadChannels(responseJson.channel_url , responseJson.data.attributes.catalogue.data.id, responseJson.data.id);
  };

  navigateToChatScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatScreen");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  apiCall = async (apiReqData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    
    this.setState({ loading: true, isMakeOfferModal: false });
    const buyerToken = await getStorageData('buerLoginToken');
    const header = {
      token: buyerToken,
      "Content-Type": contentType,
    };
    const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body)) :
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    return requestApiMessage.messageId;
  };
  getCartItemsCount = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {"token": buyertoken, "Content-Type": "application/json" };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiGetcartitemscount = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_order_management/cart_items`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handleClickSimilarAddtoCart = (product: { attributes: { inventory_details: { stock_quantity: number; }; catalogue_variants: { id: number; }[]; }; id: number; }) => {
    if (product.attributes.inventory_details.stock_quantity > 0) {
      this.handleSimilarAddToCart(product.id, product.attributes.catalogue_variants[0]?.id);
    }
  }

  handleCreatorFollow = async (id: number) => {
    const newData = { ...this.state.productDescription };
    if (newData.attributes.account.id === id) {
      newData.attributes = {
        ...newData.attributes,
        account: {
          ...newData.attributes.account,
          is_following_seller: !newData.attributes.account?.is_following_seller // Toggle the is_following_seller flag
        }
      };
    }
    this.setState({ productDescription: newData });

    this.followSellerApiCallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.followSellar,
      body: {
        "data": {
          "attributes": {
            "followable_id": id
          }
        }
      },
      type:""
    })
  };

  getFollowSellerCallback = (responseJson: any) => {
    if (responseJson) {
      this.setState({ loading: false});
      this.getProductDescription()
    }
  }

  handleOnOrderRequestModal = (selectedOrder: OnOrderData) => {
    this.checkTokenPresentOrNot();
    this.setState({ isRequestModal: !this.state.isRequestModal, selectedOnOrder: selectedOrder, selectedReqFiles: [], description: "", descriptionErr: false, imgUploadErr: "" })
  };

  handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: event.target.value, descriptionErr: false })
  };

  toggleOnOrderModal = () => {
    this.setState({ isRequestModal: !this.state.isRequestModal })
  };

  truncatedTitle = (title: string) => {
    if (title.length <= 24) {
      return title;
    }
    return title.substring(0, 24) + ' ...';
  };

  truncatedDescription = (description: string) => {
    if (description.length <= 45) {
      return description;
    }
    return description.substring(0, 45) + ' ...';
  };

  handleRemoveImage = (indexToRemove: number) => {
    const { selectedReqFiles } = this.state;
    const newArr = selectedReqFiles.filter((_, i) => i !== indexToRemove);
    this.setState({ selectedReqFiles: newArr, imgUploadErr: "" })
  };

  handleUploadClick = () => {
    if (this.fileInputRef.current) {
      this.setState({ imgUploadErr: "" });
      this.fileInputRef.current.click();
    }
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const newImages: File[] = Array.from(files);
      const validFiles: File[] = [];
      const invalidFiles: File[] = [];
      let isSizeExceed: boolean = false;

      newImages.forEach((file) => {
        const isDuplicate = this.state.selectedReqFiles.some((selectedFile) => selectedFile.name === file.name && selectedFile.size === file.size);
        if (!validImageTypes.includes(file.type)) {
          invalidFiles.push(file);
        } else if (file.size > 5 * 1024 * 1024) {
          this.setState({ imgUploadErr: "Some files exceed the 5 MB size limit!" });
          isSizeExceed = true;
        } else if (!isDuplicate) {
          validFiles.push(file);
        }
      });

      if (this.state.selectedReqFiles.length + validFiles.length > 4) {
        this.setState({ imgUploadErr: "You can upload a maximum of 4 images only" });
        return;
      }

      if (invalidFiles.length > 0) {
        this.setState({ imgUploadErr: "Only PNG, JPEG, or JPG files are allowed." });
      }

      if (!isSizeExceed && validFiles.length > 0) {
        this.setState((prevState) => ({
          selectedReqFiles: [...prevState.selectedReqFiles, ...validFiles],
        }));
      }
    }
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ expectedDate: date });
  };

  updateOrDeleteItem = (itemQuantity: number, isIncrement: boolean) => {
    if (isIncrement) {
      this.setState({ quantity: itemQuantity + 1 });
    } else if (!isIncrement && itemQuantity > 1) {
      this.setState({ quantity: itemQuantity - 1 });
    }
  };

  handleOnClickSubmit = async () => {
    const { description } = this.state;
    if (description.trim() === "") {
      this.setState({ descriptionErr: true });
      return;
    }
    await this.postOnOrderData();
  };

  postOnOrderData = async () => {
    const { description, quantity, expectedDate, selectedReqFiles, selectedOnOrder } = this.state;
    this.setState({ isRequestModal: false });
    const formdata = new FormData();
    formdata.append("catalogue_id", String(selectedOnOrder?.productId));
    formdata.append("quantity", String(quantity));
    formdata.append("request_detail", description);
    formdata.append("expected_delivery", moment(expectedDate).format("DD/MM/YYYY"));

    selectedReqFiles.length && selectedReqFiles.forEach((file) => {
      formdata.append("attachments[]", file, file.name);
    });

    this.postOnOrderDataApiId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createOnOrderEndPoint,
      body: formdata,
      type: "formData"
    });
  };

  toogleSuccessModal = () => {
    this.setState({ isSuccessModal: !this.state.isSuccessModal });
  };

  createOnOrderSuccessCallBack = (responJson: CreateOnOrderApiRes) => {
    this.setState({ isSuccessModal: true, isRequestModal: false, channelUrl: responJson.channel_url, loading: false }, async () => {
      this.loadOnOrderChannels(responJson.channel_url || '', responJson.data.attributes.id || '');
    });
  };

  loadOnOrderChannels = async (channelURL: string, productId: string) => {
    const grpChnlFilter = new GroupChannelFilter();
    grpChnlFilter.includeEmpty = true;
    const channelCollection = this.sendBird?.groupChannel.createGroupChannelCollection({
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      filter: grpChnlFilter,
    });
    const allChannels = await channelCollection?.loadMore();
    if (allChannels && allChannels.length > 0) {
      let channelIndex = allChannels.findIndex((channel) => channel.url === channelURL);
      this.setState({ channel: allChannels[channelIndex] }, () => {
        this.sendReqMessageSendBird(productId);
      });
    }
  };

  sendReqMessageSendBird = async (productId: string) => {
    const { channel, description, selectedOnOrder, quantity, expectedDate, selectedReqFiles } = this.state;
    if (!channel) return;
    try {
      if (selectedOnOrder) {
        const messageData = JSON.stringify({ productId: productId, title: selectedOnOrder.title, description: description, quantity: quantity, expectedDate: expectedDate, productUrl: `${baseUrl}${selectedOnOrder.productImageUrl}`, onOrderStatus: "pending" })
        const param: UserMessageCreateParams = {
          message: messageData,
          data: JSON.stringify({ delivered: false, read: false }),
          customType: 'on_order',
        };
        channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
          if (sentMessage) {
            const params = {
              customType: 'on_order',
              data: JSON.stringify({ delivered: true, read: false }),
            };
            try {
              await channel.updateUserMessage(sentMessage.messageId, params);
            } catch (error) {
            }
            sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
          }
        });
        if (selectedReqFiles.length > 0) {
          this.handleFileSend();
        }
        this.setState({ selectedOnOrder: null, description: "", quantity: 1, expectedDate: new Date() });
      }
    } catch (error) {
    }
  };

  handleFileSend = async () => {
    const { channel, selectedReqFiles } = this.state;
    if (!channel) return;
    selectedReqFiles.forEach((files) => {
      const fileMessageParams: FileMessageCreateParams = {
        file: files as FileCompat,
        customType: "image",
        data: JSON.stringify({ delivered: false, read: false }),
      }
      channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
        if (sentMessage) {
          const params = {
            customType: "image",
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateFileMessage(sentMessage.messageId, params);
          } catch (error) {
          }
          sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
        }
      });
    })
    this.setState({ selectedReqFiles: [] });
  };

  handleNavigate = (pathName: string, itemId: string | number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), itemId);
    this.send(message);
  };
  // Customizable Area End
}
