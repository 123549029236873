import React from "react";
// Customizable Area Start
import SellerOrderListController, {
    Props
} from "./SellerOrderListController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Paper, Box, Typography, TextField, Tabs, Tab, Button, Grid, Dialog } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { hamburgerIc, noRecord } from "../../landingpage/src/assets";
import { bellIcon } from "../../InventoryManagement3/src/assets";
import { deliveredIc, dispatchedIc, imgCancelOrder, imgOnHoldClock, imgReadyTruck, imgSearch, imgShipedtruck, imgShippingClock, returnedIc, summy, timerImg } from "./assets";
const baseURL = require("../../../framework/src/config.js").baseURL
import TabPanel from "../../../components/src/TabPanel.web";
import StayTuned from "../../../components/src/StayTuned.web";
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination";
import { conditionalRenderer } from "../../../components/src/utils";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
import SellersNotification from "../../landingpage/src/SellersNotification.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        margin: "5em 0em 0em",
        display: 'flex',
        width: '100vw',
        boxShadow: 'none'
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0em 2em",
        alignItems: 'center',
        gap: '12px',
        '@media(max-width: 656px)': {
            padding: '0em 1em'
        },
    },
    iconHamburgerStyle: {
        height: '48px',
        border: '1px solid var(--0, #FFF)',
        width: '48px',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        borderRadius: '10px',
        alignItems: "center",
        background: 'var(--0, #FFF)',
        display: "flex",
        justifyContent: "center",
        cursor: 'pointer',
        '@media(min-width: 991px)': {
            display: 'none',
        },
    },
    iconStyle: {
        height: '48px',
        width: '48px',
        border: '1px solid var(--0, #FFF)',
        borderRadius: '10px',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        background: 'var(--0, #FFF)',
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    titleTxt: {
        color: 'rgba(68, 68, 68, 0.80)',
        fontSize: '20px',
        fontWeight: 500,
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0em 2em',
        gap: '40px',
        '@media(max-width: 656px)': {
            padding: '0em 1em'
        },
    },
    outlinedInputStyle: {
        maxWidth: '482px',
        width: '100%',
        height: '48px',
        fontSize: '14px',
        color: '#44444480',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        borderRadius: '10px',
        '& .MuiOutlinedInput-input': {
            padding: '16px 14px 16px 0px',
            fontSize: '14px',
            color: '#444444CC'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
            border: 'none'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
    },
    styleTabs: {
        "& .MuiTabs-flexContainer": {
            display: 'flex',
            justifyContent: 'space-between',
            width: '542px'
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#F5EA16",
            height: '4px',
            width: '90px !important'
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#555555'
        },
        "& .MuiTab-root": {
            minWidth: '90px'
        },
        borderBottom: '1px solid #44444480',
        '@media(max-width: 697px)': {
            "& .MuiTabs-flexContainer": {
                width: 'auto',
                justifyContent: 'flex-start',
            },
        },
    },
    styleTab: {
        color: '#555555B2',
        fontSize: '16px',
        fontWeight: 500,
        width: '90px',
        whiteSpace: 'nowrap',
        textTransform: 'none'
    },
    tabContainer: {
        padding: "0em 2em",
        '@media(max-width: 656px)': {
            padding: '0em 1em'
        },
    },
    filterBtnContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px'
    },
    filterBtn: {
        width: 'auto',
        height: '29px',
        borderRadius: '20px',
        padding: '4px 20px 4px 20px',
        color: '#444444',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
        border: '1px solid #444444'
    },
    orderBox: {
        border: '1px solid #4444441A',
        maxWidth: '630px',
        width: '100%',
        minHeight: '120px',
        padding: '8px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'flexStart',
        gap: '20px',
        cursor: 'pointer',
        position: 'relative',
        '@media(max-width: 596px)': {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    onOrderBox: {
        // border: '1px solid #4444441A',
        maxWidth: '630px',
        width: '100%',
        minHeight: '120px',
        // padding: '8px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'flexStart',
        gap: '20px',
        cursor: 'pointer',
        position: 'relative',
        '@media(max-width: 596px)': {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    attachments: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 500,
    },
    mainBox:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '15px'
    },
    productDetails:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '15px'
    },
    statusBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '2px 4px',
        borderRadius: '4px',
        gap: '4px'
    },
    statusTxt: {
        color: '#555555B2',
        fontSize: '12px',
        fontWeight: 500,
        whiteSpace: 'nowrap'
    },
    noDataFoundImg: {
        maxWidth: '300px',
        width: '100%',
        MozAnimation: 'fadeIn 2s',
        animation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
        WebkitAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
    },
    noDataImgBox: {
        width: '100%', 
        display: 'flex', 
        height: '50vh', 
        justifyContent: 'center', 
        alignItems: 'center',
        flexDirection:"column"
    },
    orderTitleTxt: {
        color: '#555555',
        fontSize: '16px',
        fontWeight: 500,
        maxWidth: '350px',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
    },
    request: {
        color: '#444444',
        fontSize: '18px',
        fontWeight: 600,
        margin: '10px 0px'
    },
    acceptBtn: {
        color: '#00A912',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    declianBtn: {
        color: '#E46262',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    makeoffer: {
        color: '#444444',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    redDot: {
        height: '12px',
        width: '12px',
        backgroundColor: 'red',
        borderRadius: '50%',
        position: 'absolute',
        top: '0px',
        right: '0px',
        transform: 'translate(50%, -50%)'
    },
    modalTitle: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 500,
    },
    hadleDialog: {
        '& .MuiDialog-container': {
            '& .MuiPaper-root': {
                padding: '30px',
                borderRadius: '15px'
            }
        }
    },
    imageName: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 500,
    },
    imageSize: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 300,
    },
    viewText: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 500,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    textTitle: {
        color: '#444444',
        fontSize: '14px',
        fontWeight: 500
    },
    valueText: {
        color: '#555555',
        fontWeight: 600
    },
    sendMsg:{
        color: '#444444',
        fontSize: '18px',
        fontWeight: 500,
        textDecoration: 'underline',
        cursor: 'pointer'
    }
    
    // Customizable Area End
}

export class SellerOrderList extends SellerOrderListController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderShipmentTxt = (order: any) => {
        const { classes } = this.props;
        return (
            <Typography className={classes.textTitle}>Shipping method:-
                <span className={classes.valueText} >
                    {order.attributes.deliver_by === 'self_pickup' ? `Self-pick up` : `Delivery by flixo`}
                </span>
            </Typography>
        )
    };

    onHoldTxt = (orderStatus: string) => {
        const { classes } = this.props;
        return (
            orderStatus === "on_hold" &&
            <Box className={classes.statusBox} style={{ background: '#9D9D9D1A' }} >
                <img src={imgOnHoldClock} />
                <Typography className={classes.statusTxt}>On hold</Typography>
            </Box>
        )
    };

    onPlacedTxt = (orderStatus: string) => {
        const { classes } = this.props;
        return (
            orderStatus === "placed" &&
            <Box className={classes.statusBox} style={{ background: '#FFD6001A' }}>
                <img src={imgShippingClock} />
                <Typography className={classes.statusTxt}>Pending</Typography>
            </Box>
        )
    };

    onAcceptedTxt = (orderStatus: string, deliverBy: string) => {
        const { classes } = this.props;
        return (
            orderStatus === "accepted" &&
            <Box className={classes.statusBox} style={{ background: '#4BB5431A' }}>
                <img src={imgReadyTruck} />
                {deliverBy === "self_pickup" ?
                    <Typography className={classes.statusTxt}>Ready to pickup</Typography> :
                    <Typography className={classes.statusTxt}>Ready to ship</Typography>}
            </Box>
        )
    };

    onDeliveredTxt = (orderStatus: string) => {
        const { classes } = this.props;
        return (
            (orderStatus === "delivered" ) &&
            <Box className={classes.statusBox} style={{ background: '#4BB5431A' }}>
                <img src={deliveredIc} />
                <Typography className={classes.statusTxt}>Delivered</Typography>
            </Box>
        )
    };

    onDeclinedTxt = (orderStatus: string) => {
        const { classes } = this.props;
        return (
            (orderStatus === "declined" || orderStatus === "cancelled") &&
            <Box className={classes.statusBox} style={{ background: '#FF00001A' }}>
                <img src={imgCancelOrder} />
                <Typography className={classes.statusTxt}>Cancelled</Typography>
            </Box>
        )
    };

    onShippedTxt = (orderStatus: string) => {
        const { classes } = this.props;
        return (
            orderStatus === "shipped" &&
            <Box className={classes.statusBox} style={{ background: '#0066FF1A' }}>
                <img src={imgShipedtruck} />
                <Typography className={classes.statusTxt}>Shipped</Typography>
            </Box>
        )
    };

    inTransitText = (status: string) => {
        const { classes } = this.props;
        return (
            status === "in_transit" &&
            <Box className={classes.statusBox} style={{ background: '#0066FF1A' }}>
                <img src={dispatchedIc} />
                <Typography className={classes.statusTxt}>Dispatched</Typography>
            </Box>
        )
    };

    returntext = (status: string) => {
        const { classes } = this.props;
        return (
            status === "returned" &&
            <Box className={classes.statusBox} style={{ background: '#8F00FF1A' }}>
                <img src={returnedIc} />
                <Typography className={classes.statusTxt}>Returned</Typography>
            </Box>
        )
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { drawerOpen, orderType, statusFilter, searchValue, orderListData, loading, all, cancelled, onHold, pending, shipped, toShip, paginationCount, pageCount } = this.state;

        return (
            <Paper className={classes.root}>
                <div className="dashboard-outer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={undefined}
                            isOpen={drawerOpen}
                            activetabName="orders"
                            closeModal={this.closeDrawer}
                        />
                    </div>
                </div>
                <Loader loading={loading} />
                <Box style={{ paddingTop: "40px", width: '100%', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <Box className={classes.titleContainer}>
                        <Typography className={classes.titleTxt}>Orders</Typography>
                        <Box style={{ display: "flex", gap: '12px' }}>
                            <TextField
                                variant="outlined"
                                className={classes.outlinedInputStyle}
                                placeholder="Search for users..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={imgSearch} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchValue}
                                onKeyPress={this.handleKeyPress}
                                onBlur={this.getOrderList}
                                onChange={this.handleSearchValue}
                            />
                            <SellersNotification handleNotificationBoxToggle={this.state.isNotificationBoxOpen} data-test-id="notification-click" navigation={undefined} />
                            <Box className={classes.iconHamburgerStyle} onClick={this.closeDrawer}>
                                <img src={hamburgerIc} alt="hamburger" />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.tabContainer}>
                        <Tabs
                            value={orderType}
                            onChange={this.handleOrderTypeChange}
                            indicatorColor="primary"
                            textColor="primary"
                            className={classes.styleTabs}
                        >
                            <Tab label="Orders" className={classes.styleTab} />
                            <Tab label="Bargain" className={classes.styleTab} />
                            <Tab label="On-Order" className={classes.styleTab} />
                        </Tabs>
                    </Box>
                    <TabPanel value={orderType} index={0} >
                        <Box className={classes.innerContainer} >
                            <Box className={classes.filterBtnContainer}>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === '' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("")}
                                    data-testid='all'
                                >
                                    All ({all})
                                </Button>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === 'accepted' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("accepted")}
                                    data-testid='ready_to_ship'
                                >
                                    To ship ({toShip})
                                </Button>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === 'on_hold' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("on_hold")}
                                    data-testid='on_hold'
                                >
                                    On hold ({onHold})
                                </Button>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === 'placed' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("placed")}
                                    data-testid='pending'
                                >
                                    Pending ({pending})
                                </Button>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === 'shipped' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("shipped")}
                                    data-testid='shipped'
                                >
                                    Shipped ({shipped})
                                </Button>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === 'declined' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("declined")}
                                    data-testid='decline'
                                >
                                    Cancelled ({cancelled})
                                </Button>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ background: statusFilter === 'returned' ? '#F6F5E0' : 'none' }}
                                    onClick={() => this.handleStatusFilter("returned")}
                                    data-testid='return'
                                >
                                    Returned
                                </Button>
                            </Box>
                            <Box className={classes.filterBtnContainer} style={{ flexDirection: 'column' }}>
                                {this.state.isOrderData && (orderListData.length > 0 ?
                                    orderListData.map((order: any) => (
                                        <Box className={classes.orderBox} onClick={() => this.HandleSingleOrderStatus(order.id)}>
                                            <Box style={{ height: '100px', width: '100px', padding: '8px' }} >
                                                <img src={`${baseURL}${order.attributes.catalogue_variant.attributes.product_images[0].url}`} style={{ height: '90px', width: '90px', borderRadius: '10px' }} />
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                <Box style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                                                    <Typography className={classes.orderTitleTxt} >{order.attributes.catalogue.attributes.title}</Typography>
                                                    {this.onHoldTxt(order.attributes.status)}
                                                    {this.onPlacedTxt(order.attributes.status)}
                                                    {this.onAcceptedTxt(order.attributes.status, order.attributes.deliver_by)}
                                                    {this.onShippedTxt(order.attributes.status)}
                                                    {this.onDeclinedTxt(order.attributes.status)}
                                                    {this.onDeliveredTxt(order.attributes.status)}
                                                    {this.inTransitText(order.attributes.status)}
                                                    {this.returntext(order.attributes.status)}
                                                </Box>
                                                <Typography style={{ color: '#55555580', fontSize: '12px', fontWeight: 400 }}>{order.attributes.order_number}</Typography>
                                                <Typography style={{ color: '#444444', fontSize: '14px', fontWeight: 500 }}>Customer : <span style={{ color: '#555555', fontWeight: 600 }}>{order.attributes.shipment_details?.name}</span> </Typography>
                                                {this.renderShipmentTxt(order)}
                                            </Box>
                                        </Box>
                                    )) :
                                    <Box className={classes.noDataImgBox}>
                                        <img src={noRecord} className={classes.noDataFoundImg} />
                                        <Typography><strong>No Record was Found</strong></Typography>
                                        <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
                                    </Box>)}
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '20px' }}>
                                    <Pagination data-test-id="pagination" count={paginationCount} page={pageCount}variant="outlined" shape="rounded" onChange={this.handleChangePagiation} />
                                </div>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={orderType} index={1}>
                        <Box id='testid1' className={classes.innerContainer} >
                            <Box data-test-id='testid2' className={classes.filterBtnContainer}>
                                <Button
                                    data-testid='allBar'
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("")}
                                    style={{ background: conditionalRenderer(statusFilter === '', '#F6F5E0', 'none' )}}
                                >
                                    All ({all})
                                </Button>
                                <Button
                                    data-testid='ready_to_shipBar'
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("accepted")}
                                    style={{ background: conditionalRenderer(statusFilter === 'accepted' , '#F6F5E0' , 'none' )}}
                                >
                                    To ship ({toShip})
                                </Button>
                                <Button
                                    data-testid='on_holdBar'
                                    style={{ background: conditionalRenderer(statusFilter === 'on_hold' , '#F6F5E0' , 'none' )}}
                                    onClick={() => this.handleStatusFilter("on_hold")}
                                    className={classes.filterBtn}
                                >
                                    On hold ({onHold})
                                </Button>
                                <Button
                                    data-testid='pendingBar'
                                    style={{ background: conditionalRenderer(statusFilter === 'placed' , '#F6F5E0' , 'none' )}}
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("placed")}
                                >
                                    Pending ({pending})
                                </Button>
                                <Button
                                    data-testid='shippedBar'
                                    onClick={() => this.handleStatusFilter("shipped")}
                                    style={{ background: conditionalRenderer(statusFilter === 'shipped' , '#F6F5E0' , 'none' )}}
                                    className={classes.filterBtn}
                                >
                                    Shipped ({shipped})
                                </Button>
                                <Button
                                    onClick={() => this.handleStatusFilter("declined")}
                                    style={{ background: conditionalRenderer(statusFilter === 'declined' , '#F6F5E0' , 'none' )}}
                                    data-testid='declineBar'
                                    className={classes.filterBtn}
                                >
                                    Cancelled ({cancelled})
                                </Button>
                                <Button
                                    style={{ background: conditionalRenderer(statusFilter === 'returned' , '#F6F5E0' , 'none' )}}
                                    data-testid='returnBar'
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("returned")}
                                >
                                    Returned
                                </Button>
                            </Box>
                            <Grid container>
                                <Grid item sm={12} md={7} xs={12} >
                                    <Box className={classes.filterBtnContainer} style={{ flexDirection: 'column', position: "relative" }}>

                                        <Box className={classes.orderBox}>
                                            <Box className={classes.redDot}></Box>
                                            <Box style={{ height: '100px', width: '100px', padding: '8px' }} >
                                                <img src={summy} style={{ height: '90px', width: '90px', borderRadius: '10px' }} />
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                <Box style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                                                    <Typography className={classes.orderTitleTxt} >Loreum Ipsum </Typography>

                                                    <Box className={classes.statusBox} style={{ background: '#9D9D9D1A' }} >
                                                        <img src={imgOnHoldClock} />
                                                        <Typography className={classes.statusTxt}>On hold</Typography>
                                                    </Box>
                                                </Box>
                                                <Typography style={{ color: '#55555580', fontSize: '12px', fontWeight: 400 }}>#1233445</Typography>
                                                <Typography style={{ color: '#444444', fontSize: '14px', fontWeight: 500 }}>Customer : <span style={{ color: '#555555', fontWeight: 600 }}>Rounakk </span> </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={5} xs={12} >
                                    <Typography className={classes.request}>Requests</Typography>
                                    <Box className={classes.filterBtnContainer} style={{ flexDirection: 'column' }}>
                                 
                                                <Box className={classes.orderBox}>
                                                    <Box style={{ height: '100px', width: '100px', padding: '8px' }} >
                                                        <img src={summy} style={{ height: '90px', width: '90px', borderRadius: '10px' }} />
                                                    </Box>
                                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Box style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'end', flexWrap: 'wrap' }}>
                                                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                                                                <Typography className={classes.orderTitleTxt} >Loreum Ipsum </Typography>
                                                                <Typography style={{ color: '#55555580', fontSize: '12px', fontWeight: 400 }}>#1233445</Typography>
                                                            </div>
                                                            <div>
                                                                <img src={timerImg} alt="timerImg" />
                                                            </div>
                                                        </Box>
                                                        <Typography style={{ color: '#555555', fontWeight: 600 }}>Customer name has made an offer for this product</Typography>
                                                        <Typography style={{ color: '#444444', fontSize: '14px', fontWeight: 500 }}><span style={{ color: '#555555', fontWeight: 600 }}>$ 250 </span> </Typography>

                                                        <Box style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                                                            <Button className={classes.acceptBtn}>Accept</Button>
                                                            <Button className={classes.declianBtn}>Decline</Button>
                                                            <Button className={classes.makeoffer}>Make an offer</Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                        
                                    </Box>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '20px' }}>
                                <Pagination data-test-id="paginationBar" count={paginationCount} page={pageCount} variant="outlined" shape="rounded" onChange={this.handleChangePagiation} />
                            </div>
                        </Box>
                    </TabPanel>
                    <TabPanel value={orderType} index={2}>
                        <Box id='testid1' className={classes.innerContainer} >
                            <Box data-test-id='testid2' className={classes.filterBtnContainer}>
                                <Button
                                    data-testid='allOnorder'
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("")}
                                    style={{ background: conditionalRenderer(statusFilter === '', '#F6F5E0', 'none') }}
                                >
                                    All ({all})
                                </Button>
                                <Button
                                    data-testid='ready_to_shipOnorder'
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("accepted")}
                                    style={{ background: conditionalRenderer(statusFilter === 'accepted', '#F6F5E0', 'none') }}
                                >
                                    To ship ({toShip})
                                </Button>
                                <Button
                                    data-testid='on_holdOnorder'
                                    style={{ background: conditionalRenderer(statusFilter === 'on_hold', '#F6F5E0', 'none') }}
                                    onClick={() => this.handleStatusFilter("on_hold")}
                                    className={classes.filterBtn}
                                >
                                    On hold ({onHold})
                                </Button>
                                <Button
                                    data-testid='pendingOnorder'
                                    style={{ background: conditionalRenderer(statusFilter === 'placed', '#F6F5E0', 'none') }}
                                    className={classes.filterBtn}
                                    onClick={() => this.handleStatusFilter("placed")}
                                >
                                    Pending ({pending})
                                </Button>
                                <Button
                                    data-testid='shippedOnorder'
                                    onClick={() => this.handleStatusFilter("shipped")}
                                    style={{ background: conditionalRenderer(statusFilter === 'shipped', '#F6F5E0', 'none') }}
                                    className={classes.filterBtn}
                                >
                                    Shipped ({shipped})
                                </Button>
                                <Button
                                    onClick={() => this.handleStatusFilter("declined")}
                                    style={{ background: conditionalRenderer(statusFilter === 'declined', '#F6F5E0', 'none') }}
                                    data-testid='declineOnorder'
                                    className={classes.filterBtn}
                                >
                                    Cancelled ({cancelled})
                                </Button>
                                <Button
                                    style={{ background: conditionalRenderer(statusFilter === 'returned', '#F6F5E0', 'none') }}
                                    onClick={() => this.handleStatusFilter("returned")}
                                    data-testid='returnOnorder'
                                    className={classes.filterBtn}
                                >
                                    Returned
                                </Button>
                            </Box>
                            <Grid container>
                                <Grid item sm={12} md={7} xs={12} >
                                    <Box style={{ position: "relative",flexDirection: 'column' }} className={classes.filterBtnContainer}>
                                        <Box id="testp1" className={classes.orderBox}>
                                            <Box data-test-id='testp2' className={classes.redDot}></Box>
                                            <Box style={{ height: '100px', width: '100px', padding: '8px' }} >
                                                <img style={{ height: '90px', width: '90px', borderRadius: '10px' }} src={summy} />
                                            </Box>
                                            <Box style={{ display: 'flex',gap: '8px', flexDirection: 'column' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap', gap: '8px'  }}>
                                                    <Typography id='testp3' className={classes.orderTitleTxt} >Loreum Ipsum </Typography>
                                                    <Box style={{ background: '#9D9D9D1A' }} className={classes.statusBox} >
                                                        <img src={imgOnHoldClock} alt="clockImg" />
                                                        <Typography data-test-id="onHold" className={classes.statusTxt}>On hold</Typography>
                                                    </Box>
                                                </Box>
                                                <Typography style={{  fontSize: '12px', fontWeight: 400,color: '#55555580' }}>#1233445</Typography>
                                                <Typography style={{  fontSize: '14px', fontWeight: 500,color: '#444444' }}>Customer : <span style={{ color: '#555555', fontWeight: 600 }}>Rounakk </span> </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={5} xs={12} >
                                    <Typography className={classes.request}>Requests</Typography>
                                    <Loader loading={this.state.loadingRequest} />
                                    <Box style={{ flexDirection: 'column' }} className={classes.filterBtnContainer} >
                                        {this.state.onOrderListData.map((order: any) => (
                                            <Box className={classes.orderBox}>
                                                <Box style={{ height: '100px', width: '100px', padding: '8px' }} >
                                                    <img src={`${baseURL}${order.attributes.catalogue.data.attributes.product_images[0].url}`} style={{ height: '90px', width: '90px', borderRadius: '10px' }} />
                                                </Box>
                                                <Box style={{ width: '100%',display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                    <Box style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'flexStart', flexWrap: 'wrap' }}>
                                                        <div style={{ display: 'flex', gap: '8px', alignItems: 'flexStart', flexWrap: 'wrap' }}>
                                                            <Typography className={classes.orderTitleTxt} >{order.attributes.catalogue.data.attributes.title}</Typography>
                                                            <Typography style={{ color: '#55555580', fontSize: '12px', fontWeight: 400 }}>#1233445</Typography>
                                                        </div>
                                                        <div>
                                                            <img src={timerImg} alt="timerImg" />
                                                        </div>
                                                    </Box>
                                                    <Typography style={{ color: '#555555', fontWeight: 600 }}>{order.attributes.catalogue.data.attributes.description}</Typography>
                                                    <Typography data-test-id="seeAllData" onClick={()=> this.handleOpen(order.id)} style={{ color: '#444444', fontSize: '14px', fontWeight: 500, marginTop: '30px' }}><span style={{ color: '#555555', fontWeight: 600 }}> </span>See all details</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '20px' }}>
                                <Pagination data-test-id="paginationOnorder" count={paginationCount} page={pageCount} variant="outlined" shape="rounded" onChange={this.handleChangePagiation} />
                            </div>
                        </Box>
                    </TabPanel>

                    {/* Modal for onOrder details */}
                    <Dialog style={{ padding: '30px'}} className={classes.hadleDialog} onClose={this.handleClose} PaperProps={{
                        elevation: 0,
                    }} aria-labelledby="customized-dialog-title" open={this.state.onOrderModal}>
                        <Typography className={classes.modalTitle}>Order details</Typography>

                        <Box className={classes.filterBtnContainer} style={{ flexDirection: 'column', margin: '20px 0px' }}>
                            <Box className={classes.onOrderBox}>
                                <Box style={{ height: '100px', width: '100px', padding: '8px' }} >
                                    <img src={`${baseURL}${this.state.particularOnOrderListData.attributes.catalogue.data.attributes.product_images[0].url}`} style={{ height: '90px', width: '90px', borderRadius: '10px' }} />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                    <Box style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'end', flexWrap: 'wrap' }}>
                                        <div style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
    
                                            <Typography className={classes.orderTitleTxt} >{this.state.particularOnOrderListData.attributes.catalogue.data.attributes.title}</Typography>
                                            <Typography style={{ color: '#55555580', fontSize: '12px', fontWeight: 400 }}>{this.state.particularOnOrderListData.attributes.id}</Typography>
                                        </div>
                                    </Box>
                                    <Typography style={{ color: '#555555', fontWeight: 600 }}>Customer name : {this.state.particularOnOrderListData.attributes.account.full_name}</Typography>
                                    <Typography style={{ color: '#444444', fontSize: '14px', fontWeight: 500 }}><span style={{ color: '#555555', fontWeight: 600 }}>{this.state.particularOnOrderListData.attributes.catalogue.data.attributes.title}</span> </Typography>
                                    <Typography style={{ color: '#444444', fontSize: '14px', fontWeight: 500 }}>Expected Delivery : <span style={{ color: '#555555', fontWeight: 600 }}>{this.formatDate(this.state.particularOnOrderListData.attributes.expected_delivery)}</span></Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Typography className={classes.attachments}>Attachments</Typography>
                        
                        {
                          this.state.particularOnOrderListData.attributes.attachments ?
                            this.state.particularOnOrderListData.attributes.attachments.map((item, index) => 
                                (<div className={classes.mainBox}>
                                    <div className={classes.productDetails}>
                                        <div><img style={{ height: '28px', width: '28px' }} src={item.url} alt="dummyImg" /></div>
                                        <div className={classes.imageName}>image.png <br /><span className={classes.imageSize}>457 KB</span></div>
                                    </div>
                                    <div>
                                        <a href={item.url} target="blank" className={classes.viewText}>View</a>
                                    </div>
                                </div>)
                            ) : 
                            <div>No attachments attach</div>
                        }
                       

                        <div style={{ textAlign: 'end', marginTop: '20px'}}>
                            <Typography onClick={()=>this.handleNavigation()} data-test-id='sendMessage' className={classes.sendMsg}>Send a message</Typography>
                        </div>
                    </Dialog>
                </Box>
                <LoginFailModalWeb data-test-id="sellerModal" displayPopup={!this.state.isSellerVerified} closeErrorPopup={this.handleCloseModal} errorMessage={"Your account is not verifed by admin!"} />
            </Paper >
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(SellerOrderList);
// Customizable Area End
