Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.buyerProfileAPIEndpoint = "account_block/get_buyer_profile"
exports.apiEndPointGetCountryCodes = "account/accounts/country_code_and_flag";
exports.apiGetCountryCodesType = "GET";
exports.buyerProfileAddressAPIEndpoint = "bx_block_address/address"
exports.sellerProfileDataAPIEndpoint = "account_block/get_seller_profile"
exports.sellerUpdateBankDataAPIEndpoint = "bx_block_custom_form/bank_detail"
exports.sellerUpdateBusinessDataAPIEndpoint = "bx_block_profile/business_details"
exports.sellerLiveStreamAPIEndpoint = "bx_block_livestreaming/live_streams"
exports.sellerTopProductAPIEndpoint = "bx_block_catalogue/catalogues/get_seller_catalogues"
exports.sellerUpdateProfileAPIEndPoint = "account_block/update_seller_profile"
exports.buyerUpdateProfileAPIEndPoint = "account_block/update_buyer_profile"
exports.updateEmailAPIEndPoint = "account/accounts/email_confirmation"
exports.updateMobileAPIndPoint = "account/accounts/sms_confirmation"
exports.updatePasswordAPIEndPoint = "bx_block_profile/passwords"
exports.updateDropOffAddressAPIEndPoint = "bx_block_address/drop_off_address"
exports.updateShipmentTypeAPIEndPoint = "account_block/accounts/shipment_type"
exports.updateSameDroppOffSwitchAPIEndPoint = "account_block/accounts/same_drop_address"
exports.apiEndPointUpdateBuyerProfile = "account_block/update_buyer_profile"
exports.bankListAPIEndPoint = "bx_block_custom_form/get_banks"
exports.getSubCategoryAPIEdPoint = "bx_block_categories/sub_categories/for_you_sub_categories"
exports.getSubCategoryDataAPIEdPoint = "bx_block_advanced_search/search?type=products&sub_category_ids[]="
exports.putApiMothod = "PUT"
exports.favouriteItemEndPoint = "bx_block_favourite/favourites"
exports.delete = "DELETE"
exports.countryList = "bx_block_address/get_country_list"
exports.stateList = "bx_block_address/get_state_list"
exports.cityList = "bx_block_address/get_city_list"
exports.orderList = "bx_block_order_management/order_items"
exports.verifyBankDetailsEndpoint = "bx_block_custom_form/verify_bank_detail"
exports.resendBankOtp = "bx_block_custom_form/resend_otp"
exports.getAllNotifications = "bx_block_notifications/notifications";
exports.readAllNotifications = "bx_block_notifications/notifications/read_all";
exports.addUPIEndPOint = "account_block/add_upi?upi_id="
exports.profileQrEndpoint = "account_block/accounts/store_qrcode"
exports.btnExampleTitle = "CLICK ME";
exports.invalidUrl = "Invalid data URL format";
exports.getAllStoreEndPoint = "bx_block_address/store_addresses"
exports.getCreatorMarketPlaceEndPoint = "account_block/accounts/creator_market_place";
exports.getAffiliateMarketPlaceEndPoint = "bx_block_catalogue/catalogues/affiliated_product";
exports.getFollowersEndPoint = "bx_block_followers/follows/followers";
exports.getFollowingEndPoint = "bx_block_followers/follows";
exports.postGenerateAffiliateLinkEndPoint = "bx_block_profile/affiliated_products"
exports.postPostCreationEndPoint = "bx_block_posts/posts";
exports.otpError = "Please Enter Valid OTP";
exports.emailError = "Enter valid email i.e. johndoe@gmail.com";
exports.phoneError = "Please Enter Valid Mobile number";
exports.errorPasswordNotValid = "Password must contain 1 Uppercase, 1 Lowercase, 1 number & 1 special character.";
exports.errorPassword = "Please Enter Valid Password"
exports.confirmPwdError = "New Password & Re-enter New Password should be same";
exports.getInventoryEndPoint = "bx_block_catalogue/catalogues/get_seller_catalogues";
exports.getCreatorInventryEndPoint = "bx_block_catalogue/catalogues/catalogues_for_creator_post";
exports.bankAccNumError = "Please Enter Valid Bank Account minimum 9 digit Number";
exports.bankAccHoldNameError = "Please Enter Valid Bank Account Holder Name";
exports.bankNameError = "Please Enter Valid Bank Name";
exports.bankAccountIfscCodeError = "Please Enter Valid Bank Account IFSC Code";
exports.addressline1Error = "Please Enter Valid Address Line One";
exports.addressline2Error = "Please Enter Valid Address Line Two";
exports.pincodeError = "Please Enter Valid Pincode";
exports.cityError = "Please Enter Valid City";
exports.stateError = "Please Enter Valid State";
exports.countryError = "Please Enter Valid Country";
exports.pickUpaddressline1Error = "Please Enter Valid Address Line One";
exports.pickUpaddressline2Error = "Please Enter Valid Address Line Two";
exports.pickUppincodeError = "Please Enter Valid Pincode";
exports.pickUpcityError = "Please Enter Valid City";
exports.pickUpstateError = "Please Enter Valid State";
exports.pickUpcountryError = "Please Enter Valid Country";
exports.dropOffaddressline1Error = "Please Enter Valid Address Line One";
exports.dropOffaddressline2Error = "Please Enter Valid Address Line Two";
exports.dropOffpincodeError = "Please Enter Valid Pincode";
exports.dropOffcityError = "Please Enter Valid City";
exports.dropOffstateError = "Please Enter Valid State";
exports.dropOffcountryError = "Please Enter Valid Country";
exports.businessLegalGstPanError = "Please Enter Valid GST or PAN number";
exports.businessLegalEmailError = "Please Enater Valid Business Email i.e. johndoe@gmail.com";
exports.businessLegalBusinessLicenceError = "Please Enter Valid Business Licence Number";
exports.businessLegalDocumentError = "Please Provide Valid Business Profile Photo"
exports.profileBioError = "Please enter valid profile bio"
exports.profileUsernameError = "Please enter valid profile username"
exports.profileURLsError = "Please enter valid URL's";
exports.AddressError = "Please enter valid address"
exports.validImages = "Please upload a valid image(.jpeg,.png,.jpg)"
exports.searchTagUserApiEndPoint = "bx_block_posts/posts/tag_list";
exports.updateEmailError = "Email has already been taken"
exports.updateOTPError = "Please Enter Valid OTP"
exports.updateMobileError = "Invalid or Unrecognized Phone Number"
exports.updateUsernameError = "User Name already has been taken"
exports.bankingDetails = "Enter your banking details to verify and update"
exports.otpForRegisteredNumber = "Enter the OTP you got in your 10 digit mobile number"
exports.sendCodeAgain = "Send the code again"
exports.addUpiHelperText = "Looks like you don't have a UPI ID linked yet. Let's add one now"
exports.upi = "UPI"
exports.upiID = "UPI Id"
exports.copyId = "Copy ID"
exports.copy ="Copy"
exports.share ="Share"
exports.addNewUpiID = "Add new UPI ID"
exports.verifyAndSave  = "Verify & Save"
exports.verifyText = "Securely save UPI ID for future use"
exports.pleaseEnterValid = "Please Enter valid UPI ID"
exports.profileQrCode = "Profile QR Code"
exports.generate = "Generate"
exports.download = "Download"
exports.someThingWentWrong = "'Oops, something went wrong!'"
exports.view = "View"


exports.getFollowSellarListEndPoint = "bx_block_followers/follows";
exports.unFollowSellarEndPoint = "bx_block_followers/follows";
exports.followUserEndPoint="bx_block_followers/follows";
exports.viewStoryEndPoint = "bx_block_posts/stories/user_stories?";
exports.postLikeUnlikeEndPoint = "bx_block_like/likes";
exports.sellerpostapiEndpoint = "bx_block_posts/posts?page=1&per_page=5"
exports.followSellar = "bx_block_followers/follows";
exports.sellerFollowers = "bx_block_followers/follows/followers"
exports.creatorProfileEndPoint = "account_block/accounts/get_creator_profile";
exports.categoryEndpoint = "bx_block_categories/categories";
exports.getAccountCityEndPoint = "account_block/accounts/get_profile_city";
exports.commentOnStory = "bx_block_comments/story_comments/comment_on_story";
// Customizable Area End

exports.postCommentEndPoint = "bx_block_comments/comments/comment_on_post";
exports.getPostCommentListEndPoint = "bx_block_comments/comments/list_of_post_comments";
exports.postCommentOnCommentEndPoint = "bx_block_comments/comments/comment_on_post_comment";
exports.likeCommentEndPoint = "bx_block_like/likes/like_dislike_post_comments";
exports.postCreateServiceEndPoint = "bx_block_profile/service_offers";
exports.getAllServices = "bx_block_profile/service_offers/";
exports.creatorProfileEndPoint = "account_block/accounts/get_creator_profile";
exports.updateCreatorProfileApi = "account_block/accounts/update_creator_profile_detail";
exports.createContentType = "multipart/form-data";
exports.getCreatorMallEndPoint = "bx_block_datastorage/creator_malls/my_creator_mall";
exports.getCreatorDetailsEndPoint = "account_block/accounts/get_creator_details?id=";
exports.getBargainOrOnOrderEndPoint = "bx_block_productdescription/bargain_and_on_order_request_list";
exports.getUserProfileEndPoint = "account_block/accounts/get_account_profile"
exports.create="Create";
exports.post="Create Posts";
exports.story="Create a Story";
exports.categoriesTxt = "Categories";
exports.feedTxt = "Feed";
exports.validationNumberRegex=/^-?\d+(?:\.\d*)?$/
exports.clearAllCheck = "Clear all check";
exports.addVariant = "Add Variant";
exports.add = "Add";
exports.noInventoryTxt = "No inventory found.";
exports.selectProduct = "Select the variant related to the Story";
exports.doneTxt = "Done";
exports.noInventoryTxt = "No inventory found.";
exports.addToStroy = "Add to story";
exports.uploadStoryTxt = "Upload Story";
exports.upload = "Upload";
exports.here = "here";
exports.share = "Share";
exports.tagPeople = "Tag people";
exports.addProducts = "Add product";
exports.storyUploadedTitle = "Story uploaded";
exports.storyUploaded = "Your story has been uploaded successfully";
exports.storySwitchTitles = {
  explicitContent: "Activate messaging",
  muteWords: "Turn this on if you want to get messages from people regarding this product.",
};
exports.limitExceeds = "File size exceeds the 20MB limit. Please upload a smaller video.";
exports.showVideoWarning = "The uploaded video is {duration} seconds long and will be sliced into multiple stories of a maximum size of 30 seconds each.";
exports.validateMessageForImage = "Image size should be less than or equal to 5MB";
exports.validateMessageForVideo = "Video size should be less than or equal to 5MB";
exports.createStoryEndPoint = "bx_block_posts/stories";
exports.wentWrong = "Something went wrong";
exports.searchEndPoint = "bx_block_posts/posts/tag_list?role=all&search={userName}";
