// Customizable Area Start
import { createRef, RefObject } from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
const navigation = require('react-navigation');
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    activeStep: number
    isOpen: boolean
    isEdit: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class CreateMallController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    rootNewProductRef: RefObject<HTMLDivElement>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.rootNewProductRef = createRef<HTMLDivElement>();
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            activeStep: 0,
            isOpen: true,
            isEdit: false
            // Customizable Area End
        };

    }

    // Customizable Area Start

    async componentDidUpdate() {
        this.rootNewProductRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    };

    async componentWillUnmount() {
        localStorage.removeItem('activeStep');
        removeStorageData("mallId")
    };

    async componentDidMount() {
        const storedStep = await getStorageData('activeStep');
        const currentStep = storedStep ? parseInt(storedStep, 10) : 0;
        this.setState({ activeStep: currentStep });

        const pathName = window.location.pathname.split("/")[1];

        this.setState({
            isEdit: pathName.toLowerCase() === "updatemall"
        })

    };

    handleActiveStep = (step: number) => {
        this.setState({ activeStep: step })
        localStorage.setItem('activeStep', step.toString());
    };

    handleBackNavigation = async () => {
        const { activeStep } = this.state
        const currentStep = await getStorageData("activeStep")
        const updateActiveStep = currentStep - 1
        if (activeStep === 0) {
            history.back();
        }
        else {
            localStorage.setItem("activeStep", updateActiveStep.toString());
            this.setState({ activeStep: updateActiveStep });
        }

    };

    toggleSideDrawer = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };
    // Customizable Area End
}