Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfflixoolivewallet0";
exports.labelBodyText = "cfflixoolivewallet0 Body";
exports.selfWithdrawEndPoint = "bx_block_order_management/self_withdraw";
exports.selfDepositEndPoint = "bx_block_order_management/self_deposit?amount=100&reference_id=Ref2222";
exports.selfWalletEndPoint = "bx_block_order_management/self_wallet";
exports.transactionEndPoint = "bx_block_order_management/wallet/transactions";
exports.createDepositeOrder = "bx_block_order_management/create_deposit_order";
exports.getTransactionStatusEndPoint = "bx_block_order_management/cashfree_wallets/confirm_deposit"

exports.btnExampleTitle = "CLICK ME";
exports.amountRegex = /^(?!0\d)\d*(\.\d{0,4})?$/
// Customizable Area End