import React from "react";
// Customizable Area Start
import { IconButton, Badge } from "@material-ui/core";
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { calendarIc, notificationIcon, hamburgerIc, warningxIcon } from "./assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover'
import SellerNotificationModal from "../../../components/src/SellerNotificationModal.web";
import CreateServiceController, { Props } from "./CreateServiceController.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End


export default class CreateService extends CreateServiceController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div service-create-main-div">
                <Loader loading={this.state.isLoading} />
                <div className="dashboard-outer" data-test-id="creatorProfileMainContainer">
                        <div className="right-outer">
                            <div className="right-inner">
                                <div className="title-wrap">
                                    <div className="left-wrap">
                                        <span className="title">Create Service</span>
                                    </div>
                                </div>
                                {
                                    this.state.serviceCount?.map((card, index) => {
                                        return (
                                            <div className="service-card" key={index}>
                                                <button
                                                    className="service-card__heading"
                                                    disabled={this.state.serviceCount.length <= 1}
                                                    data-test-id={"deleteServiceModal" + index}
                                                    onClick={() => this.deleteService(index)}
                                                >
                                                    Delete
                                                </button>
                                                <div className="service-card__title">
                                                    <div className="service-card__label">Title*</div>
                                                    <input required={true} className="service-card__input" type="text" name="title" data-test-id={"titleinputTestId" + index} onChange={this.handleTitle(index)} value={card.title} />
                                                    {this.state.titleError && <div className="error-message">{this.state.titleError}</div>}
                                                </div>
                                                <div className="service-card__description">
                                                    <div className="service-card__label">Description*</div>
                                                    <textarea className="service-card__input" style={{resize: "none"}} name="description" maxLength={100} data-test-id={"textInputTestId" + index} onChange={this.handleDescription(index)} value={card.description}></textarea>
                                                    <div className="error-message-container">
                                                        {this.state.descriptionError ? <div className="error-message">{this.state.descriptionError}</div>:<div/>}
                                                        <span>{card.description?.length}/100</span>
                                                    </div>
                                                </div>

                                                <div className="service-card__input input__inc__root">
                                                    <div>No posts</div>
                                                    <div className="input__inc">
                                                        <button type="button" className="input__inc__dec" data-test-id="postDecrementCount" onClick={() => this.handleDecreasePostCount(index)}>-</button>
                                                        <input type="number" value={card.postCount.toString().padStart(2, '0')} data-test-id="postChangeTestId" onChange={this.handlePostChange(index)} />
                                                        <button type="button" className="input__inc__inc" data-test-id="postIncrementCount" onClick={() => this.handleIncreasePostCount(index)}>+</button>
                                                    </div>
                                                </div>

                                                <div className="service-card__input input__inc__root">
                                                    <div>No stories</div>
                                                    <div className="input__inc">
                                                        <button type="button" className="input__inc__dec" data-test-id="storiesDecrementCount" onClick={() => this.handleDecreaseStoriesCount(index)}>-</button>
                                                        <input type="number" value={card.storiesCount.toString().padStart(2, '0')} data-test-id="storiesChangeTestId" onChange={this.handleStoriesChange(index)} />
                                                        <button type="button" className="input__inc__inc" data-test-id="storiesIncrementCount" onClick={() => this.handleIncreaseStoriesCount(index)}>+</button>
                                                    </div>
                                                </div>

                                                <div className="service-card__input input__inc__root">
                                                    <div>No life collaboration</div>
                                                    <div className="input__inc">
                                                        <button type="button" className="input__inc__dec" data-test-id="collaborationDecrementCount" onClick={() => this.handleDecreasCollaborationCount(index)}>-</button>
                                                        <input type="number" value={card.collaborationCount.toString().padStart(2, '0')} data-test-id="collaborationChangeTestId" onChange={this.handleCollaborationChange(index)} />
                                                        <button type="button" className="input__inc__inc" data-test-id="collaborationIncrementCount" onClick={() => this.handleIncreaseCollaborationCount(index)}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {
                                    (!this.state.serviceId || this.state.serviceLenght < 5) && (
                                        <>
                                            <hr />

                                            <button
                                                type="button"
                                                disabled={(this.state.serviceCount.length === 5) || (this.state.serviceLenght === 5)}
                                                data-test-id="addServiceTestId"
                                                className="button-clear"
                                                onClick={this.addNewService}
                                            >
                                                Add Service (up to 5) +
                                            </button>

                                            {
                                                (this.state.serviceCount.length === 5  || this.state.serviceLenght === 5) && (
                                                    <div className="card--warning">
                                                        <img src={warningxIcon} alt="warning icon" />
                                                        <div>You can't add more than 5 services, delete one, and try again</div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }

                                <button
                                    type="button"
                                    data-test-id="saveorUpdateServiceTestId"
                                    className="button-create-active"
                                    onClick={this.handleCreateService}
                                >
                                    {this.state.serviceId ? "Update" : "Save"}
                                </button>
                            </div>
                        </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End