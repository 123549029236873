// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const navigation = require("react-navigation");
import { SwitchClassKey, SwitchProps } from "@material-ui/core";

interface NotificationSettingsResponse {
    notifications_settings: NotificationSettings;
}

interface NotificationSettings {
    id: number;
    push_notification: boolean;
    email_notification: boolean;
    account_id: number;
    created_at: string;
    updated_at: string;
    enable_all_notifications: boolean;
}

interface ApiPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
    newToken?: string;
}

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
};

export interface IosProps extends SwitchProps {
  classes: Styles;
};
// Customizable Area End


export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    allNotification: boolean;
    emailNotification: boolean;
    pushNotification: boolean;
    // Customizable Area End
}

interface SS {
    id: string | number;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserSettingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getNotificationApiCallId: string = "";
    updateNotificationApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            allNotification: false,
            emailNotification: false,
            pushNotification: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (responseJson) {
                if (apiRequestCallId === this.getNotificationApiCallId) {
                    this.getNotificationResponse(responseJson)
                }
            }
        }
    };

    async componentDidMount() {
        this.getNotification();
    };

    handleOnOff = (fieldName: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const updatedState: Partial<S> = { [fieldName]: checked };
      
        if (fieldName === "allNotification") {
          updatedState.emailNotification = checked;
          updatedState.pushNotification = checked;
        } else if (!checked) {
          updatedState.allNotification = false;
        } else if (checked && (fieldName === "emailNotification" || fieldName === "pushNotification")) {
          const otherFieldName = fieldName === "emailNotification" ? "pushNotification" : "emailNotification";
          if (this.state[otherFieldName]) {
            updatedState.allNotification = true;
          }
        }
      
        this.setState(updatedState as Pick<S, keyof S>, () => {
          this.updateNotification();
        });
      };
    apiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type, newToken } = apiReqData;
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": contentType,
            token: newToken ? newToken : token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        body && type != 'formData' ?
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getNotification = async () => {
        this.getNotificationApiCallId = await this.apiCall({
            contentType: "application/json",
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getNotificationSettingEndPoint
        })
    };

    updateNotification = async () => {
        const { emailNotification, pushNotification } = this.state;
        const formData = new FormData()
        formData.append(`push_notification`, pushNotification + "");
        formData.append(`email_notification`, emailNotification + "");
        this.updateNotificationApiCallId = await this.apiCall({
            method: configJSON.exampleAPiMethod,
            endPoint: configJSON.notificationsSettingsEndpoint,
            type: "formData",
            body: formData
        })
    };

    getNotificationResponse = (responseJson: NotificationSettingsResponse) => {
        if (responseJson.notifications_settings) {
            let innerOb = responseJson.notifications_settings
            this.setState({
                allNotification: innerOb.push_notification && innerOb.email_notification,
                emailNotification: innerOb.email_notification,
                pushNotification: innerOb.push_notification
            });
        }
    };
    // Customizable Area End
}
