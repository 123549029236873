import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  data: object | Array<any>;
  meta: object;
};

interface ErrorItem {
  [key: string]: string; 
}

interface CategoriesRes {
    attributes: {
      id: number,
      name: string
    }
}

export interface CreatorsProfile {
  id: string;
  type: string,
  attributes: {
    activated: boolean,
    qr_generated: boolean,
    country_code: string,
    email: string,
    upi_id: string | null,
    full_name: string,
    user_name: string,
    full_phone_number: string,
    phone_number: string,
    type: null | string,
    role: {
      id: number,
      name: string,
      created_at: string,
      updated_at: string,
      page_names: []
    },
    updated_mobile_number: null | string,
    updated_email: null | string,
    gender: null | string,
    instagram_url: null | string,
    youtube_url: null | string,
    category: [],
    profile_photo: null | string,
    background_photo: null | string,
    marketing_price: number,
    followers: number,
    address: {
      data: {
        attributes: {
          city: string,
          country: string,
        }
      }
    },
    qr_code: null | string,
    sendbird_credentials: null
  }
};

export interface SnackBarIn {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
};
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  creatorMarketData : Array<CreatorsProfile>;
  snackBarStates: SnackBarIn;
  appLoader: boolean;
  gender: string;
  location: string;
  followers: string;
  minFollowers: number;
  maxFollowers : number | null;
  price: string;
  minPrice: number;
  maxPrice : number | null;
  speciality: number | null;
  isGenderOpen: boolean;
  anchorEl: HTMLElement | null;
  categoriesData : Array<CategoriesRes>;
  locationData: string | Array<string>;
  isCreatorResponse : boolean;
  searchQuery: string;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatorMarketPlaceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCreatorMarketPlaceApiCallId : string = "";
  getCategoriesApiCallId  : string = "";
  getLocationDataApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: true,
      creatorMarketData : [],
      snackBarStates: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      appLoader: false,
      gender: "",
      location: "",
      followers: "",
      minFollowers: 0,
      maxFollowers : null,
      price: "",
      minPrice: 0,
      maxPrice : null,
      speciality: null,
      isGenderOpen: false,
      anchorEl : null,
      categoriesData: [],
      locationData: "",
      isCreatorResponse: false,
      searchQuery: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.getCreatorMarketPlaceApiCallId) {
          this.getMarketPlaceApiResponse(responseJson);
        } else if(apiRequestCallId === this.getCategoriesApiCallId){
          this.handleCategoryResponse(responseJson);
        } else if(apiRequestCallId === this.getLocationDataApiCallId){
          this.handleLocationResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getAllCreatorData();
    this.getCategories();
    this.getLocationFilterData();
  };

  rangeFollowerFilter = [
    { label: "None", minFollowers: 0, maxFollowers: null },
    { label: "1-500", minFollowers: 1, maxFollowers: 500 },
    { label: "500-1000", minFollowers: 500, maxFollowers: 1000 },
    { label: "1000+", minFollowers: 1000, maxFollowers: Infinity }, 
  ];

  rangePriceFilter = [
    { label: "None", minPr: 0, maxPr: null },
    { label: "500-1000", minPr: 500, maxPr: 1000 },
    { label: "1000-10000", minPr: 1000, maxPr: 10000 },
    { label: "10000-50000", minPr: 10000, maxPr: 50000 }, 
    { label: "50000+", minPr: 50000, maxPr: Infinity }, 
  ];

  navigateToPath = (destinationPath: string, creatorId?: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), destinationPath);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), creatorId);
    this.send(message);
  };

  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  getMarketPlaceApiResponse = (responseJson: { data: Array<CreatorsProfile>} & {errors: Array<ErrorItem> }) => {
    this.setState({ appLoader: false });
    if (responseJson.data) {
      this.setState({ creatorMarketData: responseJson.data, isCreatorResponse: true });
    } else if (responseJson?.errors) {
      this.setState({creatorMarketData : [], isCreatorResponse: true})
      this.handleAllErrors(responseJson);
    }
  };

  handleCategoryResponse = (responseJson: { data: [{ attributes: { id: number, name: string } }]; errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      this.setState({ categoriesData: responseJson.data });
    } else if (responseJson.errors) {
      this.handleAllErrors(responseJson);
    }
  };

  handleLocationResponse = (responseJson: { data: { location: string }, errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      this.setState({ locationData: responseJson.data.location, location: responseJson.data.location });
    } else if (responseJson.errors) {
      this.handleAllErrors(responseJson);
    }
  };

  handleAllErrors = (responseJson: { errors: Array<ErrorItem> }) => {
    let error = responseJson.errors[0];
    let message = error?.token ? error?.token : error?.message;
    this.setState({
      snackBarStates: {
        isOpen: true,
        isSeverity: "error",
        isMessage: message ? message : "Something went wrong"
      }
    });
  };

  apiCall = async (apiReqData: {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type, } = apiReqData;
    const token = await getStorageData("singupLogin");
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    return requestApiMessage.messageId;
  };

  getAllCreatorData = async () => {
    const { gender, minFollowers, maxFollowers, minPrice, maxPrice, speciality, location, searchQuery } = this.state;
    let genderfltr = (gender !== "" && gender !== "None") ? `&gender=${gender}` : '';
    let followerFltr = maxFollowers !== null ? `&min_follow=${minFollowers}&max_follow=${maxFollowers}` : '';
    let priceFltr = maxPrice !== null ? `&min_price=${minPrice}&max_price=${maxPrice}`: '';
    let isSp = false;
    if (speciality === 0 || speciality === null) {  
      isSp = true;
    }
     let query = searchQuery !== "" ? `&search=${searchQuery}` : ''
    let specialityFltr = isSp ? '' : `&category_id=${speciality}`;
    let locationFltr = location === "" ? "" : `&location=${location}`
    this.setState({ appLoader: true });
    this.getCreatorMarketPlaceApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCreatorMarketPlaceEndPoint + "?" + genderfltr + followerFltr + priceFltr + specialityFltr + locationFltr + query,
      type: ""
    });
  };

  getCategories = async () => {
    this.getCategoriesApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.categoryEndpoint,
      type: ""
    });
  };

  getLocationFilterData =async () => {
    this.getLocationDataApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAccountCityEndPoint,
      type: ""
    });
  }

  handleCloseSnack = () => {
    this.setState({
      snackBarStates: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    });
  };

  handleOpenLink = (linkUrl: string | null) => {
    if (linkUrl) {
      const newTab = window.open(linkUrl, "_blank");
      newTab?.focus();
    }
  };

  handleGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ gender: String(event.target.value) }, ()=> {
      this.getAllCreatorData()
    });
  };

  handleFollowerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value = String(event.target.value);
    const selectedRange = this.rangeFollowerFilter.find(range => range.label === value);
    if(selectedRange){
      this.setState({ followers: String(event.target.value) ,minFollowers: selectedRange.minFollowers, maxFollowers: selectedRange.maxFollowers }, ()=>{
        this.getAllCreatorData();
      });
    }
  };

  handleSpecialityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let specialityVal = Number(event.target.value);
      this.setState({ speciality: specialityVal }, () => {
        this.getAllCreatorData()
      });
  };

  handlePriceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let priceVal = String(event.target.value);
    const priceRange = this.rangePriceFilter.find(range => range.label === priceVal);
    if (priceRange) {
      this.setState({ price: String(event.target.value), minPrice: priceRange.minPr, maxPrice: priceRange.maxPr }, () => {
        this.getAllCreatorData()
      });
    }
  };

  handleLocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let locationVal = String(event.target.value);
      this.setState({ location: locationVal }, () => {
        this.getAllCreatorData()
      });
  };

  handleTogglGender = () => {
    this.setState({isGenderOpen: !this.state.isGenderOpen});
  };

  followersCount = (followerCnt: number | string) => {
    let count = Number(followerCnt);
    if (count > 1000) {
      const thousands = count / 1000;
      return thousands.toFixed(1) + 'K';
    }
    return count;
  };

  handleSearch = (event : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchQuery: event.target.value }, ()=> {
      if(this.state.searchQuery === ""){
        this.getAllCreatorData();
      }
    });
    
  };

  handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter"){
      this.getAllCreatorData();
    }
  };
  // Customizable Area End
}