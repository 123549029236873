import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;
const navigation = require('react-navigation')
// Customizable Area End

// Customizable Area Start
export interface StoryData {
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string
    description: string
    location: string | null,
    color: string | null,
    is_active: boolean,
    created_at: string,
    updated_at: string,
    seen: boolean,
    creator_details: {
      id: number,
      full_name: string,
      profile_url: string,
    },
    media: {
      id: number,
      filename: string,
      url: string,
      type: string,
      thumbnail: string | null
    },
    liked: boolean
  }
}
interface CreatorMallAttributes {
  id: number;
  description: null | string;
  name: string;
  state: null | string;
  city: null | string;
  pin_code: null | number;
  country: null | string;
  created_at: string;
  banner: string;
  updated_at: string;
  like_count: number;
  banner_thumbnail: string;
  catalogues: null | Catalogue
}

interface CreatorMallData {
  type: string;
  id: string;
  attributes: CreatorMallAttributes;
}

interface CreatorMallRes {
  data: CreatorMallData;
}

export interface MyMalls {
  id: string;
  attributes: CreatorMallAttributes;
}

interface MyMallResponse {
  data: MyMalls[];
}

interface ProductImages {
  id: string;
  url: string;
}
export interface CatalogueData {
  id: string;
  attributes: {
    title: string;
    description: string;
    mrp: number;
    selling_price: number;
    product_images: ProductImages[];
  }
}

interface Catalogue {
  data: CatalogueData[];
}

interface ErrorItem {
  [key: string]: string;
};

export interface SnackBarP {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
};
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  storyMuted: boolean;
  creatorMallId: string | null;
  isLoadingApp: boolean;
  mallData: CreatorMallData | null;
  myMallData: MyMalls[];
  catalogueData: CatalogueData[];
  snackBarItems: SnackBarP;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatorMallController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCreatorMallApiCallId : string = "";
  getMyMallsListingApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      storyMuted: false,
      creatorMallId: null,
      isLoadingApp: false,
      mallData: null,
      myMallData: [],
      catalogueData: [],
      snackBarItems: {
        isOpen: false,
        isSeverity: 'success',
        isMessage: ""
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (responseJson) {
      if (apiRequestCallId === this.getCreatorMallApiCallId) {
        this.getCreatorMallResponse(responseJson);
      } else if (apiRequestCallId === this.getMyMallsListingApiCallId) {
        this.getMyMallResponse(responseJson)
      }
    }
    // Customizable Area End
  }
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    const creatorToken = await getStorageData("buerLoginToken");
    creatorToken && this.setState({ token: creatorToken });
    const locArray = location.pathname.split("/");
    const mallID = locArray[locArray.length - 1];
    if (locArray.length > 2) {
      this.setState({ creatorMallId: mallID }, () => {
        this.getCreatorMallApi();
        this.getMyMallApi();
      });
    }
  };

  getMyMallApi = async () => {
    this.getMyMallsListingApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getMyMallApiEndPoint,
    });
  };

  getCreatorMallApi = async () => {
    const { creatorMallId } = this.state;
    this.setState({ isLoadingApp: true });
    this.getCreatorMallApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.updateMallApiEndpoint + `/${creatorMallId}`,
    });
  };

  apiCall = async (apiReqData: { contentType?: string; method: string; endPoint: string; body?: object | string; type?: string; }) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    let token = this.state.token;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const apiReqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiReqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiReqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    apiReqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      apiReqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : apiReqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(apiReqMsg.id, apiReqMsg);
    return apiReqMsg.messageId;
  };

  getCreatorMallResponse = (responseJson: CreatorMallRes & { errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      this.setState({ mallData: responseJson.data, isLoadingApp: false });
      if (responseJson.data.attributes.catalogues !== null) {
        this.setState({ catalogueData: responseJson.data.attributes.catalogues.data });
      }
    } else if(responseJson.errors){
      this.handleAllApiErrors(responseJson);
    }

  };

  getMyMallResponse = (responseJson: MyMallResponse & { errors: Array<ErrorItem> }) => {
    if (responseJson.data) {
      this.setState({ myMallData: responseJson.data })
    } else if(responseJson.errors){
      this.handleAllApiErrors(responseJson);
    }
  };

  returnLikeCount = (count: number) => {
    if (count > 1000) {
      return `${count}k`;
    }
    return count;
  };

  truncatedMallName = (mallName: string) => {
    if (mallName.length >= 8) {
      return mallName.substring(0, 8) + "...";
    }
    return mallName
  }

  truncatedProductName = (prodName: string) => {
    if (prodName.length >= 14) {
      return prodName.substring(0, 14) + "...";
    }
    return prodName;
  };

  navigateToFn = (userPath: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), userPath);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  navigateToId = (path: string, MallId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), MallId);
    this.send(message);
  };

  handleCloseSnack = () => {
    this.setState({ snackBarItems: { isOpen: false, isSeverity: "success", isMessage: "" }});
  };

  handleAllApiErrors = (responseJson: { errors: Array<ErrorItem> }) => {
    let error = responseJson.errors[0];
    let errorMessage = error?.token ? error?.token : error?.message;
    this.setState({
      snackBarItems: { isOpen: true, isSeverity: "error", isMessage: errorMessage ? errorMessage : "Something went wrong!" },
      isLoadingApp: false
    });
  };
  // Customizable Area End
}