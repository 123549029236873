import React from "react";
// Customizable Area Start
import { Modal } from "@material-ui/core";
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceOfferedController, { Props, ServiceData } from "./ServiceOfferedController.web";
import Loader from "../../../components/src/Loader.web";
import { editIcon, deleteIcon } from "../../InventoryManagement3/src/assets";
// Customizable Area End


export default class ServiceOffered extends ServiceOfferedController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div service-offered-main-div">
                <Loader loading={this.state.isLoading} />
                <div className="dashboard-outer" data-test-id="creatorProfileMainContainer">
                        <div className="right-outer">
                            <div className="right-container">
                                <div className="title-wrap">
                                    <div className="left-wrap">
                                        <span className="title">Service offered</span>
                                    </div>
                                </div>
                                {
                                    this.state.allServices && this.state.allServices.map((item: ServiceData) => {
                                        return (
                                            <div className="service-card">
                                                <div className="file-edit-wrap">
                                                    <div className="service-card__title">{item.attributes.name}</div>
                                                    {
                                                        !this.state.viewOnlyAccess &&
                                                        <div style={{ display: "flex"}}>
                                                            <img src={editIcon} alt="edit" height={25} data-test-id="editServiceTestId" onClick={() => this.handleEdit(item.id)} />
                                                            <img src={deleteIcon} alt="delete" height={25} style={{ marginLeft: "10px" }} data-test-id="deleteServiceTestId" onClick={() => this.handleDeleteService(item.id)} />
                                                        </div>
                                                    }
                                                </div>
                                                <div style={{padding: "16px"}}>
                                                    <div className="service-card__content">{item.attributes.description}</div>
                                                    <div className="service-card__meta">
                                                        <div className="service-card__action">
                                                            <div>{item.attributes.no_of_post}</div>
                                                            <div>Posts</div>
                                                        </div>
                                                        <div className="service-card__action">
                                                            <div>{item.attributes.no_of_story}</div>
                                                            <div>Stories</div>
                                                        </div>
                                                        <div className="service-card__action">
                                                            <div>{item.attributes.no_of_live_collaboration}</div>
                                                            <div>Life collabs</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {
                                    !this.state.viewOnlyAccess &&
                                    <button
                                        type="button"
                                        data-test-id="createServiceTestId"
                                        className="button-create-service"
                                        disabled={this.state.allServices.length >= 5}
                                        onClick={this.navigateCreateService}
                                    >
                                        Create Service
                                    </button>
                                }

                                <Modal
                                    open={this.state.isDeleteModalOpen}
                                    onClose={this.closeDeleteModal}
                                    className="delete-modal"
                                >
                                    <div className="confirmation-modal">
                                        <h3>Are you sure you want to delete this service?</h3>
                                        <div className="confirmation-buttons">
                                            <button onClick={this.handleDeleteConfirmed} data-test-id="confirmDeleteTestId" className="confirm-btn">Yes, Delete</button>
                                            <button onClick={this.closeDeleteModal} data-test-id="closeModalTestId" className="cancel-btn">Cancel</button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End