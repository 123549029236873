import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { setStorageData } from "../../../framework/src/Utilities";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
const navigation = require('react-navigation');
interface ApiPayloadType{
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
}

interface ProductImages {
    id: string;
    url: string;
}
export interface ProductData {
    id: string;
    attributes: {
        title: string;
        description: string;
        mrp: number;
        selling_price: number;
        product_images: ProductImages[];
    }
}

interface ProductResponse {
    catalogues: {
        data: ProductData[];
    },
    meta: {
        total_pages: number;
        total_counts: number;
    }
}

interface CheckedItems {
    [itemId: string]: boolean;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    handleActiveStep: (id: number) => void;
    classes: Record<string, string>;
    // Customizable Area End
}

// Customizable Area Start
export interface Steps {
    id: string,
    label: string
};
// Customizable Area End

export interface S {
    // Customizable Area Start
    isAddProduct: boolean;
    searchValue: string;
    isKeyPress: boolean;
    mallId : string;
    errorModal: boolean;
    errorMessage: string;
    pageNumber: number;
    pagePerView: number;
    productData : ProductData[];
    totalPages: number;
    selectedProducts : Array<string>;
    checkedItems : CheckedItems;
    isLoadingApp : boolean;
    successDialog: boolean;
    mallProducts : ProductData[];
    copyMallProduct: ProductData[];
    isDataRes: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AddProductController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProductListingApiCallId : string = "";
    patchUpdateMallApiCallId : string = "";
    getCreatorMallDataApiCallId : string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            isAddProduct: false,
            searchValue: "",
            isKeyPress: false,
            mallId: "",
            errorModal: false,
            errorMessage: "",
            pageNumber: 1,
            pagePerView: 10,
            productData: [],
            totalPages: 0,
            selectedProducts:[],
            checkedItems : {},
            isLoadingApp: false,
            successDialog: false,
            mallProducts: [],
            copyMallProduct: [],
            isDataRes: false,
            // Customizable Area End
        };

    }


    // Customizable Area Start    
    async componentDidMount() {
        await setStorageData('activeStep', 1);
        let creatorMallId = await getStorageData("mallId");
        const locArray = location.pathname.split("/");
        const mallID = locArray[locArray.length - 1];
        if (locArray.length > 2) {
            this.setState({  mallId: mallID });
            this.getCreatorMallData();
        } else if (creatorMallId) {
            this.setState({ mallId: creatorMallId });
            this.getCreatorMallData();
        }
        this.getProductListing();
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        if (responseJson) {
            if (apiRequestCallId === this.getProductListingApiCallId) {
                this.getProductListingResponse(responseJson);
            } else if (apiRequestCallId === this.patchUpdateMallApiCallId) {
                this.updateMallResponse(responseJson);
            } else if (apiRequestCallId === this.getCreatorMallDataApiCallId){
                this.creatorMallResponse(responseJson);
            }
        }
        // Customizable Area End
    }

    errorModalToggleClose = () => {
        this.setState({ errorModal: false, errorMessage: '' });
    };

    showError = (errorMessage: string) => {
        this.setState({ errorMessage: errorMessage, errorModal: true });
    };

    getCreatorMallData = async () => {
        const { mallId } = this.state;
        this.setState({ isLoadingApp: true }); 
        this.getCreatorMallDataApiCallId = await this.creatorApiCall({
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.updateMallApiEndpoint + `/${mallId}`,
        });
    };

    creatorMallResponse = (responseJson: { data: { attributes: { catalogues: { data : ProductData[]} } } }) => {
        const checkedItems: CheckedItems = {};
        this.setState({ isDataRes: true, isLoadingApp: false });
        if (responseJson?.data?.attributes?.catalogues !== null) {
            this.setState({ mallProducts: responseJson?.data?.attributes?.catalogues.data, copyMallProduct: responseJson?.data?.attributes?.catalogues.data }, ()=> {
                if(this.state.mallProducts.length > 0){
                    this.state.mallProducts.forEach((item)=>{
                        checkedItems[item.id] = true;
                    });
                    this.setState({checkedItems: checkedItems});
                }
            });
        }
    };

    getProductListing = async () => {
        const { pageNumber, pagePerView, searchValue } = this.state;
        this.getProductListingApiCallId = await this.creatorApiCall({
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getProductListingEndPoint + `?page=${pageNumber}&per_page=${pagePerView}&search=${searchValue}`
        });
    };

    updateMallApi = async () => {
        const { mallId } = this.state;
        this.setState({ isLoadingApp: true });
        const formData = new FormData();
        this.state.selectedProducts.forEach((prodId) => {
            formData.append("[data][attributes][catalogue_ids][]", prodId);
        })
        this.patchUpdateMallApiCallId = await this.creatorApiCall({
            method: configJSON.patchMethodType,
            endPoint: configJSON.updateMallApiEndpoint + `/${mallId}`,
            type: "formData",
            body: formData,
        });
    };

    updateMallResponse = (responseJson: { data: {} }) => {
        if (responseJson.data) {
            this.setState({ isLoadingApp: false, successDialog: true });
        } else {
            this.showError("Something went wrong!")
        }
    };

    saveProduct = () => {
        const { checkedItems } = this.state;
        const selectedIds: string[] = Object.keys(checkedItems).filter((key) => checkedItems[key]);
        this.setState({ selectedProducts: selectedIds, isAddProduct: false }, () => {
            this.updateMallApi();
        });
    };

    getProductListingResponse = (responseJson: ProductResponse & { errors: { message : string} }) => {
        if (responseJson?.catalogues?.data) {
            this.setState({ productData: responseJson.catalogues.data, totalPages: responseJson?.meta?.total_pages });
        } else if(responseJson.errors){
            this.setState({ productData: [], totalPages: 0 });
        }
    };

    creatorApiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const apiReqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        apiReqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiReqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        apiReqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        body && type != 'formData' ?
            apiReqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : apiReqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(apiReqMsg.id, apiReqMsg);
        return apiReqMsg.messageId;
    };

    handleSelectProd = (event: React.ChangeEvent<HTMLInputElement>, product: ProductData, catalogueId: string) => {
        const { copyMallProduct } = this.state;
        const productExists = copyMallProduct?.some(product => product.id === catalogueId);
        let productObject: ProductData[] = [{
            id: catalogueId,
            attributes: {
                title: product.attributes.title,
                description: product.attributes.description,
                mrp: product.attributes.mrp,
                selling_price: product.attributes.selling_price,
                product_images: [
                    {
                        id: product.attributes.product_images[0].id,
                        url: product.attributes.product_images[0].url
                    }
                ]
            }
        }];
        const { checkedItems } = this.state;
        if(productExists){
            let newProd = copyMallProduct.filter(product => product.id !== catalogueId);
            this.setState({
                checkedItems: {
                    ...checkedItems,
                    [catalogueId]: event.target.checked,
                },
                copyMallProduct: newProd,
            });
        }else{
            this.setState({
                checkedItems: {
                    ...checkedItems,
                    [catalogueId]: event.target.checked,
                },
                copyMallProduct: [...this.state.copyMallProduct, ...productObject]
            });
        }
    };

    handleAddSaveModal = () => {
        this.setState({ isAddProduct: !this.state.isAddProduct, mallProducts: [...new Set(this.state.copyMallProduct)] });
    };

    handleRemoveProduct = (mallId: string) => {
        this.setState((prevState) => ({
            copyMallProduct: prevState.copyMallProduct?.filter((product) => product.id !== mallId),
            mallProducts: prevState.mallProducts?.filter((product) => product.id !== mallId),
            checkedItems: {
                ...prevState.checkedItems,
                [mallId]: false, 
            }
          }));
    };

    handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({ searchValue: event.target.value})
        if(event.target.value === ""){
            this.getProductListing();
        }
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.setState({ isKeyPress: false }, () =>{
                this.getProductListing();
            });
        }
    };

    handleAddProductDialog = () => {
        this.setState({ isAddProduct: !this.state.isAddProduct, mallProducts: [...new Set(this.state.copyMallProduct)] });
    };

    handleSuccessDialogBox = () => {
        this.navigateToPath('CreatorProfile')
    }

    handleChangePagiation = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ pageNumber: value }, () => {
            this.getProductListing();
        });
    };

    navigateToPath = (destinationPath: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), `${destinationPath}`);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    // Customizable Area End
}