import React from "react";
// Customizable Area Start
import CreatePostOrStoryController from "./CreatePostOrStoryController";
// Customizable Area End


export default class CreatePostOrStory extends CreatePostOrStoryController {
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                Create Post Or Story
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
